//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/users/login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/admin/products/getAllProducts"
export const GET_PRODUCTS_VARIANTS = "/admin/products/getProductVariants"
export const GET_PRODUCTS_DETAIL = "/admin/products/getProductDetail"
export const ADD_PRODUCT = "/admin/products/product/add"
export const UPDATE_PRODUCT = "/admin/products/product/update"
export const DELETE_PRODUCT = "/admin/products/product/delete"
export const ADD_VARIANT = "/admin/products/productVariantAdd"
export const UPDATE_VARIANT = "admin/products/productVariantUpdate"
export const DELETE_VARIANT = "/admin/products/product/deleteProductVariant"
export const DELETE_IMAGE = "/admin/products/product/deleteImage"
export const ADD_RELATED_PRODUCT = "/admin/products/addRelatedProduct"
export const DELETE_RELATED_PRODUCT = "/admin/products/deleteRelatedProduct"

//CATEGORIES
export const GET_CATEGORIES = "/admin/catalog/getCategoryList"
export const GET_CATEGORIES_DETAIL = "/category"
export const ADD_CATEGORY = "/admin/catalog/category/add"
export const UPDATE_CATEGORY = "/admin/catalog/category/update"
export const DELETE_CATEGORY = "/admin/catalog/category/delete"

//ATTRIBUTES
export const GET_ATTRIBUTES = "/admin/catalog/getAttributeList"
export const ADD_ATTRIBUTE = "/admin/catalog/attribute/add"
export const UPDATE_ATTRIBUTE = "/admin/catalog/attribute/update"
export const DELETE_ATTRIBUTE = "/admin/catalog/attribute/delete"

//INFOSERVICE
export const GET_POSTS = "/admin/posts/getAllAdminPosts"
export const ADD_POST = "/admin/posts/post/add"
export const UPDATE_POST = "/admin/posts/post/update"
export const DELETE_POST = "/admin/posts/post/delete"
export const GET_IS_CATEGORIES = "/admin/posts/getAllAdminCategories"
export const ADD_IS_CATEGORY = "/admin/posts/category/add"
export const UPDATE_IS_CATEGORY = "/admin/posts/category/update"
export const DELETE_IS_CATEGORY = "/admin/posts/category/delete"

//CMS
export const GET_CMS_POSTS = "/admin/cms/posts/getAllAdminPosts"
export const ADD_CMS_POST = "/admin/cms/posts/add"
export const UPDATE_CMS_POST = "/admin/cms/posts/update"
export const DELETE_CMS_POST = "/admin/cms/posts/delete"
export const GET_CMS_CATEGORIES = "/admin/cms/posts/getAllAdminCategories"
export const ADD_CMS_CATEGORY = "/admin/cms/posts/category/add"
export const UPDATE_CMS_CATEGORY = "/admin/cms/posts/category/update"
export const DELETE_CMS_CATEGORY = "/admin/cms/posts/category/delete"

export const GET_CMS_PAGES = "/admin/cms/pages/getAllAdminPages"
export const ADD_CMS_PAGE = "/admin/cms/pages/add"
export const UPDATE_CMS_PAGE = "/admin/cms/pages/update"
export const DELETE_CMS_PAGE = "/admin/cms/pages/delete"

export const GET_CMS_MENU = "/admin/cms/menu/getAllAdminMenu"
export const ADD_CMS_MENU = "/admin/cms/menu/add"
export const UPDATE_CMS_MENU = "/admin/cms/menu/update"
export const DELETE_CMS_MENU = "/admin/cms/menu/delete"

export const GET_CMS_GALLERIES = "/admin/cms/galleries/getAllAdminGalleries"
export const ADD_CMS_GALLERY = "/admin/cms/galleries/gallery/add"
export const UPDATE_CMS_GALLERY = "/admin/cms/galleries/gallery/update"
export const DELETE_CMS_GALLERY = "/admin/cms/galleries/gallery/delete"
export const GET_CMS_GALLERY_ITEMS =
  "/admin/cms/galleries/getAllAdminGalleryItems"
export const ADD_CMS_GALLERY_ITEM = "/admin/cms/galleries/item/add"
export const UPDATE_CMS_GALLERY_ITEM = "/admin/cms/galleries/item/update"
export const DELETE_CMS_GALLERY_ITEM = "/admin/cms/galleries/item/delete"

export const GET_CMS_SLIDERS = "/admin/cms/sliders/getAllAdminSliders"
export const ADD_CMS_SLIDER = "/admin/cms/sliders/add"
export const UPDATE_CMS_SLIDER = "/admin/cms/sliders/update"
export const DELETE_CMS_SLIDER = "/admin/cms/sliders/delete"
export const GET_CMS_SLIDER_ITEMS = "/admin/cms/sliders/getAllAdminSliderItems"
export const ADD_CMS_SLIDER_ITEM = "/admin/cms/sliders/item/add"
export const UPDATE_CMS_SLIDER_ITEM = "/admin/cms/sliders/item/update"
export const DELETE_CMS_SLIDER_ITEM = "/admin/cms/sliders/item/delete"

export const GET_CMS_FAQS = "/admin/cms/faqs/getAllAdminFaqs"
export const ADD_CMS_FAQ = "/admin/cms/faqs/add"
export const UPDATE_CMS_FAQ = "/admin/cms/faqs/update"
export const DELETE_CMS_FAQ = "/admin/cms/faqs/delete"
export const GET_CMS_FAQ_CATEGORIES = "/admin/cms/faqs/getAllAdminFaqItems"
export const ADD_CMS_FAQ_CATEGORY = "/admin/cms/faqs/item/add"
export const UPDATE_CMS_FAQ_CATEGORY = "/admin/cms/faqs/item/update"
export const DELETE_CMS_FAQ_CATEGORY = "/admin/cms/faqs/item/delete"

//PRICES
export const GET_PRICES = "/admin/catalog/getPrices"
export const UPDATE_PRICE = "/admin/catalog/price/update"

//UNITS
export const GET_UNITS = "/admin/catalog/getPackageList"
export const ADD_UNIT = "/admin/catalog/package/add"
export const UPDATE_UNIT = "/admin/catalog/package/update"
export const DELETE_UNIT = "/admin/catalog/package/delete"

//RETURN PACKAGES
export const GET_RETURN_PACKAGES = "/admin/catalog/getReturnPackageList"
export const ADD_RETURN_PACKAGE = "/admin/catalog/returnPackage/add"
export const UPDATE_RETURN_PACKAGE = "/admin/catalog/returnPackage/update"
export const DELETE_RETURN_PACKAGE = "/admin/catalog/returnPackage/delete"

//FEATURES
export const GET_FEATURES = "/admin/catalog/getFeatureList"
export const ADD_FEATURE = "/admin/catalog/feature/add"
export const UPDATE_FEATURE = "/admin/catalog/feature/update"
export const DELETE_FEATURE = "/admin/catalog/feature/delete"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/admin/orders/getAllOrders"
export const ADD_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "admin/orders/delete"

//PAYMENTS
export const GET_PAYMENTS = "/admin/orders/getAdminPaymentTransactions"

//REPORTS
export const GET_REPORTS = "/admin/orders/getProvidersSallaryReport"
export const GET_REPORT = "/admin/orders/getProviderSallaryReport"

//PARTNER PROGRAM
export const GET_PP_PRODUCTS = "/admin/pp/getAllPProducts"
export const ADD_PP_PRODUCT = "/admin/pp/addPProduct"
export const UPDATE_PP_PRODUCT = "/admin/pp/updatePProduct"
export const DELETE_PP_PRODUCT = "/admin/pp/deletePProduct"
export const GET_PP_CATEGORIES = "/admin/pp/getAllPPCategories"
export const ADD_PP_CATEGORY = "/admin/pp/addPPCategory"
export const UPDATE_PP_CATEGORY = "/admin/pp/updatePPCategory"
export const DELETE_PP_CATEGORY = "/admin/pp/deletePPCategory"

//LOGS
export const GET_LOGS = "/admin/logs/getAllLogs"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//USERS
export const GET_USERS = "/admin/users/getAllUsers"
export const GET_USERS_INACTIVE = "/admin/users/getInactiveUsers"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "admin/users/add"
export const UPDATE_USER = "admin/users/update"
export const DELETE_USER = "admin/users/delete"
export const GET_MANUFACTURERS = "/admin/users/getManufacturers"

//BRANCHES
export const GET_BRANCHES = "/admin/branches/getAllBranches"
export const GET_BRANCH_PROFILE = "/admin/branches/branch/profile"
export const ADD_BRANCH = "/admin/branches/branch/add"
export const UPDATE_BRANCH = "/admin/branches/branch/update"
export const DELETE_BRANCH = "/admin/branches/branch/delete"

//NOTIFICATIONS
export const GET_SMS_NOTIFICATIONS = "/admin/notifications/getSmsNotifications"
export const UPDATE_SMS_NOTIFICATIONS =
  "/admin/notifications/updateSmsNotification"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

//DELIVERY FORM

export const DELIVERY_FORMS = "/admin/deliveryForms"
