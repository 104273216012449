import {
  GET_DELIVERY_FORM_PRICES_SUCCESS,
  GET_DELIVERY_FORM_PRICES_FAIL,
  ADD_DELIVERY_FORM_PRICE_SUCCESS,
  ADD_DELIVERY_FORM_PRICE_FAIL,
  UPDATE_DELIVERY_FORM_PRICE_SUCCESS,
  UPDATE_DELIVERY_FORM_PRICE_FAIL,
  DELETE_DELIVERY_FORM_PRICE_SUCCESS,
  DELETE_DELIVERY_FORM_PRICE_FAIL,
  HIDE_NOTIFICATION,
} from "./actionTypes"

const INIT_STATE = {
  dataDeliveryFormPrices: {
    success: {
      open: false,
      text: "",
    },
    error: {
      open: false,
      text: "",
    },
  },
}

const deliveryFormPrices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERY_FORM_PRICES_SUCCESS:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          [action.payload.deliveryForm.id.toString()]: {
            deliveryFormPrices: action.payload.deliveryPrices,
            deliveryForm: action.payload.deliveryForm,
          },
          success: {
            open: true,
            text: action.payload.deliveryPrices.length
              ? "Ceny dla wybranej formy dostawy zostały pobrane."
              : "Brak cen dla wybranej formy dostawy.",
          },
        },
      }

    case GET_DELIVERY_FORM_PRICES_FAIL:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          error: {
            open: true,
            text: "Podczas pobierania form dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case ADD_DELIVERY_FORM_PRICE_SUCCESS:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          [action.payload.delivery_form_id.toString()]: {
            ...state.dataDeliveryFormPrices[
              action.payload.delivery_form_id.toString()
            ],
            deliveryFormPrices: [
              ...state.dataDeliveryFormPrices[
                action.payload.delivery_form_id.toString()
              ].deliveryFormPrices,
              action.payload,
            ],
          },
          success: {
            open: true,
            text: "Forma dostawy została dodana.",
          },
        },
      }

    case ADD_DELIVERY_FORM_PRICE_FAIL:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          error: {
            open: true,
            text: "Podczas dodawania formy dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case UPDATE_DELIVERY_FORM_PRICE_SUCCESS:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          [action.payload.delivery_form_id.toString()]: {
            ...state.dataDeliveryFormPrices[
              action.payload.delivery_form_id.toString()
            ],
            deliveryFormPrices: state.dataDeliveryFormPrices[
              action.payload.delivery_form_id.toString()
            ].deliveryFormPrices.map(deliveryForm =>
              deliveryForm.id.toString() === action.payload.id.toString()
                ? { ...deliveryForm, ...action.payload }
                : deliveryForm
            ),
          },
          success: {
            open: true,
            text: "Forma dostawy została zaktualizowana.",
          },
        },
      }

    case UPDATE_DELIVERY_FORM_PRICE_FAIL:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          error: {
            open: true,
            text: "Podczas aktualizacji formy dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case DELETE_DELIVERY_FORM_PRICE_SUCCESS:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          [action.payload.deliveryForm.toString()]: {
            ...state.dataDeliveryFormPrices[
              action.payload.deliveryForm.toString()
            ],
            deliveryFormPrices: state.dataDeliveryFormPrices[
              action.payload.deliveryForm.toString()
            ].deliveryFormPrices.filter(
              deliveryForm =>
                deliveryForm.id.toString() !== action.payload.priceId.toString()
            ),
          },
          success: {
            open: true,
            text: "Forma dostawy została usunięta.",
          },
        },
      }

    case DELETE_DELIVERY_FORM_PRICE_FAIL:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          error: {
            open: true,
            text: "Podczas usuwania formy dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case HIDE_NOTIFICATION:
      return {
        ...state,
        dataDeliveryFormPrices: {
          ...state.dataDeliveryFormPrices,
          success: {
            open: false,
            text: "",
          },
          error: {
            open: false,
            text: "",
          },
        },
      }

    default:
      return state
  }
}

export default deliveryFormPrices
