import {
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  ADD_POST,
  ADD_POST_SUCCESS,
  ADD_POST_FAIL,
  UPDATE_POST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAIL,
  GET_IS_CATEGORIES,
  GET_IS_CATEGORIES_SUCCESS,
  GET_IS_CATEGORIES_FAIL,
  ADD_IS_CATEGORY,
  ADD_IS_CATEGORY_SUCCESS,
  ADD_IS_CATEGORY_FAIL,
  UPDATE_IS_CATEGORY,
  UPDATE_IS_CATEGORY_SUCCESS,
  UPDATE_IS_CATEGORY_FAIL,
  DELETE_IS_CATEGORY,
  DELETE_IS_CATEGORY_SUCCESS,
  DELETE_IS_CATEGORY_FAIL
} from "./actionTypes";

//Posts

export const getPosts = () => ({
  type: GET_POSTS,
})

export const getPostsSuccess = posts => ({
  type: GET_POSTS_SUCCESS,
  payload: posts,
})

export const getPostsFail = error => ({
  type: GET_POSTS_FAIL,
  payload: error,
})

export const addPost = post => ({
  type: ADD_POST,
  payload: post,
})

export const addPostSuccess = post => ({
  type: ADD_POST_SUCCESS,
  payload: post,
})

export const addPostFail = error => ({
  type: ADD_POST_FAIL,
  payload: error,
})

export const updatePost = post => ({
  type: UPDATE_POST,
  payload: post,
})

export const updatePostSuccess = post => ({
  type: UPDATE_POST_SUCCESS,
  payload: post,
})

export const updatePostFail = error => ({
  type: UPDATE_POST_FAIL,
  payload: error,
})

export const deletePost = post => ({
  type: DELETE_POST,
  payload: post,
})

export const deletePostSuccess = post => ({
  type: DELETE_POST_SUCCESS,
  payload: post,
})

export const deletePostFail = error => ({
  type: DELETE_POST_FAIL,
  payload: error,
})

//Categories

export const getISCategories = () => ({
  type: GET_IS_CATEGORIES,
})

export const getISCategoriesSuccess = categories => ({
  type: GET_IS_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getISCategoriesFail = error => ({
  type: GET_IS_CATEGORIES_FAIL,
  payload: error,
})

export const addISCategory = category => ({
  type: ADD_IS_CATEGORY,
  payload: category,
})

export const addISCategorySuccess = category => ({
  type: ADD_IS_CATEGORY_SUCCESS,
  payload: category,
})

export const addISCategoryFail = error => ({
  type: ADD_IS_CATEGORY_FAIL,
  payload: error,
})

export const updateISCategory = category => ({
  type: UPDATE_IS_CATEGORY,
  payload: category,
})

export const updateISCategorySuccess = category => ({
  type: UPDATE_IS_CATEGORY_SUCCESS,
  payload: category,
})

export const updateISCategoryFail = error => ({
  type: UPDATE_IS_CATEGORY_FAIL,
  payload: error,
})

export const deleteISCategory = category => ({
  type: DELETE_IS_CATEGORY,
  payload: category,
})

export const deleteISCategorySuccess = category => ({
  type: DELETE_IS_CATEGORY_SUCCESS,
  payload: category,
})

export const deleteISCategoryFail = error => ({
  type: DELETE_IS_CATEGORY_FAIL,
  payload: error,
})