import {
  GET_DELIVERY_FORMS,
  GET_DELIVERY_FORMS_SUCCESS,
  GET_DELIVERY_FORMS_FAIL,
  ADD_DELIVERY_FORM,
  ADD_DELIVERY_FORM_SUCCESS,
  ADD_DELIVERY_FORM_FAIL,
  UPDATE_DELIVERY_FORM,
  UPDATE_DELIVERY_FORM_SUCCESS,
  UPDATE_DELIVERY_FORM_FAIL,
  DELETE_DELIVERY_FORM,
  DELETE_DELIVERY_FORM_SUCCESS,
  DELETE_DELIVERY_FORM_FAIL,
  HIDE_NOTIFICATION,
} from "./actionTypes"

export const getDeliveryForms = () => ({
  type: GET_DELIVERY_FORMS,
})

export const getDeliveryFormsSuccess = deliveryForms => ({
  type: GET_DELIVERY_FORMS_SUCCESS,
  payload: deliveryForms,
})

export const getDeliveryFormsFail = error => ({
  type: GET_DELIVERY_FORMS_FAIL,
  payload: error,
})

export const addDeliveryForm = deliveryForm => ({
  type: ADD_DELIVERY_FORM,
  payload: deliveryForm,
})

export const addDeliveryFormSuccess = deliveryForm => ({
  type: ADD_DELIVERY_FORM_SUCCESS,
  payload: deliveryForm,
})

export const addDeliveryFormFail = error => ({
  type: ADD_DELIVERY_FORM_FAIL,
  payload: error,
})

export const updateDeliveryForm = deliveryForm => ({
  type: UPDATE_DELIVERY_FORM,
  payload: deliveryForm,
})

export const updateDeliveryFormSuccess = deliveryForm => ({
  type: UPDATE_DELIVERY_FORM_SUCCESS,
  payload: deliveryForm,
})

export const updateDeliveryFormFail = error => ({
  type: UPDATE_DELIVERY_FORM_FAIL,
  payload: error,
})

export const deleteDeliveryForm = id => ({
  type: DELETE_DELIVERY_FORM,
  payload: id,
})

export const deleteDeliveryFormSuccess = deliveryForm => ({
  type: DELETE_DELIVERY_FORM_SUCCESS,
  payload: deliveryForm,
})

export const deleteDeliveryFormFail = error => ({
  type: DELETE_DELIVERY_FORM_FAIL,
  payload: error,
})

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
})
