/* ATTRIBUTES */
export const GET_ATTRIBUTES = "GET_ATTRIBUTES"
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS"
export const GET_ATTRIBUTES_FAIL = "GET_ATTRIBUTES_FAIL"
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE"
export const ADD_ATTRIBUTE_SUCCESS = "ADD_ATTRIBUTE_SUCCESS"
export const ADD_ATTRIBUTE_FAIL = "ADD_ATTRIBUTE_FAIL"
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE"
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS"
export const UPDATE_ATTRIBUTE_FAIL = "UPDATE_ATTRIBUTE_FAIL"
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE"
export const DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS"
export const DELETE_ATTRIBUTE_FAIL = "DELETE_ATTRIBUTE_FAIL"
export const HIDE_ATTRIBUTE_SUCCESS = "HIDE_ATTRIBUTE_SUCCESS"
export const HIDE_ATTRIBUTE_FAIL = "HIDE_ATTRIBUTE_FAIL"

/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"
export const HIDE_CATEGORY_SUCCESS = "HIDE_CATEGORY_SUCCESS"
export const HIDE_CATEGORY_FAIL = "HIDE_CATEGORY_FAIL"

/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"
export const DELETE_IMAGE = "DELETE_IMAGE"
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS"
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL"
export const GET_RELATED = "GET_RELATED"
export const ADD_RELATED_PRODUCT = "ADD_RELATED_PRODUCT"
export const ADD_RELATED_PRODUCT_SUCCESS = "ADD_RELATED_PRODUCT_SUCCESS"
export const ADD_RELATED_PRODUCT_FAIL = "ADD_RELATED_PRODUCT_FAIL"
export const HIDE_RELATED_SUCCESS = "HIDE_RELATED_SUCCESS"
export const HIDE_RELATED_FAIL = "HIDE_RELATED_FAIL"
export const DELETE_RELATED_PRODUCT = "DELETE_RELATED_PRODUCT"
export const DELETE_RELATED_PRODUCT_SUCCESS = "DELETE_RELATED_PRODUCT_SUCCESS"
export const DELETE_RELATED_PRODUCT_FAIL = "DELETE_RELATED_PRODUCT_FAIL"
export const HIDE_PRODUCT_SUCCESS = "HIDE_PRODUCT_SUCCESS"
export const HIDE_PRODUCT_FAIL = "HIDE_PRODUCT_FAIL"
export const CLEARING_PRODUCT_DATA = "CLEARING_PRODUCT_DATA"

/* VARIANTS */
export const GET_PRODUCTS_VARIANTS = "GET_PRODUCTS_VARIANTS"
export const GET_PRODUCTS_VARIANTS_SUCCESS = "GET_PRODUCTS_VARIANTS_SUCCESS"
export const GET_PRODUCTS_VARIANTS_FAIL = "GET_PRODUCTS_VARIANTS_FAIL"
export const ADD_VARIANT = "ADD_VARIANT"
export const ADD_VARIANT_SUCCESS = "ADD_VARIANT_SUCCESS"
export const ADD_VARIANT_FAIL = "ADD_VARIANT_FAIL"
export const UPDATE_VARIANT = "UPDATE_VARIANT"
export const UPDATE_VARIANT_SUCCESS = "UPDATE_VARIANT_SUCCESS"
export const UPDATE_VARIANT_FAIL = "UPDATE_VARIANT_FAIL"
export const DELETE_VARIANT = "DELETE_VARIANT"
export const DELETE_VARIANT_SUCCESS = "DELETE_VARIANT_SUCCESS"
export const DELETE_VARIANT_FAIL = "DELETE_VARIANT_FAIL"
export const ADD_CREATED_VARIANTS = "ADD_CREATED_VARIANTS"
export const ADD_CREATED_VARIANTS_SUCCESS = "ADD_CREATED_VARIANTS_SUCCESS"
export const ADD_CREATED_VARIANTS_FAIL = "ADD_CREATED_VARIANTS_FAIL"
export const DELETE_CREATED_VARIANT = "DELETE_CREATED_VARIANT"
export const DELETE_CREATED_VARIANT_SUCCESS = "DELETE_CREATED_VARIANT_SUCCESS"
export const DELETE_CREATED_VARIANT_FAIL = "DELETE_CREATED_VARIANT_FAIL"
export const HIDE_VARIANT_SUCCESS = "HIDE_VARIANT_SUCCESS"
export const HIDE_VARIANT_FAIL = "HIDE_VARIANT_FAIL"
export const HIDE_CREATED_VARIANT_SUCCESS = "HIDE_CREATED_VARIANT_SUCCESS"
export const HIDE_CREATED_VARIANT_FAIL = "HIDE_CREATED_VARIANT_FAIL"

/* UNITS */
export const GET_UNITS = "GET_UNITS"
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS"
export const GET_UNITS_FAIL = "GET_UNITS_FAIL"
export const ADD_UNIT = "ADD_UNIT"
export const ADD_UNIT_SUCCESS = "ADD_UNIT_SUCCESS"
export const ADD_UNIT_FAIL = "ADD_UNIT_FAIL"
export const UPDATE_UNIT = "UPDATE_UNIT"
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS"
export const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL"
export const DELETE_UNIT = "DELETE_UNIT"
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS"
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL"
export const HIDE_UNIT_SUCCESS = "HIDE_UNIT_SUCCESS"
export const HIDE_UNIT_FAIL = "HIDE_UNIT_FAIL"

/* RETURN PACKAGES */
export const GET_RETURN_PACKAGES = "GET_RETURN_PACKAGES"
export const GET_RETURN_PACKAGES_SUCCESS = "GET_RETURN_PACKAGES_SUCCESS"
export const GET_RETURN_PACKAGES_FAIL = "GET_RETURN_PACKAGES_FAIL"
export const ADD_RETURN_PACKAGE = "ADD_RETURN_PACKAGE"
export const ADD_RETURN_PACKAGE_SUCCESS = "ADD_RETURN_PACKAGE_SUCCESS"
export const ADD_RETURN_PACKAGE_FAIL = "ADD_RETURN_PACKAGE_FAIL"
export const UPDATE_RETURN_PACKAGE = "UPDATE_RETURN_PACKAGE"
export const UPDATE_RETURN_PACKAGE_SUCCESS = "UPDATE_RETURN_PACKAGE_SUCCESS"
export const UPDATE_RETURN_PACKAGE_FAIL = "UPDATE_RETURN_PACKAGE_FAIL"
export const DELETE_RETURN_PACKAGE = "DELETE_RETURN_PACKAGE"
export const DELETE_RETURN_PACKAGE_SUCCESS = "DELETE_RETURN_PACKAGE_SUCCESS"
export const DELETE_RETURN_PACKAGE_FAIL = "DELETE_RETURN_PACKAGE_FAIL"
export const HIDE_RETURN_PACKAGES_SUCCESS = "HIDE_RETURN_PACKAGES_SUCCESS"
export const HIDE_RETURN_PACKAGES_FAIL = "HIDE_RETURN_PACKAGES_FAIL"

/* FEATURES */
export const GET_FEATURES = "GET_FEATURES"
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS"
export const GET_FEATURES_FAIL = "GET_PFEATURES_FAIL"
export const ADD_FEATURE = "ADD_FEATURE"
export const ADD_FEATURE_SUCCESS = "ADD_FEATURE_SUCCESS"
export const ADD_FEATURE_FAIL = "ADD_FEATURE_FAIL"
export const UPDATE_FEATURE = "UPDATE_FEATURE"
export const UPDATE_FEATURE_SUCCESS = "UPDATE_FEATURE_SUCCESS"
export const UPDATE_FEATURE_FAIL = "UPDATE_FEATURE_FAIL"
export const DELETE_FEATURE = "DELETE_FEATURE"
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS"
export const DELETE_FEATURE_FAIL = "DELETE_FEATURE_FAIL"
export const HIDE_FEATURE_SUCCESS = "HIDE_FEATURE_SUCCESS"
export const HIDE_FEATURE_FAIL = "HIDE_FEATURE_FAIL"

/* PRICES */
export const GET_PRICES = "GET_PRICES"
export const GET_PRICES_SUCCESS = "GET_PRICES_SUCCESS"
export const GET_PRICES_FAIL = "GET_PRICES_FAIL"
export const UPDATE_PRICE = "UPDATE_PRICE"
export const UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS"
export const UPDATE_PRICE_FAIL = "UPDATE_PRICE_FAIL"
export const HIDE_PRICES_SUCCESS = "HIDE_PRICES_SUCCESS"
export const HIDE_PRICES_FAIL = "HIDE_PRICES_FAIL"
export const CLEARING_PRICE_DATA = "CLEARING_PRICE_DATA"