import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Button,
  Badge,
  Spinner
} from "reactstrap"
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getPPCategories as onGetPPCategories,
  getPPProducts as onGetPPProducts,
  addPPProduct as onAddPPProduct,
  updatePPProduct as onUpdatePPProduct,
  deletePPProduct as onDeletePPProduct,
} from "store/partnerprogram/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const PPProducts = props => {
  //meta title
  document.title = "Produkty - Program Partnerski - Panel administracyjny";
  
  const dispatch = useDispatch();

  const [product, setProduct] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pp_product_id: (product && product.pp_product_id) || '',
      pp_category_id: (product && product.pp_category_id) || 1,
      pp_product_name: (product && product.pp_product_name) || '',
      pp_product_description: (product && product.pp_product_description) || '',
      pp_product_image: (product && product.pp_product_image) || '',
      pp_product_price: (product && product.pp_product_price) || '',
      pp_product_old_price: (product && product.pp_product_old_price) || '',
      pp_product_points: (product && product.pp_product_points) || '',
      pp_product_quantity: (product && product.pp_product_quantity) || '',
      pp_product_active: (product && product.pp_product_active) || 0,
    },
    validationSchema: Yup.object({
      pp_product_name: Yup.string().required("To pole jest wymagane"),
      pp_product_quantity: Yup.string().required("To pole jest wymagane"),
      pp_product_points: Yup.string().required("To pole jest wymagane"),
      pp_product_old_price: Yup.string().required("To pole jest wymagane"),
      pp_product_price: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateProduct = {
          pp_product_id: product ? product.pp_product_id : 0,
          pp_category_id: values.pp_category_id,
          pp_product_name: values.pp_product_name,
          pp_product_description: values.pp_product_description,
          pp_product_image: values.pp_product_image,
          pp_product_price: values.pp_product_price,
          pp_product_old_price: values.pp_product_old_price,
          pp_product_points: values.pp_product_points,
          pp_product_quantity: values.pp_product_quantity,
          pp_product_active: values.pp_product_active
        }
        // update product
        dispatch(onUpdatePPProduct(updateProduct));
        validation.resetForm();
      } else {
        const addProduct = {
          pp_category_id: values["pp_category_id"],
          pp_product_name: values["pp_product_name"],
          pp_product_description: values["pp_product_description"],
          pp_product_image: values["pp_product_image"],
          pp_product_price: values["pp_product_price"],
          pp_product_old_price: values["pp_product_old_price"],
          pp_product_points: values["pp_product_points"],
          pp_product_quantity: values["pp_product_quantity"],
          pp_product_active: values["pp_product_active"]
        }
        // add product
        dispatch(onAddPPProduct(addProduct));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { products } = useSelector(state => ({
    products: state.partnerprogram.dataProducts.products,
  }));

  const { success } = useSelector(state => ({
    success: state.partnerprogram.dataProducts.success,
  }));

  const { error } = useSelector(state => ({
    error: state.partnerprogram.dataProducts.error,
  }));

  const { categories } = useSelector(state => ({
    categories: state.partnerprogram.dataCategories.categories,
  }));

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "pp_product_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "pp_product_image",
      text: "Zdjęcie",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={"https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"} 
                width="50" 
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={"https://budujpl.s3.eu-north-1.amazonaws.com/uploads/partnerProgram/products/50x50/" + cellContent}
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "pp_product_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "category",
      text: "Kategoria",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {cellContent.pp_category_name}
        </React.Fragment>
      ),
    },
    {
      dataField: "pp_product_active",
      text: "Aktywny",
      sort: true,
      formatter: cellContent => 
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, product) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleProductClick(product)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(product)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (products && !products.length) {
      dispatch(onGetPPProducts());
    }
  }, [dispatch, products]);

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetPPCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    setProduct(products);
  }, [products]);

  useEffect(() => {
    if (!isEmpty(products) && !!isEdit) {
      setProduct(products);
      setIsEdit(false);
    }
  }, [products]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PP_PRODUCT_SUCCESS",
        });
      }, 5000)
      
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PP_PRODUCT_FAIL",
        });
      }, 5000)
      
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleProductClick = arg => {
    const product = arg;

    setProduct({
      pp_product_id: product.pp_product_id,
      pp_category_id: product.pp_category_id,
      pp_product_name: product.pp_product_name,
      pp_product_description: product.pp_product_description,
      pp_product_image: product.pp_product_image,
      pp_product_price: product.pp_product_price,
      pp_product_old_price: product.pp_product_old_price,
      pp_product_points: product.pp_product_points,
      pp_product_quantity: product.pp_product_quantity,
      pp_product_active: product.pp_product_active,
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (product) => {
    setProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (product.pp_product_id) {
      dispatch(onDeletePPProduct(product));
      setDeleteModal(false);
    }
  };

  const handleProductClicks = () => {
    setIsEdit(false);
    toggle();
    setProduct(products);
  };

  const defaultSorted = [
    {
      dataField: "pp_product_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  );

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteProduct}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Program partnerski" breadcrumbItem="Produkty" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="pp_product_id"
                      data={products}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleProductClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj produkt
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edycja produktu" : "Dodaj produkt"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "1",
                                              })}
                                              onClick={() => {
                                                toggleCustom("1");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Dane produktu</span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "2",
                                              })}
                                              onClick={() => {
                                                toggleCustom("2");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Cena</span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "3",
                                              })}
                                              onClick={() => {
                                                toggleCustom("3");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Zdjęcie</span>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>

                                        <TabContent
                                          activeTab={customActiveTab}
                                          className="p-3 text-muted"
                                        >
                                          <TabPane tabId="1">
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Nazwa</Label>
                                                <Input
                                                  name="pp_product_name"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_product_name}
                                                  invalid={
                                                    validation.touched.pp_product_name && validation.errors.pp_product_name ? true : false
                                                  }
                                                />
                                                {validation.touched.pp_product_name && validation.errors.pp_product_name ? (
                                                  <FormFeedback type="invalid">{validation.errors.pp_product_name}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Kategoria</Label>
                                                <Input
                                                  type="select"
                                                  name="pp_category_id"
                                                  className="form-select"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_category_id}
                                                >
                                                  <option value="">-- Wybierz --</option>
                                                  {categories.map((category, index) => (
                                                    <option 
                                                      value={category.pp_category_id} 
                                                      key={category.pp_category_id}
                                                      >{category.pp_category_name}
                                                    </option>
                                                  ))}
                                                </Input>
                                              </Col>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Opis</Label>
                                                <Input
                                                  type="textarea"
                                                  id="textarea"
                                                  onChange={validation.handleChange}
                                                  rows="3"
                                                  name="pp_product_description"
                                                  value={validation.values.pp_product_description}
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="2 mb-3">
                                                <Label className="form-label">Aktywna</Label>
                                                <Input
                                                  type="select"
                                                  name="pp_product_active"
                                                  className="form-select"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_product_active}
                                                >
                                                  <option value="0">Nie</option>
                                                  <option value="1">Tak</option>
                                                </Input>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Dostępna ilość</Label>
                                                <Input
                                                  name="pp_product_quantity"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_product_quantity}
                                                  invalid={
                                                    validation.touched.pp_product_quantity && validation.errors.pp_product_quantity ? true : false
                                                  }
                                                />
                                                {validation.touched.pp_product_quantity && validation.errors.pp_product_quantity ? (
                                                  <FormFeedback type="invalid">{validation.errors.pp_product_quantity}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Punkty</Label>
                                                <Input
                                                  name="pp_product_points"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_product_points}
                                                  invalid={
                                                    validation.touched.pp_product_points && validation.errors.pp_product_points ? true : false
                                                  }
                                                />
                                                {validation.touched.pp_product_points && validation.errors.pp_product_points ? (
                                                  <FormFeedback type="invalid">{validation.errors.pp_product_points}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Stara cena</Label>
                                                <Input
                                                  name="pp_product_old_price"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_product_old_price}
                                                  invalid={
                                                    validation.touched.pp_product_old_price && validation.errors.pp_product_old_price ? true : false
                                                  }
                                                />
                                                {validation.touched.pp_product_old_price && validation.errors.pp_product_old_price ? (
                                                  <FormFeedback type="invalid">{validation.errors.pp_product_old_price}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Nowa cena z punktami</Label>
                                                <Input
                                                  name="pp_product_price"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_product_price}
                                                  invalid={
                                                    validation.touched.pp_product_price && validation.errors.pp_product_price ? true : false
                                                  }
                                                />
                                                {validation.touched.pp_product_price && validation.errors.pp_product_price ? (
                                                  <FormFeedback type="invalid">{validation.errors.pp_product_price}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="3">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Zdjęcie</Label>
                                                <Input
                                                  type="file"
                                                  name="pp_product_image"
                                                  onChange={(event) => {
                                                    validation.setFieldValue('pp_product_image', event.currentTarget.files[0]);
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(PPProducts);
