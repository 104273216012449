import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_POSTS,
  ADD_POST,
  UPDATE_POST,
  DELETE_POST,
  GET_IS_CATEGORIES,
  ADD_IS_CATEGORY,
  UPDATE_IS_CATEGORY,
  DELETE_IS_CATEGORY
} from "./actionTypes";

import {
  getPostsSuccess,
  getPostsFail,
  addPostSuccess,
  addPostFail,
  updatePostSuccess,
  updatePostFail,
  deletePostSuccess,
  deletePostFail,
  getISCategoriesSuccess,
  getISCategoriesFail,
  addISCategorySuccess,
  addISCategoryFail,
  updateISCategorySuccess,
  updateISCategoryFail,
  deleteISCategorySuccess,
  deleteISCategoryFail
} from "./actions";

import {
  getPosts,
  addPost,
  updatePost,
  deletePost,
  getISCategories,
  addISCategory,
  updateISCategory,
  deleteISCategory
} from "helpers/backend_helper";

//Posts

function* fetchPosts() {
  try {
    const response = yield call(getPosts)
    yield put(getPostsSuccess(response.data))
  } catch (error) {
    yield put(getPostsFail(error))
  }
}

function* onAddPost({ payload: post }) {
  try {
    const response = yield call(addPost, post)
    yield put(addPostSuccess(response.data))
  } catch (error) {
    yield put(addPostFail(error))
  }
}

function* onUpdatePost({ payload: post }) {
  try {
    const response = yield call(updatePost, post)
    yield put(updatePostSuccess(response.data))
  } catch (error) {
    yield put(updatePostFail(error))
  }
}

function* onDeletePost({ payload: post }) {
  try {
    const response = yield call(deletePost, post)
    yield put(deletePostSuccess(response.data.post_id))
  } catch (error) {
    yield put(deletePostFail(error))
  }
}

//Categories

function* fetchCategories() {
  try {
    const response = yield call(getISCategories)
    yield put(getISCategoriesSuccess(response.data))
  } catch (error) {
    yield put(getISCategoriesFail(error))
  }
}

function* onAddISCategory({ payload: category }) {
  try {
    const response = yield call(addISCategory, category)
    yield put(addISCategorySuccess(response.data))
  } catch (error) {
    yield put(addISCategoryFail(error))
  }
}

function* onUpdateISCategory({ payload: category }) {
  try {
    const response = yield call(updateISCategory, category)
    yield put(updateISCategorySuccess(response.data))
  } catch (error) {
    yield put(updateISCategoryFail(error))
  }
}

function* onDeleteISCategory({ payload: category }) {
  try {
    const response = yield call(deleteISCategory, category)
    yield put(deleteISCategorySuccess(response.data.category_id ? response.data.category_id : category.category_id))
  } catch (error) {
    yield put(deleteISCategoryFail(error))
  }
}

function* InfoService() {
  yield takeEvery(GET_POSTS, fetchPosts)
  yield takeEvery(ADD_POST, onAddPost)
  yield takeEvery(UPDATE_POST, onUpdatePost)
  yield takeEvery(DELETE_POST, onDeletePost)
  yield takeEvery(GET_IS_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_IS_CATEGORY, onAddISCategory)
  yield takeEvery(UPDATE_IS_CATEGORY, onUpdateISCategory)
  yield takeEvery(DELETE_IS_CATEGORY, onDeleteISCategory)
}

export default InfoService;