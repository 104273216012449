import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip,
  Alert,
  Spinner,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
} from "store/users/actions"
import {
  getBranches as onGetBranches,
  addBranch as onAddBranch,
  updateBranch as onUpdateBranch,
  deleteBranch as onDeleteBranch,
} from "store/branches/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Branches = props => {
  //meta title
  document.title = "Oddziały - Panel administracyjny";
  
  const dispatch = useDispatch();

  const [branch, setBranch] = useState(null);
  const [employee, setEmployee] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      branch_id: (branch && branch.branch_id) || '',
      user_id: (branch && branch.user_id) || 0,
      branch_name: (branch && branch.branch_name) || '',
      branch_slug: (branch && branch.branch_slug) || '',
      branch_email: (branch && branch.branch_email) || '',
      branch_address_city: (branch && branch.branch_address_city) || '',
      branch_address_street: (branch && branch.branch_address_street) || '',
      branch_address_post: (branch && branch.branch_address_post) || '',
      branch_address_lat: (branch && branch.branch_address_lat) || '',
      branch_address_lng: (branch && branch.branch_address_lng) || '',
      branch_phone: (branch && branch.branch_phone) || '',
      branch_www: (branch && branch.branch_www) || '',
      branch_open_h_pn_pt: (branch && branch.branch_open_h_pn_pt) || '',
      branch_open_h_s: (branch && branch.branch_open_h_s) || '',
      branch_open_h_n: (branch && branch.branch_open_h_n) || '',
    },
    validationSchema: Yup.object({
      user_id: Yup.string().required("Użytkownik jest wymagany"),
      branch_name: Yup.string().required("Nazwa jest wymagana"),
      branch_email: Yup.string().required("Email jest wymagany"),
      branch_address_city: Yup.string().required("Miasto jest wymagane"),
      branch_address_street: Yup.string().required("Adres jest wymagany"),
      branch_phone: Yup.string().required("Telefon jest wymagany"),
      branch_address_post: Yup.string().required("Kod pocztowy"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBranch = {
          branch_id: branch ? branch.branch_id : 0,
          user_id: values.user_id,
          branch_name: values.branch_name,
          branch_email: values.branch_email,
          branch_address_city: values.branch_address_city,
          branch_address_street: values.branch_address_street,
          branch_address_post: values.branch_address_post,
          branch_phone: values.branch_phone,
          branch_www: values.branch_www,
          branch_open_h_pn_pt: values.branch_open_h_pn_pt,
          branch_open_h_s: values.branch_open_h_s,
          branch_open_h_n: values.branch_open_h_n,
        };
        dispatch(onUpdateBranch(updateBranch));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newBranch = {
          branch_name: values["branch_name"],
          user_id: 0,
          branch_email: values["branch_email"],
          branch_address_city: values["branch_address_city"],
          branch_address_street: values["branch_address_street"],
          branch_address_post: values["branch_address_post"],
          branch_phone: values["branch_phone"],
          branch_www: values["branch_www"],
          branch_open_h_pn_pt: values["branch_open_h_pn_pt"],
          branch_open_h_s: values["branch_open_h_s"],
          branch_open_h_n: values["branch_open_h_n"],
        };
        dispatch(onAddBranch(newBranch));
        validation.resetForm();
      }
      toggle();
    }
  });

  const { branches } = useSelector(state => ({
    branches: state.branches.dataBranches.branches,
  }));

  const { users } = useSelector(state => ({
    users: state.users.dataUsers.users,
  }))

  const { success } = useSelector(state => ({
    success: state.branches.dataBranches.success,
  }));

  const { error } = useSelector(state => ({
    error: state.branches.dataBranches.error,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search;

  const columns = [
    {
      text: "ID",
      dataField: "branch_id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: branch => {branch.branch_id},
    },
    {
      dataField: "img",
      text: "#",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, branch) => (
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {branch.branch_name.charAt(0)}
          </span>
        </div>
      ),
    },
    {
      text: "Nazwa",
      dataField: "branch_name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, branch) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {branch.branch_name}
            </Link>
          </h5>
          <p className="text-muted mb-0">{branch.branch_slug}</p>
        </>
      ),
    },
    {
      dataField: "branch_address_post",
      text: "Kod pocztowy",
      sort: true,
    },
    {
      dataField: "branch_address_city",
      text: "Miasto",
      sort: true,
    },
    {
      dataField: "branch_email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Opcje",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, branch) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleBranchClick(branch)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(branch)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (branches && !branches.length) {
      dispatch(onGetBranches());
      setIsEdit(false);
    }
  }, [dispatch, branches]);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers())
      setIsEdit(false)
    }
  }, [dispatch, users])

  useEffect(() => {
    if (users) {
      setEmployee(users.filter((user) => user.role === "employee"));
    }
  }, [users]);

  useEffect(() => {
    setBranch(branches);
    setIsEdit(false);
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(branches) && !!isEdit) {
      setBranch(branches);
      setIsEdit(false);
    }
  }, [branches]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_BRANCH_SUCCESS",
        });
      }, 5000)
      
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_BRANCH_FAIL",
        });
      }, 5000)
      
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleBranchClick = arg => {
    const branch = arg;

    setBranch({
      branch_id: branch.branch_id,
      user_id: branch.user_id,
      branch_name: branch.branch_name,
      branch_slug: branch.branch_slug,
      branch_address_city: branch.branch_address_city,
      branch_address_street: branch.branch_address_street,
      branch_address_post: branch.branch_address_post,
      branch_address_lat: branch.branch_address_lat,
      branch_address_lng: branch.branch_address_lng,
      branch_www: branch.branch_www,
      branch_phone: branch.branch_phone,
      branch_email: branch.branch_email,
      branch_open_h_pn_pt: branch.branch_open_h_pn_pt,
      branch_open_h_s: branch.branch_open_h_s,
      branch_open_h_n: branch.branch_open_h_n,
    });
    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (branches) => {
    setBranch(branches);
    setDeleteModal(true);
  };

  const handleDeleteBranch = () => {
    dispatch(onDeleteBranch(branch));
    setDeleteModal(false);
  };

  const handleBranchClicks = () => {
    setBranch("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "branch_id",
      order: "desc",
    },
  ];

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteBranch}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs breadcrumbItem="Oddziały" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="branch_id"
                      data={branches}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleBranchClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj oddział
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edycja oddziału" : "Dodaj oddział"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <div className="mb-3">
                                          <Label className="form-label">Użytkownik (sprzedawca)</Label>
                                          <Input
                                            type="select"
                                            name="user_id"
                                            className="form-select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.user_id}
                                            invalid={
                                              validation.touched.user_id && validation.errors.user_id ? true : false
                                            }
                                          >
                                            <option value="">-- Wybierz --</option>
                                            {employee.map((emp, index) => (
                                              <option value={emp.id} key={emp.id}>
                                                {emp.email}
                                              </option>
                                              )
                                            )}
                                          </Input>
                                          {validation.touched.user_id && validation.errors.user_id ? (
                                            <FormFeedback type="invalid">{validation.errors.user_id}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Nazwa</Label>
                                          <Input
                                            name="branch_name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_name || ""}
                                            invalid={
                                              validation.touched.branch_name && validation.errors.branch_name ? true : false
                                            }
                                          />
                                          {validation.touched.branch_name && validation.errors.branch_name ? (
                                            <FormFeedback type="invalid">{validation.errors.branch_name}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Email</Label>
                                          <Input
                                            name="branch_email"
                                            label="Email"
                                            type="email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_email || ""}
                                            invalid={
                                              validation.touched.branch_email && validation.errors.branch_email ? true : false
                                            }
                                          />
                                          {validation.touched.branch_email && validation.errors.branch_email ? (
                                            <FormFeedback type="invalid">{validation.errors.branch_email}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Godziny otwarcia pn-pt</Label>
                                          <Input
                                            name="branch_open_h_pn_pt"
                                            label="Godziny otwarcia pn-pt"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_open_h_pn_pt || ""}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Ulica</Label>
                                          <Input
                                            name="branch_address_street"
                                            label="Ulica"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_address_street || ""}
                                            invalid={
                                              validation.touched.branch_address_street && validation.errors.branch_address_street ? true : false
                                            }
                                          />
                                          {validation.touched.branch_address_street && validation.errors.branch_address_street ? (
                                            <FormFeedback type="invalid">{validation.errors.branch_address_street}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Telefon</Label>
                                          <Input
                                            name="branch_phone"
                                            label="Telefon"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_phone || ""}
                                            invalid={
                                              validation.touched.branch_phone && validation.errors.branch_phone ? true : false
                                            }
                                          />
                                          {validation.touched.branch_phone && validation.errors.branch_phone ? (
                                            <FormFeedback type="invalid">{validation.errors.branch_phone}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Godziny otwarcia sobota</Label>
                                          <Input
                                            name="branch_open_h_s"
                                            label="Godziny otwarcia sobota"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_open_h_s || ""}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Miasto</Label>
                                          <Input
                                            name="branch_address_city"
                                            label="Miasto"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_address_city || ""}
                                            invalid={
                                              validation.touched.branch_address_city && validation.errors.branch_address_city ? true : false
                                            }
                                          />
                                          {validation.touched.branch_address_city && validation.errors.branch_address_city ? (
                                            <FormFeedback type="invalid">{validation.errors.branch_address_city}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Strona www</Label>
                                          <Input
                                            name="branch_www"
                                            label="Stona www"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_www || ""}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Godziny otwarcia niedziela</Label>
                                          <Input
                                            name="branch_open_h_n"
                                            label="Godziny otwarcia niedziela"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_open_h_n || ""}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Kod pocztowy</Label>
                                          <Input
                                            name="branch_address_post"
                                            label="Kod pocztowy"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_address_post || ""}
                                            invalid={
                                              validation.touched.branch_address_post && validation.errors.branch_address_post ? true : false
                                            }
                                          />
                                          {validation.touched.branch_address_post && validation.errors.branch_address_post ? (
                                            <FormFeedback type="invalid">{validation.errors.branch_address_post}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Adres lat</Label>
                                          <Input
                                            name="branch_address_lat"
                                            label="Adres lat"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_address_lat || ""}
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label className="form-label">Adres lng</Label>
                                          <Input
                                            name="branch_address_lng"
                                            label="Adres lng"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_address_lng || ""}
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(Branches);
