/* Get DELIVERY_FORM */
export const GET_DELIVERY_FORMS = "GET_DELIVERY_FORM"
export const GET_DELIVERY_FORMS_SUCCESS = "GET_DELIVERY_FORM_SUCCESS"
export const GET_DELIVERY_FORMS_FAIL = "GET_DELIVERY_FORM_FAIL"

/**
 * Add DELIVERY_FORM
 */
export const ADD_DELIVERY_FORM = "ADD_DELIVERY_FORM"
export const ADD_DELIVERY_FORM_SUCCESS = "ADD_DELIVERY_FORM_SUCCESS"
export const ADD_DELIVERY_FORM_FAIL = "ADD_DELIVERY_FORM_FAIL"

/**
 * Edit DELIVERY_FORM
 */
export const UPDATE_DELIVERY_FORM = "UPDATE_DELIVERY_FORM"
export const UPDATE_DELIVERY_FORM_SUCCESS = "UPDATE_DELIVERY_FORM_SUCCESS"
export const UPDATE_DELIVERY_FORM_FAIL = "UPDATE_DELIVERY_FORM_FAIL"

/**
 * Delete DELIVERY_FORM
 */
export const DELETE_DELIVERY_FORM = "DELETE_DELIVERY_FORM"
export const DELETE_DELIVERY_FORM_SUCCESS = "DELETE_DELIVERY_FORM_SUCCESS"
export const DELETE_DELIVERY_FORM_FAIL = "DELETE_DELIVERY_FORM_FAIL"

/**
 * Hide notification
 */

export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION"
