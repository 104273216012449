/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
export const GET_USERS_INACTIVE = "GET_USERS_INACTIVE"
export const GET_USERS_INACTIVE_SUCCESS = "GET_USERS_INACTIVE_SUCCESS"
export const GET_USERS_INACTIVE_FAIL = "GET_USERS_INACTIVE_FAIL"
export const HIDE_USER_SUCCESS = "HIDE_USER_SUCCESS"
export const HIDE_USER_FAIL = "HIDE_USER_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

/* MANUFACTURER */
export const GET_MANUFACTURERS = "GET_MANUFACTURERS"
export const GET_MANUFACTURERS_SUCCESS = "GET_MANUFACTURERS_SUCCESS"
export const GET_MANUFACTURERS_FAIL = "GET_MANUFACTURERS_FAIL"
export const ADD_MANUFACTURER = "ADD_MANUFACTURER"
export const ADD_MANUFACTURER_SUCCESS = "ADD_MANUFACTURER_SUCCESS"
export const ADD_MANUFACTURER_FAIL = "ADD_MANUFACTURER_FAIL"
export const UPDATE_MANUFACTURER = "UPDATE_MANUFACTURER"
export const UPDATE_MANUFACTURER_SUCCESS = "UPDATE_MANUFACTURER_SUCCESS"
export const UPDATE_MANUFACTURER_FAIL = "UPDATE_MANUFACTURER_FAIL"
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER"
export const DELETE_MANUFACTURER_SUCCESS = "DELETE_MANUFACTURER_SUCCESS"
export const DELETE_MANUFACTURER_FAIL = "DELETE_MANUFACTURER_FAIL"
export const HIDE_MANUFACTURER_SUCCESS = "HIDE_MANUFACTURER_SUCCESS"
export const HIDE_MANUFACTURER_FAIL = "HIDE_MANUFACTURER_FAIL"