import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CMS_POSTS,
  ADD_CMS_POST,
  UPDATE_CMS_POST,
  DELETE_CMS_POST,
  GET_CMS_CATEGORIES,
  ADD_CMS_CATEGORY,
  UPDATE_CMS_CATEGORY,
  DELETE_CMS_CATEGORY,
  GET_CMS_PAGES,
  ADD_CMS_PAGE,
  UPDATE_CMS_PAGE,
  DELETE_CMS_PAGE,
  GET_CMS_MENU,
  ADD_CMS_MENU,
  UPDATE_CMS_MENU,
  DELETE_CMS_MENU,
} from "./actionTypes";

import {
  getCmsPostsSuccess,
  getCmsPostsFail,
  addCmsPostSuccess,
  addCmsPostFail,
  updateCmsPostSuccess,
  updateCmsPostFail,
  deleteCmsPostSuccess,
  deleteCmsPostFail,
  getCmsCategoriesSuccess,
  getCmsCategoriesFail,
  addCmsCategorySuccess,
  addCmsCategoryFail,
  updateCmsCategorySuccess,
  updateCmsCategoryFail,
  deleteCmsCategorySuccess,     
  deleteCmsCategoryFail,
  getCmsPagesSuccess,
  getCmsPagesFail,
  addCmsPageSuccess,
  addCmsPageFail,
  updateCmsPageSuccess,
  updateCmsPageFail,
  deleteCmsPageSuccess,
  deleteCmsPageFail,
  getCmsMenuSuccess,
  getCmsMenuFail,
  addCmsMenuSuccess,
  addCmsMenuFail,
  updateCmsMenuSuccess,
  updateCmsMenuFail,
  deleteCmsMenuSuccess,
  deleteCmsMenuFail,
} from "./actions";

import {
  getCmsPosts,
  addCmsPost,
  updateCmsPost,
  deleteCmsPost,
  getCmsCategories,
  addCmsCategory,
  updateCmsCategory,
  deleteCmsCategory,
  getCmsPages,
  addCmsPage,
  updateCmsPage,
  deleteCmsPage,
  getCmsMenu,
  addCmsMenu,
  updateCmsMenu,
  deleteCmsMenu,
} from "helpers/backend_helper";

//CmsPosts

function* fetchCmsPosts() {
  try {
    const response = yield call(getCmsPosts)
    yield put(getCmsPostsSuccess(response.data))
  } catch (error) {
    yield put(getCmsPostsFail(error))
  }
}

function* onAddCmsPost({ payload: post }) {
  try {
    const response = yield call(addCmsPost, post)
    yield put(addCmsPostSuccess(response.data))
  } catch (error) {
    yield put(addCmsPostFail(error))
  }
}

function* onUpdateCmsPost({ payload: post }) {
  try {
    const response = yield call(updateCmsPost, post)
    yield put(updateCmsPostSuccess(response.data))
  } catch (error) {
    yield put(updateCmsPostFail(error))
  }
}

function* onDeleteCmsPost({ payload: post }) {
  try {
    const response = yield call(deleteCmsPost, post)
    yield put(deleteCmsPostSuccess(response.data.post_id))
  } catch (error) {
    yield put(deleteCmsPostFail(error))
  }
}

//Categories

function* fetchCategories() {
  try {
    const response = yield call(getCmsCategories)
    yield put(getCmsCategoriesSuccess(response.data))
  } catch (error) {
    yield put(getCmsCategoriesFail(error))
  }
}

function* onAddCmsCategory({ payload: category }) {
  try {
    const response = yield call(addCmsCategory, category)
    yield put(addCmsCategorySuccess(response.data))
  } catch (error) {
    yield put(addCmsCategoryFail(error))
  }
}

function* onUpdateCmsCategory({ payload: category }) {
  try {
    const response = yield call(updateCmsCategory, category)
    yield put(updateCmsCategorySuccess(response.data))
  } catch (error) {
    yield put(updateCmsCategoryFail(error))
  }
}

function* onDeleteCmsCategory({ payload: category }) {
  try {
    const response = yield call(deleteCmsCategory, category)
    yield put(deleteCmsCategorySuccess(response.data.category_id ? response.data.category_id : category.category_id))
  } catch (error) {
    yield put(deleteCmsCategoryFail(error))
  }
}

//Pages

function* fetchCmsPages() {
    try {
      const response = yield call(getCmsPages)
      yield put(getCmsPagesSuccess(response.data))
    } catch (error) {
      yield put(getCmsPagesFail(error))
    }
  }
  
  function* onAddCmsPage({ payload: page }) {
    try {
      const response = yield call(addCmsPage, page)
      yield put(addCmsPageSuccess(response.data))
    } catch (error) {
      yield put(addCmsPageFail(error))
    }
  }
  
  function* onUpdateCmsPage({ payload: page }) {
    try {
      const response = yield call(updateCmsPage, page)
      yield put(updateCmsPageSuccess(response.data))
    } catch (error) {
      yield put(updateCmsPageFail(error))
    }
  }
  
  function* onDeleteCmsPage({ payload: page }) {
    try {
      const response = yield call(deleteCmsPage, page)
      yield put(deleteCmsPageSuccess(response.data.id))
    } catch (error) {
      yield put(deleteCmsPageFail(error))
    }
  }

  //Menu

function* fetchCmsMenu() {
    try {
      const response = yield call(getCmsMenu)
      yield put(getCmsMenuSuccess(response.data))
    } catch (error) {
      yield put(getCmsMenuFail(error))
    }
  }
  
  function* onAddCmsMenu({ payload: menu }) {
    try {
      const response = yield call(addCmsMenu, menu)
      yield put(addCmsMenuSuccess(response.data))
    } catch (error) {
      yield put(addCmsMenuFail(error))
    }
  }
  
  function* onUpdateCmsMenu({ payload: post }) {
    try {
      const response = yield call(updateCmsMenu, menu)
      yield put(updateCmsMenuSuccess(response.data))
    } catch (error) {
      yield put(updateCmsMenuFail(error))
    }
  }
  
  function* onDeleteCmsMenu({ payload: menu }) {
    try {
      const response = yield call(deleteCmsMenu, menu)
      yield put(deleteCmsMenuSuccess(response.data.id))
    } catch (error) {
      yield put(deleteCmsMenuFail(error))
    }
  }

function* Cms() {
  yield takeEvery(GET_CMS_POSTS, fetchCmsPosts)
  yield takeEvery(ADD_CMS_POST, onAddCmsPost)
  yield takeEvery(UPDATE_CMS_POST, onUpdateCmsPost)
  yield takeEvery(DELETE_CMS_POST, onDeleteCmsPost)
  yield takeEvery(GET_CMS_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_CMS_CATEGORY, onAddCmsCategory)
  yield takeEvery(UPDATE_CMS_CATEGORY, onUpdateCmsCategory)
  yield takeEvery(DELETE_CMS_CATEGORY, onDeleteCmsCategory)
  yield takeEvery(GET_CMS_PAGES, fetchCmsPages)
  yield takeEvery(ADD_CMS_PAGE, onAddCmsPage)
  yield takeEvery(UPDATE_CMS_PAGE, onUpdateCmsPage)
  yield takeEvery(DELETE_CMS_PAGE, onDeleteCmsPage)
  yield takeEvery(GET_CMS_MENU, fetchCmsMenu)
  yield takeEvery(ADD_CMS_MENU, onAddCmsMenu)
  yield takeEvery(UPDATE_CMS_MENU, onUpdateCmsMenu)
  yield takeEvery(DELETE_CMS_MENU, onDeleteCmsMenu)
}

export default Cms;