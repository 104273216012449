import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_SMS_NOTIFICATIONS,
  UPDATE_SMS_NOTIFICATIONS
} from "./actionTypes"
import {
  getSMSNotificationsSuccess,
  getSMSNotificationsFail,
  updateSMSNotificationsSuccess,
  updateSMSNotificationsFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSMSNotifications,
  updateSMSNotifications
} from "helpers/backend_helper"

//Notifications

function* fetchSMSNotifications() {
  try {
    const response = yield call(getSMSNotifications)
    yield put(getSMSNotificationsSuccess(response.data))
  } catch (error) {
    yield put(getSMSNotificationsFail(error))
  }
}

function* onUpdateSMSNotifications({ payload: notifications }) {
  try {
    const response = yield call(updateSMSNotifications, notifications)
    yield put(updateSMSNotificationsSuccess(response.data))
  } catch (error) {
    yield put(updateSMSNotificationsFail(error))
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_SMS_NOTIFICATIONS, fetchSMSNotifications)
  yield takeEvery(UPDATE_SMS_NOTIFICATIONS, onUpdateSMSNotifications)
}

export default notificationsSaga;