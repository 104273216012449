import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_REPORTS,
  GET_REPORT,
} from "./actionTypes";

import {
  getReportsSuccess,
  getReportsFail,
  getReportSuccess,
  getReportFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProvidersSallaryReport,
  getProviderSallaryReport,
} from "helpers/backend_helper";

//Reports

function* fetchReports({ payload: date}) {
  try {
    const response = yield call(getProvidersSallaryReport, date)
    yield put(getReportsSuccess(response.data))
  } catch (error) {
    yield put(getReportsFail(error))
  }
}

function* fetchReport({ payload: date}) {
  try {
    const response = yield call(getProviderSallaryReport, date)
    yield put(getReportSuccess(response.data))
  } catch (error) {
    yield put(getReportFail(error))
  }
}

function* Reports() {
  yield takeEvery(GET_REPORTS, fetchReports)
  yield takeEvery(GET_REPORT, fetchReport)
}

export default Reports;