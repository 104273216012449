import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getLogs as onGetLogs,
} from "store/logs/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Logs = props => {
  //meta title
  document.title = "Logi - Panel administracyjny";
  
  const dispatch = useDispatch();

  const { logs } = useSelector(state => ({
    logs: state.logs.logs,
  }));

  useEffect(() => {
    if (logs && !logs.length) {
      dispatch(onGetLogs());
    }
  }, [dispatch, logs]);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "event",
      text: "Typ",
      sort: true,
    },
    {
      dataField: "value",
      text: "Opis",
      sort: true,
    },
    {
      dataField: "date",
      text: "Data",
      sort: true,
    },
    {
      dataField: "user",
      text: "Użytkownik",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {cellContent.name}
        </React.Fragment>
      ),
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Logi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={logs}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {props => (
                      <React.Fragment>
                        <Row>
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder="Szukaj..."
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              defaultSorted={defaultSorted}
                              pagination={paginationFactory()}
                              classes={"align-middle"}
                              headerWrapperClasses={"table-light"}
                              noDataIndication={() => <NoDataIndication />}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Logs);
