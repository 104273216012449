import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DELIVERY_FORMS,
  ADD_DELIVERY_FORM,
  UPDATE_DELIVERY_FORM,
  DELETE_DELIVERY_FORM,
} from "./actionTypes"

import {
  getDeliveryFormsSuccess,
  getDeliveryFormsFail,
  addDeliveryFormSuccess,
  addDeliveryFormFail,
  updateDeliveryFormSuccess,
  updateDeliveryFormFail,
  deleteDeliveryFormSuccess,
  deleteDeliveryFormFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDeliveryForms,
  addDeliveryForm,
  updateDeliveryForm,
  deleteDeliveryForm,
} from "../../helpers/backend_helper"

function* fetchDeliveryForms() {
  try {
    const response = yield call(getDeliveryForms)
    yield put(getDeliveryFormsSuccess(response.data))
  } catch (error) {
    yield put(getDeliveryFormsFail(error))
  }
}

function* onAddDeliveryForm({ payload }) {
  try {
    const response = yield call(addDeliveryForm, payload)

    yield put(addDeliveryFormSuccess(response.data))
  } catch (error) {
    yield put(addDeliveryFormFail(error))
  }
}

function* onUpdateDeliveryForm({ payload }) {
  try {
    const response = yield call(updateDeliveryForm, payload)
    yield put(updateDeliveryFormSuccess(response.data))
  } catch (error) {
    yield put(updateDeliveryFormFail(error))
  }
}

function* onDeleteDeliveryForm({ payload }) {
  try {
    yield call(deleteDeliveryForm, payload)
    yield put(deleteDeliveryFormSuccess(payload))
  } catch (error) {
    yield put(deleteDeliveryFormFail(error))
  }
}

function* deliveryFormSaga() {
  yield takeEvery(GET_DELIVERY_FORMS, fetchDeliveryForms)
  yield takeEvery(ADD_DELIVERY_FORM, onAddDeliveryForm)
  yield takeEvery(UPDATE_DELIVERY_FORM, onUpdateDeliveryForm)
  yield takeEvery(DELETE_DELIVERY_FORM, onDeleteDeliveryForm)
}

export default deliveryFormSaga
