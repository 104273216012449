import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner
} from "reactstrap"

import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUnits as onGetUnits,
  addUnit as onAddUnit,
  updateUnit as onUpdateUnit,
  deleteUnit as onDeleteUnit,
} from "store/catalog/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Units = props => {
  //meta title
  document.title = "Jednostki sprzedażowe - Panel administracyjny";

  const dispatch = useDispatch();

  const [unit, setUnit] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      package_id: (unit && unit.package_id) || '',
      package_name: (unit && unit.package_name) || '',
      package_value: (unit && unit.package_value) || '',
      package_value_type: (unit && unit.package_value_type) || 'static',
    },
    validationSchema: Yup.object({
      package_name: Yup.string().required("To pole jest wymagane"),
      package_value: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUnit = {
          package_id: unit ? unit.package_id : 0,
          package_name: values.package_name,
          package_value: values.package_value,
          package_value_type: values.package_value_type,
        };
        dispatch(onUpdateUnit(updateUnit));
        validation.resetForm();
      } else {
        const addUnit = {
          package_name: values["package_name"],
          package_value: values["package_value"],
          package_value_type: values["package_value_type"],
        };
        dispatch(onAddUnit(addUnit));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { units } = useSelector(state => ({
    units: state.catalog.dataUnits.units,
  }));

  const { success } = useSelector(state => ({
    success: state.catalog.dataUnits.success,
  }));

  const { error } = useSelector(state => ({
    error: state.catalog.dataUnits.error,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "package_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "package_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "package_value",
      text: "Jednostka",
      sort: true,
    },
    {
      dataField: "package_value_type",
      text: "Typ jednostki",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {(cellContent === 'static') ? 'Całkowita' : 'Metryczna'}
        </React.Fragment>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, singlePackage) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleUnitClick(singlePackage)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(singlePackage)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (units && !units.length) {
      dispatch(onGetUnits());
    }
  }, [dispatch, units]);

  useEffect(() => {
    setUnit(units);
  }, [units]);

  useEffect(() => {
    if (!isEmpty(units) && !!isEdit) {
      setUnit(units);
      setIsEdit(false);
    }
  }, [units]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_UNIT_SUCCESS",
        });
      }, 5000)
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_UNIT_FAIL",
        });
      }, 5000)
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUnitClick = arg => {
    const unit = arg;

    setUnit({
      package_id: unit.package_id,
      package_name: unit.package_name,
      package_value: unit.package_value,
      package_value_type: unit.package_value_type,
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (unit) => {
    setUnit(unit);
    setDeleteModal(true);
  };

  const handleDeleteUnit = () => {
    if (unit.package_id) {
      dispatch(onDeleteUnit(unit));
      setDeleteModal(false);
    }
  };

  const handleUnitClicks = () => {
    setUnit("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "package_id",
      order: "desc",
    },
  ];

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteUnit}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Katalog" breadcrumbItem="Jednostki sprzedażowe" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="package_id"
                      data={units}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleUnitClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj jednostke sprzedażową
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edytuj jednostkę" : "Dodaj jednostkę"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Input
                                            name="package_id"
                                            type="hidden"
                                            value={validation.values.single_package_id || ""}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <Label className="form-label">Nazwa</Label>
                                          <Input
                                            name="package_name"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.package_name}
                                            invalid={
                                              validation.touched.package_name && validation.errors.package_name ? true : false
                                            }
                                          />
                                          {validation.touched.package_name && validation.errors.package_name ? (
                                            <FormFeedback type="invalid">{validation.errors.package_name}</FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className="mb-3">
                                          <Label className="form-label">Jednostka</Label>
                                          <Input
                                            name="package_value"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.package_value}
                                            invalid={
                                              validation.touched.package_value && validation.errors.package_value ? true : false
                                            }
                                          />
                                          {validation.touched.package_value && validation.errors.package_value ? (
                                            <FormFeedback type="invalid">{validation.errors.package_value}</FormFeedback>
                                          ) : null}
                                        </div>
                                        
                                        <div className="mb-3">
                                          <Label className="form-label">Typ jednostki</Label>
                                          <Input
                                            type="select"
                                            name="package_value_type"
                                            className="form-select"
                                            multiple={false}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.package_value_type || []}
                                            
                                          >
                                            <option value="static">Całkowita</option>
                                            <option value="metric">Metryczna</option>
                                          </Input>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Units)
