import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//contacts
import users from "./users/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Catalog
import catalog from "./catalog/reducer"

//orders
import orders from "./orders/reducer"

//branches
import branches from "./branches/reducer"

//payments
import payments from "./payments/reducer"

//partner program
import partnerprogram from "./partnerprogram/reducer"

//reports
import reports from "./reports/reducer"

//info service
import infoService from "./infoservice/reducer"

//sms notifications
import notifications from "./settings/reducer"

//logs
import logs from "./logs/reducer"

//cms
import Cms from "./cms/reducer"

//delivery forms
import deliveryForms from "./delivery-forms/reducer"
import deliveryFormPrices from "./delivery-form-prices/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  users,
  catalog,
  orders,
  branches,
  payments,
  partnerprogram,
  reports,
  infoService,
  notifications,
  logs,
  Cms,
  deliveryForms,
  deliveryFormPrices,
})

export default rootReducer
