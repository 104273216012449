import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import usersSaga from "./users/saga"
import dashboardSaga from "./dashboard/saga"
import CatalogSaga from "./catalog/saga"
import OrdersSaga from "./orders/saga"
import BranchesSaga from "./branches/saga"
import PaymentsSaga from "./payments/saga"
import PPSaga from "./partnerprogram/saga"
import Reports from "./reports/saga"
import InfoService from "./infoservice/saga"
import notificationsSaga from "./settings/saga"
import logsSaga from "./logs/saga"
import Cms from "./cms/saga"
import deliveryFormSaga from "./delivery-forms/saga"
import deliveryFormPricesSaga from "./delivery-form-prices/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(usersSaga),
    fork(dashboardSaga),
    fork(CatalogSaga),
    fork(OrdersSaga),
    fork(BranchesSaga),
    fork(PaymentsSaga),
    fork(PPSaga),
    fork(Reports),
    fork(InfoService),
    fork(notificationsSaga),
    fork(logsSaga),
    fork(Cms),
    fork(deliveryFormSaga),
    fork(deliveryFormPricesSaga),
  ])
}
