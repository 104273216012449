import {
  GET_DELIVERY_FORMS_SUCCESS,
  GET_DELIVERY_FORMS_FAIL,
  ADD_DELIVERY_FORM_SUCCESS,
  ADD_DELIVERY_FORM_FAIL,
  UPDATE_DELIVERY_FORM_SUCCESS,
  UPDATE_DELIVERY_FORM_FAIL,
  DELETE_DELIVERY_FORM_SUCCESS,
  DELETE_DELIVERY_FORM_FAIL,
  HIDE_NOTIFICATION,
} from "./actionTypes"

const INIT_STATE = {
  dataDeliveryForms: {
    deliveryForms: undefined,
    success: {
      open: false,
      text: "",
    },
    error: {
      open: false,
      text: "",
    },
  },
}

const deliveryForms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERY_FORMS_SUCCESS:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          deliveryForms: action.payload,
          success: {
            open: true,
            text: action.payload.length
              ? "Formy dostawy zostały pobrane."
              : "Brak form dostawy.",
          },
        },
      }

    case GET_DELIVERY_FORMS_FAIL:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          error: {
            open: true,
            text: "Podczas pobierania form dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case ADD_DELIVERY_FORM_SUCCESS:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          deliveryForms: [
            ...state.dataDeliveryForms.deliveryForms,
            action.payload,
          ],
          success: {
            open: true,
            text: "Forma dostawy została dodana.",
          },
        },
      }

    case ADD_DELIVERY_FORM_FAIL:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          error: {
            open: true,
            text: "Podczas dodawania formy dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case UPDATE_DELIVERY_FORM_SUCCESS:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          deliveryForms: state.dataDeliveryForms.deliveryForms.map(
            deliveryForm =>
              deliveryForm.id.toString() === action.payload.id.toString()
                ? { ...deliveryForm, ...action.payload }
                : deliveryForm
          ),
          success: {
            open: true,
            text: "Forma dostawy została zaktualizowana.",
          },
        },
      }

    case UPDATE_DELIVERY_FORM_FAIL:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          error: {
            open: true,
            text: "Podczas aktualizacji formy dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case DELETE_DELIVERY_FORM_SUCCESS:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          deliveryForms: state.dataDeliveryForms.deliveryForms.filter(
            deliveryForm =>
              deliveryForm.id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Forma dostawy została usunięta.",
          },
        },
      }

    case DELETE_DELIVERY_FORM_FAIL:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          error: {
            open: true,
            text: "Podczas usuwania formy dostawy wystąpił błąd, spróbuj ponownie.",
          },
        },
      }

    case HIDE_NOTIFICATION:
      return {
        ...state,
        dataDeliveryForms: {
          ...state.dataDeliveryForms,
          success: {
            open: false,
          },
        },
      }

    default:
      return state
  }
}

export default deliveryForms
