import {
  GET_PP_PRODUCTS,
  GET_PP_PRODUCTS_SUCCESS,
  GET_PP_PRODUCTS_FAIL,
  ADD_PP_PRODUCT,
  ADD_PP_PRODUCT_SUCCESS,
  ADD_PP_PRODUCT_FAIL,
  UPDATE_PP_PRODUCT,
  UPDATE_PP_PRODUCT_SUCCESS,
  UPDATE_PP_PRODUCT_FAIL,
  DELETE_PP_PRODUCT,
  DELETE_PP_PRODUCT_SUCCESS,
  DELETE_PP_PRODUCT_FAIL,
  HIDE_PRODUCT_SUCCESS,
  GET_PP_CATEGORIES,
  GET_PP_CATEGORIES_SUCCESS,
  GET_PP_CATEGORIES_FAIL,
  ADD_PP_CATEGORY,
  ADD_PP_CATEGORY_SUCCESS,
  ADD_PP_CATEGORY_FAIL,
  UPDATE_PP_CATEGORY,
  UPDATE_PP_CATEGORY_SUCCESS,
  UPDATE_PP_CATEGORY_FAIL,
  DELETE_PP_CATEGORY,
  DELETE_PP_CATEGORY_SUCCESS,
  DELETE_PP_CATEGORY_FAIL,
} from "./actionTypes"

// Products

export const getPPProducts = () => ({
  type: GET_PP_PRODUCTS,
})

export const getPPProductsSuccess = ppProducts => ({
  type: GET_PP_PRODUCTS_SUCCESS,
  payload: ppProducts,
})

export const getPPProductsFail = error => ({
  type: GET_PP_PRODUCTS_FAIL,
  payload: error,
})

export const addPPProduct = product => ({
  type: ADD_PP_PRODUCT,
  payload: product,
})

export const addPPProductSuccess = product => ({
  type: ADD_PP_PRODUCT_SUCCESS,
  payload: product,
})

export const addPPProductFail = error => ({
  type: ADD_PP_PRODUCT_FAIL,
  payload: error,
})

export const updatePPProduct = product => ({
  type: UPDATE_PP_PRODUCT,
  payload: product,
})

export const updatePPProductSuccess = product => ({
  type: UPDATE_PP_PRODUCT_SUCCESS,
  payload: product,
})

export const updatePPProductFail = error => ({
  type: UPDATE_PP_PRODUCT_FAIL,
  payload: error,
})

export const deletePPProduct = product => ({
  type: DELETE_PP_PRODUCT,
  payload: product,
})

export const deletePPProductSuccess = product => ({
  type: DELETE_PP_PRODUCT_SUCCESS,
  payload: product,
})

export const deletePPProductFail = error => ({
  type: DELETE_PP_PRODUCT_FAIL,
  payload: error,
})

// Categories

export const getPPCategories = () => ({
  type: GET_PP_CATEGORIES,
})

export const getPPCategoriesSuccess = categories => ({
  type: GET_PP_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getPPCategoriesFail = error => ({
  type: GET_PP_CATEGORIES_FAIL,
  payload: error,
})

export const addPPCategory = category => ({
  type: ADD_PP_CATEGORY,
  payload: category,
})

export const addPPCategorySuccess = category => ({
  type: ADD_PP_CATEGORY_SUCCESS,
  payload: category,
})

export const addPPCategoryFail = error => ({
  type: ADD_PP_CATEGORY_FAIL,
  payload: error,
})

export const updatePPCategory = category => ({
  type: UPDATE_PP_CATEGORY,
  payload: category,
})

export const updatePPCategorySuccess = category => ({
  type: UPDATE_PP_CATEGORY_SUCCESS,
  payload: category,
})

export const updatePPCategoryFail = error => ({
  type: UPDATE_PP_CATEGORY_FAIL,
  payload: error,
})

export const deletePPCategory = category => ({
  type: DELETE_PP_CATEGORY,
  payload: category,
})

export const deletePPCategorySuccess = category => ({
  type: DELETE_PP_CATEGORY_SUCCESS,
  payload: category,
})

export const deletePPCategoryFail = error => ({
  type: DELETE_PP_CATEGORY_FAIL,
  payload: error,
})