import {
  GET_BRANCHES,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAIL,
  GET_BRANCH_PROFILE,
  GET_BRANCH_PROFILE_SUCCESS,
  GET_BRANCH_PROFILE_FAIL,
  ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
} from "./actionTypes"

export const getBranches = () => ({
  type: GET_BRANCHES,
})

export const getBranchesSuccess = branches => ({
  type: GET_BRANCHES_SUCCESS,
  payload: branches,
})

export const getBranchesFail = error => ({
  type: GET_BRANCHES_FAIL,
  payload: error,
})

export const getBranchProfile = () => ({
  type: GET_BRANCH_PROFILE,
})

export const getBranchProfileSuccess = branchProfile => ({
  type: GET_BRANCH_PROFILE_SUCCESS,
  payload: branchProfile,
})

export const getBranchProfileFail = error => ({
  type: GET_BRANCH_PROFILE_FAIL,
  payload: error,
})

export const addBranch = branch => ({
  type: ADD_BRANCH,
  payload: branch,
})

export const addBranchSuccess = branch => ({
  type: ADD_BRANCH_SUCCESS,
  payload: branch,
})

export const addBranchFail = error => ({
  type: ADD_BRANCH_FAIL,
  payload: error,
})

export const updateBranch = branch => ({
  type: UPDATE_BRANCH,
  payload: branch,
})

export const updateBranchSuccess = branch => ({
  type: UPDATE_BRANCH_SUCCESS,
  payload: branch,
})

export const updateBranchFail = error => ({
  type: UPDATE_BRANCH_FAIL,
  payload: error,
})

export const deleteBranch = branch => ({
  type: DELETE_BRANCH,
  payload: branch,
})

export const deleteBranchSuccess = branch => ({
  type: DELETE_BRANCH_SUCCESS,
  payload: branch,
})

export const deleteBranchFail = error => ({
  type: DELETE_BRANCH_FAIL,
  payload: error,
})
