import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner
} from "reactstrap"

import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getReturnPackages as onGetReturnPackages,
  addReturnPackage as onAddReturnPackage,
  updateReturnPackage as onUpdateReturnPackage,
  deleteReturnPackage as onDeleteReturnPackage,
} from "store/catalog/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const ReturnPackages = props => {
  //meta title
  document.title = "Opakowania zwrotne - Panel administracyjny";

  const dispatch = useDispatch();

  const [returnPackage, setReturnPackage] = useState();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      return_package_id: (returnPackage && returnPackage.return_package_id) || '',
      return_package_name: (returnPackage && returnPackage.return_package_name) || '',
    },
    validationSchema: Yup.object({
      return_package_name: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateReturnPackage = {
          return_package_id: returnPackage ? returnPackage.return_package_id : 0,
          return_package_name: values.return_package_name,
        };
        dispatch(onUpdateReturnPackage(updateReturnPackage));
        validation.resetForm();
      } else {
        const newReturnPackage = {
          return_package_name: values["return_package_name"],
        };
        dispatch(onAddReturnPackage(newReturnPackage));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { returnPackages } = useSelector(state => ({
    returnPackages: state.catalog.dataReturnPackages.returnPackages,
  }));

  const { success } = useSelector(state => ({
    success: state.catalog.dataReturnPackages.success,
  }));

  const { error } = useSelector(state => ({
    error: state.catalog.dataReturnPackages.error,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "return_package_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "return_package_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, returnPackage) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleReturnPackageClick(returnPackage)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(returnPackage)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (returnPackages && !returnPackages.length) {
      dispatch(onGetReturnPackages());
    }
  }, [dispatch, returnPackages]);

  useEffect(() => {
    setReturnPackage(returnPackages);
  }, [returnPackages]);

  useEffect(() => {
    if (!isEmpty(returnPackages) && !!isEdit) {
        setReturnPackage(returnPackages);
      setIsEdit(false);
    }
  }, [returnPackages]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_RETURN_PACKAGES_SUCCESS",
        });
      }, 5000)
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_RETURN_PACKAGES_FAIL",
        });
      }, 5000)
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleReturnPackageClick = arg => {
    const returnPackage = arg;

    setReturnPackage({
      return_package_id: returnPackage.return_package_id,
      return_package_name: returnPackage.return_package_name,
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (returnPackage) => {
    setReturnPackage(returnPackage);
    setDeleteModal(true);
  };

  const handleDeleteReturnPackage = () => {
    if (returnPackage.return_package_id) {
      dispatch(onDeleteReturnPackage(returnPackage));
      setDeleteModal(false);
    }
  };

  const handleReturnPackageClicks = () => {
    setReturnPackage("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "return_package_id",
      order: "desc",
    },
  ];

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteReturnPackage}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Katalog" breadcrumbItem="Opakowania zwrotne" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="return_package_id"
                      data={returnPackages}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleReturnPackageClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj opakowanie
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edytuj opakowanie zwrotne" : "Dodaj opakowanie zwrotne"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Input
                                            name="return_package_id"
                                            type="hidden"
                                            value={validation.values.return_package_id || ""}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <div style={{fontSize: '15px', fontWeight: '500'}}>Ceny opakowań zwrotnych ustawia się na:
                                            <ul>
                                              <li>produkcie - jeśli jest to produkt bez wariantów</li>
                                              <li>wariantach - jeśli jest to produkt z wariantami</li>
                                            </ul>
                                          </div>
                                          <Label className="form-label">Nazwa</Label>
                                          <Input
                                            name="return_package_name"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.return_package_name}
                                            invalid={
                                              validation.touched.return_package_name && validation.errors.return_package_name ? true : false
                                            }
                                          />
                                          {validation.touched.return_package_name && validation.errors.return_package_name ? (
                                            <FormFeedback type="invalid">{validation.errors.return_package_name}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(ReturnPackages)
