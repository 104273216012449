import { call, put, takeEvery } from "redux-saga/effects";
//redux
import { useDispatch } from "react-redux";
// Ecommerce Redux States
import { 
  GET_ATTRIBUTES, 
  ADD_ATTRIBUTE, 
  UPDATE_ATTRIBUTE, 
  DELETE_ATTRIBUTE,
  GET_CATEGORIES,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  GET_UNITS,
  ADD_UNIT,
  DELETE_UNIT,
  UPDATE_UNIT,
  GET_RETURN_PACKAGES,
  ADD_RETURN_PACKAGE,
  DELETE_RETURN_PACKAGE,
  UPDATE_RETURN_PACKAGE,
  GET_FEATURES,
  ADD_FEATURE,
  DELETE_FEATURE,
  UPDATE_FEATURE,
  DELETE_IMAGE,
  GET_PRICES,
  UPDATE_PRICE,
  ADD_RELATED_PRODUCT,
  DELETE_RELATED_PRODUCT,
  GET_PRODUCTS_VARIANTS,
  ADD_VARIANT,
  UPDATE_VARIANT,
  DELETE_VARIANT,
  ADD_CREATED_VARIANTS,
  DELETE_CREATED_VARIANT,
} from "./actionTypes";
import {
  getAttributesFail,
  getAttributesSuccess,
  addAttributeFail,
  addAttributeSuccess,
  updateAttributeSuccess,
  updateAttributeFail,
  deleteAttributeSuccess,
  deleteAttributeFail,
  getCategoriesFail,
  getCategoriesSuccess,
  addCategoryFail,
  addCategorySuccess,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
  getProductsFail,
  getProductsSuccess,
  addProductFail,
  addProductSuccess,
  updateProductSuccess,
  updateProductFail,
  deleteProductSuccess,
  deleteProductFail,
  getUnitsFail,
  getUnitsSuccess,
  addUnitFail,
  addUnitSuccess,
  updateUnitSuccess,
  updateUnitFail,
  deleteUnitSuccess,
  deleteUnitFail,
  getReturnPackagesFail,
  getReturnPackagesSuccess,
  addReturnPackageFail,
  addReturnPackageSuccess,
  updateReturnPackageSuccess,
  updateReturnPackageFail,
  deleteReturnPackageSuccess,
  deleteReturnPackageFail,
  getFeaturesFail,
  getFeaturesSuccess,
  addFeatureFail,
  addFeatureSuccess,
  updateFeatureSuccess,
  updateFeatureFail,
  deleteFeatureSuccess,
  deleteFeatureFail,
  deleteImageSuccess,
  deleteImageFail,
  getPricesFail,
  getPricesSuccess,
  updatePriceSuccess,
  updatePriceFail,
  addRelatedProductSuccess,
  addRelatedProductFail,
  deleteRelatedProductSuccess,
  deleteRelatedProductFail,
  getProductsVariantsFail,
  getProductsVariantsSuccess,
  updateVariantSuccess,
  updateVariantFail,
  addVariantSuccess,
  addVariantFail,
  deleteVariantSuccess,
  deleteVariantFail,
  addCreatedVariantsSuccess,
  addCreatedVariantsFail,
  deleteCreatedVariantSuccess,
  deleteCreatedVariantFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAttributes,
  addAttribute,
  updateAttribute,
  deleteAttribute,
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  getUnits,
  addUnit,
  updateUnit,
  deleteUnit,
  getReturnPackages,
  addReturnPackage,
  updateReturnPackage,
  deleteReturnPackage,
  getFeatures,
  addFeature,
  updateFeature,
  deleteFeature,
  deleteImage,
  getPrices,
  updatePrice,
  addRelatedProduct,
  deleteRelatedProduct,
  getProductsVariants,
  addVariant,
  updateVariant,
  deleteVariant,
} from "helpers/backend_helper"

//Attributes

function* fetchAttributes() {
  try {
    const response = yield call(getAttributes)
    yield put(getAttributesSuccess(response.data))
  } catch (error) {
    yield put(getAttributesFail(error))
  }
}

function* onAddAttribute({ payload: attribute }) {
  try {
    const response = yield call(addAttribute, attribute)
    yield put(addAttributeSuccess(response.data))
  } catch (error) {
    yield put(addAttributeFail(error))
  }
}

function* onUpdateAttribute({ payload: attribute }) {
  try {
    const response = yield call(updateAttribute, attribute)
    yield put(updateAttributeSuccess(response.data))
  } catch (error) {
    yield put(updateAttributeFail(error))
  }
}

function* onDeleteAttribute({ payload: attribute }) {
  try {
    const response = yield call(deleteAttribute, attribute)
    yield put(deleteAttributeSuccess(response.data))
  } catch (error) {
    yield put(deleteAttributeFail(error))
  }
}

//Categories

function* fetchCategories() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response.data))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* onAddCategory({ payload: category }) {
  try {
    const response = yield call(addCategory, category)
    yield put(addCategorySuccess(category))
  } catch (error) {
    yield put(addCategoryFail(error))
  }
}

function* onUpdateCategory({ payload: category }) {
  try {
    const response = yield call(updateCategory, category)
    yield put(updateCategorySuccess(category))
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* onDeleteCategory({ payload: category }) {
  try {
    const response = yield call(deleteCategory, category)
    yield put(deleteCategorySuccess(response.data))
  } catch (error) {
    yield put(deleteCategoryFail(error))
  }
}

//Products

function* fetchProducts() {
  try {
    const response = yield call(getProducts)
    yield put(getProductsSuccess(response.data))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* onAddProduct({ payload: product }) {
  try {
    const response = yield call(addProduct, product)
    yield put(addProductSuccess(response.data))
  } catch (error) {
    yield put(addProductFail(error))
  }
}

function* onUpdateProduct({ payload: product }) {
  try {
    const response = yield call(updateProduct, product)
    yield put(updateProductSuccess(response.data))
  } catch (error) {
    yield put(updateProductFail(error))
  }
}

function* onDeleteProduct({ payload: product }) {
  try {
    const response = yield call(deleteProduct, product)
    yield put(deleteProductSuccess(response.data))
  } catch (error) {
    yield put(deleteProductFail(error))
  }
}

function* onDeleteImage({ payload: data }) {
  try {
    const response = yield call(deleteImage, data)
    yield put(deleteImageSuccess(data, response.data))
  } catch (error) {
    yield put(deleteImageFail(error))
  }
}

//Variants

function* fetchProductsVariants({payload: product_id}) {
  try {
    const response = yield call(getProductsVariants, product_id);
    yield put(getProductsVariantsSuccess(response.data))
  } catch (error) {
    yield put(getProductsVariantsFail(error))
  }
}

function* onAddVariant({ payload: variant }) {
  try {
    const response = yield call(addVariant, variant)
    yield put(addVariantSuccess(response.data))
    yield put(deleteCreatedVariantSuccess(variant))
  } catch (error) {
    yield put(addVariantFail(error))
  }
}

function* onUpdateVariant({payload: variant}) {
  try {
    const response = yield call(updateVariant, variant);
    yield put(updateVariantSuccess(response.data))
  } catch (error) {
    yield put(updateVariantFail(error))
  }
}

function* onDeleteVariant({ payload: variant }) {
  try {
    const response = yield call(deleteVariant, variant)
    yield put(deleteVariantSuccess(response.data))
  } catch (error) {
    yield put(deleteVariantFail(error))
  }
}

function* onAddCreatedVariants({ payload: variants }) {
  try {
    yield put(addCreatedVariantsSuccess(variants))
  } catch (error) {
    yield put(addCreatedVariantsFail(error))
  }
}

function* onDeleteCreatedVariant({ payload: variant }) {
  try {
    yield put(deleteCreatedVariantSuccess(variant))
  } catch (error) {
    yield put(deleteCreatedVariantFail(error))
  }
}

//Units

function* fetchUnits() {
  try {
    const response = yield call(getUnits)
    yield put(getUnitsSuccess(response.data))
  } catch (error) {
    yield put(getUnitsFail(error))
  }
}

function* onAddUnit({ payload: unit }) {
  try {
    const response = yield call(addUnit, unit)
    yield put(addUnitSuccess(response.data))
  } catch (error) {
    yield put(addUnitFail(error))
  }
}

function* onUpdateUnit({ payload: unit }) {
  try {
    const response = yield call(updateUnit, unit)
    yield put(updateUnitSuccess(response.data))
  } catch (error) {
    yield put(updateUnitFail(error))
  }
}

function* onDeleteUnit({ payload: unit }) {
  try {
    const response = yield call(deleteUnit, unit)
    yield put(deleteUnitSuccess(response.data))
  } catch (error) {
    yield put(deleteUnitFail(error))
  }
}

//Return Packages

function* fetchReturnPackages() {
  try {
    const response = yield call(getReturnPackages)
    yield put(getReturnPackagesSuccess(response.data))
  } catch (error) {
    yield put(getReturnPackagesFail(error))
  }
}

function* onAddReturnPackage({ payload: returnPackage }) {
  try {
    const response = yield call(addReturnPackage, returnPackage)
    yield put(addReturnPackageSuccess(response.data))
  } catch (error) {
    yield put(addReturnPackageFail(error))
  }
}

function* onUpdateReturnPackage({ payload: returnPackage }) {
  try {
    const response = yield call(updateReturnPackage, returnPackage)
    yield put(updateReturnPackageSuccess(response.data))
  } catch (error) {
    yield put(updateReturnPackageFail(error))
  }
}

function* onDeleteReturnPackage({ payload: returnPackage }) {
  try {
    const response = yield call(deleteReturnPackage, returnPackage)
    yield put(deleteReturnPackageSuccess(response.data))
  } catch (error) {
    yield put(deleteReturnPackageFail(error))
  }
}

//Features

function* fetchFeatures() {
  try {
    const response = yield call(getFeatures)
    yield put(getFeaturesSuccess(response.data))
  } catch (error) {
    yield put(getFeaturesFail(error))
  }
}

function* onAddFeature({ payload: feature }) {
  try {
    const response = yield call(addFeature, feature)
    yield put(addFeatureSuccess(response.data))
  } catch (error) {
    yield put(addFeatureFail(error))
  }
}

function* onUpdateFeature({ payload: feature }) {
  try {
    const response = yield call(updateFeature, feature)
    yield put(updateFeatureSuccess(response.data))
  } catch (error) {
    yield put(updateFeatureFail(error))
  }
}

function* onDeleteFeature({ payload: feature }) {
  try {
    const response = yield call(deleteFeature, feature)
    yield put(deleteFeatureSuccess(response.data))
  } catch (error) {
    yield put(deleteFeatureFail(error))
  }
}

//Prices

function* fetchPrices() {
  try {
    const response = yield call(getPrices)
    yield put(getPricesSuccess(response.data))
  } catch (error) {
    yield put(getPricesFail(error))
  }
}

function* onUpdatePrice({ payload: singlePrice }) {
  try {
    const response = yield call(updatePrice, singlePrice)
    yield put(updatePriceSuccess(response.data))
  } catch (error) {
    yield put(updatePriceFail(error))
  }
}

//Relations

function* onAddRelatedProduct({payload: related, product}) {
  try {
    const response = yield call(addRelatedProduct, related)
    yield put(addRelatedProductSuccess({isAdded: response.data, related, product}))
  } catch (error) {
    yield put(addRelatedProductFail(error))
  }
}

function* onDeleteRelatedProduct({ payload: related }) {
  try {
    const response = yield call(deleteRelatedProduct, related)
    yield put(deleteRelatedProductSuccess(related.product_related_id))
  } catch (error) {
    yield put(deleteRelatedProductFail(error))
  }
}

function* CatalogSaga() {
  yield takeEvery(GET_ATTRIBUTES, fetchAttributes)
  yield takeEvery(ADD_ATTRIBUTE, onAddAttribute)
  yield takeEvery(UPDATE_ATTRIBUTE, onUpdateAttribute)
  yield takeEvery(DELETE_ATTRIBUTE, onDeleteAttribute)
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_CATEGORY, onAddCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(ADD_PRODUCT, onAddProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(GET_UNITS, fetchUnits)
  yield takeEvery(ADD_UNIT, onAddUnit)
  yield takeEvery(UPDATE_UNIT, onUpdateUnit)
  yield takeEvery(DELETE_UNIT, onDeleteUnit)
  yield takeEvery(GET_RETURN_PACKAGES, fetchReturnPackages)
  yield takeEvery(ADD_RETURN_PACKAGE, onAddReturnPackage)
  yield takeEvery(UPDATE_RETURN_PACKAGE, onUpdateReturnPackage)
  yield takeEvery(DELETE_RETURN_PACKAGE, onDeleteReturnPackage)
  yield takeEvery(GET_FEATURES, fetchFeatures)
  yield takeEvery(ADD_FEATURE, onAddFeature)
  yield takeEvery(UPDATE_FEATURE, onUpdateFeature)
  yield takeEvery(DELETE_FEATURE, onDeleteFeature)
  yield takeEvery(DELETE_IMAGE, onDeleteImage)
  yield takeEvery(GET_PRICES, fetchPrices)
  yield takeEvery(UPDATE_PRICE, onUpdatePrice)
  yield takeEvery(ADD_RELATED_PRODUCT, onAddRelatedProduct)
  yield takeEvery(DELETE_RELATED_PRODUCT, onDeleteRelatedProduct)
  yield takeEvery(GET_PRODUCTS_VARIANTS, fetchProductsVariants)
  yield takeEvery(ADD_VARIANT, onAddVariant)
  yield takeEvery(UPDATE_VARIANT, onUpdateVariant)
  yield takeEvery(DELETE_VARIANT, onDeleteVariant)
  yield takeEvery(ADD_CREATED_VARIANTS, onAddCreatedVariants)
  yield takeEvery(DELETE_CREATED_VARIANT, onDeleteCreatedVariant)
}

export default CatalogSaga;
