import {
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  reports: [],
  report: []
}

const reports = (state = INIT_STATE, action) => {
  switch (action.type) {

    //Reports

    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
      }

    case GET_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
      }

    case GET_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default reports;