import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert
} from "reactstrap";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getSMSNotifications as onGetSMSNotifications,
  updateSMSNotifications as onUpdateSMSNotifications
} from "store/settings/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const NotificationsSMS = props => {
  //meta title
  document.title = "Powiadomienia SMS - Panel administracyjny";
  
  const dispatch = useDispatch();

  const { notifications } = useSelector(state => ({
    notifications: state.notifications.sms,
  }));

  const { success } = useSelector(state => ({
    success: state.notifications.success,
  }));

  const { error } = useSelector(state => ({
    error: state.notifications.error,
  }));

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      notification_id: (notifications && notifications.notification_id) || 0,
      sms_sender: (notifications && notifications.sms_sender) || '',
      register_sms_notification_on: (notifications && notifications.register_sms_notification_on) || 0,
      register_sms_notification_content: (notifications && notifications.register_sms_notification_content) || '',
      register_order_sms_notification_on: (notifications && notifications.register_order_sms_notification_on) || 0,
      register_order_sms_notification_content: (notifications && notifications.register_order_sms_notification_content) || '',
      register_order_buy_sms_notification_on: (notifications && notifications.register_order_buy_sms_notification_on) || 0,
      register_order_buy_sms_notification_content: (notifications && notifications.register_order_buy_sms_notification_content) || '',
      client_new_order_sms_notification_on: (notifications && notifications.client_new_order_sms_notification_on) || 0,
      client_new_order_sms_notification_content: (notifications && notifications.client_new_order_sms_notification_content) || '',
      client_new_order_buy_sms_notification_on: (notifications && notifications.client_new_order_buy_sms_notification_on) || 0,
      client_new_order_buy_sms_notification_content: (notifications && notifications.client_new_order_buy_sms_notification_content) || '',
      client_new_order_from_provider_sms_notification_on: (notifications && notifications.client_new_order_from_provider_sms_notification_on) || 0,
      client_new_order_from_provider_sms_notification_content: (notifications && notifications.client_new_order_from_provider_sms_notification_content) || '',
      client_status_2_order_sms_notification_on: (notifications && notifications.client_status_2_order_sms_notification_on) || 0,
      client_status_2_order_sms_notification_content: (notifications && notifications.client_status_2_order_sms_notification_content) || '',
      client_status_3_order_sms_notification_on: (notifications && notifications.client_status_3_order_sms_notification_on) || 0,
      client_status_3_order_sms_notification_content: (notifications && notifications.client_status_3_order_sms_notification_content) || '',
      client_status_4_order_sms_notification_on: (notifications && notifications.client_status_4_order_sms_notification_on) || 0,
      client_status_4_order_sms_notification_content: (notifications && notifications.client_status_4_order_sms_notification_content) || '',
      client_status_5_order_sms_notification_on: (notifications && notifications.client_status_5_order_sms_notification_on) || 0,
      client_status_5_order_sms_notification_content: (notifications && notifications.client_status_5_order_sms_notification_content) || '',
      client_status_6_order_sms_notification_on: (notifications && notifications.client_status_6_order_sms_notification_on) || 0,
      client_status_6_order_sms_notification_content: (notifications && notifications.client_status_6_order_sms_notification_content) || '',
      client_status_7_order_sms_notification_on: (notifications && notifications.client_status_7_order_sms_notification_on) || 0,
      client_status_7_order_sms_notification_content: (notifications && notifications.client_status_7_order_sms_notification_content) || '',
      client_status_8_order_sms_notification_on: (notifications && notifications.client_status_8_order_sms_notification_on) || 0,
      client_status_8_order_sms_notification_content: (notifications && notifications.client_status_8_order_sms_notification_content) || '',
      provider_new_order_sms_notification_on: (notifications && notifications.provider_new_order_sms_notification_on) || 0,
      provider_new_order_sms_notification_content: (notifications && notifications.provider_new_order_sms_notification_content) || '',
      provider_new_order_buy_sms_notification_on: (notifications && notifications.provider_new_order_buy_sms_notification_on) || 0,
      provider_new_order_buy_sms_notification_content: (notifications && notifications.provider_new_order_buy_sms_notification_content) || '',
      provider_status_2_order_sms_notification_on: (notifications && notifications.provider_status_2_order_sms_notification_on) || 0,
      provider_status_2_order_sms_notification_content: (notifications && notifications.provider_status_2_order_sms_notification_content) || '',
      provider_status_3_order_sms_notification_on: (notifications && notifications.provider_status_3_order_sms_notification_on) || 0,
      provider_status_3_order_sms_notification_content: (notifications && notifications.provider_status_3_order_sms_notification_content) || '',
      provider_status_4_order_sms_notification_on: (notifications && notifications.provider_status_4_order_sms_notification_on) || 0,
      provider_status_4_order_sms_notification_content: (notifications && notifications.provider_status_4_order_sms_notification_content) || '',
      provider_status_5_order_sms_notification_on: (notifications && notifications.provider_status_5_order_sms_notification_on) || 0,
      provider_status_5_order_sms_notification_content: (notifications && notifications.provider_status_5_order_sms_notification_content) || '',
      provider_status_6_order_sms_notification_on: (notifications && notifications.provider_status_6_order_sms_notification_on) || 0,
      provider_status_6_order_sms_notification_content: (notifications && notifications.provider_status_6_order_sms_notification_content) || '',
      provider_status_7_order_sms_notification_on: (notifications && notifications.provider_status_7_order_sms_notification_on) || 0,
      provider_status_7_order_sms_notification_content: (notifications && notifications.provider_status_7_order_sms_notification_content) || '',
      provider_status_8_order_sms_notification_on: (notifications && notifications.provider_status_8_order_sms_notification_on) || 0,
      provider_status_8_order_sms_notification_content: (notifications && notifications.provider_status_8_order_sms_notification_content) || '',
      new_message_sms_notification_on: (notifications && notifications.new_message_sms_notification_on) || 0,
      new_message_sms_notification_content: (notifications && notifications.new_message_sms_notification_content) || '',
      new_comment_sms_notification_on: (notifications && notifications.new_comment_sms_notification_on) || 0,
      new_comment_sms_notification_content: (notifications && notifications.new_comment_sms_notification_content) || '',
      accepted_comment_sms_notification_on: (notifications && notifications.accepted_comment_sms_notification_on) || 0,
      accepted_comment_sms_notification_content: (notifications && notifications.accepted_comment_sms_notification_content) || '',
      rejected_comment_sms_notification_on: (notifications && notifications.rejected_comment_sms_notification_on) || 0,
      rejected_comment_sms_notification_content: (notifications && notifications.rejected_comment_sms_notification_content) || '',
    },
    validationSchema: Yup.object({
      sms_sender: Yup.string().required("To pole jest wymagane."),
    }),
    onSubmit: (values) => {
      const update = {
        notification_id: notifications.notification_id,
        sms_sender: values.sms_sender,
        register_sms_notification_on: values.register_sms_notification_on,
        register_sms_notification_content: values.register_sms_notification_content,
        register_order_sms_notification_on: values.register_order_sms_notification_on,
        register_order_sms_notification_content: values.register_order_sms_notification_content,
        register_order_buy_sms_notification_on: values.register_order_buy_sms_notification_on,
        register_order_buy_sms_notification_content: values.register_order_buy_sms_notification_content,
        client_new_order_sms_notification_on: values.client_new_order_sms_notification_on,
        client_new_order_sms_notification_content: values.client_new_order_sms_notification_content,
        client_new_order_buy_sms_notification_on: values.client_new_order_buy_sms_notification_on,
        client_new_order_buy_sms_notification_content: values.client_new_order_buy_sms_notification_content,
        client_new_order_from_provider_sms_notification_on: values.client_new_order_from_provider_sms_notification_on,
        client_new_order_from_provider_sms_notification_content: values.client_new_order_from_provider_sms_notification_content,
        client_status_2_order_sms_notification_on: values.client_status_2_order_sms_notification_on,
        client_status_2_order_sms_notification_content: values.client_status_2_order_sms_notification_content,
        client_status_3_order_sms_notification_on: values.client_status_3_order_sms_notification_on,
        client_status_3_order_sms_notification_content: values.client_status_3_order_sms_notification_content,
        client_status_4_order_sms_notification_on: values.client_status_4_order_sms_notification_on,
        client_status_4_order_sms_notification_content: values.client_status_4_order_sms_notification_content,
        client_status_5_order_sms_notification_on: values.client_status_5_order_sms_notification_on,
        client_status_5_order_sms_notification_content: values.client_status_5_order_sms_notification_content,
        client_status_6_order_sms_notification_on: values.client_status_6_order_sms_notification_on,
        client_status_6_order_sms_notification_content: values.client_status_6_order_sms_notification_content,
        client_status_7_order_sms_notification_on: values.client_status_7_order_sms_notification_on,
        client_status_7_order_sms_notification_content: values.client_status_7_order_sms_notification_content,
        client_status_8_order_sms_notification_on: values.client_status_8_order_sms_notification_on,
        client_status_8_order_sms_notification_content: values.client_status_8_order_sms_notification_content,
        provider_new_order_sms_notification_on: values.provider_new_order_sms_notification_on,
        provider_new_order_sms_notification_content: values.provider_new_order_sms_notification_content,
        provider_new_order_buy_sms_notification_on: values.provider_new_order_buy_sms_notification_on,
        provider_new_order_buy_sms_notification_content: values.provider_new_order_buy_sms_notification_content,
        provider_status_2_order_sms_notification_on: values.provider_status_2_order_sms_notification_on,
        provider_status_2_order_sms_notification_content: values.provider_status_2_order_sms_notification_content,
        provider_status_3_order_sms_notification_on: values.provider_status_3_order_sms_notification_on,
        provider_status_3_order_sms_notification_content: values.provider_status_3_order_sms_notification_content,
        provider_status_4_order_sms_notification_on: values.provider_status_4_order_sms_notification_on,
        provider_status_4_order_sms_notification_content: values.provider_status_4_order_sms_notification_content,
        provider_status_5_order_sms_notification_on: values.provider_status_5_order_sms_notification_on,
        provider_status_5_order_sms_notification_content: values.provider_status_5_order_sms_notification_content,
        provider_status_6_order_sms_notification_on: values.provider_status_6_order_sms_notification_on,
        provider_status_6_order_sms_notification_content: values.provider_status_6_order_sms_notification_content,
        provider_status_7_order_sms_notification_on: values.provider_status_7_order_sms_notification_on,
        provider_status_7_order_sms_notification_content: values.provider_status_7_order_sms_notification_content,
        provider_status_8_order_sms_notification_on: values.provider_status_8_order_sms_notification_on,
        provider_status_8_order_sms_notification_content: values.provider_status_8_order_sms_notification_content,
        new_message_sms_notification_on: values.new_message_sms_notification_on,
        new_message_sms_notification_content: values.new_message_sms_notification_content,
        new_comment_sms_notification_on: values.new_comment_sms_notification_on,
        new_comment_sms_notification_content: values.new_comment_sms_notification_content,
        accepted_comment_sms_notification_on: values.accepted_comment_sms_notification_on,
        accepted_comment_sms_notification_content: values.accepted_comment_sms_notification_content,
        rejected_comment_sms_notification_on: values.rejected_comment_sms_notification_on,
        rejected_comment_sms_notification_content: values.rejected_comment_sms_notification_content,
      }
      // update notifications
      dispatch(onUpdateSMSNotifications(update));
    },
  });

  useEffect(() => {
    if (!notifications) {
      dispatch(onGetSMSNotifications());
    }
  }, [dispatch, notifications]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_SMS_NOTIFICATIONS_SUCCESS",
        });
      }, 5000)
      
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_SMS_NOTIFICATIONS_FAIL",
        });
      }, 5000)
      
    }
  }, [error]);

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Ustawienia" breadcrumbItem="Powiadomienia SMS" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {(success || error) && 
                      <Row>
                        <Col lg="auto">
                          <Alert isOpen={success.open}>
                            {success.text}
                          </Alert>
                          <Alert isOpen={error.open} color="danger">
                            {error.text}
                          </Alert>
                        </Col>
                      </Row>}
                      <Row>
                        <Col lg="12 mb-3">
                          <Label className="form-label">Nazwa nadawcy</Label>
                          <Input
                            name="sms_sender"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sms_sender}
                            invalid={
                              validation.touched.sms_sender && validation.errors.sms_sender ? true : false
                            }
                          />
                          {validation.touched.sms_sender && validation.errors.sms_sender ? (
                            <FormFeedback type="invalid">{validation.errors.sms_sender}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row form>
                        <Col className="col-12">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleCustom("1");
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Rejestracja</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleCustom("2");
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Zamówienia - klient</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                  toggleCustom("3");
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Zamówienia - sprzedawca</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                  toggleCustom("4");
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Inne</span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId="1">
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.register_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('register_sms_notification_on', validation.values.register_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Rejestracja</Label>
                                  </FormGroup>
                                  <Input
                                    name="register_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.register_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.register_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('register_order_sms_notification_on', validation.values.register_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Rejestracja przy składaniu zamówienia / zapytania</Label>
                                  </FormGroup>
                                  <Input
                                    name="register_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.register_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.register_order_buy_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('register_order_buy_sms_notification_on', validation.values.register_order_buy_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Rejestracja ??</Label>
                                  </FormGroup>
                                  <Input
                                    name="register_order_buy_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.register_order_buy_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="2">
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_new_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_new_order_sms_notification_on', validation.values.client_new_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowe zapytanie / wycena</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_new_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_new_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_new_order_buy_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_new_order_buy_sms_notification_on', validation.values.client_new_order_buy_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowe zamówienie</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_new_order_buy_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_new_order_buy_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_2_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_2_order_sms_notification_on', validation.values.client_status_2_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Wycenione zapytanie</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_2_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_2_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_3_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_3_order_sms_notification_on', validation.values.client_status_3_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Wycena zaakceptowana</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_3_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_3_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_4_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_4_order_sms_notification_on', validation.values.client_status_4_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Opłacono</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_4_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_4_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_5_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_5_order_sms_notification_on', validation.values.client_status_5_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Dostarczono</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_5_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_5_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_6_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_6_order_sms_notification_on', validation.values.client_status_6_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Brak wyceny</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_6_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_6_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_7_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_7_order_sms_notification_on', validation.values.client_status_7_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Brak akceptacji</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_7_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_7_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_status_8_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_status_8_order_sms_notification_on', validation.values.client_status_8_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Anulowano</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_status_8_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_status_8_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.client_new_order_from_provider_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('client_new_order_from_provider_sms_notification_on', validation.values.client_new_order_from_provider_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowa oferta od dostawcy</Label>
                                  </FormGroup>
                                  <Input
                                    name="client_new_order_from_provider_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_new_order_from_provider_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="3">
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_new_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_new_order_sms_notification_on', validation.values.provider_new_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowe zapytanie / wycena</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_new_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_new_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_new_order_buy_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_new_order_buy_sms_notification_on', validation.values.provider_new_order_buy_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowe zamówienie</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_new_order_buy_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_new_order_buy_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_2_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_2_order_sms_notification_on', validation.values.provider_status_2_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Wycenione zapytanie</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_2_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_2_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_3_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_3_order_sms_notification_on', validation.values.provider_status_3_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Wycena zaakceptowana</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_3_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_3_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_4_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_4_order_sms_notification_on', validation.values.provider_status_4_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Opłacono</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_4_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_4_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_5_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_5_order_sms_notification_on', validation.values.provider_status_5_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Dostarczono</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_5_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_5_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_6_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_6_order_sms_notification_on', validation.values.provider_status_6_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Brak wyceny</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_6_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_6_order_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_7_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_7_order_sms_notification_on', validation.values.provider_status_7_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Brak akceptacji</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_7_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_7_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.provider_status_8_order_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('provider_status_8_order_sms_notification_on', validation.values.provider_status_8_order_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Anulowano</Label>
                                  </FormGroup>
                                  <Input
                                    name="provider_status_8_order_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.provider_status_8_order_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="4">
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.new_message_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('new_message_sms_notification_on', validation.values.new_message_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowa wiadomość</Label>
                                  </FormGroup>
                                  <Input
                                    name="new_message_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.new_message_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.new_comment_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('new_comment_sms_notification_on', validation.values.new_comment_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Nowy komentarz</Label>
                                  </FormGroup>
                                  <Input
                                    name="new_comment_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.new_comment_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.accepted_comment_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('accepted_comment_sms_notification_on', validation.values.accepted_comment_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Komentarz zaakceptowany</Label>
                                  </FormGroup>
                                  <Input
                                    name="accepted_comment_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.accepted_comment_sms_notification_content}
                                  />
                                </Col>
                                <Col lg="6 mb-3">
                                  <FormGroup switch>
                                    <Input
                                      type="switch"
                                      checked={validation.values.rejected_comment_sms_notification_on}
                                      onChange={() => {
                                        validation.setFieldValue('rejected_comment_sms_notification_on', validation.values.rejected_comment_sms_notification_on ? 0 : 1);
                                      }}
                                    />
                                    <Label check>Komentarz odrzucony</Label>
                                  </FormGroup>
                                  <Input
                                    name="rejected_comment_sms_notification_content"
                                    type="textarea"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    value={validation.values.rejected_comment_sms_notification_content}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <Row>
                              <Col>
                                <button
                                  type="submit"
                                  className="btn btn-success save-product"
                                >Zapisz</button>
                              </Col>
                            </Row>
                          </TabContent>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(NotificationsSMS);
