import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Alert
} from "reactstrap"

import { useFormik, FormikProvider } from "formik";

import {
  addRelatedProduct as onAddRelatedProduct,
  deleteRelatedProduct as onDeleteRelatedProduct,
} from "store/catalog/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Relations = props => {
  const dispatch = useDispatch();
  
  const { products, product_id, related } = props;
  const [product, setProduct] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      product_id: product_id,
      product_related_id: (product && product.product_id) || '',
    },
    onSubmit: (values) => {},
  });

  const { success } = useSelector(state => ({
    success: state.catalog.dataRelated.success,
  }))

  const { error } = useSelector(state => ({
    error: state.catalog.dataRelated.error,
  }))

  const choiceColumn = [
    {
      dataField: "product_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "product_sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "images",
      text: "Zdjęcie",
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent[0] ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"
                }
                width="50"
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/products/50x50/" + cellContent[0].product_image
                }
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "product_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "manufacturer.name",
      text: "Producent",
      sort: true,
    },
    {
      dataField: "product_active",
      text: "Aktywny",
      sort: true,
      formatter: cellContent =>
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleProductClick(row)}
            >
              <i className="mdi mdi-plus-thick font-size-18" id="addtooltip" />
              <UncontrolledTooltip placement="top" target="addtooltip">
                Dodaj
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ];
 
  const relationsColumn = [
    {
      dataField: "product_related_id",
      text: "ID pow.",
      sort: true,
      hidden: true
    },
    {
      dataField: "product.product_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "product.product_sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "product.product_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "product.product_active",
      text: "Aktywny",
      sort: true,
      formatter: cellContent =>
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteRelated(row)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ];

  const handleProductClick = arg => {
    const product = arg;
    setProduct(product);
  };

  useEffect(() => {
    if (validation.values.product_related_id !== '') {
      dispatch(onAddRelatedProduct(validation.values, product));
    }
  }, [dispatch, validation.values.product_related_id])

  //delete related
  const handleDeleteRelated = (product) => {
    if (product.product_id && product.product_related_id) {
      dispatch(onDeleteRelatedProduct(product));
    }
  };

  const { SearchBar } = Search;

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_RELATED_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_RELATED_FAIL",
        })
      }, 5000)
    }
  }, [error])

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  const defaultSortedRelated = [
    {
      dataField: "product_related_id",
      order: "desc",
    },
  ]

  const defaultSortedChoice = [
    {
      dataField: "product_id",
      order: "desc",
    },
  ]

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <div className="page-content" style={{paddingTop: '0'}}>
          <Container fluid>
            <Row>
              <Col lg="12">
                {related.length > 0 ? 
                  <Card>
                    <CardBody>
                      <Col sm="12">
                        <h3>Produkty powiązane</h3>
                      </Col>
                      {(success || error) && (
                        <Row>
                          <Col lg="auto">
                            <Alert isOpen={success.open}>{success.text}</Alert>
                            <Alert isOpen={error.open} color="danger">
                              {error.text}
                            </Alert>
                          </Col>
                        </Row>
                      )}
                      <ToolkitProvider
                        keyField="product_related_id"
                        data={related}
                        columns={relationsColumn}
                        bootstrap4
                        search
                      >
                        {props => (
                          <React.Fragment>
                            <Row>
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...props.searchProps}
                                      placeholder="Szukaj..."
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  {...props.baseProps}
                                  bordered={false}
                                  defaultSorted={defaultSortedRelated}
                                  pagination={paginationFactory()}
                                  classes={"align-middle"}
                                  headerWrapperClasses={"table-light"}
                                  noDataIndication={() => <NoDataIndication />}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                : null
                }
                <Card>
                  <CardBody>
                    <Col sm="12">
                      <h3>Wybierz produkty do powiązania</h3>
                    </Col>
                    <ToolkitProvider
                      keyField="product_id"
                      data={products}
                      columns={choiceColumn}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSortedChoice}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Relations)
