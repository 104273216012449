import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_PP_PRODUCTS,
  ADD_PP_PRODUCT,
  UPDATE_PP_PRODUCT,
  DELETE_PP_PRODUCT,
  GET_PP_CATEGORIES,
  ADD_PP_CATEGORY,
  UPDATE_PP_CATEGORY,
  DELETE_PP_CATEGORY,
} from "./actionTypes"

import {
  getPPProductsSuccess,
  getPPProductsFail,
  addPPProductSuccess,
  addPPProductFail,
  updatePPProductSuccess,
  updatePPProductFail,
  getPPCategoriesSuccess,
  getPPCategoriesFail,
  deletePPProductSuccess,
  deletePPProductFail,
  addPPCategorySuccess,
  addPPCategoryFail,
  updatePPCategorySuccess,
  updatePPCategoryFail,
  deletePPCategorySuccess,
  deletePPCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPPProducts,
  addPPProduct,
  updatePPProduct,
  deletePPProduct,
  getPPCategories,
  addPPCategory,
  updatePPCategory,
  deletePPCategory,
} from "helpers/backend_helper"

//Products

function* fetchPPProducts() {
  try {
    const response = yield call(getPPProducts)
    yield put(getPPProductsSuccess(response.data))
  } catch (error) {
    yield put(getPPProductsFail(error))
  }
}

function* onAddPPProduct({ payload: product }) {
  try {
    const response = yield call(addPPProduct, product)
    yield put(addPPProductSuccess(response.data))
  } catch (error) {
    yield put(addPPProductFail(error))
  }
}

function* onUpdatePPProduct({ payload: product }) {
  try {
    const response = yield call(updatePPProduct, product)
    yield put(updatePPProductSuccess(response.data))
  } catch (error) {
    yield put(updatePPProductFail(error))
  }
}

function* onDeletePPProduct({ payload: product }) {
  try {
    const response = yield call(deletePPProduct, product)
    yield put(deletePPProductSuccess(response.data))
  } catch (error) {
    yield put(deletePPProductFail(error))
  }
}

//Categories

function* fetchPPCategories() {
  try {
    const response = yield call(getPPCategories)
    yield put(getPPCategoriesSuccess(response.data))
  } catch (error) {
    yield put(getPPCategoriesFail(error))
  }
}

function* onAddPPCategory({ payload: category }) {
  try {
    const response = yield call(addPPCategory, category)
    yield put(addPPCategorySuccess(response.data))
  } catch (error) {
    yield put(addPPCategoryFail(error))
  }
}

function* onUpdatePPCategory({ payload: category }) {
  try {
    const response = yield call(updatePPCategory, category)
    yield put(updatePPCategorySuccess(response.data))
  } catch (error) {
    yield put(updatePPCategoryFail(error))
  }
}

function* onDeletePPCategory({ payload: category }) {
  try {
    const response = yield call(deletePPCategory, category)
    yield put(deletePPCategorySuccess(response.data))
  } catch (error) {
    yield put(deletePPCategoryFail(error))
  }
}

function* PPSaga() {
  yield takeEvery(GET_PP_PRODUCTS, fetchPPProducts)
  yield takeEvery(ADD_PP_PRODUCT, onAddPPProduct)
  yield takeEvery(UPDATE_PP_PRODUCT, onUpdatePPProduct)
  yield takeEvery(DELETE_PP_PRODUCT, onDeletePPProduct)
  yield takeEvery(GET_PP_CATEGORIES, fetchPPCategories)
  yield takeEvery(ADD_PP_CATEGORY, onAddPPCategory)
  yield takeEvery(UPDATE_PP_CATEGORY, onUpdatePPCategory)
  yield takeEvery(DELETE_PP_CATEGORY, onDeletePPCategory)
}

export default PPSaga