import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Badge,
  Spinner,
  Button,
} from "reactstrap"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import { useFormik, FormikProvider } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getCategories as onGetCategories,
  addCategory as onAddCategory,
  updateCategory as onUpdateCategory,
  deleteCategory as onDeleteCategory,
} from "store/catalog/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

const Categories = props => {
  //meta title
  document.title = "Kategorie - Panel administracyjny"

  const dispatch = useDispatch()

  const [category, setCategory] = useState(null)

  //validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: (category && category.category_id) || "",
      category_parent_id: (category && category.category_parent_id) || "",
      category_name: (category && category.category_name) || "",
      category_description: (category && category.category_description) || "",
      category_icon: (category && category.category_icon) || "",
      category_image: (category && category.category_image) || "",
      category_image_alt: (category && category.category_image_alt) || "",
      category_image_title: (category && category.category_image_title) || "",
      category_meta_title: (category && category.category_meta_title) || "",
      category_meta_keywords:
        (category && category.category_meta_keywords) || "",
      category_meta_description:
        (category && category.category_meta_description) || "",
      category_active: (category && category.category_active) || 0,
    },
    validationSchema: Yup.object({
      category_name: Yup.string().required("To pole jest wymagane"),
      category_meta_title: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCategory = {
          category_id: category ? category.category_id : 0,
          category_parent_id: values.category_parent_id,
          category_name: values.category_name,
          category_description: values.category_description,
          category_icon: values.category_icon,
          category_image: values.category_image,
          category_image_alt: values.category_image_alt,
          category_image_title: values.category_image_title,
          category_meta_title: values.category_meta_title,
          category_meta_keywords: values.category_meta_keywords,
          category_meta_description: values.category_meta_description,
          category_active: values.category_active,
        }
        // update category
        dispatch(onUpdateCategory(updateCategory))
        setIsEdit(false)
        validation.resetForm()
      } else {
        const newCategory = {
          category_parent_id: values["category_parent_id"],
          category_name: values["category_name"],
          category_description: values["category_description"],
          category_icon: values["category_icon"],
          category_image: values["category_image"],
          category_image_alt: values["category_image_alt"],
          category_image_title: values["category_image_title"],
          category_meta_title: values["category_meta_title"],
          category_meta_keywords: values["category_meta_keywords"],
          category_meta_description: values["category_meta_description"],
          category_active: values["category_active"],
        }
        // save new category
        dispatch(onAddCategory(newCategory))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { categories } = useSelector(state => ({
    categories: state.catalog.dataCategories.categories,
  }))

  const { success } = useSelector(state => ({
    success: state.catalog.dataCategories.success,
  }))

  const { error } = useSelector(state => ({
    error: state.catalog.dataCategories.error,
  }))

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "category_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "category_image",
      text: "Zdjęcie",
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"
                }
                width="50"
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/products/50x50/" +
                  cellContent
                }
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "category_icon",
      text: "Ikona",
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"
                }
                width="50"
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/categories/icon/" +
                  cellContent
                }
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "category_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "category_active",
      text: "Aktywna",
      sort: true,
      formatter: cellContent =>
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleCategoryClick(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original
                onClickDelete(row)
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetCategories())
    }
  }, [dispatch, categories])

  useEffect(() => {
    setCategory(categories)
  }, [categories])

  useEffect(() => {
    if (!isEmpty(categories) && !!isEdit) {
      setCategory(categories)
      setIsEdit(false)
    }
  }, [categories])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_CATEGORY_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_CATEGORY_FAIL",
        })
      }, 5000)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const handleCategoryClick = arg => {
    const category = arg

    setCategory({
      category_id: category.category_id,
      category_parent_id: category.category_parent_id,
      category_name: category.category_name,
      category_description: category.category_description,
      category_icon: category.category_icon,
      category_image: category.category_image,
      category_image_alt: category.category_image_alt,
      category_image_title: category.category_image_title,
      category_meta_title: category.category_meta_title,
      category_meta_keywords: category.category_meta_keywords,
      category_meta_description: category.category_meta_description,
      category_active: category.category_active,
    })

    setIsEdit(true)

    toggle()
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = category => {
    setCategory(category)
    setDeleteModal(true)
  }

  const handleDeleteCategory = () => {
    if (category.category_id) {
      dispatch(onDeleteCategory(category))
      setDeleteModal(false)
    }
  }

  const handleCategoryClicks = () => {
    setCategory("")
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "category_id",
      order: "asc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCategory}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Katalog" breadcrumbItem="Kategorie" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && (
                      <Row>
                        <Col lg="auto">
                          <Alert isOpen={success.open}>{success.text}</Alert>
                          <Alert isOpen={error.open} color="danger">
                            {error.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <ToolkitProvider
                      keyField="category_id"
                      data={categories}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleCategoryClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj kategorię
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edytuj kategorię"
                                    : "Dodaj kategorię"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <Nav
                                          tabs
                                          className="nav-tabs-custom nav-justified"
                                        >
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "1",
                                              })}
                                              onClick={() => {
                                                toggleCustom("1")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Dane kategorii
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "2",
                                              })}
                                              onClick={() => {
                                                toggleCustom("2")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Zdjęcia
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "3",
                                              })}
                                              onClick={() => {
                                                toggleCustom("3")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Dane meta
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                        <TabContent
                                          activeTab={customActiveTab}
                                          className="p-3 text-muted"
                                        >
                                          <TabPane tabId="1">
                                            <Row>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Nazwa
                                                  </Label>
                                                  <Input
                                                    name="category_name"
                                                    type="text"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .category_name
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .category_name &&
                                                      validation.errors
                                                        .category_name
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .category_name &&
                                                  validation.errors
                                                    .category_name ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .category_name
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Kategoria nadrzędna
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="category_parent_id"
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .category_parent_id ||
                                                      ""
                                                    }
                                                  >
                                                    <option value="0">
                                                      -- Wybierz --
                                                    </option>
                                                    {categories.map(
                                                      category => (
                                                        <option
                                                          value={
                                                            category.category_id
                                                          }
                                                          key={
                                                            category.category_id
                                                          }
                                                        >
                                                          {
                                                            category.category_name
                                                          }
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                </div>
                                              </Col>
                                            </Row>
                                            <Col lg="12">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Opis
                                                </Label>
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  name="category_description"
                                                  data={
                                                    validation.values
                                                      .category_description
                                                  }
                                                  onReady={editor => {
                                                    // console.log(editor);
                                                  }}
                                                  onChange={(event, editor) => {
                                                    validation.setFieldValue(
                                                      "category_description",
                                                      editor.getData()
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                            <Col lg="2">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Aktywna
                                                </Label>
                                                <Input
                                                  type="select"
                                                  name="category_active"
                                                  className="form-select"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .category_active || 0
                                                  }
                                                >
                                                  <option value="0">Nie</option>
                                                  <option value="1">Tak</option>
                                                </Input>
                                              </div>
                                            </Col>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <Row>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Ikona kategorii
                                                  </Label>
                                                  {validation.values
                                                    .category_icon ? (
                                                    <div>
                                                      <img
                                                        src={`https://budujpl.s3.eu-north-1.amazonaws.com/uploads/categories/50x50/${validation.values.category_icon}`}
                                                      />
                                                    </div>
                                                  ) : null}
                                                  <Input
                                                    type="file"
                                                    name="category_icon"
                                                    onChange={event => {
                                                      validation.setFieldValue(
                                                        "category_icon",
                                                        event.currentTarget
                                                          .files[0]
                                                      )
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Zdjęcie kategorii
                                                  </Label>
                                                  {validation.values
                                                    .category_image ? (
                                                    <div>
                                                      <img
                                                        src={`https://budujpl.s3.eu-north-1.amazonaws.com/uploads/categories/50x50/${validation.values.category_image}`}
                                                      />
                                                    </div>
                                                  ) : null}
                                                  <Input
                                                    type="file"
                                                    name="category_image"
                                                    onChange={event => {
                                                      validation.setFieldValue(
                                                        "category_image",
                                                        event.currentTarget
                                                          .files[0]
                                                      )
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Tytuł (zdjęcie)
                                                  </Label>
                                                  <Input
                                                    name="category_image_title"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .category_image_title
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Tekst alternatywny (zdjęcie)
                                                  </Label>
                                                  <Input
                                                    name="category_image_alt"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .category_image_alt
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="3">
                                            <Col lg="12">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Tytuł
                                                </Label>
                                                <Input
                                                  name="category_meta_title"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .category_meta_title
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .category_meta_title &&
                                                    validation.errors
                                                      .category_meta_title
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .category_meta_title &&
                                                validation.errors
                                                  .category_meta_title ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .category_meta_title
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            </Col>
                                            <Col lg="12">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Słowa kluczowe
                                                </Label>
                                                <Input
                                                  type="textarea"
                                                  id="textarea"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  rows="3"
                                                  name="category_meta_keywords"
                                                  value={
                                                    validation.values
                                                      .category_meta_keywords
                                                  }
                                                />
                                              </div>
                                            </Col>
                                            <Col lg="12">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Opis
                                                </Label>
                                                <Input
                                                  type="textarea"
                                                  id="textarea"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  rows="3"
                                                  name="category_meta_description"
                                                  value={
                                                    validation.values
                                                      .category_meta_description
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          </TabPane>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Categories)
