import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner,
  Badge
} from "reactstrap"

import { useFormik, FormikProvider } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getPrices as onGetPrices,
  updatePrice as onUpdatePrice,
} from "store/catalog/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

const Prices = props => {
  //meta title
  document.title = "Cennik - Panel administracyjny"

  const dispatch = useDispatch()

  const [singlePrice, setPrice] = useState(null)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      price_id: (singlePrice && singlePrice.price_id) || "",
      price_middle: (singlePrice && singlePrice.price_middle) || "",
      price: (singlePrice && singlePrice.price) || "",
      price_from: (singlePrice && singlePrice.price_from) || "",
      price_to: (singlePrice && singlePrice.price_to) || "",
      promo_on: (singlePrice && singlePrice.promo_on) || 0,
      promo_home_on: (singlePrice && singlePrice.promo_home_on) || 0,
      promo_price_on: (singlePrice && singlePrice.promo_price_on) || 0,
      promo_price_date_from:
        (singlePrice && singlePrice.promo_price_date_from) || "",
      promo_price_date_to:
        (singlePrice && singlePrice.promo_price_date_to) || "",
      promo_price: (singlePrice && singlePrice.promo_price) || "",
      promo_quantity: (singlePrice && singlePrice.promo_quantity) || 0,
      product_sku: (singlePrice && singlePrice.product_sku) || "",
      product: (singlePrice && singlePrice.product) || "",
      variant: (singlePrice && singlePrice.variant) || "",
    },
    validationSchema: Yup.object({
      price: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatePrice = {
          price_id: singlePrice ? singlePrice.price_id : 0,
          price_middle: values.price_middle,
          price: values.price,
          price_from: values.price_from,
          price_to: values.price_to,
          promo_on: values.promo_on,
          promo_home_on: values.promo_home_on,
          promo_price_on: values.promo_price_on,
          promo_price_date_from: values.promo_price_date_from,
          promo_price_date_to: values.promo_price_date_to,
          promo_price: values.promo_price,
          promo_quantity: values.promo_quantity,
          product_sku: values.product_sku,
          product: values.product,
          variant: values.variant,
          // product_id: values.product.product_id,
          // product_id: values.variant.product_variant_id,
        }
        dispatch(onUpdatePrice(updatePrice))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { prices } = useSelector(state => ({
    prices: state.catalog.dataPrices.prices,
  }))

  const { success } = useSelector(state => ({
    success: state.catalog.dataPrices.success,
  }))

  const { error } = useSelector(state => ({
    error: state.catalog.dataPrices.error,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "price_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "product.product_name",
      text: "Nazwa",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
          {row.variant
            ? row.variant.product_variant_name
            : row.product
            ? row.product.product_name
            : "---"}
        </React.Fragment>
      ),
    },
    {
      dataField: "product_sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "price",
      text: "Cena (do wyceny)",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
          {cellContent} zł
          <div style={{ fontSize: "9px" }}>
            ({row.price_from + " - " + row.price_to}){" "}
          </div>
        </React.Fragment>
      ),
    },
    {
      dataField: "promo_price",
      text: "Cena (promo./KT.)",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {cellContent === 0 || cellContent === "0.00" || cellContent === ""
            ? "--"
            : cellContent + " zł"}
        </React.Fragment>
      ),
    },
    {
      dataField: "promo_on",
      text: "Promocja aktywna",
      sort: true,
      formatter: cellContent => 
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handlePriceClick(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    // if (prices && !prices.length) {
    dispatch({
      type: "CLEARING_PRICE_DATA",
    })
    dispatch(onGetPrices())
    // }
  }, [dispatch])

  useEffect(() => {
    setPrice(prices)
  }, [prices])

  useEffect(() => {
    if (!isEmpty(prices) && !!isEdit) {
      setPrice(prices)
      setIsEdit(false)
    }
  }, [prices])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PRICES_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PRICES_FAIL",
        })
      }, 5000)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const handlePriceClick = arg => {
    const singlePrice = arg

    setPrice({
      price_id: singlePrice.price_id,
      price_midle: singlePrice.price_midle,
      price: singlePrice.price,
      price_from: singlePrice.price_from,
      price_to: singlePrice.price_to,
      promo_on: singlePrice.promo_on,
      promo_home_on: singlePrice.promo_home_on,
      promo_price_on: singlePrice.promo_price_on,
      promo_price_date_from: singlePrice.promo_price_date_from,
      promo_price_date_to: singlePrice.promo_price_date_to,
      promo_price: singlePrice.promo_price,
      promo_quantity: singlePrice.promo_quantity,
      product_sku: singlePrice.product_sku,
      product: singlePrice.product,
      variant: singlePrice.variant,
    })

    setIsEdit(true)

    toggle()
  }

  const defaultSorted = [
    {
      dataField: "price_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Katalog" breadcrumbItem="Cennik" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && (
                      <Row>
                        <Col lg="auto">
                          <Alert isOpen={success.open}>{success.text}</Alert>
                          <Alert isOpen={error.open} color="danger">
                            {error.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <ToolkitProvider
                      keyField="price_id"
                      data={prices}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  Edytuj cenę
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Cena (do wyceny)
                                          </Label>
                                          <Input
                                            name="price"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.price}
                                            invalid={
                                              validation.touched.price &&
                                              validation.errors.price
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.values.price_from +
                                            " - " +
                                            validation.values.price_to}
                                          {validation.touched.price &&
                                          validation.errors.price ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.price}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Cena promocyjna / kup teraz
                                          </Label>
                                          <Input
                                            name="promo_price"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.promo_price
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Ilość promocyjna / kup teraz
                                          </Label>
                                          <Input
                                            name="promo_quantity"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.promo_quantity
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label className="mb-0">
                                            <Input
                                              name="promo_price_on"
                                              type="checkbox"
                                              className="selection-input-4"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              checked={
                                                validation.values
                                                  .promo_price_on === 1 ||
                                                validation.values.promo_price_on
                                                  .length
                                                  ? 1
                                                  : 0
                                              }
                                              value={
                                                validation.values
                                                  .promo_price_on === 1
                                                  ? 0
                                                  : 1
                                              }
                                            />{" "}
                                            Aktywuj kup teraz
                                          </Label>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label className="mb-0">
                                            <Input
                                              name="promo_on"
                                              type="checkbox"
                                              className="selection-input-4"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              checked={
                                                validation.values.promo_on ===
                                                  1 ||
                                                validation.values.promo_on
                                                  .length
                                                  ? 1
                                                  : 0
                                              }
                                              value={
                                                validation.values.promo_on === 1
                                                  ? 0
                                                  : 1
                                              }
                                            />{" "}
                                            Aktywuj promocję
                                          </Label>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label className="mb-0">
                                            <Input
                                              name="promo_home_on"
                                              type="checkbox"
                                              className="selection-input-4"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              checked={
                                                validation.values
                                                  .promo_home_on === 1 ||
                                                validation.values.promo_home_on
                                                  .length
                                                  ? 1
                                                  : 0
                                              }
                                              value={
                                                validation.values
                                                  .promo_home_on === 1
                                                  ? 0
                                                  : 1
                                              }
                                            />{" "}
                                            Aktywuj na stronie głównej
                                          </Label>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label>
                                            od
                                            <Input
                                              name="promo_price_date_from"
                                              type="date"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .promo_price_date_from
                                              }
                                            />
                                          </Label>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label>
                                            do
                                            <Input
                                              name="promo_price_date_to"
                                              type="date"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .promo_price_date_to
                                              }
                                            />
                                          </Label>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Prices)
