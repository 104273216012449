import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_INACTIVE,
  GET_USERS_INACTIVE_SUCCESS,
  GET_USERS_INACTIVE_FAIL,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_MANUFACTURERS,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_FAIL,
  ADD_MANUFACTURER,
  ADD_MANUFACTURER_SUCCESS,
  ADD_MANUFACTURER_FAIL,
  UPDATE_MANUFACTURER,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_FAIL,
  DELETE_MANUFACTURER,
  DELETE_MANUFACTURER_SUCCESS,
  DELETE_MANUFACTURER_FAIL
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersInActive = () => ({
  type: GET_USERS_INACTIVE,
})

export const getUsersInActiveSuccess = users => ({
  type: GET_USERS_INACTIVE_SUCCESS,
  payload: users,
})

export const getUsersInActiveFail = error => ({
  type: GET_USERS_INACTIVE_FAIL,
  payload: error,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

//Manufacturers

export const getManufacturers = () => ({
  type: GET_MANUFACTURERS,
})

export const getManufacturersSuccess = manufacturers => ({
  type: GET_MANUFACTURERS_SUCCESS,
  payload: manufacturers,
})

export const getManufacturersFail = error => ({
  type: GET_MANUFACTURERS_FAIL,
  payload: error,
})

export const addManufacturer = manufacturer => ({
  type: ADD_MANUFACTURER,
  payload: manufacturer,
})

export const addManufacturerSuccess = manufacturer => ({
  type: ADD_MANUFACTURER_SUCCESS,
  payload: manufacturer,
})

export const addManufacturerFail = error => ({
  type: ADD_MANUFACTURER_FAIL,
  payload: error,
})

export const updateManufacturer = manufacturer => ({
  type: UPDATE_MANUFACTURER,
  payload: manufacturer,
})

export const updateManufacturerSuccess = manufacturer => ({
  type: UPDATE_MANUFACTURER_SUCCESS,
  payload: manufacturer,
})

export const updateManufacturerFail = error => ({
  type: UPDATE_MANUFACTURER_FAIL,
  payload: error,
})

export const deleteManufacturer = manufacturer => ({
  type: DELETE_MANUFACTURER,
  payload: manufacturer,
})

export const deleteManufacturerSuccess = manufacturer => ({
  type: DELETE_MANUFACTURER_SUCCESS,
  payload: manufacturer,
})

export const deleteManufacturerFail = error => ({
  type: DELETE_MANUFACTURER_FAIL,
  payload: error,
})