import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_LOGS,
} from "./actionTypes"

import {
  getLogsSuccess,
  getLogsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLogs,
} from "helpers/backend_helper"

//Logs

function* fetchLogs() {
  try {
    const response = yield call(getLogs)
    yield put(getLogsSuccess(response.data))
  } catch (error) {
    yield put(getLogsFail(error))
  }
}

function* logsSaga() {
  yield takeEvery(GET_LOGS, fetchLogs)
}

export default logsSaga