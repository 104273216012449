import {
    GET_CMS_POSTS_SUCCESS,
    GET_CMS_POSTS_FAIL,
    ADD_CMS_POST_SUCCESS,
    ADD_CMS_POST_FAIL,
    UPDATE_CMS_POST_SUCCESS,
    UPDATE_CMS_POST_FAIL,
    DELETE_CMS_POST_SUCCESS,
    DELETE_CMS_POST_FAIL,
    GET_CMS_CATEGORIES_SUCCESS,
    GET_CMS_CATEGORIES_FAIL,
    ADD_CMS_CATEGORY_SUCCESS,
    ADD_CMS_CATEGORY_FAIL,
    UPDATE_CMS_CATEGORY_SUCCESS,
    UPDATE_CMS_CATEGORY_FAIL,
    DELETE_CMS_CATEGORY_SUCCESS,
    DELETE_CMS_CATEGORY_FAIL,
    GET_CMS_PAGES_SUCCESS,
    GET_CMS_PAGES_FAIL,
    ADD_CMS_PAGE_SUCCESS,
    ADD_CMS_PAGE_FAIL,
    UPDATE_CMS_PAGE_SUCCESS,
    UPDATE_CMS_PAGE_FAIL,
    DELETE_CMS_PAGE_SUCCESS,
    DELETE_CMS_PAGE_FAIL,
    GET_CMS_MENU_SUCCESS,
    GET_CMS_MENU_FAIL,
    ADD_CMS_MENU_SUCCESS,
    ADD_CMS_MENU_FAIL,
    UPDATE_CMS_MENU_SUCCESS,
    UPDATE_CMS_MENU_FAIL,
    DELETE_CMS_MENU_SUCCESS,
    DELETE_CMS_MENU_FAIL,
    GET_CMS_SLIDERS_SUCCESS,
    GET_CMS_SLIDERS_FAIL,
    ADD_CMS_SLIDER_SUCCESS,
    ADD_CMS_SLIDER_FAIL,
    UPDATE_CMS_SLIDER_SUCCESS,
    UPDATE_CMS_SLIDER_FAIL,
    DELETE_CMS_SLIDER_SUCCESS,
    DELETE_CMS_SLIDER_FAIL,
    GET_CMS_SLIDER_ITEMS_SUCCESS,
    GET_CMS_SLIDER_ITEMS_FAIL,
    ADD_CMS_SLIDER_ITEM_SUCCESS,
    ADD_CMS_SLIDER_ITEM_FAIL,
    UPDATE_CMS_SLIDER_ITEM_SUCCESS,
    UPDATE_CMS_SLIDER_ITEM_FAIL,
    DELETE_CMS_SLIDER_ITEM_SUCCESS,
    DELETE_CMS_SLIDER_ITEM_FAIL,
    GET_CMS_GALLERIES_SUCCESS,
    GET_CMS_GALLERIES_FAIL,
    ADD_CMS_GALLERY_SUCCESS,
    ADD_CMS_GALLERY_FAIL,
    UPDATE_CMS_GALLERY_SUCCESS,
    UPDATE_CMS_GALLERY_FAIL,
    DELETE_CMS_GALLERY_SUCCESS,
    DELETE_CMS_GALLERY_FAIL,
    GET_CMS_GALLERY_ITEMS_SUCCESS,
    GET_CMS_GALLERY_ITEMS_FAIL,
    ADD_CMS_GALLERY_ITEM_SUCCESS,
    ADD_CMS_GALLERY_ITEM_FAIL,
    UPDATE_CMS_GALLERY_ITEM_SUCCESS,
    UPDATE_CMS_GALLERY_ITEM_FAIL,
    DELETE_CMS_GALLERY_ITEM_SUCCESS,
    DELETE_CMS_GALLERY_ITEM_FAIL,
    GET_CMS_FAQS_SUCCESS,
    GET_CMS_FAQS_FAIL,
    ADD_CMS_FAQ_SUCCESS,
    ADD_CMS_FAQ_FAIL,
    UPDATE_CMS_FAQ_SUCCESS,
    UPDATE_CMS_FAQ_FAIL,
    DELETE_CMS_FAQ_SUCCESS,
    DELETE_CMS_FAQ_FAIL,
    GET_CMS_FAQ_CATEGORIES_SUCCESS,
    GET_CMS_FAQ_CATEGORIES_FAIL,
    ADD_CMS_FAQ_CATEGORY_SUCCESS,
    ADD_CMS_FAQ_CATEGORY_FAIL,
    UPDATE_CMS_FAQ_CATEGORY_SUCCESS,
    UPDATE_CMS_FAQ_CATEGORY_FAIL,
    DELETE_CMS_FAQ_CATEGORY_SUCCESS,
    DELETE_CMS_FAQ_CATEGORY_FAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    dataPosts: {
      posts: [],
      success: {
        open: false,
        text: ""
      },
      error: {
        open: false,
        text: ""
      }
    },
    dataCategories: {
      categories: [],
      success: {
        open: false,
        text: ""
      },
      error: {
        open: false,
        text: ""
      }
    },
    dataPages: {
        pages: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataGalleries: {
        galleries: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataGalleryItems: {
        items: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataSliders: {
        sliders: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataSliderItems: {
        items: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataMenu: {
        menu: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataFaq: {
        faq: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
      dataFaqCategory: {
        faqCategory: [],
        success: {
          open: false,
          text: ""
        },
        error: {
          open: false,
          text: ""
        }
      },
  }
  
  const Cms = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      //Posts
  
      case GET_CMS_POSTS_SUCCESS:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            posts: action.payload
          },
        }
  
      case GET_CMS_POSTS_FAIL:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_POST_SUCCESS:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            posts: [...state.dataPosts.posts, action.payload],
            success: {
              open: true,
              text: "Artykuł został dodany."
            }
          }
        }
    
      case ADD_CMS_POST_FAIL:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            error: {
              open: true,
              text: "Podczas dodawania artykułu wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_POST_SUCCESS:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            posts: state.dataPosts.posts.map(post =>
              post.id.toString() === action.payload.id.toString()
                ? { post, ...action.payload }
                : post
            ),
            success: {
              open: true,
              text: "Artykuł został zaktualizowany."
            } 
          }
        }
  
      case UPDATE_CMS_POST_FAIL:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            error: {
              open: true,
              text: "Podczas aktualizacji artykułu wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_POST_SUCCESS:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            posts: state.dataPosts.posts.filter(post => 
              post.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Artykuł został usunięty."
            } 
          }
        }
  
      case DELETE_CMS_POST_FAIL:
        return {
          ...state,
          dataPosts: {
            ...state.dataPosts,
            error: {
              open: true,
              text: "Podczas usuwania artykułu wystąpił błąd, spróbuj ponownie."
            }
          }
        }
  
      //Categories
  
      case GET_CMS_CATEGORIES_SUCCESS:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            categories: action.payload
          },
        }
  
      case GET_CMS_CATEGORIES_FAIL:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_CATEGORY_SUCCESS:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            categories: [...state.dataCategories.categories, action.payload],
            success: {
              open: true,
              text: "Kategoria została dodana."
            }
          }
        }
    
      case ADD_CMS_CATEGORY_FAIL:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            error: {
              open: true,
              text: "Podczas dodawania kategorii wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_CATEGORY_SUCCESS:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            categories: state.dataCategories.categories.map(category =>
              category.id.toString() === action.payload.id.toString()
                ? { category, ...action.payload }
                : category
            ),
            success: {
              open: true,
              text: "Kategoria została zaktualizowana."
            } 
          }
        }
  
      case UPDATE_CMS_CATEGORY_FAIL:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            error: {
              open: true,
              text: "Podczas aktualizacji kategorii wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_CATEGORY_SUCCESS:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            categories: state.dataCategories.categories.filter(category => 
              category.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Kategoria została usunięta."
            } 
          }
        }
  
      case DELETE_CMS_CATEGORY_FAIL:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            error: {
              open: true,
              text: "Podczas usuwania kategorii wystąpił błąd, spróbuj ponownie."
            }
          }
        }

    //Pages
  
    case GET_CMS_PAGES_SUCCESS:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            pages: action.payload
          },
        }
  
      case GET_CMS_PAGES_FAIL:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_PAGE_SUCCESS:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            pages: [...state.dataPages.pages, action.payload],
            success: {
              open: true,
              text: "Strona została dodana."
            }
          }
        }
    
      case ADD_CMS_PAGE_FAIL:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            error: {
              open: true,
              text: "Podczas dodawania strony wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_PAGE_SUCCESS:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            pages: state.dataPages.pages.map(page =>
              page.is.toString() === action.payload.id.toString()
                ? { page, ...action.payload }
                : page
            ),
            success: {
              open: true,
              text: "Strona została zaktualizowana."
            } 
          }
        }
  
      case UPDATE_CMS_PAGE_FAIL:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            error: {
              open: true,
              text: "Podczas aktualizacji strony wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_PAGE_SUCCESS:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            pages: state.dataPages.pages.filter(page => 
              page.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Strona została usunięta."
            } 
          }
        }
  
      case DELETE_CMS_PAGE_FAIL:
        return {
          ...state,
          dataPages: {
            ...state.dataPages,
            error: {
              open: true,
              text: "Podczas usuwania strony wystąpił błąd, spróbuj ponownie."
            }
          }
        }

    //Menu
  
    case GET_CMS_MENU_SUCCESS:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            posts: action.payload
          },
        }
  
      case GET_CMS_MENU_FAIL:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_MENU_SUCCESS:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            menu: [...state.dataMenu.menu, action.payload],
            success: {
              open: true,
              text: "Menu zostało dodane."
            }
          }
        }
    
      case ADD_CMS_MENU_FAIL:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            error: {
              open: true,
              text: "Podczas dodawania menu wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_MENU_SUCCESS:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            menu: state.dataMenu.menu.map(menu =>
              menu.id.toString() === action.payload.id.toString()
                ? { menu, ...action.payload }
                : menu
            ),
            success: {
              open: true,
              text: "Menu zostało zaktualizowane."
            } 
          }
        }
  
      case UPDATE_CMS_MENU_FAIL:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            error: {
              open: true,
              text: "Podczas aktualizacji menu wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_MENU_SUCCESS:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            menu: state.dataMenu.menu.filter(menu => 
              menu.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Menu zostało usunięte."
            } 
          }
        }
  
      case DELETE_CMS_MENU_FAIL:
        return {
          ...state,
          dataMenu: {
            ...state.dataMenu,
            error: {
              open: true,
              text: "Podczas usuwania menu wystąpił błąd, spróbuj ponownie."
            }
          }
        }
    
    //Galleries
  
    case GET_CMS_GALLERIES_SUCCESS:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            galleries: action.payload
          },
        }
  
      case GET_CMS_GALLERIES_FAIL:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_GALLERY_SUCCESS:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            galleries: [...state.dataGallery.gallery, action.payload],
            success: {
              open: true,
              text: "Galeria została dodana."
            }
          }
        }
    
      case ADD_CMS_GALLERY_FAIL:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            error: {
              open: true,
              text: "Podczas dodawania galerii wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_GALLERY_SUCCESS:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            gallery: state.dataGallery.gallery.map(menu =>
              gallery.id.toString() === action.payload.id.toString()
                ? { gallery, ...action.payload }
                : gallery
            ),
            success: {
              open: true,
              text: "Galeria została zaktualizowana."
            } 
          }
        }
  
      case UPDATE_CMS_GALLERY_FAIL:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            error: {
              open: true,
              text: "Podczas aktualizacji galerii wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_GALLERY_SUCCESS:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            gallery: state.dataGallery.gallery.filter(menu => 
              gallery.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Galeria została usunięta."
            } 
          }
        }
  
      case DELETE_CMS_GALLERY_FAIL:
        return {
          ...state,
          dataGallery: {
            ...state.dataGallery,
            error: {
              open: true,
              text: "Podczas usuwania galerii wystąpił błąd, spróbuj ponownie."
            }
          }
        }

        // Galleries items

        case GET_CMS_GALLERY_ITEMS_SUCCESS:
          return {
            ...state,
            dataGalleryItems: {
              ...state.dataGalleryItems,
              items: action.payload
            },
          }
    
        case GET_CMS_GALLERY_ITEMS_FAIL:
          return {
            ...state,
            dataGalleryItems: {
              ...state.dataGalleryItems,
              // error: action.payload,
            }
          }
    
        case ADD_CMS_GALLERY_ITEM_SUCCESS:
          return {
            ...state,
            dataGalleryItem: {
              ...state.dataGalleryItem,
              items: [...state.dataGalleryItems.item, action.payload],
              success: {
                open: true,
                text: "Pozycja galerii została dodana."
              }
            }
          }
      
        case ADD_CMS_GALLERY_ITEM_FAIL:
          return {
            ...state,
            dataGalleryItem: {
              ...state.dataGalleryItem,
              error: {
                open: true,
                text: "Podczas dodawania pozycji galerii wystąpił błąd, spróbuj ponownie.",
              }
            }
          }
    
        case UPDATE_CMS_GALLERY_ITEM_SUCCESS:
          return {
            ...state,
            dataGalleryItem: {
              ...state.dataGalleryItem,
              item: state.dataGalleryItem.item.map(menu =>
                item.id.toString() === action.payload.id.toString()
                  ? { item, ...action.payload }
                  : item
              ),
              success: {
                open: true,
                text: "Pozycja galerii została zaktualizowana."
              } 
            }
          }
    
        case UPDATE_CMS_GALLERY_ITEM_FAIL:
          return {
            ...state,
            dataGalleryItem: {
              ...state.dataGalleryItem,
              error: {
                open: true,
                text: "Podczas aktualizacji pozycji galerii wystąpił błąd, spróbuj ponownie.",
              }
            }
          }
    
        case DELETE_CMS_GALLERY_ITEM_SUCCESS:
          return {
            ...state,
            dataGalleryItem: {
              ...state.dataGalleryItem,
              item: state.dataGalleryItem.item.filter(menu => 
                item.id.toString() !== action.payload.toString()
              ),
              success: {
                open: true,
                text: "Pozycja galerii została usunięta."
              } 
            }
          }
    
        case DELETE_CMS_GALLERY_ITEM_FAIL:
          return {
            ...state,
            dataGalleryItem: {
              ...state.dataGalleryItem,
              error: {
                open: true,
                text: "Podczas usuwania pozycji galerii wystąpił błąd, spróbuj ponownie."
              }
            }
          }

      //Sliders
  
    case GET_CMS_SLIDERS_SUCCESS:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          galleries: action.payload
        },
      }

    case GET_CMS_SLIDERS_FAIL:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          // error: action.payload,
        }
      }

    case ADD_CMS_SLIDER_SUCCESS:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          sliders: [...state.dataSlider.slider, action.payload],
          success: {
            open: true,
            text: "Slider został dodany."
          }
        }
      }
  
    case ADD_CMS_SLIDER_FAIL:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          error: {
            open: true,
            text: "Podczas dodawania slidera wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_CMS_SLIDER_SUCCESS:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          slider: state.dataSlider.slider.map(menu =>
            slider.id.toString() === action.payload.id.toString()
              ? { slider, ...action.payload }
              : slider
          ),
          success: {
            open: true,
            text: "Slider został zaktualizowany."
          } 
        }
      }

    case UPDATE_CMS_SLIDER_FAIL:
      return {
        ...state,
        dataGallery: {
          ...state.dataGallery,
          error: {
            open: true,
            text: "Podczas aktualizacji galerii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_CMS_SLIDER_SUCCESS:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          slider: state.dataSlider.slider.filter(menu => 
            slider.id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Slider został usunięty."
          } 
        }
      }

    case DELETE_CMS_SLIDER_FAIL:
      return {
        ...state,
        dataSlider: {
          ...state.dataSlider,
          error: {
            open: true,
            text: "Podczas usuwania slidera wystąpił błąd, spróbuj ponownie."
          }
        }
      }

      // Sliders items

      case GET_CMS_SLIDER_ITEMS_SUCCESS:
        return {
          ...state,
          dataSliderItems: {
            ...state.dataSliderItems,
            items: action.payload
          },
        }
  
      case GET_CMS_SLIDER_ITEMS_FAIL:
        return {
          ...state,
          dataSliderItems: {
            ...state.dataSliderItems,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_SLIDER_ITEM_SUCCESS:
        return {
          ...state,
          dataSliderItem: {
            ...state.dataSliderItem,
            items: [...state.dataSliderItem.item, action.payload],
            success: {
              open: true,
              text: "Pozycja slidera została dodana."
            }
          }
        }
    
      case ADD_CMS_SLIDER_ITEM_FAIL:
        return {
          ...state,
          dataSliderItem: {
            ...state.dataSliderItem,
            error: {
              open: true,
              text: "Podczas dodawania pozycji slidera wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_SLIDER_ITEM_SUCCESS:
        return {
          ...state,
          dataSliderItem: {
            ...state.dataSliderItem,
            item: state.dataSliderItem.item.map(menu =>
              item.id.toString() === action.payload.id.toString()
                ? { item, ...action.payload }
                : item
            ),
            success: {
              open: true,
              text: "Pozycja slidera została zaktualizowana."
            } 
          }
        }
  
      case UPDATE_CMS_SLIDER_ITEM_FAIL:
        return {
          ...state,
          dataSliderItem: {
            ...state.dataSliderItem,
            error: {
              open: true,
              text: "Podczas aktualizacji pozycji slidera wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_SLIDER_ITEM_SUCCESS:
        return {
          ...state,
          dataSliderItem: {
            ...state.dataSliderItem,
            item: state.dataSliderItem.item.filter(menu => 
              item.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Pozycja slidera została usunięta."
            } 
          }
        }
  
      case DELETE_CMS_SLIDER_ITEM_FAIL:
        return {
          ...state,
          dataSliderItem: {
            ...state.dataSliderItem,
            error: {
              open: true,
              text: "Podczas usuwania pozycji slidera wystąpił błąd, spróbuj ponownie."
            }
          }
        }

      //Faqs
  
      case GET_CMS_FAQS_SUCCESS:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            faqs: action.payload
          },
        }
  
      case GET_CMS_FAQS_FAIL:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_FAQ_SUCCESS:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            faqs: [...state.dataFaq.faqs, action.payload],
            success: {
              open: true,
              text: "Faq został dodany."
            }
          }
        }
    
      case ADD_CMS_FAQ_FAIL:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            error: {
              open: true,
              text: "Podczas dodawania faq wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_FAQ_SUCCESS:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            faqs: state.dataFaq.faqs.map(post =>
              faq.id.toString() === action.payload.id.toString()
                ? { faq, ...action.payload }
                : faq
            ),
            success: {
              open: true,
              text: "Faq został zaktualizowany."
            } 
          }
        }
  
      case UPDATE_CMS_FAQ_FAIL:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            error: {
              open: true,
              text: "Podczas aktualizacji faq wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_FAQ_SUCCESS:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            faq: state.dataFaq.faq.filter(post => 
              faq.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Faq został usunięty."
            } 
          }
        }
  
      case DELETE_CMS_FAQ_FAIL:
        return {
          ...state,
          dataFaq: {
            ...state.dataFaq,
            error: {
              open: true,
              text: "Podczas usuwania faq wystąpił błąd, spróbuj ponownie."
            }
          }
        }
  
      //Categories
  
      case GET_CMS_FAQ_CATEGORIES_SUCCESS:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            categories: action.payload
          },
        }
  
      case GET_CMS_FAQ_CATEGORIES_FAIL:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            // error: action.payload,
          }
        }
  
      case ADD_CMS_FAQ_CATEGORY_SUCCESS:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            categories: [...state.dataFaqCategory.categories, action.payload],
            success: {
              open: true,
              text: "Kategoria została dodana."
            }
          }
        }
    
      case ADD_CMS_FAQ_CATEGORY_FAIL:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            error: {
              open: true,
              text: "Podczas dodawania kategorii wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case UPDATE_CMS_FAQ_CATEGORY_SUCCESS:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            categories: state.dataFaqCategory.categories.map(category =>
              category.id.toString() === action.payload.id.toString()
                ? { category, ...action.payload }
                : category
            ),
            success: {
              open: true,
              text: "Kategoria została zaktualizowana."
            } 
          }
        }
  
      case UPDATE_CMS_FAQ_CATEGORY_FAIL:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            error: {
              open: true,
              text: "Podczas aktualizacji kategorii wystąpił błąd, spróbuj ponownie.",
            }
          }
        }
  
      case DELETE_CMS_FAQ_CATEGORY_SUCCESS:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            categories: state.dataFaqCategory.categories.filter(category => 
              dataFaqCategory.id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Kategoria została usunięta."
            } 
          }
        }
  
      case DELETE_CMS_FAQ_CATEGORY_FAIL:
        return {
          ...state,
          dataFaqCategory: {
            ...state.dataFaqCategory,
            error: {
              open: true,
              text: "Podczas usuwania kategorii wystąpił błąd, spróbuj ponownie."
            }
          }
        }
  
      default:
        return state
    }
  }
  
  export default Cms;