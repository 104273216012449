import {
  GET_DELIVERY_FORM_PRICES,
  GET_DELIVERY_FORM_PRICES_SUCCESS,
  GET_DELIVERY_FORM_PRICES_FAIL,
  ADD_DELIVERY_FORM_PRICE,
  ADD_DELIVERY_FORM_PRICE_SUCCESS,
  ADD_DELIVERY_FORM_PRICE_FAIL,
  UPDATE_DELIVERY_FORM_PRICE,
  UPDATE_DELIVERY_FORM_PRICE_SUCCESS,
  UPDATE_DELIVERY_FORM_PRICE_FAIL,
  DELETE_DELIVERY_FORM_PRICE,
  DELETE_DELIVERY_FORM_PRICE_SUCCESS,
  DELETE_DELIVERY_FORM_PRICE_FAIL,
  HIDE_NOTIFICATION,
} from "./actionTypes"

export const getDeliveryFormPrices = deliveryForm => ({
  type: GET_DELIVERY_FORM_PRICES,
  payload: deliveryForm,
})

export const getDeliveryFormPricesSuccess = deliveryFormPrices => ({
  type: GET_DELIVERY_FORM_PRICES_SUCCESS,
  payload: deliveryFormPrices,
})

export const getDeliveryFormPricesFail = error => ({
  type: GET_DELIVERY_FORM_PRICES_FAIL,
  payload: error,
})

export const addDeliveryFormPrice = deliveryForm => ({
  type: ADD_DELIVERY_FORM_PRICE,
  payload: deliveryForm,
})

export const addDeliveryFormPriceSuccess = deliveryForm => ({
  type: ADD_DELIVERY_FORM_PRICE_SUCCESS,
  payload: deliveryForm,
})

export const addDeliveryFormPriceFail = error => ({
  type: ADD_DELIVERY_FORM_PRICE_FAIL,
  payload: error,
})

export const updateDeliveryFormPrice = deliveryForm => ({
  type: UPDATE_DELIVERY_FORM_PRICE,
  payload: deliveryForm,
})

export const updateDeliveryFormPriceSuccess = deliveryForm => ({
  type: UPDATE_DELIVERY_FORM_PRICE_SUCCESS,
  payload: deliveryForm,
})

export const updateDeliveryFormPriceFail = error => ({
  type: UPDATE_DELIVERY_FORM_PRICE_FAIL,
  payload: error,
})

export const deleteDeliveryFormPrice = id => ({
  type: DELETE_DELIVERY_FORM_PRICE,
  payload: id,
})

export const deleteDeliveryFormPriceSuccess = deliveryForm => ({
  type: DELETE_DELIVERY_FORM_PRICE_SUCCESS,
  payload: deliveryForm,
})

export const deleteDeliveryFormPriceFail = error => ({
  type: DELETE_DELIVERY_FORM_PRICE_FAIL,
  payload: error,
})

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
})
