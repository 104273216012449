/* POSTS */
export const GET_POSTS = "GET_POSTS"
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS"
export const GET_POSTS_FAIL = "GET_POSTS_FAIL"
export const ADD_POST = "ADD_POST"
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS"
export const ADD_POST_FAIL = "ADD_POST_FAIL"
export const UPDATE_POST = "UPDATE_POST"
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS"
export const UPDATE_POST_FAIL = "UPDATE_POST_FAIL"
export const DELETE_POST = "DELETE_POST"
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS"
export const DELETE_POST_FAIL = "DELETE_POST_FAIL"
export const HIDE_POST_SUCCESS = "HIDE_POST_SUCCESS"
export const HIDE_POST_FAIL = "HIDE_POST_FAIL"

/* CATEGORIES */
export const GET_IS_CATEGORIES = "GET_IS_CATEGORIES"
export const GET_IS_CATEGORIES_SUCCESS = "GET_IS_CATEGORIES_SUCCESS"
export const GET_IS_CATEGORIES_FAIL = "GET_IS_CATEGORIES_FAIL"
export const ADD_IS_CATEGORY = "ADD_IS_CATEGORY"
export const ADD_IS_CATEGORY_SUCCESS = "ADD_IS_CATEGORY_SUCCESS"
export const ADD_IS_CATEGORY_FAIL = "ADD_IS_CATEGORY_FAIL"
export const UPDATE_IS_CATEGORY = "UPDATE_IS_CATEGORY"
export const UPDATE_IS_CATEGORY_SUCCESS = "UPDATE_IS_CATEGORY_SUCCESS"
export const UPDATE_IS_CATEGORY_FAIL = "UPDATE_IS_CATEGORY_FAIL"
export const DELETE_IS_CATEGORY = "DELETE_IS_CATEGORY"
export const DELETE_IS_CATEGORY_SUCCESS = "DELETE_IS_CATEGORY_SUCCESS"
export const DELETE_IS_CATEGORY_FAIL = "DELETE_IS_CATEGORY_FAIL"
export const HIDE_CATEGORY_SUCCESS = "HIDE_CATEGORY_SUCCESS"
export const HIDE_CATEGORY_FAIL = "HIDE_CATEGORY_FAIL"