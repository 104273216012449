import { v4 as uuidv4 } from 'uuid';
import {
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTES_FAIL,
  ADD_ATTRIBUTE_SUCCESS,
  ADD_ATTRIBUTE_FAIL,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE_FAIL,
  DELETE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE_FAIL,
  HIDE_ATTRIBUTE_SUCCESS,
  HIDE_ATTRIBUTE_FAIL,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  HIDE_CATEGORY_SUCCESS,
  HIDE_CATEGORY_FAIL,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  HIDE_PRODUCT_SUCCESS,
  HIDE_PRODUCT_FAIL,
  GET_UNITS_FAIL,
  GET_UNITS_SUCCESS,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  HIDE_UNIT_SUCCESS,
  HIDE_UNIT_FAIL,
  GET_RETURN_PACKAGES_FAIL,
  GET_RETURN_PACKAGES_SUCCESS,
  ADD_RETURN_PACKAGE_SUCCESS,
  ADD_RETURN_PACKAGE_FAIL,
  UPDATE_RETURN_PACKAGE_SUCCESS,
  UPDATE_RETURN_PACKAGE_FAIL,
  DELETE_RETURN_PACKAGE_SUCCESS,
  DELETE_RETURN_PACKAGE_FAIL,
  HIDE_RETURN_PACKAGES_SUCCESS,
  HIDE_RETURN_PACKAGES_FAIL,
  GET_FEATURES_FAIL,
  GET_FEATURES_SUCCESS,
  ADD_FEATURE_SUCCESS,
  ADD_FEATURE_FAIL,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_FEATURE_FAIL,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAIL,
  HIDE_FEATURE_SUCCESS,
  HIDE_FEATURE_FAIL,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAIL,
  GET_PRICES_FAIL,
  GET_PRICES_SUCCESS,
  UPDATE_PRICE_SUCCESS,
  UPDATE_PRICE_FAIL,
  HIDE_PRICES_SUCCESS,
  HIDE_PRICES_FAIL,
  GET_RELATED,
  ADD_RELATED_PRODUCT_SUCCESS,
  ADD_RELATED_PRODUCT_FAIL,
  DELETE_RELATED_PRODUCT_SUCCESS,
  DELETE_RELATED_PRODUCT_FAIL,
  HIDE_RELATED_SUCCESS,
  HIDE_RELATED_FAIL,
  GET_PRODUCTS_VARIANTS_FAIL,
  GET_PRODUCTS_VARIANTS_SUCCESS,
  ADD_CREATED_VARIANTS_SUCCESS,
  ADD_CREATED_VARIANTS_FAIL,
  DELETE_CREATED_VARIANT_SUCCESS,
  DELETE_CREATED_VARIANT_FAIL,
  HIDE_CREATED_VARIANT_SUCCESS,
  HIDE_CREATED_VARIANT_FAIL,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
  HIDE_VARIANT_SUCCESS,
  HIDE_VARIANT_FAIL,
  CLEARING_PRICE_DATA,
  CLEARING_PRODUCT_DATA,
} from "./actionTypes"

const INIT_STATE = {
  dataAttributes: {
    attributes: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataCategories: {
    categories: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataProducts: {
    products: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataUnits: {
    units: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataReturnPackages: {
    returnPackages: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataFeatures: {
    features: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataPrices: {
    prices: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  images: [],
  dataVariants: {
    variants: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataCreatedVariants: {
    createdVariants: [],
    successCreated: {
      open: false,
      text: ""
    },
    errorCreated: {
      open: false,
      text: ""
    }
  },
  dataRelated: {
    related: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  }
}

const catalog = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    //Attributes

    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          attributes: action.payload,
        },
      }

    case GET_ATTRIBUTES_FAIL:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          // error: action.payload,
        }
      }

    case ADD_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          attributes: [...state.dataAttributes.attributes, action.payload],
          success: {
            open: true,
            text: "Atrybut został dodany."
          }
        }
      }
  
    case ADD_ATTRIBUTE_FAIL:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          error: {
            open: true,
            text: "Podczas dodawania atrybutu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          attributes: state.dataAttributes.attributes.map(attribute =>
            attribute.attribute_id.toString() === action.payload.attribute_id.toString()
              ? { attribute, ...action.payload }
              : attribute
          ),
          success: {
            open: true,
            text: "Atrybut został zaktualizowany."
          } 
        }
      }

    case UPDATE_ATTRIBUTE_FAIL:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          error: {
            open: true,
            text: "Podczas aktualizacji atrybutu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          attributes: state.dataAttributes.attributes.filter(
            attribute => attribute.attribute_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Atrybut został usunięty."
          } 
        }
      }

    case DELETE_ATTRIBUTE_FAIL:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          error: {
            open: true,
            text: "Podczas usuwania atrybutu wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          success: {
            ...state.dataAttributes.success,
            open: false
          }
        },
      }

    case HIDE_ATTRIBUTE_FAIL:
      return {
        ...state,
        dataAttributes: {
          ...state.dataAttributes,
          error: {
            ...state.dataAttributes.error,
            open: false
          }
        },
      }

    //Categories

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: action.payload,
        },
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          // error: action.payload,
        }
      }

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: [...state.dataCategories.categories, action.payload],
          success: {
            open: true,
            text: "Kategoria została dodana."
          }
        }
      }
  
    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas dodawania kategorii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: state.dataCategories.categories.map(category =>
            category.category_id.toString() === action.payload.category_id.toString()
              ? { category, ...action.payload }
              : category
          ),
          success: {
            open: true,
            text: "Kategoria została zaktualizowana."
          } 
        }
      }

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas aktualizacji kategorii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: state.dataCategories.categories.filter(
            category => category.category_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Kategoria została usunięta."
          } 
        }
      }

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas usuwania kategorii wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          success: {
            ...state.dataCategories.success,
            open: false
          }
        },
      }

    case HIDE_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            ...state.dataCategories.error,
            open: false
          }
        },
      }

    //Products

    case CLEARING_PRODUCT_DATA: 
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: []
        }
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: action.payload,
        },
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          // error: action.payload,
        }
      }

    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: [...state.dataProducts.products, action.payload],
          success: {
            open: true,
            text: "Produkt został dodany."
          }
        }
      }
  
    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            open: true,
            text: "Podczas dodawania produktu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: state.dataProducts.products.map(product =>
            product.product_id.toString() === action.payload.product_id.toString()
              ? { product, success: true, ...action.payload }
              : product
          ),
          success: {
            open: true,
            text: "Produkt został zaktualizowany."
          } 
        },
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          createdVariants: [],
        }
      }

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            open: true,
            text: "Podczas aktualizacji produktu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: state.dataProducts.products.filter(
            product => product.product_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Produkt został usunięty."
          } 
        }
      }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            open: true,
            text: "Podczas usuwania produktu wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          success: {
            ...state.dataProducts.success,
            open: false
          }
        },
      }

    case HIDE_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            ...state.dataProducts.error,
            open: false
          }
        },
      }

    case DELETE_IMAGE_SUCCESS:
      // const product = state.products.find(a => a.product_id.toString() === action.payload.product_id.toString());
      // const deleteImg = product.images.filter(b => b.product_image_id.toString() !== action.payload.image_id.toString());
      // return console.log(state.products.map(product => product.product_id.toString() === action.payload.product_id.toString()) ? { ...product, images: deleteImg } : product);
      // return {
      //   ...state,
      //   products: state.products.map(product => 
      //     product.product_id.toString() === action.payload.product_id.toString() 
      //     ? { product, ...deleteImg } 
      //     : product
      //   ),
      // }

    case DELETE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //Variants

    case GET_PRODUCTS_VARIANTS_SUCCESS:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          variants: action.payload,
        },
      }

    case GET_PRODUCTS_VARIANTS_FAIL:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          // error: action.payload,
        }
      }

    case ADD_VARIANT_SUCCESS:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          variants: [...state.dataVariants.variants, action.payload[0]],
          success: {
            open: true,
            text: "Wariant został dodany."
          }
        }
      }
  
    case ADD_VARIANT_FAIL:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          error: {
            open: true,
            text: "Podczas dodawania wariantu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_VARIANT_SUCCESS:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          variants: state.dataVariants.variants.map(variant =>
            variant.product_variant_id.toString() === action.payload[0].product_variant_id.toString()
              ? { variant, ...action.payload[0] }
              : variant
          ),
          success: {
            open: true,
            text: "Wariant został zaktualizowany."
          } 
        }
      }

    case UPDATE_VARIANT_FAIL:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          error: {
            open: true,
            text: "Podczas aktualizacji wariantu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_VARIANT_SUCCESS:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          variants: state.dataVariants.variants.filter(variant => 
            variant.product_variant_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Wariant został usunięty."
          } 
        }
      }

    case DELETE_VARIANT_FAIL:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          error: {
            open: true,
            text: "Podczas usuwania wariantu wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_VARIANT_SUCCESS:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          success: {
            ...state.dataVariants.success,
            open: false
          }
        },
      }

    case HIDE_VARIANT_FAIL:
      return {
        ...state,
        dataVariants: {
          ...state.dataVariants,
          error: {
            ...state.dataVariants.error,
            open: false
          }
        },
      }
      
    case ADD_CREATED_VARIANTS_SUCCESS:
      return {
        ...state,
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          createdVariants: action.payload,
          successCreated: {
            open: true,
            text: "Kombinacje wariantów zostały utworzone."
          }
        }
      }

    case ADD_CREATED_VARIANTS_FAIL:
      return {
        ...state,
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          errorCreated: {
            open: true,
            text: "Podczas tworzenia kombinacji wariantów wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_CREATED_VARIANT_SUCCESS:
      return {
        ...state,
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          createdVariants: state.dataCreatedVariants.createdVariants.filter(variant => 
            variant.helpful_nr.toString() !== action.payload.helpful_nr.toString()
          ),
          successCreated: {
            open: true,
            text: "Wariant został usunięty."
          } 
        }
        
      }

    case DELETE_CREATED_VARIANT_FAIL:
      return {
        ...state,
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          errorCreated: {
            open: true,
            text: "Podczas usuwania wariantu wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_CREATED_VARIANT_SUCCESS:
      return {
        ...state,
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          successCreated: {
            ...state.dataCreatedVariants.successCreated,
            open: false
          }
        },
      }

    case HIDE_CREATED_VARIANT_FAIL:
      return {
        ...state,
        dataCreatedVariants: {
          ...state.dataCreatedVariants,
          errorCreated: {
            ...state.dataCreatedVariants.errorCreated,
            open: false
          }
        },
      }

    //Units

    case GET_UNITS_SUCCESS:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          units: action.payload,
        },
      }

    case GET_UNITS_FAIL:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          // error: action.payload,
        }
      }

    case ADD_UNIT_SUCCESS:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          units: [...state.dataUnits.units, action.payload],
          success: {
            open: true,
            text: "Jednostka sprzedażowa została dodana."
          }
        }
      }
  
    case ADD_UNIT_FAIL:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          error: {
            open: true,
            text: "Podczas dodawania jednostki wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          units: state.dataUnits.units.map(unit =>
            unit.package_id.toString() === action.payload.package_id.toString()
              ? { unit, ...action.payload }
              : unit
          ),
          success: {
            open: true,
            text: "Jednostka została zaktualizowana."
          } 
        }
      }

    case UPDATE_UNIT_FAIL:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          error: {
            open: true,
            text: "Podczas aktualizacji jednostki wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          units: state.dataUnits.units.filter(
            unit => unit.package_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Jednostka została usunięta."
          } 
        }
      }

    case DELETE_UNIT_FAIL:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          error: {
            open: true,
            text: "Podczas usuwania jednostki wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_UNIT_SUCCESS:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          success: {
            ...state.dataUnits.success,
            open: false
          }
        },
      }

    case HIDE_UNIT_FAIL:
      return {
        ...state,
        dataUnits: {
          ...state.dataUnits,
          error: {
            ...state.dataUnits.error,
            open: false
          }
        },
      }

    //Return Packages

    case GET_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          returnPackages: action.payload,
        },
      }

    case GET_RETURN_PACKAGES_FAIL:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          // error: action.payload,
        }
      }

    case ADD_RETURN_PACKAGE_SUCCESS:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          returnPackages: [...state.dataReturnPackages.returnPackages, action.payload],
          success: {
            open: true,
            text: "Opakowanie zwrotne zostało dodane."
          }
        }
      }
  
    case ADD_RETURN_PACKAGE_FAIL:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          error: {
            open: true,
            text: "Podczas dodawania opakowania wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_RETURN_PACKAGE_SUCCESS:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          returnPackages: state.dataReturnPackages.returnPackages.map(returnPackage =>
            returnPackage.return_package_id.toString() === action.payload.return_package_id.toString()
              ? { returnPackage, ...action.payload }
              : returnPackage
          ),
          success: {
            open: true,
            text: "Opakowanie zwrotne zostało zaktualizowane."
          } 
        }
      }

    case UPDATE_RETURN_PACKAGE_FAIL:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          error: {
            open: true,
            text: "Podczas aktualizacji opakowania wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_RETURN_PACKAGE_SUCCESS:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          returnPackages: state.dataReturnPackages.returnPackages.filter(
            returnPackage => returnPackage.return_package_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Opakowanie zwrotne zostało usunięte."
          } 
        }
      }

    case DELETE_RETURN_PACKAGE_FAIL:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          error: {
            open: true,
            text: "Podczas usuwania opakowania wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          success: {
            ...state.dataReturnPackages.success,
            open: false
          }
        },
      }

    case HIDE_RETURN_PACKAGES_FAIL:
      return {
        ...state,
        dataReturnPackages: {
          ...state.dataReturnPackages,
          error: {
            ...state.dataReturnPackages.error,
            open: false
          }
        },
      }

    //Features

    case GET_FEATURES_SUCCESS:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          features: action.payload,
        },
      }

    case GET_FEATURES_FAIL:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          // error: action.payload,
        }
      }

    case ADD_FEATURE_SUCCESS:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          features: [...state.dataFeatures.features, action.payload],
          success: {
            open: true,
            text: "Dana techniczna została dodana."
          }
        }
      }
  
    case ADD_FEATURE_FAIL:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          error: {
            open: true,
            text: "Podczas dodawania wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_FEATURE_SUCCESS:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          features: state.dataFeatures.features.map(feature =>
            feature.feature_id.toString() === action.payload.feature_id.toString()
              ? { feature, ...action.payload }
              : feature
          ),
          success: {
            open: true,
            text: "Dana techniczna została zaktualizowana."
          } 
        }
      }

    case UPDATE_FEATURE_FAIL:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          error: {
            open: true,
            text: "Podczas aktualizacji wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_FEATURE_SUCCESS:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          features: state.dataFeatures.features.filter(
            feature => feature.feature_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Dana techniczna została usunięta."
          } 
        }
      }

    case DELETE_FEATURE_FAIL:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          error: {
            open: true,
            text: "Podczas usuwania wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_FEATURE_SUCCESS:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          success: {
            ...state.dataFeatures.success,
            open: false
          }
        },
      }

    case HIDE_FEATURE_FAIL:
      return {
        ...state,
        dataFeatures: {
          ...state.dataFeatures,
          error: {
            ...state.dataFeatures.error,
            open: false
          }
        },
      }

    //Prices

    case CLEARING_PRICE_DATA: 
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          prices: []
        }
      }

    case GET_PRICES_SUCCESS:
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          prices: action.payload,
        },
      }

    case GET_PRICES_FAIL:
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          // error: action.payload,
        }
      }

    case UPDATE_PRICE_SUCCESS:
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          prices: state.dataPrices.prices.map(singlePrice =>
            singlePrice.price_id.toString() === action.payload.price_id.toString()
              ? { singlePrice, ...action.payload }
              : singlePrice
          ),
          success: {
            open: true,
            text: "Cena została zaktualizowana."
          } 
        }
      }
  
    case UPDATE_PRICE_FAIL:
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          error: {
            open: true,
            text: "Podczas aktualizacji ceny wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case HIDE_PRICES_SUCCESS:
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          success: {
            ...state.dataPrices.success,
            open: false
          }
        },
      }

    case HIDE_PRICES_FAIL:
      return {
        ...state,
        dataPrices: {
          ...state.dataPrices,
          error: {
            ...state.dataPrices.error,
            open: false
          }
        },
      }

    //Relations

    case GET_RELATED:
      return {
        ...state,
        dataRelated: {
          ...state.dataRelated,
          related: action.payload,
        },
      }
    
    case ADD_RELATED_PRODUCT_SUCCESS:
      const { isAdded, related, product } = action.payload;

      if (isAdded) {
        return {
          ...state,
          dataRelated: {
            ...state.dataRelated,
            related: [
              ...state.dataRelated.related, {
                product_id: related.product_id,
                product_connected_id: related.product_related_id,
                product_related_id: uuidv4(),
                product: product
              }
            ],
            success: {
              open: true,
              text: "Powiązanie produktów zostało utworzone."
            }
          }
        }
      } else {
        return {
          ...state,
          dataRelated: {
            ...state.dataRelated,
            error: {
              open: true,
              text: "Powiązanie z tym produktem jest już utworzone.",
            }
          }
        }
      }

    case ADD_RELATED_PRODUCT_FAIL:
      return {
        ...state,
        dataRelated: {
          ...state.dataRelated,
          error: {
            open: true,
            text: "Podczas tworzenia powiązania wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_RELATED_PRODUCT_SUCCESS:
      return {
        ...state,
        dataRelated: {
          ...state.dataRelated,
          related: state.dataRelated.related.filter(r => r.product_related_id.toString() !== action.payload.toString()),
          success: {
            open: true,
            text: "Powiązanie zostało usunięte."
          } 
        }
      }

    case DELETE_RELATED_PRODUCT_FAIL:
      return {
        ...state,
        dataRelated: {
          ...state.dataRelated,
          error: {
            open: true,
            text: "Podczas usuwania powiązania wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case HIDE_RELATED_SUCCESS:
      return {
        ...state,
        dataRelated: {
          ...state.dataRelated,
          success: {
            ...state.dataRelated.success,
            open: false
          }
        },
      }

    case HIDE_RELATED_FAIL:
      return {
        ...state,
        dataRelated: {
          ...state.dataRelated,
          error: {
            ...state.dataRelated.error,
            open: false
          }
        },
      }

    default:
      return state
  }
}

export default catalog;
