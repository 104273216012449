import React from "react"

import { Spinner } from "reactstrap"

export const NoDataIndication = () => (
  <React.Fragment>
    <Spinner color="secondary"></Spinner>
    <div>Ładowanie danych ...</div>
  </React.Fragment>
)
