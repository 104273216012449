import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"

import withRouter from "components/Common/withRouter"
import Breadcrumbs from "components/Common/Breadcrumb"
import { FormModal } from "./components/FormModal"
import { Notification } from "./components/Notification"
import { NoDataIndication } from "./components/NoDataIndicator"

import {
  getDeliveryFormPrices,
  addDeliveryFormPrice,
  updateDeliveryFormPrice,
  deleteDeliveryFormPrice,
  hideNotification,
} from "store/delivery-form-prices/actions"

// helpers
import { defaultSorted, setColumns, getValidation } from "./helpers"

const DeliveryFormPrices = ({ router: { params } }) => {
  const dispatch = useDispatch()
  // own state
  const [pickedPrice, setPickedPrice] = useState(undefined)
  const [modal, setModal] = useState(false)
  const timeOutRef = useRef(null)
  // global state
  const { deliveryForm } = useSelector(state => ({
    deliveryForm:
      state.deliveryFormPrices.dataDeliveryFormPrices[params.id.toString()]
        ?.deliveryForm,
  }))
  const { deliveryFormPrices } = useSelector(state => ({
    deliveryFormPrices:
      state.deliveryFormPrices.dataDeliveryFormPrices[params.id.toString()]
        ?.deliveryFormPrices,
  }))
  const { success } = useSelector(state => ({
    success: state.deliveryFormPrices.dataDeliveryFormPrices.success,
  }))

  const { error } = useSelector(state => ({
    error: state.deliveryFormPrices.dataDeliveryFormPrices.error,
  }))

  // effects
  useEffect(() => {
    if (deliveryFormPrices === undefined) {
      dispatch(getDeliveryFormPrices(params.id))
    }
  }, [dispatch, deliveryFormPrices, params.id])
  //clear notification
  useEffect(() => {
    if (success.open || error.open) {
      timeOutRef.current = setTimeout(() => {
        dispatch(hideNotification())
      }, 5000)
    }

    return () => clearTimeout(timeOutRef.current)
  }, [success, error])
  // functions
  const handleEditButton = price => {
    setPickedPrice(price)
    setModal(true)
  }
  const handleCloseModal = () => {
    setModal(false)
    setPickedPrice(undefined)
  }
  const handleUpdateDeliveryFormPrice = data => {
    dispatch(
      updateDeliveryFormPrice({
        deliveryForm: params.id,
        price: pickedPrice.id,
        data: data,
      })
    )
    setPickedPrice(undefined)
    setModal(false)
  }
  const handleAddDeliveryFormPrice = data => {
    dispatch(
      addDeliveryFormPrice({
        deliveryForm: params.id,
        data: data,
      })
    )
    setModal(false)
  }
  const handleDeleteDeliveryFormPrice = id => {
    dispatch(
      deleteDeliveryFormPrice({
        deliveryForm: params.id,
        priceId: id,
      })
    )
  }
  // variables
  const columns = setColumns(handleEditButton, handleDeleteDeliveryFormPrice)
  const validation = getValidation(
    pickedPrice,
    handleAddDeliveryFormPrice,
    handleUpdateDeliveryFormPrice
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          breadcrumbItem={deliveryForm?.display_name || ""}
          title="Formy dostawy"
        />
        <Card>
          <CardBody>
            <Notification success={success} error={error} />
            {deliveryFormPrices === undefined ||
            deliveryFormPrices?.length !== 0 ? (
              <ToolkitProvider
                keyField="id"
                data={deliveryFormPrices || []}
                columns={columns}
                search
              >
                {props => {
                  return (
                    <React.Fragment>
                      <Row>
                        <Col sm="8">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Search.SearchBar
                                {...props.searchProps}
                                placeholder="Szukaj..."
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="2">
                          <Link
                            to={`/delivery-forms/`}
                            className="font-16 btn btn-secondary text-end"
                          >
                            Wróć na liste form dostawy
                          </Link>
                        </Col>
                        <Col sm="2">
                          <div className="text-sm-end">
                            <Link
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                              onClick={() => setModal(prev => !prev)}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Dodaj cenę formy dostawy
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <BootstrapTable
                            {...props.baseProps}
                            bordered={false}
                            defaultSorted={defaultSorted}
                            classes={"align-middle"}
                            headerWrapperClasses={"table-light"}
                            noDataIndication={<NoDataIndication />}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                }}
              </ToolkitProvider>
            ) : (
              <Row>
                <Col sm="8">
                  <span className="btn">
                    Brak cen dla wybranej formy dostawy
                  </span>
                </Col>
                <Col sm="2">
                  <div className="text-sm-end">
                    <Link
                      to={`/delivery-forms/`}
                      className="font-16 btn btn-secondary text-end"
                    >
                      Wróć na listę form dostawy
                    </Link>
                  </div>
                </Col>
                <Col sm="2">
                  <div className="text-sm-end">
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary"
                      onClick={() => setModal(prev => !prev)}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Dodaj cenę
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
            <FormModal
              isOpen={modal}
              toggle={handleCloseModal}
              currentPrice={pickedPrice}
              validation={validation}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default withRouter(DeliveryFormPrices)
