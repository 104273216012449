import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner,
  Badge
} from "reactstrap"

import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getManufacturers as onGetManufacturers,
  addManufacturer as onAddManufacturer,
  updateManufacturer as onUpdateManufacturer,
  deleteManufacturer as onDeleteManufacturer,
} from "store/users/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Manufacturers = props => {
  //meta title
  document.title = "Producenci - Panel administracyjny";

  const dispatch = useDispatch();

  const [manufacturer, setManufacturer] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (manufacturer && manufacturer.id) || '',
      name: (manufacturer && manufacturer.name) || '',
      image: (manufacturer && manufacturer.image) || '',
      email: (manufacturer && manufacturer.email) || '',
      phone: (manufacturer && manufacturer.phone) || '',
      password: '',
      address_city: (manufacturer && manufacturer.address_city) || '',
      address_post: (manufacturer && manufacturer.address_post) || '',
      address_state: (manufacturer && manufacturer.address_state) || '',
      address_street: (manufacturer && manufacturer.address_street) || '',
      role: (manufacturer && manufacturer.role) || 'manufacturer',
      active: (manufacturer && manufacturer.active) || 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("To pole jest wymagane"),
      email: Yup.string().required("To pole jest wymagane"),
      phone: Yup.string().required("To pole jest wymagane"),
      password: Yup.string().required("To pole jest wymagane"),
      address_street: Yup.string().required("To pole jest wymagane"),
      address_post: Yup.string().required("To pole jest wymagane"),
      address_city: Yup.string().required("To pole jest wymagane"),
      address_state: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateManufacturer = {
          id: values.id,
          name: values.name,
          image: values.image,
          email: values.email,
          phone: values.phone,
          password: values.password,
          address_city: values.address_city,
          address_post: values.address_post,
          address_state: values.address_state,
          address_street: values.address_street,
          role: values.role,
          active: values.active,
        }
        // update manufacturer
        dispatch(onUpdateManufacturer(updateManufacturer));
        validation.resetForm();
      } else {
        const addManufacturer = {
          name: values["name"],
          image: values["image"],
          email: values["email"],
          phone: values["phone"],
          password: values["password"],
          address_city: values["address_city"],
          address_post: values["address_post"],
          address_state: values["address_state"],
          address_street: values["address_street"],
          role: values["role"],
          active: values["active"],
        }
        // add manufacturer
        dispatch(onAddManufacturer(addManufacturer));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { manufacturers } = useSelector(state => ({
    manufacturers: state.users.dataManufacturers.manufacturers,
  }));

  const { success } = useSelector(state => ({
    success: state.users.dataManufacturers.success,
  }));

  const { error } = useSelector(state => ({
    error: state.users.dataManufacturers.error,
  }));

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "image",
      text: "Zdjęcie",
      sort: false,
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={"https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"} 
                width="50" 
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={"https://budujpl.s3.eu-north-1.amazonaws.com/uploads/users/50x50/" + cellContent}
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "active",
      text: "Aktywny",
      sort: true,
      formatter: cellContent => 
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, manufacturer) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleManufacturerClick(manufacturer)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(manufacturer)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (manufacturers && !manufacturers.length) {
      dispatch(onGetManufacturers());
    }
  }, [dispatch, manufacturers]);

  useEffect(() => {
    setManufacturer(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    if (!isEmpty(manufacturers) && !!isEdit) {
      setManufacturer(manufacturers);
      setIsEdit(false);
    }
  }, [manufacturers]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_MANUFACTURER_SUCCESS",
        });
      }, 5000)
      
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_MANUFACTURER_FAIL",
        });
      }, 5000)
      
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleManufacturerClick = arg => {
    const manufacturer = arg;

    setManufacturer({
      id: manufacturer.id,
      name: manufacturer.name,
      image: manufacturer.image,
      email: manufacturer.email,
      phone: manufacturer.phone,
      address_city: manufacturer.address_city,
      address_post: manufacturer.address_post,
      address_state: manufacturer.address_state,
      address_street: manufacturer.address_street,
      role: manufacturer.role,
      active: manufacturer.active,
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (manufacturer) => {
    setManufacturer(manufacturer);
    setDeleteModal(true);
  };

  const handleDeleteManufacturer = () => {
    if (manufacturer.id) {
      dispatch(onDeleteManufacturer(manufacturer));
      setDeleteModal(false);
    }
  };

  const handleManufacturerClicks = () => {
    setManufacturer("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteManufacturer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Katalog" breadcrumbItem="Producenci" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="id"
                      data={manufacturers}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleManufacturerClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj producenta
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edytuj producenta" : "Dodaj producenta"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "1",
                                              })}
                                              onClick={() => {
                                                toggleCustom("1");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Dane producenta</span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "2",
                                              })}
                                              onClick={() => {
                                                toggleCustom("2");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Adres</span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "3",
                                              })}
                                              onClick={() => {
                                                toggleCustom("3");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Logo</span>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                        <TabContent
                                          activeTab={customActiveTab}
                                          className="p-3 text-muted"
                                        >
                                          <TabPane tabId="1">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Nazwa</Label>
                                                <Input
                                                  name="name"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.name || ""}
                                                  invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                  }
                                                />
                                                {validation.touched.name && validation.errors.name ? (
                                                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                  name="email"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.email || ""}
                                                  invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                  }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Telefon</Label>
                                                <Input
                                                  name="phone"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.phone || ""}
                                                  invalid={
                                                    validation.touched.phone && validation.errors.phone ? true : false
                                                  }
                                                />
                                                {validation.touched.phone && validation.errors.phone ? (
                                                  <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Hasło</Label>
                                                <Input
                                                  name="password"
                                                  type="password"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.password || ""}
                                                  invalid={
                                                    validation.touched.password && validation.errors.password ? true : false
                                                  }
                                                />
                                                {validation.touched.password && validation.errors.password ? (
                                                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Typ konta</Label>
                                                <Input
                                                  type="select"
                                                  name="role"
                                                  className="form-select"
                                                  multiple={false}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.role}
                                                >
                                                  <option value="manufacturer">Producent</option>
                                                </Input>
                                              </Col>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Aktywny</Label>
                                                <Input
                                                  type="select"
                                                  name="active"
                                                  className="form-select"
                                                  multiple={false}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.active}
                                                >
                                                  <option value="0">Nie</option>
                                                  <option value="1">Tak</option>
                                                </Input>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Adres</Label>
                                                <Input
                                                  name="address_street"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.address_street || ""}
                                                  invalid={
                                                    validation.touched.address_street && validation.errors.address_street ? true : false
                                                  }
                                                />
                                                {validation.touched.address_street && validation.errors.address_street ? (
                                                  <FormFeedback type="invalid">{validation.errors.address_street}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Kod pocztowy</Label>
                                                <Input
                                                  name="address_post"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.address_post || ""}
                                                  invalid={
                                                    validation.touched.address_post && validation.errors.address_post ? true : false
                                                  }
                                                />
                                                {validation.touched.address_post && validation.errors.address_post ? (
                                                  <FormFeedback type="invalid">{validation.errors.address_post}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">Miasto</Label>
                                                <Input
                                                  name="address_city"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.address_city || ""}
                                                  invalid={
                                                    validation.touched.address_city && validation.errors.address_city ? true : false
                                                  }
                                                />
                                                {validation.touched.address_city && validation.errors.address_city ? (
                                                  <FormFeedback type="invalid">{validation.errors.address_city}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Województwo</Label>
                                                <Input
                                                  type="select"
                                                  name="address_state"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  className="form-select"
                                                  multiple={false}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.address_state}
                                                  invalid={
                                                    validation.touched.address_state && validation.errors.address_state ? true : false
                                                  }
                                                >
                                                  <option value="dolnośląskie">dolnośląskie</option>
                                                  <option value="kujawsko-pomorskie">kujawsko-pomorskie</option>
                                                  <option value="lubelskie">lubelskie</option>
                                                  <option value="lubuskie">lubuskie</option>
                                                  <option value="łódzkie">łódzkie</option>
                                                  <option value="małopolskie">małopolskie</option>
                                                  <option value="mazowieckie">mazowieckie</option>
                                                  <option value="opolskie">opolskie</option>
                                                  <option value="podkarpackie">podkarpackie</option>
                                                  <option value="podlaskie">podlaskie</option>
                                                  <option value="pomorskie">pomorskie</option>
                                                  <option value="śląskie">śląskie</option>
                                                  <option value="świetokrzyskie">świetokrzyskie</option>
                                                  <option value="warmińsko-mazurskie">warmińsko-mazurskie</option>
                                                  <option value="wielkopolskie">wielkopolskie</option>
                                                  <option value="zachodniopomorskie">zachodniopomorskie</option>
                                                </Input>
                                                {validation.touched.address_state && validation.errors.address_state ? (
                                                  <FormFeedback type="invalid">{validation.errors.address_state}</FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="3">
                                            <Row>
                                              <Col lg="12">
                                                <div className="mb-3">
                                                  <Label className="form-label">Logo</Label>
                                                  {validation.values.image ? 
                                                    <div><img src={`https://budujpl.s3.eu-north-1.amazonaws.com/uploads/users/150x150/${validation.values.image}`} /></div>
                                                    : null
                                                  }
                                                  <Input
                                                    type="file"
                                                    name="image"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    invalid={
                                                      validation.touched.image && validation.errors.image ? true : false
                                                    }
                                                    onChange={(event) => {
                                                      validation.setFieldValue('image', event.currentTarget.files[0]);
                                                    }}
                                                  />
                                                  {validation.touched.image && validation.errors.image ? (
                                                  <FormFeedback type="invalid">{validation.errors.image}</FormFeedback>
                                                ) : null}
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Manufacturers)
