import {
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  ADD_POST_SUCCESS,
  ADD_POST_FAIL,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAIL,
  HIDE_POST_SUCCESS,
  HIDE_POST_FAIL,
  GET_IS_CATEGORIES_SUCCESS,
  GET_IS_CATEGORIES_FAIL,
  ADD_IS_CATEGORY_SUCCESS,
  ADD_IS_CATEGORY_FAIL,
  UPDATE_IS_CATEGORY_SUCCESS,
  UPDATE_IS_CATEGORY_FAIL,
  DELETE_IS_CATEGORY_SUCCESS,
  DELETE_IS_CATEGORY_FAIL,
  HIDE_CATEGORY_SUCCESS,
  HIDE_CATEGORY_FAIL
} from "./actionTypes";

const INIT_STATE = {
  dataPosts: {
    posts: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataCategories: {
    categories: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  }
}

const infoService = (state = INIT_STATE, action) => {
  switch (action.type) {

    //Posts

    case GET_POSTS_SUCCESS:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          posts: action.payload
        },
      }

    case GET_POSTS_FAIL:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          // error: action.payload,
        }
      }

    case ADD_POST_SUCCESS:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          posts: [...state.dataPosts.posts, action.payload],
          success: {
            open: true,
            text: "Artykuł został dodany."
          }
        }
      }
  
    case ADD_POST_FAIL:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          error: {
            open: true,
            text: "Podczas dodawania artykułu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_POST_SUCCESS:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          posts: state.dataPosts.posts.map(post =>
            post.post_id.toString() === action.payload.post_id.toString()
              ? { post, ...action.payload }
              : post
          ),
          success: {
            open: true,
            text: "Artykuł został zaktualizowany."
          } 
        }
      }

    case UPDATE_POST_FAIL:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          error: {
            open: true,
            text: "Podczas aktualizacji artykułu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_POST_SUCCESS:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          posts: state.dataPosts.posts.filter(post => 
            post.post_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Artykuł został usunięty."
          } 
        }
      }

    case DELETE_POST_FAIL:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          error: {
            open: true,
            text: "Podczas usuwania artykułu wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_POST_SUCCESS:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          success: {
            ...state.dataPosts.success,
            open: false
          }
        },
      }

    case HIDE_POST_FAIL:
      return {
        ...state,
        dataPosts: {
          ...state.dataPosts,
          error: {
            ...state.dataPosts.error,
            open: false
          }
        },
      }

    //Categories

    case GET_IS_CATEGORIES_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: action.payload
        },
      }

    case GET_IS_CATEGORIES_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          // error: action.payload,
        }
      }

    case ADD_IS_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: [...state.dataCategories.categories, action.payload],
          success: {
            open: true,
            text: "Kategoria została dodana."
          }
        }
      }
  
    case ADD_IS_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas dodawania kategorii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_IS_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: state.dataCategories.categories.map(category =>
            category.category_id.toString() === action.payload.category_id.toString()
              ? { category, ...action.payload }
              : category
          ),
          success: {
            open: true,
            text: "Kategoria została zaktualizowana."
          } 
        }
      }

    case UPDATE_IS_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas aktualizacji kategorii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_IS_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: state.dataCategories.categories.filter(category => 
            category.category_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Kategoria została usunięta."
          } 
        }
      }

    case DELETE_IS_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas usuwania kategorii wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          success: {
            ...state.dataCategories.success,
            open: false
          }
        },
      }

    case HIDE_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            ...state.dataCategories.error,
            open: false
          }
        },
      }

    default:
      return state
  }
}

export default infoService;