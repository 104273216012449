import {
    GET_CMS_POSTS,
    GET_CMS_POSTS_SUCCESS,
    GET_CMS_POSTS_FAIL,
    ADD_CMS_POST,
    ADD_CMS_POST_SUCCESS,
    ADD_CMS_POST_FAIL,
    UPDATE_CMS_POST,
    UPDATE_CMS_POST_SUCCESS,
    UPDATE_CMS_POST_FAIL,
    DELETE_CMS_POST,
    DELETE_CMS_POST_SUCCESS,
    DELETE_CMS_POST_FAIL,
    GET_CMS_CATEGORIES,
    GET_CMS_CATEGORIES_SUCCESS,
    GET_CMS_CATEGORIES_FAIL,
    ADD_CMS_CATEGORY,
    ADD_CMS_CATEGORY_SUCCESS,
    ADD_CMS_CATEGORY_FAIL,
    UPDATE_CMS_CATEGORY,
    UPDATE_CMS_CATEGORY_SUCCESS,
    UPDATE_CMS_CATEGORY_FAIL,
    DELETE_CMS_CATEGORY,
    DELETE_CMS_CATEGORY_SUCCESS,
    DELETE_CMS_CATEGORY_FAIL,
    GET_CMS_PAGES,
    GET_CMS_PAGES_SUCCESS,
    GET_CMS_PAGES_FAIL,
    ADD_CMS_PAGE,
    ADD_CMS_PAGE_SUCCESS,
    ADD_CMS_PAGE_FAIL,
    UPDATE_CMS_PAGE,
    UPDATE_CMS_PAGE_SUCCESS,
    UPDATE_CMS_PAGE_FAIL,
    DELETE_CMS_PAGE,
    DELETE_CMS_PAGE_SUCCESS,
    DELETE_CMS_PAGE_FAIL,
    GET_CMS_MENU,
    GET_CMS_MENU_SUCCESS,
    GET_CMS_MENU_FAIL,
    ADD_CMS_MENU,
    ADD_CMS_MENU_SUCCESS,
    ADD_CMS_MENU_FAIL,
    UPDATE_CMS_MENU,
    UPDATE_CMS_MENU_SUCCESS,
    UPDATE_CMS_MENU_FAIL,
    DELETE_CMS_MENU,
    DELETE_CMS_MENU_SUCCESS,
    DELETE_CMS_MENU_FAIL,
    GET_CMS_SLIDERS,
    GET_CMS_SLIDERS_SUCCESS,
    GET_CMS_SLIDERS_FAIL,
    ADD_CMS_SLIDER,
    ADD_CMS_SLIDER_SUCCESS,
    ADD_CMS_SLIDER_FAIL,
    UPDATE_CMS_SLIDER,
    UPDATE_CMS_SLIDER_SUCCESS,
    UPDATE_CMS_SLIDER_FAIL,
    DELETE_CMS_SLIDER,
    DELETE_CMS_SLIDER_SUCCESS,
    DELETE_CMS_SLIDER_FAIL,
    GET_CMS_SLIDER_ITEMS,
    GET_CMS_SLIDER_ITEMS_SUCCESS,
    GET_CMS_SLIDER_ITEMS_FAIL,
    ADD_CMS_SLIDER_ITEM,
    ADD_CMS_SLIDER_ITEM_SUCCESS,
    ADD_CMS_SLIDER_ITEM_FAIL,
    UPDATE_CMS_SLIDER_ITEM,
    UPDATE_CMS_SLIDER_ITEM_SUCCESS,
    UPDATE_CMS_SLIDER_ITEM_FAIL,
    DELETE_CMS_SLIDER_ITEM,
    DELETE_CMS_SLIDER_ITEM_SUCCESS,
    DELETE_CMS_SLIDER_ITEM_FAIL,
    GET_CMS_GALLERIES,
    GET_CMS_GALLERIES_SUCCESS,
    GET_CMS_GALLERIES_FAIL,
    ADD_CMS_GALLERY,
    ADD_CMS_GALLERY_SUCCESS,
    ADD_CMS_GALLERY_FAIL,
    UPDATE_CMS_GALLERY,
    UPDATE_CMS_GALLERY_SUCCESS,
    UPDATE_CMS_GALLERY_FAIL,
    DELETE_CMS_GALLERY,
    DELETE_CMS_GALLERY_SUCCESS,
    DELETE_CMS_GALLERY_FAIL,
    GET_CMS_GALLERY_ITEMS,
    GET_CMS_GALLERY_ITEMS_SUCCESS,
    GET_CMS_GALLERY_ITEMS_FAIL,
    ADD_CMS_GALLERY_ITEM,
    ADD_CMS_GALLERY_ITEM_SUCCESS,
    ADD_CMS_GALLERY_ITEM_FAIL,
    UPDATE_CMS_GALLERY_ITEM,
    UPDATE_CMS_GALLERY_ITEM_SUCCESS,
    UPDATE_CMS_GALLERY_ITEM_FAIL,
    DELETE_CMS_GALLERY_ITEM,
    DELETE_CMS_GALLERY_ITEM_SUCCESS,
    DELETE_CMS_GALLERY_ITEM_FAIL,
    GET_CMS_FAQS,
    GET_CMS_FAQS_SUCCESS,
    GET_CMS_FAQS_FAIL,
    ADD_CMS_FAQ,
    ADD_CMS_FAQ_SUCCESS,
    ADD_CMS_FAQ_FAIL,
    UPDATE_CMS_FAQ,
    UPDATE_CMS_FAQ_SUCCESS,
    UPDATE_CMS_FAQ_FAIL,
    DELETE_CMS_FAQ,
    DELETE_CMS_FAQ_SUCCESS,
    DELETE_CMS_FAQ_FAIL,
    GET_CMS_FAQ_CATEGORIES,
    GET_CMS_FAQ_CATEGORIES_SUCCESS,
    GET_CMS_FAQ_CATEGORIES_FAIL,
    ADD_CMS_FAQ_CATEGORY,
    ADD_CMS_FAQ_CATEGORY_SUCCESS,
    ADD_CMS_FAQ_CATEGORY_FAIL,
    UPDATE_CMS_FAQ_CATEGORY,
    UPDATE_CMS_FAQ_CATEGORY_SUCCESS,
    UPDATE_CMS_FAQ_CATEGORY_FAIL,
    DELETE_CMS_FAQ_CATEGORY,
    DELETE_CMS_FAQ_CATEGORY_SUCCESS,
    DELETE_CMS_FAQ_CATEGORY_FAIL,
  } from "./actionTypes";
  
  //Posts
  
  export const getCmsPosts = () => ({
    type: GET_CMS_POSTS,
  })
  
  export const getCmsPostsSuccess = posts => ({
    type: GET_CMS_POSTS_SUCCESS,
    payload: posts,
  })
  
  export const getCmsPostsFail = error => ({
    type: GET_CMS_POSTS_FAIL,
    payload: error,
  })
  
  export const addCmsPost = post => ({
    type: ADD_CMS_POST,
    payload: post,
  })
  
  export const addCmsPostSuccess = post => ({
    type: ADD_CMS_POST_SUCCESS,
    payload: post,
  })
  
  export const addCmsPostFail = error => ({
    type: ADD_CMS_POST_FAIL,
    payload: error,
  })
  
  export const updateCmsPost = post => ({
    type: UPDATE_CMS_POST,
    payload: post,
  })
  
  export const updateCmsPostSuccess = post => ({
    type: UPDATE_CMS_POST_SUCCESS,
    payload: post,
  })
  
  export const updateCmsPostFail = error => ({
    type: UPDATE_CMS_POST_FAIL,
    payload: error,
  })
  
  export const deleteCmsPost = post => ({
    type: DELETE_CMS_POST,
    payload: post,
  })
  
  export const deleteCmsPostSuccess = post => ({
    type: DELETE_CMS_POST_SUCCESS,
    payload: post,
  })
  
  export const deleteCmsPostFail = error => ({
    type: DELETE_CMS_POST_FAIL,
    payload: error,
  })
  
  //Categories
  
  export const getCmsCategories = () => ({
    type: GET_CMS_CATEGORIES,
  })
  
  export const getCmsCategoriesSuccess = categories => ({
    type: GET_CMS_CATEGORIES_SUCCESS,
    payload: categories,
  })
  
  export const getCmsCategoriesFail = error => ({
    type: GET_CMS_CATEGORIES_FAIL,
    payload: error,
  })
  
  export const addCmsCategory = category => ({
    type: ADD_CMS_CATEGORY,
    payload: category,
  })
  
  export const addCmsCategorySuccess = category => ({
    type: ADD_CMS_CATEGORY_SUCCESS,
    payload: category,
  })
  
  export const addCmsCategoryFail = error => ({
    type: ADD_CMS_CATEGORY_FAIL,
    payload: error,
  })
  
  export const updateCmsCategory = category => ({
    type: UPDATE_CMS_CATEGORY,
    payload: category,
  })
  
  export const updateCmsCategorySuccess = category => ({
    type: UPDATE_CMS_CATEGORY_SUCCESS,
    payload: category,
  })
  
  export const updateCmsCategoryFail = error => ({
    type: UPDATE_CMS_CATEGORY_FAIL,
    payload: error,
  })
  
  export const deleteCmsCategory = category => ({
    type: DELETE_CMS_CATEGORY,
    payload: category,
  })
  
  export const deleteCmsCategorySuccess = category => ({
    type: DELETE_CMS_CATEGORY_SUCCESS,
    payload: category,
  })
  
  export const deleteCmsCategoryFail = error => ({
    type: DELETE_CMS_CATEGORY_FAIL,
    payload: error,
  })

  //Pages
  
  export const getCmsPages = () => ({
    type: GET_CMS_PAGES,
  })
  
  export const getCmsPagesSuccess = pages => ({
    type: GET_CMS_PAGES_SUCCESS,
    payload: pages,
  })
  
  export const getCmsPagesFail = error => ({
    type: GET_CMS_PAGES_FAIL,
    payload: error,
  })
  
  export const addCmsPage = page => ({
    type: ADD_CMS_PAGE,
    payload: page,
  })
  
  export const addCmsPageSuccess = page => ({
    type: ADD_CMS_PAGE_SUCCESS,
    payload: page,
  })
  
  export const addCmsPageFail = error => ({
    type: ADD_CMS_PAGE_FAIL,
    payload: error,
  })
  
  export const updateCmsPage = page => ({
    type: UPDATE_CMS_PAGE,
    payload: page,
  })
  
  export const updateCmsPageSuccess = page => ({
    type: UPDATE_CMS_PAGE_SUCCESS,
    payload: page,
  })
  
  export const updateCmsPageFail = error => ({
    type: UPDATE_CMS_PAGE_FAIL,
    payload: error,
  })
  
  export const deleteCmsPage = page => ({
    type: DELETE_CMS_PAGE,
    payload: page,
  })
  
  export const deleteCmsPageSuccess = page => ({
    type: DELETE_CMS_PAGE_SUCCESS,
    payload: page,
  })
  
  export const deleteCmsPageFail = error => ({
    type: DELETE_CMS_PAGE_FAIL,
    payload: error,
  })

  //Menu
  
  export const getCmsMenu = () => ({
    type: GET_CMS_MENU,
  })
  
  export const getCmsMenuSuccess = menu => ({
    type: GET_CMS_MENU_SUCCESS,
    payload: menu,
  })
  
  export const getCmsMenuFail = error => ({
    type: GET_CMS_MENU_FAIL,
    payload: error,
  })
  
  export const addCmsMenu = menu => ({
    type: ADD_CMS_MENU,
    payload: menu,
  })
  
  export const addCmsMenuSuccess = menu => ({
    type: ADD_CMS_MENU_SUCCESS,
    payload: menu,
  })
  
  export const addCmsMenuFail = error => ({
    type: ADD_CMS_MENU_FAIL,
    payload: error,
  })
  
  export const updateCmsMenu = menu => ({
    type: UPDATE_CMS_MENU,
    payload: menu,
  })
  
  export const updateCmsMenuSuccess = menu => ({
    type: UPDATE_CMS_MENU_SUCCESS,
    payload: menu,
  })
  
  export const updateCmsMenuFail = error => ({
    type: UPDATE_CMS_MENU_FAIL,
    payload: error,
  })
  
  export const deleteCmsMenu = menu => ({
    type: DELETE_CMS_MENU,
    payload: menu,
  })
  
  export const deleteCmsMenuSuccess = menu => ({
    type: DELETE_CMS_MENU_SUCCESS,
    payload: menu,
  })
  
  export const deleteCmsMenuFail = error => ({
    type: DELETE_CMS_MENU_FAIL,
    payload: error,
  })

  //Galleries
  
  export const getCmsGalleries = () => ({
    type: GET_CMS_GALLERIES,
  })
  
  export const getCmsGalleriesSuccess = galleries => ({
    type: GET_CMS_GALLERIES_SUCCESS,
    payload: galleries,
  })
  
  export const getCmsGalleriesFail = error => ({
    type: GET_CMS_GALLERIES_FAIL,
    payload: error,
  })
  
  export const addCmsGallery = gallery => ({
    type: ADD_CMS_GALLERY,
    payload: gallery,
  })
  
  export const addCmsGallerySuccess = gallery => ({
    type: ADD_CMS_GALLERY_SUCCESS,
    payload: gallery,
  })
  
  export const addCmsGalleryFail = error => ({
    type: ADD_CMS_GALLERY_FAIL,
    payload: error,
  })
  
  export const updateCmsGallery = gallery => ({
    type: UPDATE_CMS_GALLERY,
    payload: gallery,
  })
  
  export const updateCmsGallerySuccess = gallery => ({
    type: UPDATE_CMS_GALLERY_SUCCESS,
    payload: gallery,
  })
  
  export const updateCmsGalleryFail = error => ({
    type: UPDATE_CMS_GALLERY_FAIL,
    payload: error,
  })
  
  export const deleteCmsGallery = gallery => ({
    type: DELETE_CMS_GALLERY,
    payload: gallery,
  })
  
  export const deleteCmsGallerySuccess = gallery => ({
    type: DELETE_CMS_GALLERY_SUCCESS,
    payload: gallery,
  })
  
  export const deleteCmsGalleryFail = error => ({
    type: DELETE_CMS_GALLERY_FAIL,
    payload: error,
  })

  //Galleries Items
  
  export const getCmsGalleryItems = () => ({
    type: GET_CMS_GALLERY_ITEMS,
  })
  
  export const getCmsGalleryItemsSuccess = gallery_items => ({
    type: GET_CMS_GALLERY_ITEMS_SUCCESS,
    payload: gallery_items,
  })
  
  export const getCmsGalleryItemsFail = error => ({
    type: GET_CMS_GALLERY_ITEMS_FAIL,
    payload: error,
  })
  
  export const addCmsGalleryItem = gallery_item => ({
    type: ADD_CMS_GALLERY_ITEM,
    payload: gallery_item,
  })
  
  export const addCmsGalleryItemSuccess = gallery_item => ({
    type: ADD_CMS_GALLERY_ITEM_SUCCESS,
    payload: gallery_item,
  })
  
  export const addCmsGalleryItemFail = error => ({
    type: ADD_CMS_GALLERY_ITEM_FAIL,
    payload: error,
  })
  
  export const updateCmsGalleryItem = gallery_item => ({
    type: UPDATE_CMS_GALLERY_ITEM,
    payload: gallery_item,
  })
  
  export const updateCmsGalleryItemSuccess = gallery_item => ({
    type: UPDATE_CMS_GALLERY_ITEM_SUCCESS,
    payload: gallery_item,
  })
  
  export const updateCmsGalleryItemFail = error => ({
    type: UPDATE_CMS_GALLERY_ITEM_FAIL,
    payload: error,
  })
  
  export const deleteCmsGalleryItem = gallery_item => ({
    type: DELETE_CMS_GALLERY_ITEM,
    payload: gallery_item,
  })
  
  export const deleteCmsGalleryItemSuccess = gallery_item => ({
    type: DELETE_CMS_GALLERY_ITEM_SUCCESS,
    payload: gallery_item,
  })
  
  export const deleteCmsGalleryItemFail = error => ({
    type: DELETE_CMS_GALLERY_ITEM_FAIL,
    payload: error,
  })

  //Sliders
  
  export const getCmsSliders = () => ({
    type: GET_CMS_SLIDERS,
  })
  
  export const getCmsSlidersSuccess = sliders => ({
    type: GET_CMS_SLIDERS_SUCCESS,
    payload: sliders,
  })
  
  export const getCmsSlidersFail = error => ({
    type: GET_CMS_SLIDERS_FAIL,
    payload: error,
  })
  
  export const addCmsSlider = slider => ({
    type: ADD_CMS_SLIDER,
    payload: slider,
  })
  
  export const addCmsSliderSuccess = slider => ({
    type: ADD_CMS_SLIDER_SUCCESS,
    payload: slider,
  })
  
  export const addCmsSliderFail = error => ({
    type: ADD_CMS_SLIDER_FAIL,
    payload: error,
  })
  
  export const updateCmsSlider = slider => ({
    type: UPDATE_CMS_SLIDER,
    payload: slider,
  })
  
  export const updateCmsSliderSuccess = slider => ({
    type: UPDATE_CMS_SLIDER_SUCCESS,
    payload: slider,
  })
  
  export const updateCmsSliderFail = error => ({
    type: UPDATE_CMS_SLIDER_FAIL,
    payload: error,
  })
  
  export const deleteCmsSlider = slider => ({
    type: DELETE_CMS_SLIDER,
    payload: slider,
  })
  
  export const deleteCmsSliderSuccess = slider => ({
    type: DELETE_CMS_SLIDER_SUCCESS,
    payload: slider,
  })
  
  export const deleteCmsSliderFail = error => ({
    type: DELETE_CMS_SLIDER_FAIL,
    payload: error,
  })

  //Sliders Items
  
  export const getCmsSliderItems = () => ({
    type: GET_CMS_SLIDER_ITEMS,
  })
  
  export const getCmsSliderItemsSuccess = slider_items => ({
    type: GET_CMS_SLIDER_ITEMS_SUCCESS,
    payload: slider_items,
  })
  
  export const getCmsSliderItemsFail = error => ({
    type: GET_CMS_SLIDER_ITEMS_FAIL,
    payload: error,
  })
  
  export const addCmsSliderItem = slider_item => ({
    type: ADD_CMS_SLIDER_ITEM,
    payload: slider_item,
  })
  
  export const addCmsSliderItemSuccess = slider_item => ({
    type: ADD_CMS_SLIDER_ITEM_SUCCESS,
    payload: slider_item,
  })
  
  export const addCmsSliderItemFail = error => ({
    type: ADD_CMS_SLIDER_ITEM_FAIL,
    payload: error,
  })
  
  export const updateCmsSliderItem = slider_item => ({
    type: UPDATE_CMS_SLIDER_ITEM,
    payload: slider_item,
  })
  
  export const updateCmsSliderItemSuccess = slider_item => ({
    type: UPDATE_CMS_SLIDER_ITEM_SUCCESS,
    payload: slider_item,
  })
  
  export const updateCmsSliderItemFail = error => ({
    type: UPDATE_CMS_SLIDER_ITEM_FAIL,
    payload: error,
  })
  
  export const deleteCmsSliderItem = slider_item => ({
    type: DELETE_CMS_SLIDER_ITEM,
    payload: slider_item,
  })
  
  export const deleteCmsSliderItemSuccess = slider_item => ({
    type: DELETE_CMS_SLIDER_ITEM_SUCCESS,
    payload: slider_item,
  })
  
  export const deleteCmsSliderItemFail = error => ({
    type: DELETE_CMS_SLIDER_ITEM_FAIL,
    payload: error,
  })

  //Faq
  
  export const getCmsFaqs = () => ({
    type: GET_CMS_FAQS,
  })
  
  export const getCmsFaqsSuccess = faqs => ({
    type: GET_CMS_FAQS_SUCCESS,
    payload: faqs,
  })
  
  export const getCmsFaqsFail = error => ({
    type: GET_CMS_FAQS_FAIL,
    payload: error,
  })
  
  export const addCmsFaq = faq => ({
    type: ADD_CMS_FAQ,
    payload: faq,
  })
  
  export const addCmsFaqSuccess = faq => ({
    type: ADD_CMS_FAQ_SUCCESS,
    payload: faq,
  })
  
  export const addCmsFaqFail = error => ({
    type: ADD_CMS_FAQ_FAIL,
    payload: error,
  })
  
  export const updateCmsFaq = faq => ({
    type: UPDATE_CMS_FAQ,
    payload: faq,
  })
  
  export const updateCmsFaqSuccess = faq => ({
    type: UPDATE_CMS_FAQ_SUCCESS,
    payload: faq,
  })
  
  export const updateCmsFaqFail = error => ({
    type: UPDATE_CMS_FAQ_FAIL,
    payload: error,
  })
  
  export const deleteCmsFaq = faq => ({
    type: DELETE_CMS_FAQ,
    payload: faq,
  })
  
  export const deleteCmsFaqSuccess = faq => ({
    type: DELETE_CMS_FAQ_SUCCESS,
    payload: faq,
  })
  
  export const deleteCmsFaqFail = error => ({
    type: DELETE_CMS_FAQ_FAIL,
    payload: error,
  })

  //Faq Categories
  
  export const getCmsFaqCategories = () => ({
    type: GET_CMS_FAQ_CATEGORIES,
  })
  
  export const getCmsFaqCategoriesSuccess = faqCategories => ({
    type: GET_CMS_FAQ_CATEGORIES_SUCCESS,
    payload: faqCategories,
  })
  
  export const getCmsFaqCategoriesFail = error => ({
    type: GET_CMS_FAQ_CATEGORIES_FAIL,
    payload: error,
  })
  
  export const addCmsFaqCategory = faqCategory => ({
    type: ADD_CMS_FAQ_CATEGORY,
    payload: faqCategory,
  })
  
  export const addCmsFaqCategorySuccess = faqCategory => ({
    type: ADD_CMS_FAQ_CATEGORY_SUCCESS,
    payload: faqCategory,
  })
  
  export const addCmsFaqCategoryFail = error => ({
    type: ADD_CMS_FAQ_CATEGORY_FAIL,
    payload: error,
  })
  
  export const updateCmsFaqCategory = faqCategory => ({
    type: UPDATE_CMS_FAQ_CATEGORY,
    payload: faqCategory,
  })
  
  export const updateCmsFaqCategorySuccess = faqCategory => ({
    type: UPDATE_CMS_FAQ_CATEGORY_SUCCESS,
    payload: faqCategory,
  })
  
  export const updateCmsFaqCategoryFail = error => ({
    type: UPDATE_CMS_FAQ_CATEGORY_FAIL,
    payload: error,
  })
  
  export const deleteCmsFaqCategory = faqCategory => ({
    type: DELETE_CMS_FAQ_CATEGORY,
    payload: faqCategory,
  })
  
  export const deleteCmsFaqCategorySuccess = faqCategory => ({
    type: DELETE_CMS_FAQ_CATEGORY_SUCCESS,
    payload: faqCategory,
  })
  
  export const deleteCmsFaqCategoryFail = error => ({
    type: DELETE_CMS_FAQ_CATEGORY_FAIL,
    payload: error,
  })