import {
  GET_SMS_NOTIFICATIONS,
  GET_SMS_NOTIFICATIONS_SUCCESS,
  GET_SMS_NOTIFICATIONS_FAIL,
  UPDATE_SMS_NOTIFICATIONS,
  UPDATE_SMS_NOTIFICATIONS_SUCCESS,
  UPDATE_SMS_NOTIFICATIONS_FAIL
} from "./actionTypes"

// Notifications

export const getSMSNotifications = () => ({
  type: GET_SMS_NOTIFICATIONS,
})

export const getSMSNotificationsSuccess = notifications => ({
  type: GET_SMS_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const getSMSNotificationsFail = error => ({
  type: GET_SMS_NOTIFICATIONS_FAIL,
  payload: error,
})

export const updateSMSNotifications = notifications => ({
  type: UPDATE_SMS_NOTIFICATIONS,
  payload: notifications,
})

export const updateSMSNotificationsSuccess = notifications => ({
  type: UPDATE_SMS_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const updateSMSNotificationsFail = error => ({
  type: UPDATE_SMS_NOTIFICATIONS_FAIL,
  payload: error,
})