import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik, FormikProvider } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getOrders as onGetOrders,
  addOrder as onAddOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/orders/actions"

import { getBranches as onGetBranches } from "store/branches/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

const Orders = props => {
  //meta title
  document.title = "Zamówienia - Panel administracyjny"

  const dispatch = useDispatch()

  const [order, setOrder] = useState(null)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      order_id: (order && order.order_id) || "",
      order_nr: (order && order.order_nr) || "",
      order_create_at: (order && order.order_create_at) || "",
      branch_id: (order && order.branch_id) || "",
      order_type: (order && order.order_type) || "",
      order_status: (order && order.order_status) || "",
      client_name: (order && order.client_name) || "",
      client_last_name: (order && order.client_last_name) || "",
      client_email: (order && order.client_email) || "",
      client_phone: (order && order.client_phone) || "",
      client_delivery_method: (order && order.client_delivery_method) || "",
      client_delivery_address_street:
        (order && order.client_delivery_address_street) || "",
      client_delivery_address_post:
        (order && order.client_delivery_address_post) || "",
      client_delivery_address_city:
        (order && order.client_delivery_address_city) || "",
      client_payment_method: (order && order.client_payment_method) || "",
      provider_delivery_cost: (order && order.provider_delivery_cost) || "",
      provider_delivery_days: (order && order.provider_delivery_days) || "",
      client_invoice_on: (order && order.client_invoice_on) || false,
      client_invoice_address_street:
        (order && order.client_invoice_address_street) || "",
      client_invoice_address_post:
        (order && order.client_invoice_address_post) || "",
      client_invoice_address_city:
        (order && order.client_invoice_address_city) || "",
      client_comment: (order && order.client_comment) || "",
      provider_comment: (order && order.provider_comment) || "",
      provider_delivery_method: (order && order.provider_delivery_method) || "",
    },
    validationSchema: Yup.object({
      client_name: Yup.string().required("To pole jest wymagane"),
      client_last_name: Yup.string().required("To pole jest wymagane"),
      client_email: Yup.string().required("To pole jest wymagane"),
      client_phone: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateOrder = {
          order_id: order ? order.order_id : 0,
          order_nr: values.order_nr,
          order_create_at: values.order_create_at,
          branch_id: values.branch_id,
          order_type: values.order_type,
          order_status: values.order_status,
          client_name: values.client_name,
          client_last_name: values.client_last_name,
          client_email: values.client_email,
          client_phone: values.client_phone,
          client_delivery_method: values.client_delivery_method,
          client_delivery_address_street: values.client_delivery_address_street,
          client_delivery_address_post: values.client_delivery_address_post,
          client_delivery_address_city: values.client_delivery_address_city,
          client_payment_method: values.client_payment_method,
          provider_delivery_cost: values.provider_delivery_cost,
          provider_delivery_days: values.provider_delivery_days,
          client_invoice_on: values.client_invoice_on,
          client_invoice_address_street: values.client_invoice_address_street,
          client_invoice_address_post: values.client_invoice_address_post,
          client_invoice_address_city: values.client_invoice_address_city,
          client_comment: values.client_comment,
          provider_comment: values.provider_comment,
        }
        // update order
        dispatch(onUpdateOrder(updateOrder))
        validation.resetForm()
      } else {
        const addOrder = {
          order_id: values["order_id"],
          order_nr: values["order_nr"],
        }
        // save new order
        dispatch(onAddOrder(addOrder))
        validation.resetForm()
      }
      toggle()
    },
  })

  console.log(order)

  const { orders } = useSelector(state => ({
    orders: state.orders.orders,
  }))
  const { success } = useSelector(state => ({
    success: state.orders.success,
  }))

  const { error } = useSelector(state => ({
    error: state.orders.error,
  }))

  const { branches } = useSelector(state => ({
    branches: state.branches.dataBranches.branches,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "order_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "order_nr",
      text: "Nr. zamówienia",
      sort: true,
      formatter: cellContent => <React.Fragment>#{cellContent}</React.Fragment>,
    },
    {
      dataField: "branch.branch_name",
      text: "Oddział",
      sort: true,
    },
    {
      dataField: "client_name",
      text: "Klient",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
          {cellContent} {row.client_last_name}
        </React.Fragment>
      ),
    },
    {
      dataField: "order_create_at",
      text: "Data zamówienia",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ]
  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders())
    }
  }, [dispatch, orders])

  useEffect(() => {
    if (branches && !branches.length) {
      dispatch(onGetBranches())
    }
  }, [dispatch, branches])

  useEffect(() => {
    setOrder(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrder(orders)
      setIsEdit(false)
    }
  }, [orders])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_ORDER_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_ORDER_FAIL",
        })
      }, 5000)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const processString = value => {
    try {
      const parsed = JSON.parse(value)
      return parsed
    } catch (e) {
      return value
    }
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrder({
      order_id: order.order_id,
      order_nr: order.order_nr,
      order_create_at: order.order_create_at,
      branch_id: order.branch ? order.branch.branch_id : "",
      order_type: order.order_type,
      order_status: order.order_status,
      client_name: order.client_name,
      client_last_name: order.client_last_name,
      client_email: order.client_email,
      client_phone: order.client_phone,
      client_delivery_method: order.client_delivery_method,
      client_delivery_address_street: order.client_delivery_address_street
        ? order.client_delivery_address_street
        : "",
      client_delivery_address_post: order.client_delivery_address_post
        ? order.client_delivery_address_post
        : "",
      client_delivery_address_city: order.client_delivery_address_city
        ? order.client_delivery_address_city
        : "",
      client_payment_method: order.client_payment_method,
      provider_delivery_cost: order.provider_delivery_cost
        ? order.provider_delivery_cost
        : "",
      provider_delivery_days: order.provider_delivery_days
        ? order.provider_delivery_days
        : "",
      client_invoice_on: order.client_invoice_on,
      client_invoice_address_street: order.client_invoice_address_street,
      client_invoice_address_post: order.client_invoice_address_post,
      client_invoice_address_city: order.client_invoice_address_city,
      client_comment: order.client_comment,
      provider_comment: order.provider_comment,
      provider_delivery_method: order.provider_delivery_method
        ? processString(order.provider_delivery_method)
        : "",
    })

    setIsEdit(true)

    toggle()
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order.order_id) {
      dispatch(onDeleteOrder(order))
      setDeleteModal(false)
    }
  }

  const defaultSorted = [
    {
      dataField: "order_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )
  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Zamówienia" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && (
                      <Row>
                        <Col lg="auto">
                          <Alert isOpen={success.open}>{success.text}</Alert>
                          <Alert isOpen={error.open} color="danger">
                            {error.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <ToolkitProvider
                      keyField="order_id"
                      data={orders}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="xl">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? `Edytuj zamówienie ${
                                        "#" + validation.values.order_nr
                                      } - ${validation.values.order_create_at}`
                                    : "Nowe zamówienie"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            ID
                                          </Label>
                                          <Input
                                            name="order_id"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.order_id || ""
                                            }
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Zamówienie nr
                                          </Label>
                                          <Input
                                            name="order_nr"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              "#" + validation.values.order_nr
                                            }
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-4">
                                        <div className="mb-2">
                                          <Label className="form-label">
                                            Oddział
                                          </Label>
                                          <Input
                                            name="branch_id"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.branch_id}
                                          >
                                            {branches.map((branch, index) => (
                                              <option
                                                value={branch.branch_id}
                                                key={branch.branch_id}
                                              >
                                                {branch.branch_name}
                                              </option>
                                            ))}
                                          </Input>
                                        </div>
                                      </Col>
                                      <Col className="col-4">
                                        <div className="mb-2">
                                          <Label className="form-label">
                                            Typ zamówienia
                                          </Label>
                                          <Input
                                            name="order_type"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.order_type}
                                          >
                                            <option value="pricing">
                                              wycena
                                            </option>
                                            <option value="buy">
                                              kup teraz
                                            </option>
                                          </Input>
                                        </div>
                                      </Col>
                                      <Col className="col-4">
                                        <div className="mb-2">
                                          <Label className="form-label">
                                            Status zamówienia
                                          </Label>
                                          <Input
                                            name="order_status"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.order_status
                                            }
                                          >
                                            <option value="1">do wyceny</option>
                                            <option value="2">wyceniona</option>
                                            <option value="3">
                                              zaakceptowana/kup teraz
                                              (nieopłacona)
                                            </option>
                                            <option value="4">
                                              zaakceptowana/kup teraz (opłacona)
                                            </option>
                                            <option value="5">
                                              dostarczona
                                            </option>
                                            <option value="6">
                                              wygasła - brak wyceny
                                            </option>
                                            <option value="7">
                                              wygasła - brak akceptacji
                                            </option>
                                            <option value="8">anulowana</option>
                                          </Input>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={12}>
                                        <hr
                                          style={{
                                            height: "1px",
                                            backgroundColor: "currentcolor",
                                            opacity: ".2",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Imię klienta
                                          </Label>
                                          <Input
                                            name="client_name"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.client_name
                                            }
                                            invalid={
                                              validation.touched.client_name &&
                                              validation.errors.client_name
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.client_name &&
                                          validation.errors.client_name ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.client_name}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Nazwisko klienta
                                          </Label>
                                          <Input
                                            name="client_last_name"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.client_last_name
                                            }
                                            invalid={
                                              validation.touched
                                                .client_last_name &&
                                              validation.errors.client_last_name
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched
                                            .client_last_name &&
                                          validation.errors.client_last_name ? (
                                            <FormFeedback type="invalid">
                                              {
                                                validation.errors
                                                  .client_last_name
                                              }
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            E-mail klienta
                                          </Label>
                                          <Input
                                            name="client_email"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.client_email
                                            }
                                            invalid={
                                              validation.touched.client_email &&
                                              validation.errors.client_email
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.client_email &&
                                          validation.errors.client_email ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.client_email}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Telefon klienta
                                          </Label>
                                          <Input
                                            name="client_phone"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.client_phone
                                            }
                                            invalid={
                                              validation.touched.client_phone &&
                                              validation.errors.client_phone
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.client_phone &&
                                          validation.errors.client_phone ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.client_phone}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Sposób dostawy
                                          </Label>
                                          <Row>
                                            <Col className="col-6">
                                              <label
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Input
                                                  name="client_delivery_method"
                                                  type="radio"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value="provider_shop_delivery_self"
                                                  checked={
                                                    validation.values
                                                      .client_delivery_method ===
                                                    "provider_shop_delivery_self"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                &nbsp;&nbsp;Odbiór osobisty
                                              </label>
                                            </Col>
                                            <Col className="col-6">
                                              <label
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Input
                                                  name="client_delivery_method"
                                                  type="radio"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value="provider_shop_delivery_small_hds"
                                                  checked={
                                                    validation.values
                                                      .client_delivery_method ===
                                                    "provider_shop_delivery_small_hds"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                &nbsp;&nbsp;Dostawa pod wskazany
                                                adres
                                              </label>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>

                                    {validation.values.client_delivery_method.includes(
                                      "provider_shop_delivery_custom"
                                    ) && (
                                      <>
                                        <Row>
                                          <Col className="col-6">
                                            <label
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <Input
                                                name="client_delivery_method"
                                                type="radio"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value="provider_shop_delivery_custom"
                                                checked={
                                                  validation.values
                                                    .provider_delivery_method
                                                    ?.name ===
                                                  "provider_shop_delivery_custom"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              {
                                                validation.values
                                                  .provider_delivery_method
                                                  ?.params?.name
                                              }
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="col-4">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Adres
                                              </Label>
                                              <Input
                                                name="client_delivery_address_street"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .client_delivery_address_street
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-4">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Kod pocztowy
                                              </Label>
                                              <Input
                                                name="client_delivery_address_post"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .client_delivery_address_post
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-4">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Miasto
                                              </Label>
                                              <Input
                                                name="client_delivery_address_city"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .client_delivery_address_city
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="col-6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Koszt dostawy
                                              </Label>
                                              <div className="input-group">
                                                <Input
                                                  name="provider_delivery_cost"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .provider_delivery_cost
                                                  }
                                                />
                                                <span className="input-group-text">
                                                  zł
                                                </span>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                    {validation.values
                                      .client_delivery_method ===
                                    "provider_shop_delivery_small_hds" ? (
                                      <React.Fragment>
                                        <Row>
                                          <Col className="col-4">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Adres
                                              </Label>
                                              <Input
                                                name="client_delivery_address_street"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .client_delivery_address_street
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-4">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Kod pocztowy
                                              </Label>
                                              <Input
                                                name="client_delivery_address_post"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .client_delivery_address_post
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-4">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Miasto
                                              </Label>
                                              <Input
                                                name="client_delivery_address_city"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .client_delivery_address_city
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="col-6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Koszt dostawy
                                              </Label>
                                              <div className="input-group">
                                                <Input
                                                  name="provider_delivery_cost"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .provider_delivery_cost
                                                  }
                                                />
                                                <span className="input-group-text">
                                                  zł
                                                </span>
                                              </div>
                                            </div>
                                          </Col>
                                          {validation.values.order_type ===
                                          "pricing" ? (
                                            <Col className="col-6">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Czas dostawy
                                                </Label>
                                                <div className="input-group">
                                                  <Input
                                                    name="provider_delivery_days"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .provider_delivery_days
                                                    }
                                                  />
                                                  <span className="input-group-text">
                                                    dni
                                                  </span>
                                                </div>
                                              </div>
                                            </Col>
                                          ) : null}
                                        </Row>
                                      </React.Fragment>
                                    ) : null}
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Sposób płatności
                                          </Label>
                                          <Row>
                                            <Col className="col-6">
                                              <label
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Input
                                                  name="client_payment_method"
                                                  type="radio"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value="cash"
                                                  checked={
                                                    validation.values
                                                      .client_payment_method ===
                                                    "cash"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                &nbsp;&nbsp;Płatność przy
                                                odbiorze
                                              </label>
                                            </Col>
                                            <Col className="col-6">
                                              <label
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Input
                                                  name="client_payment_method"
                                                  type="radio"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value="bank"
                                                  checked={
                                                    validation.values
                                                      .client_payment_method ===
                                                    "bank"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                &nbsp;&nbsp;Płatność przelewem
                                              </label>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <Input
                                              name="client_invoice_on"
                                              type="checkbox"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value="1"
                                              checked={
                                                validation.values
                                                  .client_invoice_on
                                              }
                                            />
                                            &nbsp;&nbsp;Faktura VAT
                                          </Label>
                                        </div>
                                      </Col>
                                    </Row>
                                    {validation.values.client_invoice_on ? (
                                      <Row>
                                        <Col className="col-4">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Adres do faktury
                                            </Label>
                                            <Input
                                              name="client_invoice_address_street"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .client_invoice_address_street
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col className="col-4">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Kod pocztowy do faktury
                                            </Label>
                                            <Input
                                              name="client_invoice_address_post"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .client_invoice_address_post
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col className="col-4">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Miasto do faktury
                                            </Label>
                                            <Input
                                              name="client_invoice_address_city"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .client_invoice_address_city
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    ) : null}
                                    <Row>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Komentarz klienta
                                          </Label>
                                          <Input
                                            name="client_comment"
                                            type="textarea"
                                            rows="3"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.client_comment
                                            }
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Komentarz sprzedawcy
                                          </Label>
                                          <Input
                                            name="provider_comment"
                                            type="textarea"
                                            rows="3"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.provider_comment
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Orders)
