import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner
} from "reactstrap";

import { useFormik, FieldArray, Field, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getAttributes as onGetAttributes,
  addAttribute as onAddAttribute,
  updateAttribute as onUpdateAttribute,
  deleteAttribute as onDeleteAttribute,
} from "store/catalog/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Attributes = props => {
  //meta title
  document.title = "Atrybuty - Panel administracyjny";
  
  const dispatch = useDispatch();

  const [attribute, setAttribute] = useState(null);

  //validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      attribute_id: (attribute && attribute.attribute_id) || "",
      attribute_name: (attribute && attribute.attribute_name) || "",
      attribute_value: (attribute && attribute.attribute_value) || "",
      attribute_type: (attribute && attribute.attribute_type) || "list",
      attribute_items: (attribute && attribute.attribute_items) || "",
    },
    validationSchema: Yup.object({
      attribute_name: Yup.string().required("To pole jest wymagane"),
      attribute_value: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateAttribute = {
          attribute_id: attribute ? attribute.attribute_id : 0,
          attribute_name: values.attribute_name,
          attribute_value: values.attribute_value,
          attribute_type: values.attribute_type,
          attribute_items: values.attribute_items,
        };
        // update
        dispatch(onUpdateAttribute(updateAttribute));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newAttribute = {
          attribute_name: values["attribute_name"],
          attribute_value: values["attribute_value"],
          attribute_type: values["attribute_type"],
          attribute_items: values["attribute_items"],
        };
        // add new
        dispatch(onAddAttribute(newAttribute));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { attributes } = useSelector(state => ({
    attributes: state.catalog.dataAttributes.attributes,
  }));

  const { success } = useSelector(state => ({
    success: state.catalog.dataAttributes.success,
  }));

  const { error } = useSelector(state => ({
    error: state.catalog.dataAttributes.error,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "attribute_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "attribute_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "attribute_value",
      text: "Watrość",
      sort: true,
    },
    {
      dataField: "attribute_type",
      text: "Typ",
      sort: true,
      formatter: cellContent => (
        (cellContent === 'list') ? 'Lista' : 
        (cellContent === 'radio') ? 'Radio' : 
        (cellContent === 'box') ? 'Box' : 
        (cellContent === 'group') ? 'Grupowy' : ''
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, attribute) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleAttributeClick(attribute)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(attribute)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    if (attributes && !attributes.length) {
      dispatch(onGetAttributes());
    }
  }, [dispatch, attributes]);

  useEffect(() => {
    setAttribute(attributes);
  }, [attributes]);

  useEffect(() => {
    if (!isEmpty(attributes) && !!isEdit) {
      setAttribute(attributes);
      setIsEdit(false);
    }
  }, [attributes]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_ATTRIBUTE_SUCCESS",
        });
      }, 5000)
      
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_ATTRIBUTE_FAIL",
        });
      }, 5000)
      
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleAttributeClick = arg => {
    const attribute = arg;

    const newItems = attribute.items.map(attr => ({...attr, 
      attribute_id: attr.attribute_id,
      attribute_item_created_at: attr.attribute_item_created_at,
      attribute_item_file: attr.attribute_item_file ? attr.attribute_item_file : "",
      attribute_item_id: attr.attribute_item_id,
      attribute_item_is_group: attr.attribute_item_is_group,
      attribute_item_name: attr.attribute_item_name,
      attribute_item_name_slug: attr.attribute_item_name_slug,
      attribute_item_parent_id: attr.attribute_item_parent_id,
      attribute_item_updated_at: attr.attribute_item_updated_at,
      attribute_item_value: attr.attribute_item_value,
    }))

    setAttribute({
      attribute_id: attribute.attribute_id,
      attribute_name: attribute.attribute_name,
      attribute_value: attribute.attribute_value,
      attribute_type: attribute.attribute_type,
      attribute_items: newItems,
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (attribute) => {
    setAttribute(attribute);
    setDeleteModal(true);
  };

  const handleDeleteAttribute = () => {
    if (attribute.attribute_id) {
      dispatch(onDeleteAttribute(attribute));
      setDeleteModal(false);
    }
  };

  const handleAttributeClicks = () => {
    setAttribute("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "attribute_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteAttribute}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Katalog" breadcrumbItem="Atrybuty" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="attribute_id"
                      data={attributes}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleAttributeClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj atrybut
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edytuj atrybut" : "Dodaj atrybut"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col xs={12} sm={4} className="mb-3">
                                        <Label className="form-label">
                                          Nazwa robocza
                                        </Label>
                                        <Input
                                          name="attribute_name"
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.attribute_name}
                                          invalid={
                                            validation.touched.attribute_name &&
                                            validation.errors.attribute_name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.attribute_name &&
                                        validation.errors.attribute_name ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.attribute_name}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                      <Col xs={12} sm={4} className="mb-3">
                                        <Label className="form-label">
                                          Nazwa wyświetlana
                                        </Label>
                                        <Input
                                          name="attribute_value"
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.attribute_value}
                                          invalid={
                                            validation.touched.attribute_value &&
                                            validation.errors.attribute_value
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.attribute_value &&
                                        validation.errors.attribute_value ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.attribute_value}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                      <Col xs={12} sm={4} className="mb-3">
                                        <Label className="form-label">Typ</Label>
                                        <Input
                                          type="select"
                                          name="attribute_type"
                                          className="form-select"
                                          multiple={false}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.attribute_type || []}
                                        >
                                          <option value="list">Lista</option>
                                          <option value="radio">Radio</option>
                                          <option value="box">Box</option>
                                          <option value="group">Grupowy</option>
                                        </Input>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={12}>
                                        <hr
                                          style={{
                                            height: "1px",
                                            backgroundColor: "currentcolor",
                                            opacity: ".2",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={12}>
                                        <h4 className="mb-3">Wartości atrybutu</h4>
                                      </Col>
                                    </Row>
                                    <FieldArray
                                      name="attribute_items"
                                      validateOnChange={false}
                                      render={array_attribute => (
                                        <React.Fragment>
                                          {validation.values.attribute_items &&
                                          validation.values.attribute_items.length > 0
                                            ? validation.values.attribute_items.map(
                                                (attr, index) => (
                                                  <Row
                                                    key={attr.attribute_item_id}
                                                    className="align-items-center"
                                                  >
                                                    <Col xs={5} sm={4} className="mb-3">
                                                      <Label className="form-label">
                                                        Nazwa
                                                      </Label>
                                                      <Field
                                                        name={`attribute_items[${index}].attribute_item_name`}
                                                        className="form-control"
                                                      />
                                                    </Col>
                                                    <Col xs={5} sm={4} className="mb-3">
                                                      <Label className="form-label">
                                                        Wartość
                                                      </Label>
                                                      <Field
                                                        name={`attribute_items[${index}].attribute_item_value`}
                                                        className="form-control"
                                                      />
                                                    </Col>
                                                    <Col xs={2} sm="auto">
                                                      <Button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          array_attribute.remove(index)
                                                        }
                                                      >
                                                        Usuń
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                )
                                              )
                                            : ""}
                                          <Row>
                                            <Col xs={12} sm={4}>
                                              <Button
                                                className="btn btn-success"
                                                onClick={() =>
                                                  array_attribute.push({
                                                    attribute_id:
                                                      validation.values.attribute_id,
                                                    attribute_item_id: "",
                                                    attribute_item_created_at: "",
                                                    attribute_item_updated_at: "",
                                                    attribute_item_file: "",
                                                    attribute_item_is_group: 0,
                                                    attribute_item_name: "",
                                                    attribute_item_value: "",
                                                    attribute_item_name_slug: "",
                                                    attribute_item_parent_id: 0,
                                                  })
                                                }
                                              >
                                                Dodaj
                                              </Button>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    />
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(Attributes);
