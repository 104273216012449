import React from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
import classnames from "classnames"

export const FormModal = ({ isOpen, toggle, currentUser, validation }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {!!currentUser ? "Edytuj formę dostawy" : "Dodaj formę dostawy"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={12}>
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: true,
                    })}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Forma dostawy</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={"1"} className="p-3 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col lg="6 mb-3">
                      <Label className="form-label">Nazwa</Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col lg="6 mb-3">
                      <Label className="form-label">Nazwa wyświetlana</Label>
                      <Input
                        name="display_name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.display_name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6 mb-3">
                      <Label className="form-label">Maksymalna waga</Label>
                      <Input
                        name="max_weight"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.max_weight || 1}
                      />
                    </Col>
                    <Col lg="6 mb-3">
                      <Label className="form-label">Maksymalna objętość</Label>
                      <Input
                        name="max_volume"
                        type="number"
                        step={0.01}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.max_volume || 1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6 mb-3">
                      <Label className="form-label">
                        Minimalna wartość zakupów
                      </Label>
                      <Input
                        name="min_shipping_value"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.min_shipping_value || ""}
                      />
                    </Col>
                    <Col lg="6 mb-3">
                      <Label className="form-label">Aktywny</Label>
                      <Input
                        type="select"
                        name="active"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.active == 1 ? "1" : "0"}
                      >
                        <option value="1">Tak</option>
                        <option value="0">Nie</option>
                      </Input>
                    </Col>
                    <Button type="submit" color="primary">
                      Zapisz
                    </Button>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
