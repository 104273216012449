import {
  GET_PP_PRODUCTS_SUCCESS,
  GET_PP_PRODUCTS_FAIL,
  ADD_PP_PRODUCT_SUCCESS,
  ADD_PP_PRODUCT_FAIL,
  UPDATE_PP_PRODUCT_SUCCESS,
  UPDATE_PP_PRODUCT_FAIL,
  DELETE_PP_PRODUCT_SUCCESS,
  DELETE_PP_PRODUCT_FAIL,
  HIDE_PP_PRODUCT_SUCCESS,
  HIDE_PP_PRODUCT_FAIL,
  HIDE_PP_CATEGORY_SUCCESS,
  HIDE_PP_CATEGORY_FAIL,
  GET_PP_CATEGORIES_SUCCESS,
  GET_PP_CATEGORIES_FAIL,
  ADD_PP_CATEGORY_SUCCESS,
  ADD_PP_CATEGORY_FAIL,
  UPDATE_PP_CATEGORY_SUCCESS,
  UPDATE_PP_CATEGORY_FAIL,
  DELETE_PP_CATEGORY_SUCCESS,
  DELETE_PP_CATEGORY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataProducts: {
    products: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataCategories: {
    categories: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  }
}

const partnerprogram = (state = INIT_STATE, action) => {
  switch (action.type) {

    // Products

    case GET_PP_PRODUCTS_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: action.payload
        },
      }

    case GET_PP_PRODUCTS_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          // error: action.payload,
        }
      }

    case ADD_PP_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: [...state.dataProducts.products, action.payload],
          success: {
            open: true,
            text: "Produkt został dodany."
          }
        }
      }

    case ADD_PP_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            open: true,
            text: "Podczas dodawania produktu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_PP_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: state.dataProducts.products.map(product =>
            product.pp_product_id.toString() === action.payload.pp_product_id.toString()
              ? { product, ...action.payload }
              : product
          ),
          success: {
            open: true,
            text: "Produkt został zaktualizowany."
          } 
        }
      }

    case UPDATE_PP_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            open: true,
            text: "Podczas aktualizacji produktu wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_PP_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          products: state.dataProducts.products.filter(product => 
            product.pp_product_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Produkt został usunięty."
          } 
        }
      }

    case DELETE_PP_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            open: true,
            text: "Podczas usuwania produktu wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_PP_PRODUCT_SUCCESS:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          success: {
            ...state.dataProducts.success,
            open: false
          }
        },
      }

    case HIDE_PP_PRODUCT_FAIL:
      return {
        ...state,
        dataProducts: {
          ...state.dataProducts,
          error: {
            ...state.dataProducts.error,
            open: false
          }
        },
      }

    // Categories

    case GET_PP_CATEGORIES_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: action.payload
        },
      }

    case GET_PP_CATEGORIES_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          // error: action.payload,
        }
      }

    case ADD_PP_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: [...state.dataCategories.categories, action.payload],
          success: {
            open: true,
            text: "Kategoria została dodana."
          }
        }
      }

    case ADD_PP_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas dodawania kategorii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_PP_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          categories: state.dataCategories.categories.map(category =>
            category.pp_category_id.toString() === action.payload.pp_category_id.toString()
              ? { category, ...action.payload }
              : category
          ),
          success: {
            open: true,
            text: "Kategoria została zaktualizowana."
          } 
        }
      }

    case UPDATE_PP_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas aktualizacji kategorii wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_PP_CATEGORY_SUCCESS:
        return {
          ...state,
          dataCategories: {
            ...state.dataCategories,
            categories: state.dataCategories.categories.filter(category => 
              category.pp_category_id.toString() !== action.payload.toString()
            ),
            success: {
              open: true,
              text: "Kategoria została usunięta."
            } 
          }
          
        }
  
    case DELETE_PP_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            open: true,
            text: "Podczas usuwania kategorii wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_PP_CATEGORY_SUCCESS:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          success: {
            ...state.dataCategories.success,
            open: false
          }
        },
      }

    case HIDE_PP_CATEGORY_FAIL:
      return {
        ...state,
        dataCategories: {
          ...state.dataCategories,
          error: {
            ...state.dataCategories.error,
            open: false
          }
        },
      }

    default:
      return state
  }
}

export default partnerprogram