import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DELIVERY_FORM_PRICES,
  ADD_DELIVERY_FORM_PRICE,
  UPDATE_DELIVERY_FORM_PRICE,
  DELETE_DELIVERY_FORM_PRICE,
} from "./actionTypes"

import {
  getDeliveryFormPricesSuccess,
  getDeliveryFormPricesFail,
  addDeliveryFormPriceSuccess,
  addDeliveryFormPriceFail,
  updateDeliveryFormPriceSuccess,
  updateDeliveryFormPriceFail,
  deleteDeliveryFormPriceSuccess,
  deleteDeliveryFormPriceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDeliveryForm,
  getDeliveryFormPrices,
  addDeliveryFormPrice,
  updateDeliveryFormPrice,
  deleteDeliveryFormPrice,
} from "../../helpers/backend_helper"

function* fetchDeliveryFormPrices({ payload }) {
  try {
    const response = yield call(getDeliveryFormPrices, payload)
    const deliveryForm = yield call(getDeliveryForm, payload)
    yield put(
      getDeliveryFormPricesSuccess({
        deliveryPrices: response.data,
        deliveryForm: deliveryForm.data,
      })
    )
  } catch (error) {
    yield put(getDeliveryFormPricesFail(error))
  }
}

function* onAddDeliveryFormPrice({ payload }) {
  try {
    const response = yield call(addDeliveryFormPrice, payload)

    yield put(addDeliveryFormPriceSuccess(response.data))
  } catch (error) {
    yield put(addDeliveryFormPriceFail(error))
  }
}

function* onUpdateDeliveryFormPrice({ payload }) {
  try {
    const response = yield call(updateDeliveryFormPrice, payload)
    yield put(updateDeliveryFormPriceSuccess(response.data))
  } catch (error) {
    yield put(updateDeliveryFormPriceFail(error))
  }
}

function* onDeleteDeliveryFormPrice({ payload }) {
  try {
    yield call(deleteDeliveryFormPrice, payload)
    yield put(deleteDeliveryFormPriceSuccess(payload))
  } catch (error) {
    yield put(deleteDeliveryFormPriceFail(error))
  }
}

function* deliveryFormPriceSaga() {
  yield takeEvery(GET_DELIVERY_FORM_PRICES, fetchDeliveryFormPrices)
  yield takeEvery(ADD_DELIVERY_FORM_PRICE, onAddDeliveryFormPrice)
  yield takeEvery(UPDATE_DELIVERY_FORM_PRICE, onUpdateDeliveryFormPrice)
  yield takeEvery(DELETE_DELIVERY_FORM_PRICE, onDeleteDeliveryFormPrice)
}

export default deliveryFormPriceSaga
