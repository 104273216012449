import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
    <SimpleBar className="h-100" ref={ref}>
      <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Pulpit")} </li>
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bx-home-circle" />
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("CMS")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-detail" />
                  <span>{props.t("CMS")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/cms-pages">
                      {props.t("Strony")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cms-posts">
                      {props.t("Aktualności")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cms-categories">{props.t("Kategorie")}</Link>
                  </li>
                  <li>
                    <Link to="/cms-faq">
                      {props.t("Faq")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cms-faq-categories">
                      {props.t("Faq kategorie")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cms-menu">
                      {props.t("Menu")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cms-sliders">
                      {props.t("Slidery")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cms-galleries">{props.t("Galerie")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-list-ul" />
                  <span>{props.t("Serwis informacyjny")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/infoservice/categories">
                      {props.t("Kategorie")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/infoservice/posts">
                      {props.t("Wpisy")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{props.t("Sklep")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store" />
                  <span>{props.t("Katalog")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/catalog/products">{props.t("Produkty")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalog/categories">{props.t("Kategorie")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalog/attributes">{props.t("Atrybuty")}</Link>
                  </li>
                  <li>
                    <Link to="/catalog/features">{props.t("Dane techniczne")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalog/units">{props.t("Jednostki sprzedażowe")}</Link>
                  </li>
                  <li>
                    <Link to="/catalog/return-packages">{props.t("Opakowania zwrotne")}</Link>
                  </li>
                  <li>
                    <Link to="/catalog/manufacturers">{props.t("Producenci")}</Link>
                  </li>
                  <li>
                    <Link to="/catalog/prices">{props.t("Cennik")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/orders" className="">
                  <i className="bx bx-task" />
                  <span>{props.t("Zamówienia")}</span>
                </Link>
              </li>
              <li>
                <Link to="/payments" className="">
                  <i className="bx bx-wallet" />
                  <span>{props.t("Płatności")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className='bx bx-group'></i>
                  <span>{props.t("Program partnerski")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/partner-program/products">{props.t("Produkty")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/partner-program/categories">{props.t("Kategorie")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/reports" className="">
                  <i className="bx bxs-bar-chart-alt-2" />
                  <span>{props.t("Raporty")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Ogólne")}</li>
              <li>
                <Link to="/branches" className="">
                  <i className="bx bx-briefcase-alt-2" />
                  <span>{props.t("Oddziały")}</span>
                </Link>
              </li>
              <li>
                <Link to="/users-list" className="">
                  <i className="bx bxs-user-detail" />
                  <span>{props.t("Użytkownicy")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className='bx bx-group'></i>
                  <span>{props.t("Ustawienia")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/notifications-sms">{props.t("Powiadomienia SMS")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/delivery-forms">{props.t("Formy transportu")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/logs" className="">
                  <i className="bx bx-clipboard" />
                  <span>{props.t("Logi")}</span>
                </Link>
              </li>
            </ul>
          
      </div>
    </SimpleBar>
  </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
