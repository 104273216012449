import axios from "axios"
import { del, get, post, postWithFile, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const postLogin = data => post(url.POST_LOGIN, data)

// get DeliveryForms
export const getDeliveryForms = () => get(url.DELIVERY_FORMS)
export const addDeliveryForm = deliveryForm =>
  post(url.DELIVERY_FORMS, deliveryForm)
export const updateDeliveryForm = deliveryForm => {
  return put(`${url.DELIVERY_FORMS}/${deliveryForm.id}`, deliveryForm.data)
}
export const deleteDeliveryForm = id => del(`${url.DELIVERY_FORMS}/${id}`)
export const getDeliveryForm = id => get(`${url.DELIVERY_FORMS}/${id}`)
// get delivery form prices
export const getDeliveryFormPrices = deliveryForm =>
  get(`${url.DELIVERY_FORMS}/${deliveryForm}/prices`)
export const addDeliveryFormPrice = data =>
  post(`${url.DELIVERY_FORMS}/${data.deliveryForm}/prices`, data.data)
export const updateDeliveryFormPrice = data =>
  put(
    `${url.DELIVERY_FORMS}/${data.deliveryForm}/prices/${data.price}`,
    data.data
  )
export const deleteDeliveryFormPrice = data =>
  del(`${url.DELIVERY_FORMS}/${data.deliveryForm}/prices/${data.priceId}`)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)
export const getProductsVariants = id =>
  get(`${url.GET_PRODUCTS_VARIANTS}/${id}`)
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })
export const addProduct = product => post(url.ADD_PRODUCT, product)
export const updateProduct = product => post(url.UPDATE_PRODUCT, product)
export const deleteProduct = product =>
  del(url.DELETE_PRODUCT, { params: { product_id: product.product_id } })
export const addVariant = variant => post(url.ADD_VARIANT, variant)
export const updateVariant = variant => post(url.UPDATE_VARIANT, variant)
export const deleteVariant = variant =>
  del(url.DELETE_VARIANT, {
    params: {
      product_variant_id: variant.product_variant_id,
      product_variant_sku: variant.product_variant_sku,
    },
  })
export const deleteImage = data =>
  del(url.DELETE_IMAGE, { params: { product_image_id: data.image_id } })
export const addRelatedProduct = related =>
  post(url.ADD_RELATED_PRODUCT, {
    product_id: related.product_id,
    product_related_id: related.product_related_id,
  })
export const deleteRelatedProduct = related =>
  post(url.DELETE_RELATED_PRODUCT, {
    product_id: related.product_id,
    product_related_id: related.product_connected_id,
  })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)
export const addCategory = category => post(url.ADD_CATEGORY, category)
export const updateCategory = category => post(url.UPDATE_CATEGORY, category)
export const deleteCategory = category =>
  del(url.DELETE_CATEGORY, { params: { category_id: category.category_id } })

// get Prices
export const getPrices = () => get(url.GET_PRICES)
export const updatePrice = singlePrice => post(url.UPDATE_PRICE, singlePrice)

// get Units
export const getUnits = () => get(url.GET_UNITS)
export const addUnit = unit => post(url.ADD_UNIT, unit)
export const updateUnit = unit => put(url.UPDATE_UNIT, unit)
export const deleteUnit = unit =>
  del(url.DELETE_UNIT, { params: { package_id: unit.package_id } })

// get Return Packages
export const getReturnPackages = () => get(url.GET_RETURN_PACKAGES)
export const addReturnPackage = returnPackage =>
  post(url.ADD_RETURN_PACKAGE, returnPackage)
export const updateReturnPackage = returnPackage =>
  put(url.UPDATE_RETURN_PACKAGE, returnPackage)
export const deleteReturnPackage = returnPackage =>
  del(url.DELETE_RETURN_PACKAGE, {
    params: { return_package_id: returnPackage.return_package_id },
  })

// catalog Fetures
export const getFeatures = () => get(url.GET_FEATURES)
export const addFeature = feature => post(url.ADD_FEATURE, feature)
export const updateFeature = feature => put(url.UPDATE_FEATURE, feature)
export const deleteFeature = feature =>
  del(url.DELETE_FEATURE, { params: { feature_id: feature.feature_id } })

// get Attributes
export const getAttributes = () => get(url.GET_ATTRIBUTES)
export const addAttribute = attribute => post(url.ADD_ATTRIBUTE, attribute)
export const updateAttribute = attribute =>
  post(url.UPDATE_ATTRIBUTE, attribute)
export const deleteAttribute = attribute =>
  del(url.DELETE_ATTRIBUTE, {
    params: { attribute_id: attribute.attribute_id },
  })

// InfoService
export const getPosts = () => get(url.GET_POSTS)
export const addPost = infoServicePost =>
  postWithFile(url.ADD_POST, infoServicePost)
export const updatePost = infoServicePost =>
  postWithFile(url.UPDATE_POST, infoServicePost)
export const deletePost = infoServicePost =>
  del(url.DELETE_POST, { params: { post_id: infoServicePost.post_id } })
export const getISCategories = () => get(url.GET_IS_CATEGORIES)
export const addISCategory = category => post(url.ADD_IS_CATEGORY, category)
export const updateISCategory = category =>
  post(url.UPDATE_IS_CATEGORY, category)
export const deleteISCategory = category =>
  del(url.DELETE_IS_CATEGORY, { params: { category_id: category.category_id } })

// CMS
export const getCmsPosts = () => get(url.GET_CMS_POSTS)
export const addCmsPost = post => post(url.ADD_CMS_POST, post)
export const updateCmsPost = post => post(url.UPDATE_CMS_POST, post)
export const deleteCmsPost = post =>
  del(url.DELETE_CMS_POST, { params: { id: post.id } })
export const getCmsCategories = () => get(url.GET_CMS_CATEGORIES)
export const addCmsCategory = category => post(url.ADD_CMS_CATEGORY, category)
export const updateCmsCategory = category =>
  post(url.UPDATE_CMS_CATEGORY, category)
export const deleteCmsCategory = category =>
  del(url.DELETE_CMS_CATEGORY, { params: { id: category.id } })

export const getCmsPages = () => get(url.GET_CMS_PAGES)
export const addCmsPage = page => post(url.ADD_CMS_PAGE, page)
export const updateCmsPage = page => post(url.UPDATE_CMS_PAGE, page)
export const deleteCmsPage = page =>
  del(url.DELETE_CMS_PAGE, { params: { id: page.id } })

export const getCmsMenu = () => get(url.GET_CMS_MENU)
export const addCmsMenu = menu => post(url.ADD_CMS_MENU, menu)
export const updateCmsMenu = menu => post(url.UPDATE_CMS_MENU, menu)
export const deleteCmsMenu = menu =>
  del(url.DELETE_CMS_MENU, { params: { id: menu.id } })

export const getCmsGalleries = () => get(url.GET_CMS_GALLERIES)
export const addCmsGallery = gallery => post(url.ADD_CMS_GALLERY, gallery)
export const updateCmsGallery = gallery => post(url.UPDATE_CMS_GALLERY, gallery)
export const deleteCmsGallery = gallery =>
  del(url.DELETE_CMS_GALLERY, { params: { id: gallery.id } })
export const getCmsGalleryItems = () => get(url.GET_CMS_GALLERY_ITEMS)
export const addCmsGalleryItem = item => post(url.ADD_CMS_GALLERY_ITEM, item)
export const updateCmsGalleryItem = item =>
  post(url.UPDATE_CMS_GALLERY_ITEM, item)
export const deleteCmsGalleryItem = item =>
  del(url.DELETE_CMS_GALLERY_ITEM, { params: { id: item.id } })

export const getCmsSliders = () => get(url.GET_CMS_SLIDERS)
export const addCmsSlider = slider => post(url.ADD_CMS_SLIDER, slider)
export const updateCmsSlider = slider => post(url.UPDATE_CMS_SLIDER, slider)
export const deleteCmsSlider = slider =>
  del(url.DELETE_CMS_SLIDER, { params: { id: slider.id } })
export const getCmsSliderItems = () => get(url.GET_CMS_SLIDER_ITEMS)
export const addCmsSliderItem = item => post(url.ADD_CMS_SLIDER_ITEM, item)
export const updateCmsSliderItem = item =>
  post(url.UPDATE_CMS_SLIDER_ITEM, item)
export const deleteCmsSliderItem = item =>
  del(url.DELETE_CMS_SLIDER_ITEM, { params: { id: item.id } })

export const getCmsFaqs = () => get(url.GET_CMS_FAQS)
export const addCmsFaq = faq => post(url.ADD_CMS_FAQ, faq)
export const updateCmsFaq = faq => post(url.UPDATE_CMS_FAQ, faq)
export const deleteCmsFaq = faq =>
  del(url.DELETE_CMS_FAQ, { params: { id: faq.id } })
export const getCmsFaqCategories = () => get(url.GET_CMS_FAQ_CATEGORIES)
export const addCmsFaqCategory = category =>
  post(url.ADD_CMS_FAQ_CATEGORY, category)
export const updateCmsFaqCategory = category =>
  post(url.UPDATE_CMS_FAQ_CATEGORY, category)
export const deleteCmsFaqCategory = category =>
  del(url.DELETE_CMS_FAQ_CATEGORY, { params: { id: category.id } })

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get payments
export const getPayments = () => get(url.GET_PAYMENTS)

// get reports
export const getProvidersSallaryReport = date =>
  get(`${url.GET_REPORTS}/${date.month}/${date.year}`)
export const getProviderSallaryReport = date =>
  get(`${url.GET_REPORT}/${date.branch_id}/${date.month}/${date.year}`)

// partner program products
export const getPPProducts = () => get(url.GET_PP_PRODUCTS)
export const addPPProduct = product => post(url.ADD_PP_PRODUCT, product)
export const updatePPProduct = product => post(url.UPDATE_PP_PRODUCT, product)
export const deletePPProduct = product =>
  del(url.DELETE_PP_PRODUCT, {
    params: { pp_product_id: product.pp_product_id },
  })
export const getPPCategories = () => get(url.GET_PP_CATEGORIES)
export const addPPCategory = category => post(url.ADD_PP_CATEGORY, category)
export const updatePPCategory = category =>
  post(url.UPDATE_PP_CATEGORY, category)
export const deletePPCategory = category =>
  del(url.DELETE_PP_CATEGORY, {
    params: { pp_category_id: category.pp_category_id },
  })

// order
export const getOrders = () => get(url.GET_ORDERS)
export const addOrder = order => post(url.ADD_ORDER, order)
export const updateOrder = order => put(url.UPDATE_ORDER, order)
export const deleteOrder = order =>
  del(url.DELETE_ORDER, {
    params: { order_id: order.order_id, order_nr: order.order_nr },
  })

// logs
export const getLogs = () => get(url.GET_LOGS)

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get branches
export const getBranches = () => get(url.GET_BRANCHES)
export const getBranchProfile = () => get(url.GET_BRANCH_PROFILE)
export const addBranch = branch => post(url.ADD_BRANCH, branch)
export const updateBranch = branch => post(url.UPDATE_BRANCH, branch)
export const deleteBranch = branch =>
  del(url.DELETE_BRANCH, { params: { branch_id: branch.branch_id } })

// get users
export const getUsers = () => get(url.GET_USERS)
export const getUsersInActive = () => get(url.GET_USERS_INACTIVE)
export const addNewUser = user => post(url.ADD_NEW_USER, user)
export const updateUser = user => post(url.UPDATE_USER, user)
export const deleteUser = user =>
  del(url.DELETE_USER, { params: { id: user.id } })
export const getManufacturers = () => get(url.GET_MANUFACTURERS)

// sms notifications
export const getSMSNotifications = () => get(url.GET_SMS_NOTIFICATIONS)
export const updateSMSNotifications = notifications =>
  post(url.UPDATE_SMS_NOTIFICATIONS, notifications)

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
