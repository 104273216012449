import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  UncontrolledTooltip,
  Input,
  Form,
  FormFeedback,
  Spinner
} from "reactstrap";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import Details from "./details";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getReports as onGetReports,
} from "store/reports/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Reports = props => {
  //meta title
  document.title = "Raporty - Panel administracyjny";
  
  const dispatch = useDispatch();

  const [provider, setProvider] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      year: '',
      month: '',
    },
    validationSchema: Yup.object({
      year: Yup.string().required("To pole jest wymagane"),
      month: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      const getReports = {
        year: values.year,
        month: values.month,
      };

      dispatch(onGetReports(getReports));
    },
  });

  const { reports } = useSelector(state => ({
    reports: state.reports.reports,
  }));

  const { SearchBar } = Search;

  const columns = [
    {
      text: "ID",
      dataField: "branch_id",
      sort: true,
    },
    {
      text: "Nazwa",
      dataField: "branch_name",
      sort: true,
    },
    {
      text: "Ilość zamówień",
      dataField: "sum_orders",
      sort: true,
    },
    {
      text: "Suma sprzedaży",
      dataField: "sum_price",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>{cellContent.toFixed(2)} zł</React.Fragment>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link
            to="#"
            className="btn btn-success save-user"
            onClick={() => handleReportClick(row)}
          >
            <i className="mdi mdi-information-outline font-size-18" id="showdetails" />
            <UncontrolledTooltip placement="top" target="showdetails">
              Szczegóły
            </UncontrolledTooltip>
          </Link>
        </div>
      ),
    },
  ];

  const handleReportClick = arg => {
    const provider = arg;

    setProvider({
      branch_id: provider.branch_id,
      branch_name: provider.branch_name,
      sum_orders: provider.sum_orders,
      sum_price: provider.sum_price,
    });
  };

  const defaultSorted = [
    {
      dataField: "branch_id",
      order: "asc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs breadcrumbItem="Raporty" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row className="align-items-center">
                        <Col className="col-lg-5">
                          <div className="mb-3">
                            <Label className="form-label">Wybierz rok</Label>
                            <Input
                              name="year"
                              type="select"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.year}
                              invalid={
                                validation.touched.year && validation.errors.year ? true : false
                              }
                            >
                              <option value="">-- Wybierz rok --</option>
                              <option value="2023">2023</option>
                              <option value="2022">2022</option>
                              <option value="2021">2021</option>
                              <option value="2020">2020</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                            </Input>
                            {validation.touched.year && validation.errors.year ? (
                              <FormFeedback type="invalid">{validation.errors.year}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-lg-5">
                          <div className="mb-3">
                            <Label className="form-label">Wybierz miesiąc</Label>
                            <Input
                              name="month"
                              type="select"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.month}
                              invalid={
                                validation.touched.month && validation.errors.month ? true : false
                              }
                            >
                              <option value="">-- Wybierz miesiąc --</option>
                              <option value="1">styczeń</option>
                              <option value="2">luty</option>
                              <option value="3">marzec</option>
                              <option value="4">kwiecień</option>
                              <option value="5">maj</option>
                              <option value="6">czerwiec</option>
                              <option value="7">lipiec</option>
                              <option value="8">sierpień</option>
                              <option value="9">wrzesień</option>
                              <option value="10">październik</option>
                              <option value="11">listopad</option>
                              <option value="12">grudzień</option>
                            </Input>
                            {validation.touched.month && validation.errors.month ? (
                              <FormFeedback type="invalid">{validation.errors.month}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-lg-2">
                          <div className="mb-3">
                            <Label className="form-label"></Label>
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Generuj
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    {reports.length > 0 ? 
                      <React.Fragment>
                        <ToolkitProvider
                          keyField="branch_id"
                          data={reports}
                          columns={columns}
                          bootstrap4
                          search
                        >
                          {props => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...props.searchProps}
                                        placeholder="Szukaj..."
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    bordered={false}
                                    defaultSorted={defaultSorted}
                                    pagination={paginationFactory()}
                                    classes={"align-middle"}
                                    headerWrapperClasses={"table-light"}
                                    noDataIndication={() => <NoDataIndication />}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                        <Details 
                          provider={provider}
                          validation={validation}
                        />
                      </React.Fragment>
                    : null }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(Reports);
