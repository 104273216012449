import {
  GET_PAYMENTS, 
  GET_PAYMENTS_SUCCESS, 
  GET_PAYMENTS_FAIL,
} from "./actionTypes";

// Payments

export const getPayments = () => ({
  type: GET_PAYMENTS,
});

export const getPaymentsSuccess = payments => ({
  type: GET_PAYMENTS_SUCCESS,
  payload: payments,
});

export const getPaymentsFail = error => ({
  type: GET_PAYMENTS_FAIL,
  payload: error,
});