import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"

import { Link } from "react-router-dom"

export const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
]

export const setColumns = (
  handleUserClick = () => {},
  onClickDelete = () => {}
) => [
  //   {
  //     text: "index",
  //     dataField: "index",
  //     sort: true,
  //     formatter: (cell, row, rowIndex) => rowIndex + 1,
  //   },
  {
    text: "ID",
    dataField: "id",
    sort: true,
  },
  {
    text: "Cena",
    dataField: "price",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryFormPrices) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {deliveryFormPrices.price}
          </Link>
        </h5>
      </>
    ),
  },
  {
    text: "Waga od:",
    dataField: "weight_from",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryFormPrices) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to="#_" className="text-dark">
            {deliveryFormPrices.weight_from}
          </Link>
        </h5>
      </>
    ),
  },
  {
    text: "Waga do:",
    dataField: "weight_to",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryFormPrices) => (
      <>{deliveryFormPrices.weight_to}</>
    ),
  },
  {
    dataField: "created_at",
    text: "Data utworzenia",
    sort: true,
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Opcje",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryFormPrices) => (
      <div className="d-flex gap-3">
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => {
              handleUserClick(prev => deliveryFormPrices)
            }}
          ></i>
        </Link>
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => {
              onClickDelete(deliveryFormPrices.id)
            }}
          ></i>
        </Link>
      </div>
    ),
  },
]

export const getValidation = (user, addAction, editAction) =>
  useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      price: user?.price || 0,
      weight_from: user?.weight_from || null,
      weight_to: user?.weight_to || null,
    },
    validationSchema: Yup.object({
      price: Yup.string().required("Cena jest wymagana."),
    }),
    onSubmit: (values, { resetForm }) => {
      const fd = {
        price: values.price,
        weight_from: values.weight_from,
        weight_to: values.weight_to,
      }
      user ? editAction(fd) : addAction(fd)
      resetForm()
    },
  })
