import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Alert,
  Button,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/users/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

const UsersList = props => {
  //meta title
  document.title = "Użytkownicy - Panel administracyjny"

  const dispatch = useDispatch()
  const [user, setUser] = useState()

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (user && user.id) || "",
      name: (user && user.name) || "",
      last_name: (user && user.last_name) || "",
      email: (user && user.email) || "",
      phone: (user && user.phone) || "",
      password: (user && user.password) || "",
      company_name: (user && user.company_name) || "",
      company_nip: (user && user.company_nip) || "",
      company_street: (user && user.company_street) || "",
      company_post: (user && user.company_post) || "",
      company_city: (user && user.company_city) || "",
      role: (user && user.role) || "",
      partner_points: (user && user.partner_points) || "",
      active: (user && user.active) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Imię jest wymagane."),
      // last_name: Yup.string().required("Nazwisko jest wymagane."),
      role: Yup.string().required("Typ konta jest wymagany."),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: user.id,
          name: values.name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
          password: values.password,
          company_name: values.company_name,
          company_nip: values.company_nip,
          company_street: values.company_street,
          company_post: values.company_post,
          company_city: values.company_city,
          role: values.role,
          partner_points: values.partner_points,
          active: values.active,
        }
        // update user
        dispatch(onUpdateUser(updateUser))
        setIsEdit(false)
        validation.resetForm()
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          last_name: values["last_name"],
          role: values["role"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          password: values["password"],
          tags: values["tags"],
          projects: values["projects"],
          active: values["active"],
        }
        // save new user
        dispatch(onAddNewUser(newUser))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { users } = useSelector(state => ({
    users: state.users.dataUsers.users,
  }))

  const { success } = useSelector(state => ({
    success: state.users.dataUsers.success,
  }))

  const { error } = useSelector(state => ({
    error: state.users.dataUsers.error,
  }))

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const columns = [
    {
      text: "ID",
      dataField: "id",
      sort: true,
    },
    {
      text: "Imię i nazwisko",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.name} {user.last_name}
            </Link>
          </h5>
        </>
      ),
    },
    {
      text: "E-mail",
      dataField: "email",
      sort: true,
    },
    {
      text: "Telefon",
      dataField: "phone",
      sort: true,
    },
    {
      dataField: "role",
      text: "Typ",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          {cellContent === "admin" ? "Administrator" : ""}
          {cellContent === "client" ? "Klient" : ""}
          {cellContent === "employee" ? "Pracownik" : ""}
          {cellContent === "manufacturer" ? "Producent" : ""}
          {cellContent === "contractor" ? "Wykonawca" : ""}
        </>
      ),
    },
    {
      dataField: "created_at",
      text: "Data utworzenia",
      sort: true,
    },
    {
      dataField: "active",
      text: "Aktywny",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) =>
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Opcje",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(user)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(user)}
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers())
      setIsEdit(false)
    }
  }, [dispatch, users])

  useEffect(() => {
    setUser(users)
    setIsEdit(false)
  }, [users])

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setUser(users)
      setIsEdit(false)
    }
  }, [users])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_USER_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_USER_FAIL",
        })
      }, 5000)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg

    setUser({
      id: user.id,
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      company_name: user.company_name,
      company_nip: user.company_nip,
      company_street: user.company_street,
      company_post: user.company_post,
      company_city: user.company_city,
      role: user.role,
      partner_points: user.partner_points,
      active: user.active,
    })
    setIsEdit(true)

    toggle()
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = users => {
    setUser(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (user.id) {
      dispatch(onDeleteUser(user))
      setDeleteModal(false)
    }
  }

  const handleUserClicks = () => {
    setUser("")
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Użytkownicy" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {(success || error) && (
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>{success.text}</Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  <ToolkitProvider
                    keyField="id"
                    data={users}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {props => (
                      <React.Fragment>
                        <Row>
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder="Szukaj..."
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={handleUserClicks}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Dodaj użytkownika
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              defaultSorted={defaultSorted}
                              pagination={paginationFactory()}
                              classes={"align-middle"}
                              headerWrapperClasses={"table-light"}
                              noDataIndication={() => <NoDataIndication />}
                            />
                            <Modal isOpen={modal} toggle={toggle} size="lg">
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit
                                  ? "Edytuj użytkownika"
                                  : "Dodaj użytkownika"}
                              </ModalHeader>
                              <ModalBody>
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                  }}
                                >
                                  <Row>
                                    <Col xs={12}>
                                      <Nav
                                        tabs
                                        className="nav-tabs-custom nav-justified"
                                      >
                                        <NavItem>
                                          <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                              active: customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                              toggleCustom("1")
                                            }}
                                          >
                                            <span className="d-block d-sm-none">
                                              <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block">
                                              Dane klienta
                                            </span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                              active: customActiveTab === "2",
                                            })}
                                            onClick={() => {
                                              toggleCustom("2")
                                            }}
                                          >
                                            <span className="d-block d-sm-none">
                                              <i className="far fa-user"></i>
                                            </span>
                                            <span className="d-none d-sm-block">
                                              Faktura VAT
                                            </span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                              active: customActiveTab === "3",
                                            })}
                                            onClick={() => {
                                              toggleCustom("3")
                                            }}
                                          >
                                            <span className="d-block d-sm-none">
                                              <i className="far fa-user"></i>
                                            </span>
                                            <span className="d-none d-sm-block">
                                              Program partnerski
                                            </span>
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                      <TabContent
                                        activeTab={customActiveTab}
                                        className="p-3 text-muted"
                                      >
                                        <TabPane tabId="1">
                                          <Row>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Imię
                                              </Label>
                                              <Input
                                                name="name"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.name || ""
                                                }
                                                invalid={
                                                  validation.touched.name &&
                                                  validation.errors.name
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.touched.name &&
                                              validation.errors.name ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.name}
                                                </FormFeedback>
                                              ) : null}
                                            </Col>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Nazwisko
                                              </Label>
                                              <Input
                                                name="last_name"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.last_name ||
                                                  ""
                                                }
                                                // invalid={
                                                //   validation.touched.last_name &&
                                                //   validation.errors.last_name
                                                //     ? true
                                                //     : false
                                                // }
                                              />
                                              {/* {validation.touched.last_name &&
                                              validation.errors.last_name ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.last_name}
                                                </FormFeedback>
                                              ) : null} */}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Email
                                              </Label>
                                              <Input
                                                name="email"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.email || ""
                                                }
                                              />
                                            </Col>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Telefon
                                              </Label>
                                              <Input
                                                name="phone"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.phone || ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="12 mb-3">
                                              <Label className="form-label">
                                                Hasło
                                              </Label>
                                              <Input
                                                name="password"
                                                type="password"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.password ||
                                                  ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="12 mb-3">
                                              <Label className="form-label">
                                                Typ konta
                                              </Label>
                                              <Input
                                                type="select"
                                                name="role"
                                                className="form-select"
                                                multiple={false}
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.role || []
                                                }
                                                invalid={
                                                  validation.touched.role &&
                                                  validation.errors.role
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value="client">
                                                  Klient
                                                </option>
                                                <option value="contractor">
                                                  Wykonawca
                                                </option>
                                                <option value="employee">
                                                  Pracownik
                                                </option>
                                                <option value="admin">
                                                  Admin
                                                </option>
                                                <option value="manufacturer">
                                                  Producent
                                                </option>
                                              </Input>
                                              {validation.touched.role &&
                                              validation.errors.role ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.role}
                                                </FormFeedback>
                                              ) : null}
                                            </Col>
                                            <Col lg="12 mb-3">
                                              <Label className="form-label">
                                                Aktywny
                                              </Label>
                                              <Input
                                                type="select"
                                                name="active"
                                                className="form-select"
                                                multiple={false}
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.active || 0
                                                }
                                              >
                                                <option value="1">Tak</option>
                                                <option value="0">Nie</option>
                                              </Input>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                          <Row>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Nazwa firmy
                                              </Label>
                                              <Input
                                                name="company_name"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .company_name || ""
                                                }
                                              />
                                            </Col>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                NIP
                                              </Label>
                                              <Input
                                                name="company_nip"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .company_nip || ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="12 mb-3">
                                              <Label className="form-label">
                                                Adres
                                              </Label>
                                              <Input
                                                name="company_street"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .company_street || ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Kod pocztowy
                                              </Label>
                                              <Input
                                                name="company_post"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .company_post || ""
                                                }
                                              />
                                            </Col>
                                            <Col lg="6 mb-3">
                                              <Label className="form-label">
                                                Miasto
                                              </Label>
                                              <Input
                                                name="company_city"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .company_city || ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                          <Col lg="12 mb-3">
                                            <Label className="form-label">
                                              Ilość punktów
                                            </Label>
                                            <Input
                                              name="partner_points"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .partner_points || ""
                                              }
                                            />
                                          </Col>
                                        </TabPane>
                                      </TabContent>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Zapisz
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UsersList)
