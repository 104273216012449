import {
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  payments: [],
}

const payments = (state = INIT_STATE, action) => {
  switch (action.type) {

    //Payments

    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      }

    case GET_PAYMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default payments;