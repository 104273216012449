/* PRODUCTS */
export const GET_PP_PRODUCTS = "GET_PP_PRODUCTS"
export const GET_PP_PRODUCTS_SUCCESS = "GET_PP_PRODUCTS_SUCCESS"
export const GET_PP_PRODUCTS_FAIL = "GET_PP_PRODUCTS_FAIL"
export const ADD_PP_PRODUCT = "ADD_PP_PRODUCT"
export const ADD_PP_PRODUCT_SUCCESS = "ADD_PP_PRODUCT_SUCCESS"
export const ADD_PP_PRODUCT_FAIL = "ADD_PP_PRODUCT_FAIL"
export const UPDATE_PP_PRODUCT = "UPDATE_PP_PRODUCT"
export const UPDATE_PP_PRODUCT_SUCCESS = "UPDATE_PP_PRODUCT_SUCCESS"
export const UPDATE_PP_PRODUCT_FAIL = "UPDATE_PP_PRODUCT_FAIL"
export const DELETE_PP_PRODUCT = "DELETE_PP_PRODUCT"
export const DELETE_PP_PRODUCT_SUCCESS = "DELETE_PP_PRODUCT_SUCCESS"
export const DELETE_PP_PRODUCT_FAIL = "DELETE_PP_PRODUCT_FAIL"
export const HIDE_PP_PRODUCT_SUCCESS = "HIDE_PP_PRODUCT_SUCCESS"
export const HIDE_PP_PRODUCT_FAIL = "HIDE_PP_PRODUCT_FAIL"

/* CATEGORIES */
export const GET_PP_CATEGORIES = "GET_PP_CATEGORIES"
export const GET_PP_CATEGORIES_SUCCESS = "GET_PP_CATEGORIES_SUCCESS"
export const GET_PP_CATEGORIES_FAIL = "GET_PP_CATEGORIES_FAIL"
export const ADD_PP_CATEGORY = "ADD_PP_CATEGORY"
export const ADD_PP_CATEGORY_SUCCESS = "ADD_PP_CATEGORY_SUCCESS"
export const ADD_PP_CATEGORY_FAIL = "ADD_PP_CATEGORY_FAIL"
export const UPDATE_PP_CATEGORY = "UPDATE_PP_CATEGORY"
export const UPDATE_PP_CATEGORY_SUCCESS = "UPDATE_PP_CATEGORY_SUCCESS"
export const UPDATE_PP_CATEGORY_FAIL = "UPDATE_PP_CATEGORY_FAIL"
export const DELETE_PP_CATEGORY = "DELETE_PP_CATEGORY"
export const DELETE_PP_CATEGORY_SUCCESS = "DELETE_PP_CATEGORY_SUCCESS"
export const DELETE_PP_CATEGORY_FAIL = "DELETE_PP_CATEGORY_FAIL"
export const HIDE_PP_CATEGORY_SUCCESS = "HIDE_PP_CATEGORY_SUCCESS"
export const HIDE_PP_CATEGORY_FAIL = "HIDE_PP_CATEGORY_FAIL"