import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_INACTIVE_SUCCESS,
  GET_USERS_INACTIVE_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  HIDE_USER_SUCCESS,
  HIDE_USER_FAIL,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_FAIL,
  ADD_MANUFACTURER_SUCCESS,
  ADD_MANUFACTURER_FAIL,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_FAIL,
  DELETE_MANUFACTURER_SUCCESS,
  DELETE_MANUFACTURER_FAIL,
  HIDE_MANUFACTURER_SUCCESS,
  HIDE_MANUFACTURER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  dataUsers: {
    users: [],
    usersInActive: null,
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  dataManufacturers: {
    manufacturers: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  userProfile: {},
  dataUsersInActive: null
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          users: action.payload,
        },
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          // error: action.payload,
        }
      }

    case GET_USERS_INACTIVE_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          usersInActive: action.payload,
        },
      }

    case GET_USERS_INACTIVE_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          // error: action.payload,
        }
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          users: [...state.dataUsers.users, action.payload],
          success: {
            open: true,
            text: "Użytkownik został dodany."
          }
        }
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          error: {
            open: true,
            text: Object.values(action.payload.response.data.message).join('; ') ?? "Podczas dodawania użytkownika wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          users: state.dataUsers.users.map(user =>
            user.id.toString() === action.payload.id.toString()
              ? { user, ...action.payload }
              : user
          ),
          success: {
            open: true,
            text: "Użytkownik został zaktualizowany."
          } 
        }
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          error: {
            open: true,
            text: "Podczas aktualizacji użytkownika wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          users: state.dataUsers.users.filter(
            user => user.id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Użytkownik został usunięty."
          } 
        }
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          error: {
            open: true,
            text: "Podczas usuwania użytkownika wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case HIDE_USER_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          success: {
            ...state.dataUsers.success,
            open: false
          }
        },
      }

    case HIDE_USER_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          error: {
            ...state.dataUsers.error,
            open: false
          }
        },
      }

    //Manufacturers

    case GET_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          manufacturers: action.payload,
        },
      }

    case GET_MANUFACTURERS_FAIL:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          // error: action.payload,
        }
      }

    case ADD_MANUFACTURER_SUCCESS:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          manufacturers: [...state.dataManufacturers.manufacturers, action.payload],
          success: {
            open: true,
            text: "Producent został dodany."
          }
        }
      }

    case ADD_MANUFACTURER_FAIL:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          error: {
            open: true,
            text: "Podczas dodawania producenta wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          manufacturers: state.dataManufacturers.manufacturers.map(manufacturer =>
            manufacturer.id.toString() === action.payload.id.toString()
              ? { manufacturer, ...action.payload }
              : manufacturer
          ),
          success: {
            open: true,
            text: "Producent został zaktualizowany."
          } 
        }
      }

    case UPDATE_MANUFACTURER_FAIL:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          error: {
            open: true,
            text: "Podczas aktualizacji producenta wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case DELETE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          manufacturers: state.dataManufacturers.manufacturers.filter(
            manufacturers =>
              manufacturers.id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Producent został usunięty."
          } 
        }
      }

    case DELETE_MANUFACTURER_FAIL:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          error: {
            open: true,
            text: "Podczas usuwania producenta wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          success: {
            ...state.dataManufacturers.success,
            open: false
          }
        },
      }

    case HIDE_MANUFACTURER_FAIL:
      return {
        ...state,
        dataManufacturers: {
          ...state.dataManufacturers,
          error: {
            ...state.dataManufacturers.error,
            open: false
          }
        },
      }

    default:
      return state
  }
}

export default users
