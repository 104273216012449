import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"

import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

export const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
]

export const setColumns = (
  handleUserClick = () => {},
  onClickDelete = () => {}
) => [
  {
    text: "ID",
    dataField: "id",
    sort: true,
  },
  {
    text: "Nazwa",
    dataField: "name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={`/delivery-forms/${deliveryForm.id}`} className="text-dark">
            {deliveryForm.name}
          </Link>
        </h5>
      </>
    ),
  },
  {
    text: "Nazwa wyświetlana",
    dataField: "display_name",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={`/delivery-forms/${deliveryForm.id}`} className="text-dark">
            {deliveryForm.display_name}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "created_at",
    text: "Data utworzenia",
    sort: true,
  },
  {
    dataField: "active",
    text: "Aktywny",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) =>
      deliveryForm.active == 1 ? (
        <Badge color="success" className="bg-success font-size-12">
          Tak
        </Badge>
      ) : (
        <Badge color="danger" className="bg-danger font-size-12">
          Nie
        </Badge>
      ),
  },
  {
    text: "Maksymalna waga",
    dataField: "max_weight",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => <>{deliveryForm.max_weight}</>,
  },
  {
    text: "Maksymalna objętość",
    dataField: "max_volume",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => <>{deliveryForm.max_volume}</>,
  },
  {
    text: "Minimalna wartość zakupów",
    dataField: "min_shipping_value",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => (
      <>{deliveryForm.min_shipping_value}</>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "Opcje",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => (
      <div className="d-flex gap-3">
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => {
              handleUserClick(prev => deliveryForm)
            }}
          ></i>
        </Link>
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => {
              onClickDelete(deliveryForm.id)
            }}
          ></i>
        </Link>
      </div>
    ),
  },
  {
    dataField: "link",
    text: "Link do cen",
    isDummyField: true,
    editable: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, deliveryForm) => (
      <>
        <Link
          to={`/delivery-forms/${deliveryForm.id}`}
          className="text-dark font-size-14"
        >
          Zobacz ceny
        </Link>
      </>
    ),
  },
]

export const getValidation = (user, addAction, editAction) =>
  useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: user?.name || "",
      display_name: user?.display_name || "",
      max_weight: user?.max_weight || 1,
      max_volume: user?.max_volume || 1,
      min_shipping_value: user?.min_shipping_value || null,
      active: user?.active || 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nazwa jest wymagana."),
      display_name: Yup.string().required("Wyświetlana nazwa jest wymagana."),
      max_weight: Yup.number()
        .required("Maksymalna waga jest wymagana.")
        .min(1, "Maksymalna waga nie może być mniejsza niż 1."),
      max_volume: Yup.number()
        .required("Maksymalna objętość jest wymagana.")
        .min(0.01, "Maksymalna objętość nie może być mniejsza niż 0.01."),
    }),
    onSubmit: (values, { resetForm }) => {
      const fd = {
        name: values.name,
        display_name: values.display_name,
        max_weight: values.max_weight,
        max_volume: values.max_volume,
        min_shipping_value: values.min_shipping_value,
        active: values.active,
      }
      user ? editAction(fd) : addAction(fd)
      resetForm()
    },
  })
