import {
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
} from "./actionTypes";

// Reports

export const getReports = (date) => ({
  type: GET_REPORTS,
  payload: date,
});

export const getReportsSuccess = reports => ({
  type: GET_REPORTS_SUCCESS,
  payload: reports,
});

export const getReportsFail = error => ({
  type: GET_REPORTS_FAIL,
  payload: error,
});

export const getReport = (date) => ({
  type: GET_REPORT,
  payload: date,
});

export const getReportSuccess = reports => ({
  type: GET_REPORT_SUCCESS,
  payload: reports,
});

export const getReportFail = error => ({
  type: GET_REPORT_FAIL,
  payload: error,
});