import axios from "axios"

let token = null
const obj = JSON.parse(localStorage.getItem("authUser"))
if (obj) token = "Bearer " + obj.token

export const API_URL =
  window.location.protocol +
  "//api." +
  (window.location.port === "8000"
    ? window.location.hostname + ":8001"
    : window.location.host.split(".")[1] + ".pl") +
  "/api"
// export const API_URL = "http://api.localhost:8000/api"
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data"

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postWithFile(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
