import {
  GET_LOGS_SUCCESS,
  GET_LOGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  logs: []
}

const logs = (state = INIT_STATE, action) => {
  switch (action.type) {

    // Logs

    case GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.payload,
      }

    case GET_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default logs;