import {
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  HIDE_ORDER_SUCCESS,
  HIDE_ORDER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  orders: [],
  success: {
    open: false,
    text: ""
  },
  error: {
    open: false,
    text: ""
  }
}

const orders = (state = INIT_STATE, action) => {
  switch (action.type) {

    //Orders

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        // error: action.payload,
      }

    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        orders: [...state.orders, action.payload],
        success: {
          open: true,
          text: "Zamówienie zostało dodane."
        }
      }

    case ADD_ORDER_FAIL:
      return {
        ...state,
        error: {
          open: true,
          text: "Podczas dodawania zamówienia wystąpił błąd, spróbuj ponownie."
        }
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(order =>
          order.id.toString() === action.payload.id.toString()
            ? { order, ...action.payload }
            : order
        ),
        success: {
          open: true,
          text: "Zamówienie zostało zaktualizowane."
        }
      }

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        error: {
          open: true,
          text: "Podczas aktualizacji zamówienia wystąpił błąd, spróbuj ponownie.",
        }
      }

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.filter(
          order => order.order_id.toString() !== action.payload.toString()
        ),
        success: {
          open: true,
          text: "Zamówienie zostało usunięte."
        }
      }

    case DELETE_ORDER_FAIL:
      return {
        ...state,
        error: {
          open: true,
          text: "Podczas usuwania zamówienia wystąpił błąd, spróbuj ponownie.",
        }
      }

    case HIDE_ORDER_SUCCESS:
      return {
        ...state,
        success: {
          ...state.success,
          open: false
        }
      }

    case HIDE_ORDER_FAIL:
      return {
        ...state,
        error: {
          ...state.error,
          open: false
        }
      }

    default:
      return state
  }
}

export default orders