import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import TableContainer from "../../../../components/Common/TableContainer"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import classnames from "classnames"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import { useFormik, FormikProvider } from "formik"

import {
  getProductsVariants as onGetProductsVariants,
  addVariant as onAddVariant,
  updateVariant as onUpdateVariant,
  deleteVariant as onDeleteVariant,
  deleteCreatedVariant as onDeleteCreatedVariant,
} from "store/catalog/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

const Variants = props => {
  const dispatch = useDispatch()
  const { product, createdVariants, returnPackages } = props

  const [variant, setVariant] = useState(null)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      product_id: (variant && variant.product_id) || "",
      product_variant_id: (variant && variant.product_variant_id) || 0,
      product_variant_name: (variant && variant.product_variant_name) || "",
      product_variant_sku: (variant && variant.product_variant_sku) || "",
      product_variant_ean: (variant && variant.product_variant_ean) || 0,
      product_variant_attr1: (variant && variant.product_variant_attr1) || 0,
      product_variant_attr2: (variant && variant.product_variant_attr2) || 0,
      product_variant_attr3: (variant && variant.product_variant_attr3) || 0,
      product_variant_wapro_index:
        (variant && variant.product_variant_wapro_index) || 0,
      product_variant_wapro_nr:
        (variant && variant.product_variant_wapro_nr) || 0,
      product_variant_short_description:
        (variant && variant.product_variant_short_description) || "",
      product_variant_description:
        (variant && variant.product_variant_description) || "",
      product_variant_delivery_description:
        variant?.product_variant_delivery_description || "",
      product_variant_active: (variant && variant.product_variant_active) || 0,
      product_variant_meta_title:
        (variant && variant.product_variant_meta_title) || "",
      product_variant_meta_keywords:
        (variant && variant.product_variant_meta_keywords) || "",
      product_variant_meta_description:
        (variant && variant.product_variant_meta_description) || "",
      product_variant_price_id:
        (variant && variant.product_variant_price_id) || "",
      product_variant_price: (variant && variant.product_variant_price) || 0,
      product_variant_promo_price:
        (variant && variant.product_variant_promo_price) || 0,
      product_variant_promo_on:
        (variant && variant.product_variant_promo_on) || 0,
      product_variant_promo_home_on:
        (variant && variant.product_variant_promo_home_on) || false,
      product_variant_promo_price_on:
        (variant && variant.product_variant_promo_price_on) || 0,
      product_variant_promo_price_date_from:
        (variant && variant.product_variant_promo_price_date_from) || "",
      product_variant_promo_price_date_to:
        (variant && variant.product_variant_promo_price_date_to) || "",
      product_variant_promo_quantity:
        (variant && variant.product_variant_promo_quantity) || 0,
      product_variant_return_package_id:
        (variant && variant.product_variant_return_package_id) || "",
      product_variant_return_package_min:
        (variant && variant.product_variant_return_package_min) || 0,
      product_variant_return_package_max:
        (variant && variant.product_variant_return_package_max) || 0,
      product_variant_return_package_price:
        (variant && variant.product_variant_return_package_price) || 0,
      product_variant_volume: (variant && variant.product_variant_volume) || 1,
      product_variant_weight: (variant && variant.product_variant_weight) || 1,
      product_variant_order: (variant && variant.product_variant_order) || 0,
      product_variant_package_type_full_qty:
        (variant && variant.product_variant_package_type_full_qty) || 0,
      helpful_nr: (variant && variant.helpful_nr) || "",
    },
    validationSchema: Yup.object({
      product_variant_name: Yup.string().required("To pole jest wymagane"),
      product_variant_price: Yup.string().required("To pole jest wymagane"),
      product_variant_sku: Yup.string().required("To pole jest wymagane"),
      product_variant_meta_title: Yup.string().required(
        "To pole jest wymagane"
      ),
      product_variant_meta_keywords: Yup.string().required(
        "To pole jest wymagane"
      ),
      product_variant_volume: Yup.string().required("To pole jest wymagane"),
      product_variant_weight: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateVariant = {
          product_id: product ? product.product_id : 0,
          product_variant_id: variant ? variant.product_variant_id : 0,
          product_variant_name: values.product_variant_name,
          product_variant_sku: values.product_variant_sku,
          product_variant_ean: values.product_variant_ean,
          product_variant_attr1: values.product_variant_attr1,
          product_variant_attr2: values.product_variant_attr2,
          product_variant_attr3: values.product_variant_attr3,
          product_variant_wapro_index: values.product_variant_wapro_index,
          product_variant_wapro_nr: values.product_variant_wapro_nr,
          product_variant_short_description:
            values.product_variant_short_description,
          product_variant_description: values.product_variant_description,
          product_variant_delivery_description:
            values.product_variant_delivery_description,
          product_variant_active: parseInt(values.product_variant_active),
          product_variant_meta_title: values.product_variant_meta_title,
          product_variant_meta_keywords: values.product_variant_meta_keywords,
          product_variant_meta_description:
            values.product_variant_meta_description,
          product_variant_price_id: values.product_variant_price_id,
          product_variant_price: values.product_variant_price,
          product_variant_promo_price: values.product_variant_promo_price,
          product_variant_promo_on: values.product_variant_promo_on,
          product_variant_promo_home_on: values.product_variant_promo_home_on,
          product_variant_promo_price_on: values.product_variant_promo_price_on,
          product_variant_promo_price_date_from:
            values.product_variant_promo_price_date_from,
          product_variant_promo_price_date_to:
            values.product_variant_promo_price_date_to,
          product_variant_promo_quantity: values.product_variant_promo_quantity,
          product_variant_return_package_id:
            values.product_variant_return_package_id,
          product_variant_return_package_min:
            values.product_variant_return_package_min,
          product_variant_return_package_max:
            values.product_variant_return_package_max,
          product_variant_return_package_price:
            values.product_variant_return_package_price,
          product_variant_volume: values.product_variant_volume,
          product_variant_weight: values.product_variant_weight,
          product_variant_order: values.product_variant_order,
          product_variant_package_type_full_qty:
            values.product_variant_package_type_full_qty,
        }
        // update variant
        dispatch(onUpdateVariant(updateVariant))
        validation.resetForm()
      } else {
        const addVariant = {
          product_id: product ? product.product_id : 0,
          product_variant_name: values["product_variant_name"],
          product_variant_sku: values["product_variant_sku"],
          product_variant_ean: values["product_variant_ean"],
          product_variant_attr1: values["product_variant_attr1"],
          product_variant_attr2: values["product_variant_attr2"],
          product_variant_attr3: values["product_variant_attr3"],
          product_variant_wapro_index: values["product_variant_wapro_index"],
          product_variant_wapro_nr: values["product_variant_wapro_nr"],
          product_variant_short_description:
            values["product_variant_short_description"],
          product_variant_delivery_description:
            values["product_variant_delivery_description"],
          product_variant_description: values["product_variant_description"],
          product_variant_active: values["product_variant_active"],
          product_variant_meta_title: values["product_variant_meta_title"],
          product_variant_meta_keywords:
            values["product_variant_meta_keywords"],
          product_variant_meta_description:
            values["product_variant_meta_description"],
          product_variant_price: values["product_variant_price"],
          product_variant_promo_price: values["product_variant_promo_price"],
          product_variant_promo_on: values["product_variant_promo_on"],
          product_variant_promo_home_on:
            values["product_variant_promo_home_on"],
          product_variant_promo_price_on:
            values["product_variant_promo_price_on"],
          product_variant_promo_price_date_from:
            values["product_variant_promo_price_date_from"],
          product_variant_promo_price_date_to:
            values["product_variant_promo_price_date_to"],
          product_variant_promo_quantity:
            values["product_variant_promo_quantity"],
          product_variant_return_package_id:
            values["product_variant_return_package_id"],
          product_variant_return_package_min:
            values["product_variant_return_package_min"],
          product_variant_return_package_max:
            values["product_variant_return_package_max"],
          product_variant_return_package_price:
            values["product_variant_return_package_price"],
          product_variant_volume: values["product_variant_volume"],
          product_variant_weight: values["product_variant_weight"],
          product_variant_order: values["product_variant_order"],
          product_variant_package_type_full_qty:
            values["product_variant_package_type_full_qty"],
          helpful_nr: values["helpful_nr"],
        }
        // add variant
        dispatch(onAddVariant(addVariant))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { variants } = useSelector(state => ({
    variants: state.catalog.dataVariants.variants,
  }))

  const { success } = useSelector(state => ({
    success: state.catalog.dataVariants.success,
  }))

  const { error } = useSelector(state => ({
    error: state.catalog.dataVariants.error,
  }))

  const { successCreated } = useSelector(state => ({
    successCreated: state.catalog.dataCreatedVariants.successCreated,
  }))

  const { errorCreated } = useSelector(state => ({
    errorCreated: state.catalog.dataCreatedVariants.errorCreated,
  }))

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "product_variant_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "product_variant_sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "product_variant_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, variant) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleVariantClick(variant)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteVariant(variant)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    if (product && product.product_id) {
      dispatch(onGetProductsVariants(product.product_id))
    }
  }, [dispatch, product])

  useEffect(() => {
    setVariant(variants)
  }, [variants])

  useEffect(() => {
    if (!isEmpty(variants) && !!isEdit) {
      setVariant(variants)
      setIsEdit(false)
    }
  }, [variants])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_VARIANT_SUCCESS",
        })
      }, 5000)
    }
    if (successCreated.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_CREATED_VARIANT_SUCCESS",
        })
      }, 5000)
    }
  }, [success, successCreated])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_VARIANT_FAIL",
        })
      }, 5000)
    }
    if (errorCreated.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_CREATED_VARIANT_FAIL",
        })
      }, 5000)
    }
  }, [error, errorCreated])

  const toggle = () => {
    setModal(!modal)
  }

  const handleVariantClick = arg => {
    const variant = arg

    setVariant({
      product_id: variant.product_id,
      product_variant_id: variant.product_variant_id,
      product_variant_name: variant.product_variant_name,
      product_variant_sku: variant.product_variant_sku,
      product_variant_attr1: variant.product_variant_attr1,
      product_variant_attr2: variant.product_variant_attr2,
      product_variant_attr3: variant.product_variant_attr3,
      product_variant_ean: variant.product_variant_ean,
      product_variant_wapro_index: variant.product_variant_wapro_index,
      product_variant_wapro_nr: variant.product_variant_wapro_id,
      product_variant_short_description:
        variant.product_variant_short_description,
      product_variant_description: variant.product_variant_description,
      product_variant_delivery_description:
        variant.product_variant_delivery_description || "",
      product_variant_active: variant.product_variant_active,
      product_variant_meta_title: variant.product_variant_meta_title,
      product_variant_meta_description:
        variant.product_variant_meta_description,
      product_variant_meta_keywords: variant.product_variant_meta_keywords,
      product_variant_price_id: variant.prices ? variant.prices.price_id : "",
      product_variant_price: variant.prices ? variant.prices.price : "",
      product_variant_promo_price: variant.prices
        ? variant.prices.promo_price
        : "",
      product_variant_promo_price_on: variant.prices
        ? variant.prices.promo_price_on
        : "",
      product_variant_promo_on: variant.prices ? variant.prices.promo_on : "",
      product_variant_promo_home_on: variant.prices
        ? variant.prices.promo_home_on
        : "",
      product_variant_promo_price_date_from: variant.prices
        ? variant.prices.promo_price_date_from
        : "",
      product_variant_promo_price_date_to: variant.prices
        ? variant.prices.promo_price_date_to
        : "",
      product_variant_promo_quantity: variant.prices
        ? variant.prices.promo_quantity
        : "",
      product_variant_return_package_id: variant.product_return_package
        ? variant.product_return_package
            .product_return_package_return_package_id
        : "",
      product_variant_return_package_min: variant.product_return_package
        ? variant.product_return_package.product_return_package_min
        : "",
      product_variant_return_package_max: variant.product_return_package
        ? variant.product_return_package.product_return_package_max
        : "",
      product_variant_return_package_price: variant.product_return_package
        ? variant.product_return_package.product_return_package_price
        : "",
      product_variant_volume: variant.product_variant_volume,
      product_variant_weight: variant.product_variant_weight,
      product_variant_order: variant.product_variant_order,
      product_variant_package_type_full_qty:
        variant.product_variant_package_type_full_qty,
      helpful_nr: variant.helpful_nr ? variant.helpful_nr : "",
    })

    if (variant && variant.product_variant_id) {
      setIsEdit(true)
    }

    toggle()
  }

  const handleDeleteVariant = variant => {
    if (variant.product_variant_id) {
      dispatch(onDeleteVariant(variant))
    } else {
      dispatch(onDeleteCreatedVariant(variant))
    }
  }

  const defaultSorted = [
    {
      dataField: "product_variant_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <div
          className="page-content"
          style={{ paddingTop: "calc(24px * 0.75)", paddingBottom: "20px" }}
        >
          <Container fluid>
            <Row>
              <Col lg="12">
                {variants.length > 0 ? (
                  <Card>
                    <CardBody>
                      {(success || error) && (
                        <Row>
                          <Col lg="auto">
                            <Alert isOpen={success.open}>{success.text}</Alert>
                            <Alert isOpen={error.open} color="danger">
                              {error.text}
                            </Alert>
                          </Col>
                        </Row>
                      )}
                      <Col sm="12">
                        <h3>Warianty</h3>
                      </Col>
                      <ToolkitProvider
                        keyField="product_variant_id"
                        data={variants}
                        columns={columns}
                        bootstrap4
                        search
                      >
                        {props => (
                          <React.Fragment>
                            <Row>
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...props.searchProps}
                                      placeholder="Szukaj..."
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  {...props.baseProps}
                                  bordered={false}
                                  defaultSorted={defaultSorted}
                                  pagination={paginationFactory()}
                                  classes={"align-middle"}
                                  headerWrapperClasses={"table-light"}
                                  noDataIndication={() => <NoDataIndication />}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                ) : null}
                {createdVariants.length > 0 ? (
                  <Card>
                    <CardBody>
                      {(successCreated || errorCreated) && (
                        <Row>
                          <Col lg="auto">
                            <Alert isOpen={successCreated.open}>
                              {successCreated.text}
                            </Alert>
                            <Alert isOpen={errorCreated.open} color="danger">
                              {errorCreated.text}
                            </Alert>
                          </Col>
                        </Row>
                      )}
                      <Col sm="12">
                        <h3>Nowo utworzone warianty</h3>
                      </Col>
                      <ToolkitProvider
                        keyField="product_variant_id"
                        data={createdVariants}
                        columns={columns}
                        bootstrap4
                        search
                      >
                        {props => (
                          <React.Fragment>
                            <Row>
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...props.searchProps}
                                      placeholder="Szukaj..."
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  {...props.baseProps}
                                  bordered={false}
                                  defaultSorted={defaultSorted}
                                  pagination={paginationFactory()}
                                  classes={"align-middle"}
                                  headerWrapperClasses={"table-light"}
                                  noDataIndication={() => <NoDataIndication />}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                ) : null}
                <Col sm="12">
                  <h3 className="mb-0 text-danger text-end">
                    Aby zapisać produkt jako całość zmień zakładkę na inną.
                  </h3>
                </Col>
                <Modal isOpen={modal} toggle={toggle} size="xl">
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <ModalHeader toggle={toggle} tag="h4">
                      Edytuj wariant
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col className="col-12">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleCustom("1")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Dane wariantu
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleCustom("2")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Zaawansowane
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                  toggleCustom("3")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Dane meta
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                  toggleCustom("4")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Cena</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "5",
                                })}
                                onClick={() => {
                                  toggleCustom("5")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Opakowania zwrotne
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="pt-3 text-muted"
                          >
                            <TabPane tabId="1">
                              <Row className="mb-3">
                                <Col lg="6">
                                  <Label className="form-label">Nazwa</Label>
                                  <Input
                                    name="product_variant_name"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_name
                                    }
                                    invalid={
                                      validation.touched.product_variant_name &&
                                      validation.errors.product_variant_name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.product_variant_name &&
                                  validation.errors.product_variant_name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.product_variant_name}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col lg="6">
                                  <Label className="form-label">Kod SKU</Label>
                                  <Input
                                    name="product_variant_sku"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_sku
                                    }
                                    invalid={
                                      validation.touched.product_variant_sku &&
                                      validation.errors.product_variant_sku
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.product_variant_sku &&
                                  validation.errors.product_variant_sku ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.product_variant_sku}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg="6">
                                  <Label className="form-label">
                                    Index Wapro
                                  </Label>
                                  <Input
                                    name="product_variant_wapro_index"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_wapro_index
                                    }
                                  />
                                </Col>
                                <Col lg="6">
                                  <Label className="form-label">Nr Wapro</Label>
                                  <Input
                                    name="product_variant_wapro_nr"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_wapro_nr
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg="4">
                                  <Label className="form-label">
                                    Kolejność wyświetlania wariantu
                                  </Label>
                                  <Input
                                    name="product_variant_order"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_order
                                    }
                                  />
                                </Col>
                                <Col lg="4">
                                  <Label className="form-label">
                                    Kubatura (m3)
                                  </Label>
                                  <Input
                                    name="product_variant_volume"
                                    type="number"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_volume
                                    }
                                    invalid={
                                      validation.touched
                                        .product_variant_volume &&
                                      validation.errors.product_variant_volume
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.product_variant_volume &&
                                  validation.errors.product_variant_volume ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.product_variant_volume}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col lg="4">
                                  <Label className="form-label">
                                    Waga (kg)
                                  </Label>
                                  <Input
                                    name="product_variant_weight"
                                    type="number"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_weight
                                    }
                                    invalid={
                                      validation.touched
                                        .product_variant_weight &&
                                      validation.errors.product_variant_weight
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.product_variant_weight &&
                                  validation.errors.product_variant_weight ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.product_variant_weight}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg="6">
                                  <Label className="form-label">
                                    Krótki opis
                                  </Label>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    name="product_variant_short_description"
                                    data={
                                      validation.values
                                        .product_variant_short_description
                                    }
                                    onReady={editor => {
                                      // console.log(editor);
                                    }}
                                    onChange={(event, editor) => {
                                      validation.setFieldValue(
                                        "product_variant_short_description",
                                        editor.getData()
                                      )
                                    }}
                                  />
                                </Col>
                                <Col lg="6">
                                  <Label className="form-label">Opis</Label>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    name="product_variant_description"
                                    data={
                                      validation.values
                                        .product_variant_description
                                    }
                                    onReady={editor => {
                                      // console.log(editor);
                                    }}
                                    onChange={(event, editor) => {
                                      validation.setFieldValue(
                                        "product_variant_description",
                                        editor.getData()
                                      )
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <Label className="form-label">Dostawa</Label>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    name="product_variant_delivery_description"
                                    data={
                                      validation.values
                                        .product_variant_delivery_description
                                    }
                                    // onReady={editor => {
                                    //   console.log(editor);
                                    // }}
                                    onChange={(event, editor) => {
                                      validation.setFieldValue(
                                        "product_variant_delivery_description",
                                        editor.getData()
                                      )
                                    }}
                                  />
                                </Col>
                                <Col lg="2">
                                  <Label className="form-label">Aktywny</Label>
                                  <Input
                                    type="select"
                                    name="product_variant_active"
                                    className="form-select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_active || 0
                                    }
                                  >
                                    <option value="0">Nie</option>
                                    <option value="1">Tak</option>
                                  </Input>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="2">
                              <Row className="mb-3">
                                <Col lg="4">
                                  <div className="mb-4">
                                    <Label className="form-label">
                                      Ilość w pełnym opakowaniu
                                    </Label>
                                    <Input
                                      name="product_variant_package_type_full_qty"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .product_variant_package_type_full_qty
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="3">
                              <Row className="mb-3">
                                <Col lg="6">
                                  <Label className="form-label">Tytuł</Label>
                                  <Input
                                    name="product_variant_meta_title"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_meta_title
                                    }
                                    invalid={
                                      validation.touched
                                        .product_variant_meta_title &&
                                      validation.errors
                                        .product_variant_meta_title
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched
                                    .product_variant_meta_title &&
                                  validation.errors
                                    .product_variant_meta_title ? (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors
                                          .product_variant_meta_title
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg="12">
                                  <Label className="form-label">
                                    Słowa kluczowe
                                  </Label>
                                  <Input
                                    name="product_variant_meta_keywords"
                                    type="textarea"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_meta_keywords
                                    }
                                    invalid={
                                      validation.touched
                                        .product_variant_meta_keywords &&
                                      validation.errors
                                        .product_variant_meta_keywords
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched
                                    .product_variant_meta_keywords &&
                                  validation.errors
                                    .product_variant_meta_keywords ? (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors
                                          .product_variant_meta_keywords
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <Label className="form-label">Opis</Label>
                                  <Input
                                    name="product_variant_meta_description"
                                    type="textarea"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_meta_description
                                    }
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="4">
                              <Row className="mb-3">
                                <Col lg="3">
                                  <Label className="form-label">
                                    Cena (do wyceny)
                                  </Label>
                                  <Input
                                    name="product_variant_price"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_price
                                    }
                                    invalid={
                                      validation.touched
                                        .product_variant_price &&
                                      validation.errors.product_variant_price
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.product_variant_price &&
                                  validation.errors.product_variant_price ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.product_variant_price}
                                    </FormFeedback>
                                  ) : null}
                                  {(
                                    validation.values.product_variant_price -
                                    validation.values.product_variant_price *
                                      0.25
                                  ).toFixed(2) +
                                    " - " +
                                    (
                                      parseFloat(
                                        validation.values.product_variant_price
                                      ) +
                                      validation.values.product_variant_price *
                                        0.25
                                    ).toFixed(2)}
                                </Col>
                                <Col lg="3">
                                  <Label className="form-label">
                                    Cena promocyjna / kup teraz
                                  </Label>
                                  <Input
                                    name="product_variant_promo_price"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_promo_price
                                    }
                                  />
                                </Col>
                                <Col lg="3">
                                  <Label className="form-label">
                                    Ostatnia najniższa cena z 30 dni
                                  </Label>
                                  <Input
                                    name="product_variant_ean"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.product_variant_ean
                                    }
                                  />
                                </Col>
                                <Col lg="3">
                                  <Label className="form-label">
                                    Ilość promocyjna / kup teraz
                                  </Label>
                                  <Input
                                    name="product_variant_promo_quantity"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .product_variant_promo_quantity
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col lg="6">
                                  <Row>
                                    <Col lg="4">
                                      <Label className="mb-0">
                                        <Input
                                          name="product_variant_promo_price_on"
                                          type="checkbox"
                                          className="selection-input-4"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          checked={
                                            validation.values
                                              .product_variant_promo_price_on ===
                                              1 ||
                                            validation.values
                                              .product_variant_promo_price_on
                                              .length
                                              ? 1
                                              : 0
                                          }
                                          value={
                                            validation.values
                                              .product_variant_promo_price_on ===
                                            1
                                              ? 0
                                              : 1
                                          }
                                        />{" "}
                                        Aktywuj kup teraz
                                      </Label>
                                    </Col>
                                    <Col lg="4">
                                      <Label className="mb-0">
                                        <Input
                                          name="product_variant_promo_on"
                                          type="checkbox"
                                          className="selection-input-4"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          checked={
                                            validation.values
                                              .product_variant_promo_on === 1 ||
                                            validation.values
                                              .product_variant_promo_on.length
                                              ? 1
                                              : 0
                                          }
                                          value={
                                            validation.values
                                              .product_variant_promo_on === 1
                                              ? 0
                                              : 1
                                          }
                                        />{" "}
                                        Aktywuj promocję
                                      </Label>
                                    </Col>
                                    <Col lg="4">
                                      <Label className="mb-0">
                                        <Input
                                          name="product_variant_promo_home_on"
                                          type="checkbox"
                                          className="selection-input-4"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          checked={
                                            validation.values
                                              .product_variant_promo_home_on
                                          }
                                          value={
                                            validation.values
                                              .product_variant_promo_home_on ===
                                            1
                                              ? true
                                              : false
                                          }
                                        />{" "}
                                        Aktywuj na stronie głównej
                                      </Label>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg="6">
                                  <Row>
                                    <Col lg="4">
                                      <Label>
                                        od
                                        <Input
                                          name="product_variant_promo_price_date_from"
                                          type="date"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values
                                              .product_variant_promo_price_date_from
                                          }
                                        />
                                      </Label>
                                    </Col>
                                    <Col lg="4">
                                      <Label>
                                        do
                                        <Input
                                          name="product_variant_promo_price_date_to"
                                          type="date"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values
                                              .product_variant_promo_price_date_to
                                          }
                                        />
                                      </Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="5">
                              <Row>
                                <Col lg="4">
                                  <div className="mb-4">
                                    <Label className="form-label">
                                      Rodzaj opakowania zwrotnego
                                    </Label>
                                    <Input
                                      type="select"
                                      name="product_variant_return_package_id"
                                      className="form-select"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .product_variant_return_package_id ||
                                        ""
                                      }
                                    >
                                      <option value="">-- Wybierz --</option>
                                      {returnPackages.map(
                                        (returnPackage, index) => (
                                          <option
                                            value={
                                              returnPackage.return_package_id
                                            }
                                            key={
                                              returnPackage.return_package_id
                                            }
                                          >
                                            {returnPackage.return_package_name}
                                          </option>
                                        )
                                      )}
                                    </Input>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-4">
                                    <Label className="form-label">
                                      Minimalna ilość dla opakowania
                                    </Label>
                                    <Input
                                      name="product_variant_return_package_min"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .product_variant_return_package_min
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-4">
                                    <Label className="form-label">
                                      Maksymalna ilość dla opakowania
                                    </Label>
                                    <Input
                                      name="product_variant_return_package_max"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .product_variant_return_package_max
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-4">
                                    <Label className="form-label">
                                      Kaucja za opakowanie (zł)
                                    </Label>
                                    <Input
                                      name="product_variant_return_package_price"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .product_variant_return_package_price
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Zapisz
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Form>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Variants)
