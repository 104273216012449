import { call, put, takeEvery } from "redux-saga/effects"

import { 
  GET_BRANCHES, 
  GET_BRANCH_PROFILE, 
  ADD_BRANCH, 
  DELETE_BRANCH, 
  UPDATE_BRANCH 
} from "./actionTypes"

import {
  getBranchesSuccess,
  getBranchesFail,
  getBranchProfileSuccess,
  getBranchProfileFail,
  addBranchFail,
  addBranchSuccess,
  updateBranchSuccess,
  updateBranchFail,
  deleteBranchSuccess,
  deleteBranchFail,
} from "./actions"

import { 
  getBranches, 
  getBranchProfile , 
  addBranch, 
  updateBranch, 
  deleteBranch 
} from "../../helpers/backend_helper"

function* fetchBranches() {
  try {
    const response = yield call(getBranches)
    yield put(getBranchesSuccess(response.data))
  } catch (error) {
    yield put(getBranchesFail(error))
  }
}

function* fetchBranchProfile() {
  try {
    const response = yield call(getBranchProfile)
    yield put(getBranchProfileSuccess(response.data))
  } catch (error) {
    yield put(getBranchProfileFail(error))
  }
}

function* onAddBranch({ payload: branch }) {
  try {
    const response = yield call(addBranch, branch)
    yield put(addBranchSuccess(response.data))
  } catch (error) {
    yield put(addBranchFail(error))
  }
}

function* onUpdateBranch({ payload: branch }) {
  try {
    const response = yield call(updateBranch, branch)
    yield put(updateBranchSuccess(response.data))
  } catch (error) {
    yield put(updateBranchFail(error))
  }
}

function* onDeleteBranch({ payload: branch }) {
  try {
    const response = yield call(deleteBranch, branch)
    yield put(deleteBranchSuccess(response.data))
  } catch (error) {
    yield put(deleteBranchFail(error))
  }
}

function* BranchesSaga() {
  yield takeEvery(GET_BRANCHES, fetchBranches)
  yield takeEvery(GET_BRANCH_PROFILE, fetchBranchProfile)
  yield takeEvery(ADD_BRANCH, onAddBranch)
  yield takeEvery(UPDATE_BRANCH, onUpdateBranch)
  yield takeEvery(DELETE_BRANCH, onDeleteBranch)
}

export default BranchesSaga;
