/* Get DELIVERY_FORM_PRICES */
export const GET_DELIVERY_FORM_PRICES = "GET_DELIVERY_FORM_PRICES"
export const GET_DELIVERY_FORM_PRICES_SUCCESS =
  "GET_DELIVERY_FORM_PRICES_SUCCESS"
export const GET_DELIVERY_FORM_PRICES_FAIL = "GET_DELIVERY_FORM_PRICES_FAIL"

/**
 * Add DELIVERY_FORM_PRICES
 */
export const ADD_DELIVERY_FORM_PRICE = "ADD_DELIVERY_FORM_PRICE"
export const ADD_DELIVERY_FORM_PRICE_SUCCESS = "ADD_DELIVERY_FORM_PRICE_SUCCESS"
export const ADD_DELIVERY_FORM_PRICE_FAIL = "ADD_DELIVERY_FORM_PRICE_FAIL"

/**
 * Edit DELIVERY_FORM_PRICES
 */
export const UPDATE_DELIVERY_FORM_PRICE = "UPDATE_DELIVERY_FORM_PRICE"
export const UPDATE_DELIVERY_FORM_PRICE_SUCCESS =
  "UPDATE_DELIVERY_FORM_PRICE_SUCCESS"
export const UPDATE_DELIVERY_FORM_PRICE_FAIL = "UPDATE_DELIVERY_FORM_PRICE_FAIL"

/**
 * Delete DELIVERY_FORM_PRICES
 */
export const DELETE_DELIVERY_FORM_PRICE = "DELETE_DELIVERY_FORM_PRICE"
export const DELETE_DELIVERY_FORM_PRICE_SUCCESS =
  "DELETE_DELIVERY_FORM_PRICE_SUCCESS"
export const DELETE_DELIVERY_FORM_PRICE_FAIL = "DELETE_DELIVERY_FORM_PRICE_FAIL"

/**
 * Hide notification
 */

export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION"
