import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_PAYMENTS,
} from "./actionTypes"
import {
  getPaymentsSuccess, 
  getPaymentsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPayments,
} from "helpers/backend_helper"

//Payments

function* fetchPayments() {
  try {
    const response = yield call(getPayments)
    yield put(getPaymentsSuccess(response.data))
  } catch (error) {
    yield put(getPaymentsFail(error))
  }
}

function* PaymentsSaga() {
  yield takeEvery(GET_PAYMENTS, fetchPayments)
}

export default PaymentsSaga;
