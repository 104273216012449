import React, { useState, useEffect } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import { Icon } from "@iconify/react"
//redux
import { useSelector, useDispatch } from "react-redux"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  getUsers as onGetUsers,
  getUsersInActive as onGetUsersInActive,
} from "store/users/actions"

import { getReports as onGetReports } from "store/reports/actions"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  //meta title
  document.title = "Panel administracyjny - Pulpit"

  const dispatch = useDispatch()

  const { reports } = useSelector(state => ({
    reports: state.reports.reports,
  }))

  const { users } = useSelector(state => ({
    users: state.users.dataUsers.users,
  }))

  const { usersInActive } = useSelector(state => ({
    usersInActive: state.users.dataUsers.usersInActive,
  }))

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers())
    }
  }, [dispatch, users])

  useEffect(() => {
    if (!usersInActive) {
      dispatch(onGetUsersInActive())
    }
  }, [dispatch, usersInActive])

  const currMonth = new Date().getMonth()
  const currYear = new Date().getFullYear()

  useEffect(() => {
    if (reports && !reports.length) {
      dispatch(onGetReports({ month: currMonth + 1, year: currYear }))
    }
  }, [dispatch, reports])

  let sum = a => a.reduce((x, y) => x + y)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col lg="3">
              <StatCard>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <Avatar>
                      <div className="avatar-title bg-gradient">
                        <Icon icon="formkit:dollar" width="26" height="26" />
                      </div>
                    </Avatar>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-muted">Przychód</p>
                    <h4 className="mb-0">
                      {reports.length &&
                        sum(reports.map(report => report.sum_price)).toFixed(
                          2
                        )}{" "}
                      zł
                    </h4>
                  </div>
                </div>
              </StatCard>
            </Col>
            <Col lg="3">
              <StatCard>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <Avatar>
                      <div className="avatar-title bg-gradient">
                        <Icon icon="uil:shopping-bag" width="26" height="26" />
                      </div>
                    </Avatar>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-muted">Zamówienia</p>
                    <h4 className="mb-0">
                      {reports.length &&
                        sum(reports.map(report => report.sum_orders))}
                    </h4>
                  </div>
                </div>
              </StatCard>
            </Col>
            <Col lg="3">
              <StatCard>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <Avatar>
                      <div className="avatar-title bg-gradient">
                        <Icon icon="lucide:users" width="26" height="26" />
                      </div>
                    </Avatar>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-muted">Klienci</p>
                    <h4 className="mb-0">{users.length}</h4>
                  </div>
                </div>
              </StatCard>
            </Col>
            <Col lg="3">
              <StatCard>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <Avatar>
                      <div className="avatar-title bg-gradient">
                        <Icon
                          icon="line-md:account-delete"
                          width="26"
                          height="26"
                        />
                      </div>
                    </Avatar>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-muted">Niepotwierdzone konta</p>
                    <h4 className="mb-0">{usersInActive}</h4>
                  </div>
                </div>
              </StatCard>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const StatCard = styled(Card)`
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 2px 3px #e4e8f0;
  p {
    margin-bottom: 0.25rem;
  }
`
const Avatar = styled.div`
  width: 3rem;
  height: 3rem;
  .avatar-title {
    background-color: #ff6a00;
    border-radius: 15px;
  }
`

export default withTranslation()(Dashboard)
