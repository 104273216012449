import React from "react"
import { Navigate } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard/index"
import Products from "../pages/Catalog/Products/index"
import Orders from "../pages/Orders/index"
import Payments from "../pages/Payments/index"
import Reports from "../pages/Reports/index"
import Categories from "../pages/Catalog/Categories/index"
import Attributes from "../pages/Catalog/Attributes/index"
import Features from "../pages/Catalog/Features/index"
import Units from "../pages/Catalog/Units/index"
import Prices from "../pages/Catalog/Prices/index"
import ReturnPackages from "../pages/Catalog/ReturnPackages/index"
import Manufacturers from "../pages/Catalog/Manufacturers/index"
import UsersList from "../pages/Users/UsersList/users-list"
import Branches from "../pages/Branches/index"
import InfoServiceCategories from "../pages/InfoService/Categories/index"
import InfoServicePosts from "../pages/InfoService/Posts/index"
import PPProducts from "../pages/PartnerProgram/Products/index"
import PPCategories from "../pages/PartnerProgram/Categories/index"
import Logs from "../pages/Logs/index"
import NotificationsSMS from "../pages/Settings/index"
import DeliveryForms from "../pages/Settings/DeliveryForms/index"
import DeliveryFormPrices from "../pages/Settings/DeliveryFormPrices"
// import CmsPages from "../pages/Cms/Pages/index"
// import CmsPosts from "../pages/Cms/Posts/index"
// import CmsCategories from "../pages/Cms/Categories/index"
// import CmsMenu from "../pages/Cms/Menu/index"
// import CmsGalleries from "../pages/Cms/Galleries/index"
// import CmsSliders from "../pages/Cms/Sliders/index"
// import CmsFaq from "../pages/Cms/Faq/index"
// import CmsFaqCategories from "../pages/Cms/FaqCategories/index"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //InfoService
  { path: "/infoservice/categories", component: <InfoServiceCategories /> },
  { path: "/infoservice/posts", component: <InfoServicePosts /> },

  //Catalog
  // { path: "/catalog-products/:id", component: CatalogProducts },
  { path: "/catalog/products", component: <Products /> },
  //{ path: "/catalog-product-details/:id", component: CatalogProductDetail },
  { path: "/catalog/categories", component: <Categories /> },
  { path: "/catalog/attributes", component: <Attributes /> },
  { path: "/catalog/features", component: <Features /> },
  { path: "/catalog/units", component: <Units /> },
  { path: "/catalog/return-packages", component: <ReturnPackages /> },
  { path: "/catalog/manufacturers", component: <Manufacturers /> },
  { path: "/catalog/prices", component: <Prices /> },

  //CMS
  // { path: "/cms/pages", component: <CmsPages /> },
  // { path: "/cms/posts", component: <CmsPosts /> },
  // { path: "/cms/categories", component: <CmsCategories /> },
  // { path: "/cms/menu", component: <CmsMenu /> },
  // { path: "/cms/sliders", component: <CmsSliders /> },
  // { path: "/cms/galleries", component: <CmsGalleries /> },
  // { path: "/cms/faq", component: <CmsFaq /> },
  // { path: "/cms/faq-categories", component: <CmsFaqCategories /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  //Partner Program
  { path: "/partner-program/products", component: <PPProducts /> },
  { path: "/partner-program/categories", component: <PPCategories /> },

  //Orders
  { path: "/orders", component: <Orders /> },

  //Payments
  { path: "/payments", component: <Payments /> },

  //Reports
  { path: "/reports", component: <Reports /> },

  //Logs
  { path: "/logs", component: <Logs /> },

  // Users
  { path: "/users-list", component: <UsersList /> },
  //{ path: "/users-profile", component: UsersProfile },

  // Branches
  { path: "/branches", component: <Branches /> },

  // Notifications sms
  { path: "/notifications-sms", component: <NotificationsSMS /> },

  // Delivery forms
  { path: "/delivery-forms", component: <DeliveryForms /> },
  { path: "/delivery-forms/:id", component: <DeliveryFormPrices /> },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }
