import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_ORDERS,
  ADD_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
} from "./actionTypes"
import {
  getOrdersFail,
  getOrdersSuccess,
  addOrderFail,
  addOrderSuccess,
  updateOrderSuccess,
  updateOrderFail,
  deleteOrderSuccess,
  deleteOrderFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getOrders,
  addOrder,
  updateOrder,
  deleteOrder,
} from "helpers/backend_helper"

//Orders

function* fetchOrders() {
  try {
    const response = yield call(getOrders)
    yield put(getOrdersSuccess(response.data))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

function* onAddOrder({ payload: order }) {
  try {
    const response = yield call(addOrder, order)
    yield put(addOrderSuccess(response))
  } catch (error) {
    yield put(addOrderFail(error))
  }
}

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(updateOrder, order)
    yield put(updateOrderSuccess(response))
  } catch (error) {
    yield put(updateOrderFail(error))
  }
}

function* onDeleteOrder({ payload: order }) {
  try {
    const response = yield call(deleteOrder, order)
    yield put(deleteOrderSuccess(response.data))
  } catch (error) {
    yield put(deleteOrderFail(error))
  }
}

function* OrdersSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(ADD_ORDER, onAddOrder)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(DELETE_ORDER, onDeleteOrder)
}

export default OrdersSaga