import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getPayments as onGetPayments,
} from "store/payments/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Payments = props => {
  //meta title
  document.title = "Płatności - Panel administracyjny";
  
  const dispatch = useDispatch();

  const [payment, setPayment] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      p24_order_id: (payment && payment.p24_order_id) || '',
      order_id: (payment && payment.order_id) || '',
      status: (payment && payment.status) || '',
      order_nr: (payment && payment.order_nr) || '',
    },
    onSubmit: (values) => {}
  });

  const { payments } = useSelector(state => ({
    payments: state.payments.payments,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "p24_order_id",
      text: "Nr. płatności",
      sort: true,
      formatter: (cellContent, row) => (cellContent ? `#${cellContent}` : '--'),
    },
    {
      dataField: "order",
      text: "Nr. zamówienia",
      sort: true,
      formatter: (cellContent, row) => (cellContent ? `#${cellContent.order_nr}` : '--'),
    },
    {
      dataField: "created_at",
      text: "Data płatności",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        cellContent === 'in_progress' ?
          <span className="text-secondary">Oczekuje na płatność</span>
        : cellContent === 'success' ?
          <span className="text-success">Zapłacono</span>
        : 
        <>
          <span className="text-danger">Błąd płatności</span>
          {row.error_code && <div>{row.error_code && row.error_code}: {row.error_description && row.error_description}</div>}
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handlePaymentClick(payment)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(payment)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (payments && !payments.length) {
      dispatch(onGetPayments());
    }
  }, [dispatch, payments]);

  useEffect(() => {
    setPayment(payments);
  }, [payments]);

  useEffect(() => {
    if (!isEmpty(payments) && !!isEdit) {
      setPayment(payments);
      setIsEdit(false);
    }
  }, [payments]);

  const toggle = () => {
    setModal(!modal);
  };

  const handlePaymentClick = arg => {
    const payment = arg;

    setPayment({
      p24_order_id: payment.p24_order_id,
      order_id: payment.order_id,
      status: payment.status,
      order_nr: payment.order && payment.order.order_nr
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (payment) => {
    setPayment(payment);
    setDeleteModal(true);
  };

  const handleDeletePayment = () => {
    if (payment.order_id) {
      // dispatch(onDeleteOrder(payment));
      setDeleteModal(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "p24_order_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeletePayment}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs breadcrumbItem="Płatności" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="p24_order_id"
                      data={payments}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {`Edytuj płatność ${'#' + validation.values.p24_order_id} - zamówienie nr. ${validation.values.order_nr}`}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">Nr. płatności</Label>
                                          <Input
                                            name="p24_order_id"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.p24_order_id || ""}
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="mb-3">
                                          <Label className="form-label">Nr. zamówienia</Label>
                                          <Input
                                            name="order_nr"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.order_nr || ""}
                                            disabled
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">Status</Label>
                                          <Input
                                            name="status"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.status}
                                          >
                                            <option 
                                              value="success" 
                                              >Zapłacono
                                            </option>
                                            <option 
                                              value="fail" 
                                              >Błąd płatności
                                            </option>
                                            <option 
                                              value="in_progress" 
                                              >Oczekuje na płatność
                                            </option>
                                          </Input>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(Payments);
