import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import withRouter from "components/Common/withRouter";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Row,
  Col,
} from "reactstrap";

import {
  getReport as onGetReport,
} from "store/reports/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Details = props => {
  const { provider, validation, } = props;
  const { year, month } = validation.values;
  const dispatch = useDispatch();
  
  const { report } = useSelector(state => ({
    report: state.reports.report,
  }));

  const columns = [
    {
      text: "Nr. zamówienia",
      dataField: "order_nr",
      sort: true,
      formatter: cellContent => <React.Fragment>#{cellContent}</React.Fragment>,
    },
    {
      text: "Klient",
      dataField: "client_name",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
          {cellContent} {row.client_last_name}
        </React.Fragment>
      ),
    },
    {
      text: "Data",
      dataField: "order_accepted_at",
      sort: true,
    },
    {
      text: "Suma sprzedaży",
      dataField: "order_sum_price",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>{cellContent} zł</React.Fragment>
      ),
    },
  ];

  const [modal, setModal] = useState(false);

  const { SearchBar } = Search;

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (provider && year && month) {
      const date = {
        branch_id: provider.branch_id,
        year: year,
        month: month,
      };

      dispatch(onGetReport(date));

      toggle();
    }
  }, [dispatch, provider]);

  const defaultSorted = [
    {
      dataField: "order_nr",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle} tag="h4">
          {`Raport oddziału ${provider && provider.branch_name}`}
        </ModalHeader>
        <ModalBody>
          {provider && (
            <>
              <div className="h5">Ilość zamówień: <span style={{color:'#ff6a00',fontWeight:'600'}}>{provider.sum_orders}</span></div>
              <div className="h5">Suma sprzedaży: <span style={{color:'#ff6a00',fontWeight:'600'}}>{provider.sum_price.toFixed(2)} zł</span></div>
            </>
          )}
          <ToolkitProvider
            keyField="order_nr"
            data={report}
            columns={columns}
            bootstrap4
            search
          >
            {props => (
              <React.Fragment>
                <Row>
                  <Col sm="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Szukaj..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <BootstrapTable
                      {...props.baseProps}
                      bordered={false}
                      defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      classes={"align-middle"}
                      headerWrapperClasses={"table-light"}
                      noDataIndication={() => <NoDataIndication />}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(Details);
