import React from "react"

import { Row, Col, Alert } from "reactstrap"

export const Notification = ({ success, error }) => {
  if (!success.open && !error.open) return null

  return (
    <React.Fragment>
      <Row>
        <Col lg="auto">
          <Alert isOpen={success.open}>{success.text}</Alert>
          <Alert isOpen={error.open} color="danger">
            {error.text}
          </Alert>
        </Col>
      </Row>
    </React.Fragment>
  )
}
