import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
// import paginationFactory from "react-bootstrap-table2-paginator"
// components
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Notification } from "./commponents/Notification"
import { NoDataIndication } from "./commponents/NoDataIndicator"
import { FormModal } from "./commponents/FormModal"
//store
import {
  getDeliveryForms,
  addDeliveryForm as onAddDeliveryForm,
  updateDeliveryForm,
  deleteDeliveryForm,
  hideNotification,
} from "store/delivery-forms/actions"
// helpers
import { defaultSorted, setColumns, getValidation } from "./helpers"

const DeliveryForms = () => {
  // meta title
  document.title = "Użytkownicy - Panel administracyjny"
  // own state
  const [pickedUser, setPickedUser] = useState(undefined)
  const [modal, setModal] = useState(false)
  const timeOutRef = useRef(null)
  // global state
  const dispatch = useDispatch()
  const { deliveryForms } = useSelector(state => ({
    deliveryForms: state.deliveryForms.dataDeliveryForms.deliveryForms,
  }))
  const { success } = useSelector(state => ({
    success: state.deliveryForms.dataDeliveryForms.success,
  }))

  const { error } = useSelector(state => ({
    error: state.deliveryForms.dataDeliveryForms.error,
  }))

  // effects
  useEffect(() => {
    if (deliveryForms === undefined) {
      dispatch(getDeliveryForms())
    }
  }, [dispatch, deliveryForms])

  useEffect(() => {
    if (success.open || error.open) {
      timeOutRef.current = setTimeout(() => {
        dispatch(hideNotification())
      }, 5000)
    }

    return () => clearTimeout(timeOutRef.current)
  }, [success, error])
  // handlers
  const addDeliveryForm = fd => {
    dispatch(onAddDeliveryForm(fd))
    setPickedUser(undefined)
    setModal(false)
  }
  const editDeliveryForm = fd => {
    dispatch(updateDeliveryForm({ id: pickedUser.id, data: fd }))
    setPickedUser(undefined)
    setModal(false)
  }
  const deleteDeliveryFor = id => {
    dispatch(deleteDeliveryForm(id))
  }

  const handleEditButton = user => {
    setPickedUser(user)
    setModal(true)
  }

  const handleCloseModal = () => {
    setPickedUser(prev => undefined)
    setModal(false)
  }

  const toggleModal = () => {
    setModal(prev => !prev)
  }
  // variables
  const columns = setColumns(handleEditButton, deleteDeliveryFor)
  const validation = getValidation(
    pickedUser,
    addDeliveryForm,
    editDeliveryForm
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs breadcrumbItem="Formy dostawy" />
        <Card>
          <CardBody>
            <Notification success={success} error={error} />
            {deliveryForms === undefined || deliveryForms?.length !== 0 ? (
              <ToolkitProvider
                keyField="id"
                data={deliveryForms || []}
                columns={columns}
                search
              >
                {props => {
                  return (
                    <React.Fragment>
                      <Row>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Search.SearchBar
                                {...props.searchProps}
                                placeholder="Szukaj..."
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                              onClick={toggleModal}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Dodaj formy dostawy
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <BootstrapTable
                            {...props.baseProps}
                            bordered={false}
                            defaultSorted={defaultSorted}
                            classes={"align-middle"}
                            headerWrapperClasses={"table-light"}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                }}
              </ToolkitProvider>
            ) : (
              <Row>
                <Col sm="6">
                  <span>Brak form dostawy</span>
                </Col>
                <Col sm="6">
                  <div className="text-sm-end">
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary"
                      onClick={toggleModal}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Dodaj formy dostawy
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
            <FormModal
              isOpen={modal}
              toggle={handleCloseModal}
              currentUser={pickedUser}
              validation={validation}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default withRouter(DeliveryForms)
