/* NOTIFICATIONS */
export const GET_SMS_NOTIFICATIONS = "GET_SMS_NOTIFICATIONS";
export const GET_SMS_NOTIFICATIONS_SUCCESS = "GET_SMS_NOTIFICATIONS_SUCCESS";
export const GET_SMS_NOTIFICATIONS_FAIL = "GET_SMS_NOTIFICATIONS_FAIL";

export const UPDATE_SMS_NOTIFICATIONS = "UPDATE_SMS_NOTIFICATIONS";
export const UPDATE_SMS_NOTIFICATIONS_SUCCESS = "UPDATE_SMS_NOTIFICATIONS_SUCCESS";
export const UPDATE_SMS_NOTIFICATIONS_FAIL = "UPDATE_SMS_NOTIFICATIONS_FAIL";

export const HIDE_SMS_NOTIFICATIONS_SUCCESS = "HIDE_SMS_NOTIFICATIONS_SUCCESS";
export const HIDE_SMS_NOTIFICATIONS_FAIL = "HIDE_SMS_NOTIFICATIONS_FAIL";