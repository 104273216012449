/* POSTS */
export const GET_CMS_POSTS = "GET_CMS_POSTS"
export const GET_CMS_POSTS_SUCCESS = "GET_CMS_POSTS_SUCCESS"
export const GET_CMS_POSTS_FAIL = "GET_CMS_POSTS_FAIL"
export const ADD_CMS_POST = "ADD_CMS_POST"
export const ADD_CMS_POST_SUCCESS = "ADD_CMS_POST_SUCCESS"
export const ADD_CMS_POST_FAIL = "ADD_CMS_POST_FAIL"
export const UPDATE_CMS_POST = "UPDATE_CMS_POST"
export const UPDATE_CMS_POST_SUCCESS = "UPDATE_CMS_POST_SUCCESS"
export const UPDATE_CMS_POST_FAIL = "UPDATE_CMS_POST_FAIL"
export const DELETE_CMS_POST = "DELETE_CMS_POST"
export const DELETE_CMS_POST_SUCCESS = "DELETE_CMS_POST_SUCCESS"
export const DELETE_CMS_POST_FAIL = "DELETE_CMS_POST_FAIL"

/* CATEGORIES */
export const GET_CMS_CATEGORIES = "GET_CMS_CATEGORIES"
export const GET_CMS_CATEGORIES_SUCCESS = "GET_CMS_CATEGORIES_SUCCESS"
export const GET_CMS_CATEGORIES_FAIL = "GET_CMS_CATEGORIES_FAIL"
export const ADD_CMS_CATEGORY = "ADD_CMS_CATEGORY"
export const ADD_CMS_CATEGORY_SUCCESS = "ADD_CMS_CATEGORY_SUCCESS"
export const ADD_CMS_CATEGORY_FAIL = "ADD_CMS_CATEGORY_FAIL"
export const UPDATE_CMS_CATEGORY = "UPDATE_CMS_CATEGORY"
export const UPDATE_CMS_CATEGORY_SUCCESS = "UPDATE_CMS_CATEGORY_SUCCESS"
export const UPDATE_CMS_CATEGORY_FAIL = "UPDATE_CMS_CATEGORY_FAIL"
export const DELETE_CMS_CATEGORY = "DELETE_CMS_CATEGORY"
export const DELETE_CMS_CATEGORY_SUCCESS = "DELETE_CMS_CATEGORY_SUCCESS"
export const DELETE_CMS_CATEGORY_FAIL = "DELETE_CMS_CATEGORY_FAIL"

/* PAGES */
export const GET_CMS_PAGES = "GET_CMS_PAGES"
export const GET_CMS_PAGES_SUCCESS = "GET_CMS_PAGES_SUCCESS"
export const GET_CMS_PAGES_FAIL = "GET_CMS_PAGES_FAIL"
export const ADD_CMS_PAGE = "ADD_CMS_PAGE"
export const ADD_CMS_PAGE_SUCCESS = "ADD_CMS_PAGE_SUCCESS"
export const ADD_CMS_PAGE_FAIL = "ADD_CMS_PAGE_FAIL"
export const UPDATE_CMS_PAGE = "UPDATE_CMS_PAGE"
export const UPDATE_CMS_PAGE_SUCCESS = "UPDATE_CMS_PAGE_SUCCESS"
export const UPDATE_CMS_PAGE_FAIL = "UPDATE_CMS_PAGE_FAIL"
export const DELETE_CMS_PAGE = "DELETE_CMS_PAGE"
export const DELETE_CMS_PAGE_SUCCESS = "DELETE_CMS_PAGE_SUCCESS"
export const DELETE_CMS_PAGE_FAIL = "DELETE_CMS_PAGE_FAIL"

/* MENU */
export const GET_CMS_MENU = "GET_CMS_MENU"
export const GET_CMS_MENU_SUCCESS = "GET_CMS_MENU_SUCCESS"
export const GET_CMS_MENU_FAIL = "GET_CMS_MENU_FAIL"
export const ADD_CMS_MENU = "ADD_CMS_MENU"
export const ADD_CMS_MENU_SUCCESS = "ADD_CMS_MENU_SUCCESS"
export const ADD_CMS_MENU_FAIL = "ADD_CMS_MENU_FAIL"
export const UPDATE_CMS_MENU = "UPDATE_CMS_MENU"
export const UPDATE_CMS_MENU_SUCCESS = "UPDATE_CMS_MENU_SUCCESS"
export const UPDATE_CMS_MENU_FAIL = "UPDATE_CMS_MENU_FAIL"
export const DELETE_CMS_MENU = "DELETE_CMS_MENU"
export const DELETE_CMS_MENU_SUCCESS = "DELETE_CMS_MENU_SUCCESS"
export const DELETE_CMS_MENU_FAIL = "DELETE_CMS_MENU_FAIL"

/* GALLERIES */
export const GET_CMS_GALLERIES = "GET_CMS_GALLERIES"
export const GET_CMS_GALLERIES_SUCCESS = "GET_CMS_GALLERIES_SUCCESS"
export const GET_CMS_GALLERIES_FAIL = "GET_CMS_GALLERIES_FAIL"
export const ADD_CMS_GALLERY = "ADD_CMS_GALLERY"
export const ADD_CMS_GALLERY_SUCCESS = "ADD_CMS_GALLERY_SUCCESS"
export const ADD_CMS_GALLERY_FAIL = "ADD_CMS_GALLERY_FAIL"
export const UPDATE_CMS_GALLERY = "UPDATE_CMS_GALLERY"
export const UPDATE_CMS_GALLERY_SUCCESS = "UPDATE_CMS_GALLERY_SUCCESS"
export const UPDATE_CMS_GALLERY_FAIL = "UPDATE_CMS_GALLERY_FAIL"
export const DELETE_CMS_GALLERY = "DELETE_CMS_GALLERY"
export const DELETE_CMS_GALLERY_SUCCESS = "DELETE_CMS_GALLERY_SUCCESS"
export const DELETE_CMS_GALLERY_FAIL = "DELETE_CMS_GALLERY_FAIL"

/* GALLERIES ITEMS */
export const GET_CMS_GALLERY_ITEMS = "GET_CMS_GALLERY_ITEMS"
export const GET_CMS_GALLERY_ITEMS_SUCCESS = "GET_CMS_GALLERY_ITEMS_SUCCESS"
export const GET_CMS_GALLERY_ITEMS_FAIL = "GET_CMS_GALLERY_ITEMS_FAIL"
export const ADD_CMS_GALLERY_ITEM = "ADD_CMS_GALLERY_ITEM"
export const ADD_CMS_GALLERY_ITEM_SUCCESS = "ADD_CMS_GALLERY_ITEM_SUCCESS"
export const ADD_CMS_GALLERY_ITEM_FAIL = "ADD_CMS_GALLERY_ITEM_FAIL"
export const UPDATE_CMS_GALLERY_ITEM = "UPDATE_CMS_GALLERY_ITEM"
export const UPDATE_CMS_GALLERY_ITEM_SUCCESS = "UPDATE_CMS_GALLERY_ITEM_SUCCESS"
export const UPDATE_CMS_GALLERY_ITEM_FAIL = "UPDATE_CMS_GALLERY_ITEM_FAIL"
export const DELETE_CMS_GALLERY_ITEM = "DELETE_CMS_GALLERY_ITEM"
export const DELETE_CMS_GALLERY_ITEM_SUCCESS = "DELETE_CMS_GALLERY_ITEM_SUCCESS"
export const DELETE_CMS_GALLERY_ITEM_FAIL = "DELETE_CMS_GALLERY_ITEM_FAIL"

/* SLIDERS */
export const GET_CMS_SLIDERS = "GET_CMS_SLIDERS"
export const GET_CMS_SLIDERS_SUCCESS = "GET_CMS_SLIDERS_SUCCESS"
export const GET_CMS_SLIDERS_FAIL = "GET_CMS_SLIDERS_FAIL"
export const ADD_CMS_SLIDER = "ADD_CMS_SLIDER"
export const ADD_CMS_SLIDER_SUCCESS = "ADD_CMS_SLIDER_SUCCESS"
export const ADD_CMS_SLIDER_FAIL = "ADD_CMS_SLIDER_FAIL"
export const UPDATE_CMS_SLIDER = "UPDATE_CMS_SLIDER"
export const UPDATE_CMS_SLIDER_SUCCESS = "UPDATE_CMS_SLIDER_SUCCESS"
export const UPDATE_CMS_SLIDER_FAIL = "UPDATE_CMS_SLIDER_FAIL"
export const DELETE_CMS_SLIDER = "DELETE_CMS_SLIDER"
export const DELETE_CMS_SLIDER_SUCCESS = "DELETE_CMS_SLIDER_SUCCESS"
export const DELETE_CMS_SLIDER_FAIL = "DELETE_CMS_SLIDER_FAIL"

/* SLIDERS ITEMS */
export const GET_CMS_SLIDER_ITEMS = "GET_CMS_SLIDER_ITEMS"
export const GET_CMS_SLIDER_ITEMS_SUCCESS = "GET_CMS_SLIDER_ITEMS_SUCCESS"
export const GET_CMS_SLIDER_ITEMS_FAIL = "GET_CMS_SLIDER_ITEMS_FAIL"
export const ADD_CMS_SLIDER_ITEM = "ADD_CMS_SLIDER_ITEM"
export const ADD_CMS_SLIDER_ITEM_SUCCESS = "ADD_CMS_SLIDER_ITEM_SUCCESS"
export const ADD_CMS_SLIDER_ITEM_FAIL = "ADD_CMS_SLIDER_ITEM_FAIL"
export const UPDATE_CMS_SLIDER_ITEM = "UPDATE_CMS_SLIDER_ITEM"
export const UPDATE_CMS_SLIDER_ITEM_SUCCESS = "UPDATE_CMS_SLIDER_ITEM_SUCCESS"
export const UPDATE_CMS_SLIDER_ITEM_FAIL = "UPDATE_CMS_SLIDER_ITEM_FAIL"
export const DELETE_CMS_SLIDER_ITEM = "DELETE_CMS_SLIDER_ITEM"
export const DELETE_CMS_SLIDER_ITEM_SUCCESS = "DELETE_CMS_SLIDER_ITEM_SUCCESS"
export const DELETE_CMS_SLIDER_ITEM_FAIL = "DELETE_CMS_SLIDER_ITEM_FAIL"

/* FAQ */
export const GET_CMS_FAQS = "GET_CMS_FAQS"
export const GET_CMS_FAQS_SUCCESS = "GET_CMS_FAQS_SUCCESS"
export const GET_CMS_FAQS_FAIL = "GET_CMS_FAQS_FAIL"
export const ADD_CMS_FAQ = "ADD_CMS_FAQ"
export const ADD_CMS_FAQ_SUCCESS = "ADD_CMS_FAQ_SUCCESS"
export const ADD_CMS_FAQ_FAIL = "ADD_CMS_FAQ_FAIL"
export const UPDATE_CMS_FAQ = "UPDATE_CMS_FAQ"
export const UPDATE_CMS_FAQ_SUCCESS = "UPDATE_CMS_FAQ_SUCCESS"
export const UPDATE_CMS_FAQ_FAIL = "UPDATE_CMS_FAQ_FAIL"
export const DELETE_CMS_FAQ = "DELETE_CMS_FAQ"
export const DELETE_CMS_FAQ_SUCCESS = "DELETE_CMS_FAQ_SUCCESS"
export const DELETE_CMS_FAQ_FAIL = "DELETE_CMS_FAQ_FAIL"

/* FAQ CATEGORIES */
export const GET_CMS_FAQ_CATEGORIES = "GET_CMS_FAQ_CATEGORIES"
export const GET_CMS_FAQ_CATEGORIES_SUCCESS = "GET_CMS_FAQ_CATEGORIES_SUCCESS"
export const GET_CMS_FAQ_CATEGORIES_FAIL = "GET_CMS_FAQ_CATEGORIES_FAIL"
export const ADD_CMS_FAQ_CATEGORY = "ADD_CMS_FAQ_CATEGORY"
export const ADD_CMS_FAQ_CATEGORY_SUCCESS = "ADD_CMS_FAQ_CATEGORY_SUCCESS"
export const ADD_CMS_FAQ_CATEGORY_FAIL = "ADD_CMS_FAQ_CATEGORY_FAIL"
export const UPDATE_CMS_FAQ_CATEGORY = "UPDATE_CMS_FAQ_CATEGORY"
export const UPDATE_CMS_FAQ_CATEGORY_SUCCESS = "UPDATE_CMS_FAQ_CATEGORY_SUCCESS"
export const UPDATE_CMS_FAQ_CATEGORY_FAIL = "UPDATE_CMS_FAQ_CATEGORY_FAIL"
export const DELETE_CMS_FAQ_CATEGORY = "DELETE_CMS_FAQ_CATEGORY"
export const DELETE_CMS_FAQ_CATEGORY_SUCCESS = "DELETE_CMS_FAQ_CATEGORY_SUCCESS"
export const DELETE_CMS_FAQ_CATEGORY_FAIL = "DELETE_CMS_FAQ_CATEGORY_FAIL"