import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Badge,
  Spinner,
  Button,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import * as Yup from "yup"
import { useFormik, FormikProvider } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getPosts as onGetPosts,
  addPost as onAddPost,
  updatePost as onUpdatePost,
  deletePost as onDeletePost,
  getISCategories as onGetISCategories,
} from "store/infoservice/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

const InfoServicePosts = props => {
  //meta title
  document.title = "Wpisy - Serwis informacyjny - Panel administracyjny"

  const dispatch = useDispatch()

  const [post, setPost] = useState(null)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      post_id: (post && post.post_id) || "",
      post_name: (post && post.post_name) || "",
      category_id: (post && post.category_id) || "",
      post_orientation: (post && post.post_orientation) || "",
      post_content: (post && post.post_content) || "",
      post_active: (post && post.post_active) || 0,
      post_image: (post && post.post_image) || "",
      post_image_title: (post && post.post_image_title) || "",
      post_image_alt: (post && post.post_image_alt) || "",
      post_meta_title: (post && post.post_meta_title) || "",
      post_meta_keywords: (post && post.post_meta_keywords) || "",
      post_meta_description: (post && post.post_meta_description) || "",
      category: (post && post.category) || "",
    },
    validationSchema: Yup.object({
      post_name: Yup.string().required("To pole jest wymagane"),
      category_id: Yup.string().required("To pole jest wymagane"),
      post_meta_title: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const formData = new FormData()
        formData.append("post_id", post.post_id)
        formData.append("post_name", values.post_name)
        formData.append("category_id", values.category_id)
        formData.append("post_orientation", values.post_orientation)
        formData.append("post_content", values.post_content)
        formData.append("post_active", values.post_active)
        formData.append("post_image", values.post_image)
        formData.append("post_image_title", values.post_image_title)
        formData.append("post_image_alt", values.post_image_alt)
        formData.append("post_meta_title", values.post_meta_title)
        formData.append("post_meta_keywords", values.post_meta_keywords)
        formData.append("post_meta_description", values.post_meta_description)

        dispatch(onUpdatePost(formData))
        validation.resetForm()
      } else {
        const formData = new FormData()

        formData.append("post_name", values.post_name)
        formData.append("category_id", values.category_id)
        formData.append("post_orientation", values.post_orientation)
        formData.append("post_content", values.post_content)
        formData.append("post_active", values.post_active)
        formData.append("post_image", values.post_image)
        formData.append("post_image_title", values.post_image_title)
        formData.append("post_image_alt", values.post_image_alt)
        formData.append("post_meta_title", values.post_meta_title)
        formData.append("post_meta_keywords", values.post_meta_keywords)
        formData.append("post_meta_description", values.post_meta_description)

        dispatch(onAddPost(formData))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { posts } = useSelector(state => ({
    posts: state.infoService.dataPosts.posts,
  }))

  const { success } = useSelector(state => ({
    success: state.infoService.dataPosts.success,
  }))

  const { error } = useSelector(state => ({
    error: state.infoService.dataPosts.error,
  }))

  const { categories } = useSelector(state => ({
    categories: state.infoService.dataCategories.categories,
  }))

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search
  const columns = [
    {
      dataField: "post_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "post_image",
      text: "Zdjęcie",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
          {!cellContent ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"
                }
                width="50"
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/infoservice/posts/mini/" +
                  cellContent
                }
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "post_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "category",
      text: "Kategoria",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{cellContent?.category_name}</React.Fragment>
      ),
    },
    {
      dataField: "post_active",
      text: "Aktywny",
      sort: true,
      formatter: cellContent =>
        cellContent === 1 || cellContent === "1" ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, post) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handlePostClick(post)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(post)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    if (posts && !posts.length) {
      dispatch(onGetPosts())
    }
  }, [dispatch, posts])

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetISCategories())
    }
  }, [dispatch, categories])

  useEffect(() => {
    setPost(posts)
  }, [posts])

  useEffect(() => {
    if (!isEmpty(posts) && !!isEdit) {
      setPost(posts)
      setIsEdit(false)
    }
  }, [posts])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_POST_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_POST_FAIL",
        })
      }, 5000)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const handlePostClick = arg => {
    const post = arg

    setPost({
      post_id: post.post_id,
      post_name: post.post_name,
      category_id: post.category_id,
      post_orientation: post.post_orientation,
      post_content: post.post_content,
      post_active: post.post_active,
      post_image: post.post_image,
      post_image_title: post.post_image_title,
      post_image_alt: post.post_image_alt,
      post_meta_title: post.post_meta_title,
      post_meta_keywords: post.post_meta_keywords,
      post_meta_description: post.post_meta_description,
      category: post.category,
    })

    setIsEdit(true)

    toggle()
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = post => {
    setPost(post)
    setDeleteModal(true)
  }

  const handleDeletePost = () => {
    if (post.post_id) {
      dispatch(onDeletePost(post))
      setDeleteModal(false)
    }
  }

  const handlePostClicks = () => {
    setPost("")
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "post_id",
      order: "asc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeletePost}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Wpisy" breadcrumbItem="Kategorie" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && (
                      <Row>
                        <Col lg="auto">
                          <Alert isOpen={success.open}>{success.text}</Alert>
                          <Alert isOpen={error.open} color="danger">
                            {error.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <ToolkitProvider
                      keyField="post_id"
                      data={posts}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handlePostClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj wpis
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edytuj wpis" : "Dodaj wpis"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <Nav
                                          tabs
                                          className="nav-tabs-custom nav-justified"
                                        >
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "1",
                                              })}
                                              onClick={() => {
                                                toggleCustom("1")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Dane artykułu
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "2",
                                              })}
                                              onClick={() => {
                                                toggleCustom("2")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Zdjęcie
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "3",
                                              })}
                                              onClick={() => {
                                                toggleCustom("3")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Dane meta
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>

                                        <TabContent
                                          activeTab={customActiveTab}
                                          className="p-3 text-muted"
                                        >
                                          <TabPane tabId="1">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">
                                                  Nazwa
                                                </Label>
                                                <Input
                                                  name="post_name"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.post_name
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .post_name &&
                                                    validation.errors.post_name
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.post_name &&
                                                validation.errors.post_name ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .post_name
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Kategoria
                                                </Label>
                                                <Input
                                                  type="select"
                                                  name="category_id"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  className="form-select"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .category_id
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .category_id &&
                                                    validation.errors
                                                      .category_id
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  <option value="">
                                                    -- Wybierz --
                                                  </option>
                                                  {categories.map(
                                                    (category, index) => (
                                                      <option
                                                        value={
                                                          category.category_id
                                                        }
                                                        key={
                                                          category.category_id
                                                        }
                                                      >
                                                        {category.category_name}
                                                      </option>
                                                    )
                                                  )}
                                                </Input>
                                                {validation.touched
                                                  .category_id &&
                                                validation.errors
                                                  .category_id ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .category_id
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Kolejność wyświetlania
                                                </Label>
                                                <Input
                                                  name="post_orientation"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .post_orientation
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">
                                                  Treść
                                                </Label>
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  name="post_content"
                                                  data={
                                                    validation.values
                                                      .post_content
                                                  }
                                                  onReady={editor => {
                                                    // console.log(editor);
                                                  }}
                                                  onChange={(event, editor) => {
                                                    validation.setFieldValue(
                                                      "post_content",
                                                      editor.getData()
                                                    )
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="2 mb-3">
                                                <Label className="form-label">
                                                  Aktywny
                                                </Label>
                                                <Input
                                                  type="select"
                                                  name="post_active"
                                                  className="form-select"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .post_active
                                                  }
                                                >
                                                  <option value="0">Nie</option>
                                                  <option value="1">Tak</option>
                                                </Input>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">
                                                  Zdjęcie
                                                </Label>
                                                <Input
                                                  type="file"
                                                  name="post_image"
                                                  onChange={event => {
                                                    validation.setFieldValue(
                                                      "post_image",
                                                      event.currentTarget
                                                        .files[0]
                                                    )
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Tytuł zdjęcia
                                                </Label>
                                                <Input
                                                  name="post_image_title"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .post_image_title
                                                  }
                                                />
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Tekst alternatywny
                                                </Label>
                                                <Input
                                                  name="post_image_alt"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .post_image_alt
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="3">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">
                                                  Tytuł
                                                </Label>
                                                <Input
                                                  name="post_meta_title"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .post_meta_title
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .post_meta_title &&
                                                    validation.errors
                                                      .post_meta_title
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .post_meta_title &&
                                                validation.errors
                                                  .post_meta_title ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .post_meta_title
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">
                                                  Słowa kluczowe
                                                </Label>
                                                <Input
                                                  type="textarea"
                                                  id="textarea"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  rows="3"
                                                  name="post_meta_keywords"
                                                  value={
                                                    validation.values
                                                      .post_meta_keywords
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">
                                                  Opis
                                                </Label>
                                                <Input
                                                  type="textarea"
                                                  id="textarea1"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  rows="3"
                                                  name="post_meta_description"
                                                  value={
                                                    validation.values
                                                      .post_meta_description
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(InfoServicePosts)
