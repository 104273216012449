import {
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_FAIL,
  GET_ATTRIBUTES_SUCCESS,
  ADD_ATTRIBUTE,
  ADD_ATTRIBUTE_SUCCESS,
  ADD_ATTRIBUTE_FAIL,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE_FAIL,
  DELETE_ATTRIBUTE,
  DELETE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  GET_UNITS,
  GET_UNITS_FAIL,
  GET_UNITS_SUCCESS,
  ADD_UNIT,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  UPDATE_UNIT,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  GET_RETURN_PACKAGES,
  GET_RETURN_PACKAGES_FAIL,
  GET_RETURN_PACKAGES_SUCCESS,
  ADD_RETURN_PACKAGE,
  ADD_RETURN_PACKAGE_SUCCESS,
  ADD_RETURN_PACKAGE_FAIL,
  UPDATE_RETURN_PACKAGE,
  UPDATE_RETURN_PACKAGE_SUCCESS,
  UPDATE_RETURN_PACKAGE_FAIL,
  DELETE_RETURN_PACKAGE,
  DELETE_RETURN_PACKAGE_SUCCESS,
  DELETE_RETURN_PACKAGE_FAIL,
  GET_FEATURES,
  GET_FEATURES_FAIL,
  GET_FEATURES_SUCCESS,
  ADD_FEATURE,
  ADD_FEATURE_SUCCESS,
  ADD_FEATURE_FAIL,
  UPDATE_FEATURE,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_FEATURE_FAIL,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAIL,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAIL,
  GET_PRICES,
  GET_PRICES_FAIL,
  GET_PRICES_SUCCESS,
  UPDATE_PRICE,
  UPDATE_PRICE_SUCCESS,
  UPDATE_PRICE_FAIL,
  ADD_RELATED_PRODUCT,
  ADD_RELATED_PRODUCT_SUCCESS,
  ADD_RELATED_PRODUCT_FAIL,
  DELETE_RELATED_PRODUCT,
  DELETE_RELATED_PRODUCT_SUCCESS,
  DELETE_RELATED_PRODUCT_FAIL,
  GET_PRODUCTS_VARIANTS,
  GET_PRODUCTS_VARIANTS_FAIL,
  GET_PRODUCTS_VARIANTS_SUCCESS,
  ADD_VARIANT,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  UPDATE_VARIANT,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  DELETE_VARIANT,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
  ADD_CREATED_VARIANTS,
  ADD_CREATED_VARIANTS_SUCCESS,
  ADD_CREATED_VARIANTS_FAIL,
  DELETE_CREATED_VARIANT,
  DELETE_CREATED_VARIANT_SUCCESS,
  DELETE_CREATED_VARIANT_FAIL,
} from "./actionTypes";

//Attributes

export const getAttributes = () => ({
  type: GET_ATTRIBUTES,
})

export const getAttributesSuccess = attributes => ({
  type: GET_ATTRIBUTES_SUCCESS,
  payload: attributes,
})

export const getAttributesFail = error => ({
  type: GET_ATTRIBUTES_FAIL,
  payload: error,
})

export const addAttribute = attribute => ({
  type: ADD_ATTRIBUTE,
  payload: attribute,
})

export const addAttributeSuccess = attribute => ({
  type: ADD_ATTRIBUTE_SUCCESS,
  payload: attribute,
})

export const addAttributeFail = error => ({
  type: ADD_ATTRIBUTE_FAIL,
  payload: error,
})

export const updateAttribute = attribute => ({
  type: UPDATE_ATTRIBUTE,
  payload: attribute,
})

export const updateAttributeSuccess = attribute => ({
  type: UPDATE_ATTRIBUTE_SUCCESS,
  payload: attribute,
})

export const updateAttributeFail = error => ({
  type: UPDATE_ATTRIBUTE_FAIL,
  payload: error,
})

export const deleteAttribute = attribute => ({
  type: DELETE_ATTRIBUTE,
  payload: attribute,
})

export const deleteAttributeSuccess = attribute => ({
  type: DELETE_ATTRIBUTE_SUCCESS,
  payload: attribute,
})

export const deleteAttributeFail = error => ({
  type: DELETE_ATTRIBUTE_FAIL,
  payload: error,
})

//Categories

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const addCategory = category => ({
  type: ADD_CATEGORY,
  payload: category,
})

export const addCategorySuccess = category => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category,
})

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
})

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: category,
})

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

export const deleteCategory = category => ({
  type: DELETE_CATEGORY,
  payload: category,
})

export const deleteCategorySuccess = category => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category,
})

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
})

//Products

export const getProducts = () => ({
  type: GET_PRODUCTS,
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})

export const addProduct = product => ({
  type: ADD_PRODUCT,
  payload: product,
})

export const addProductSuccess = product => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: product,
})

export const addProductFail = error => ({
  type: ADD_PRODUCT_FAIL,
  payload: error,
})

export const updateProduct = product => ({
  type: UPDATE_PRODUCT,
  payload: product,
})

export const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
})

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
})

export const deleteProduct = product => ({
  type: DELETE_PRODUCT,
  payload: product,
})

export const deleteProductSuccess = product => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: product,
})

export const deleteProductFail = error => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
})

//Variants

export const getProductsVariants = product_id => ({
  type: GET_PRODUCTS_VARIANTS,
  payload: product_id,
})

export const getProductsVariantsSuccess = variants => ({
  type: GET_PRODUCTS_VARIANTS_SUCCESS,
  payload: variants,
})

export const getProductsVariantsFail = error => ({
  type: GET_PRODUCTS_VARIANTS_FAIL,
  payload: error,
})

export const addVariant = variant => ({
  type: ADD_VARIANT,
  payload: variant,
})

export const addVariantSuccess = variant => ({
  type: ADD_VARIANT_SUCCESS,
  payload: variant,
})

export const addVariantFail = error => ({
  type: ADD_VARIANT_FAIL,
  payload: error,
})

export const updateVariant = variant => ({
  type: UPDATE_VARIANT,
  payload: variant,
})

export const updateVariantSuccess = variant => ({
  type: UPDATE_VARIANT_SUCCESS,
  payload: variant,
})

export const updateVariantFail = error => ({
  type: UPDATE_VARIANT_FAIL,
  payload: error,
})

export const deleteVariant = variant => ({
  type: DELETE_VARIANT,
  payload: variant,
})

export const deleteVariantSuccess = variant => ({
  type: DELETE_VARIANT_SUCCESS,
  payload: variant,
})

export const deleteVariantFail = error => ({
  type: DELETE_VARIANT_FAIL,
  payload: error,
})

export const addCreatedVariants = variants => ({
  type: ADD_CREATED_VARIANTS,
  payload: variants
})

export const addCreatedVariantsSuccess = variants => ({
  type: ADD_CREATED_VARIANTS_SUCCESS,
  payload: variants
})

export const addCreatedVariantsFail = error => ({
  type: ADD_CREATED_VARIANTS_FAIL,
  payload: error
})

export const deleteCreatedVariant = variant => ({
  type: DELETE_CREATED_VARIANT,
  payload: variant
})

export const deleteCreatedVariantSuccess = variant => ({
  type: DELETE_CREATED_VARIANT_SUCCESS,
  payload: variant
})

export const deleteCreatedVariantFail = error => ({
  type: DELETE_CREATED_VARIANT_FAIL,
  payload: error
})

//Units

export const getUnits = () => ({
  type: GET_UNITS,
})

export const getUnitsSuccess = units => ({
  type: GET_UNITS_SUCCESS,
  payload: units,
})

export const getUnitsFail = error => ({
  type: GET_UNITS_FAIL,
  payload: error,
})

export const addUnit = unit => ({
  type: ADD_UNIT,
  payload: unit,
})

export const addUnitSuccess = unit => ({
  type: ADD_UNIT_SUCCESS,
  payload: unit,
})

export const addUnitFail = error => ({
  type: ADD_UNIT_FAIL,
  payload: error,
})

export const updateUnit = unit => ({
  type: UPDATE_UNIT,
  payload: unit,
})

export const updateUnitSuccess = unit => ({
  type: UPDATE_UNIT_SUCCESS,
  payload: unit,
})

export const updateUnitFail = error => ({
  type: UPDATE_UNIT_FAIL,
  payload: error,
})

export const deleteUnit = unit => ({
  type: DELETE_UNIT,
  payload: unit,
})

export const deleteUnitSuccess = unit => ({
  type: DELETE_UNIT_SUCCESS,
  payload: unit,
})

export const deleteUnitFail = error => ({
  type: DELETE_UNIT_FAIL,
  payload: error,
})

//Return Packages

export const getReturnPackages = () => ({
  type: GET_RETURN_PACKAGES,
})

export const getReturnPackagesSuccess = returnPackages => ({
  type: GET_RETURN_PACKAGES_SUCCESS,
  payload: returnPackages,
})

export const getReturnPackagesFail = error => ({
  type: GET_RETURN_PACKAGES_FAIL,
  payload: error,
})

export const addReturnPackage = returnPackage => ({
  type: ADD_RETURN_PACKAGE,
  payload: returnPackage,
})

export const addReturnPackageSuccess = returnPackage => ({
  type: ADD_RETURN_PACKAGE_SUCCESS,
  payload: returnPackage,
})

export const addReturnPackageFail = error => ({
  type: ADD_RETURN_PACKAGE_FAIL,
  payload: error,
})

export const updateReturnPackage = returnPackage => ({
  type: UPDATE_RETURN_PACKAGE,
  payload: returnPackage,
})

export const updateReturnPackageSuccess = returnPackage => ({
  type: UPDATE_RETURN_PACKAGE_SUCCESS,
  payload: returnPackage,
})

export const updateReturnPackageFail = error => ({
  type: UPDATE_RETURN_PACKAGE_FAIL,
  payload: error,
})

export const deleteReturnPackage = returnPackage => ({
  type: DELETE_RETURN_PACKAGE,
  payload: returnPackage,
})

export const deleteReturnPackageSuccess = returnPackage => ({
  type: DELETE_RETURN_PACKAGE_SUCCESS,
  payload: returnPackage,
})

export const deleteReturnPackageFail = error => ({
  type: DELETE_RETURN_PACKAGE_FAIL,
  payload: error,
})

//Features

export const getFeatures = () => ({
  type: GET_FEATURES,
})

export const getFeaturesSuccess = features => ({
  type: GET_FEATURES_SUCCESS,
  payload: features,
})

export const getFeaturesFail = error => ({
  type: GET_FEATURES_FAIL,
  payload: error,
})

export const addFeature = feature => ({
  type: ADD_FEATURE,
  payload: feature,
})

export const addFeatureSuccess = feature => ({
  type: ADD_FEATURE_SUCCESS,
  payload: feature,
})

export const addFeatureFail = error => ({
  type: ADD_FEATURE_FAIL,
  payload: error,
})

export const updateFeature = feature => ({
  type: UPDATE_FEATURE,
  payload: feature,
})

export const updateFeatureSuccess = feature => ({
  type: UPDATE_FEATURE_SUCCESS,
  payload: feature,
})

export const updateFeatureFail = error => ({
  type: UPDATE_FEATURE_FAIL,
  payload: error,
})

export const deleteFeature = feature => ({
  type: DELETE_FEATURE,
  payload: feature,
})

export const deleteFeatureSuccess = feature => ({
  type: DELETE_FEATURE_SUCCESS,
  payload: feature,
})

export const deleteFeatureFail = error => ({
  type: DELETE_FEATURE_FAIL,
  payload: error,
})

export const deleteImage = (data) => ({
  type: DELETE_IMAGE,
  payload: data,
})

export const deleteImageSuccess = image => ({
  type: DELETE_IMAGE_SUCCESS,
  payload: image,
})

export const deleteImageFail = error => ({
  type: DELETE_IMAGE_FAIL,
  payload: error,
})

//Prices

export const getPrices = () => ({
  type: GET_PRICES,
})

export const getPricesSuccess = prices => ({
  type: GET_PRICES_SUCCESS,
  payload: prices,
})

export const getPricesFail = error => ({
  type: GET_PRICES_FAIL,
  payload: error,
})

export const updatePrice = singlePrice => ({
  type: UPDATE_PRICE,
  payload: singlePrice,
})

export const updatePriceSuccess = singlePrice => ({
  type: UPDATE_PRICE_SUCCESS,
  payload: singlePrice,
})

export const updatePriceFail = error => ({
  type: UPDATE_PRICE_FAIL,
  payload: error,
})

//Relations

export const addRelatedProduct = (related, product) => ({
  type: ADD_RELATED_PRODUCT,
  payload: related, product,
})

export const addRelatedProductSuccess = related => ({
  type: ADD_RELATED_PRODUCT_SUCCESS,
  payload: related,
})

export const addRelatedProductFail = error => ({
  type: ADD_RELATED_PRODUCT_FAIL,
  payload: error,
})

export const deleteRelatedProduct = related => ({
  type: DELETE_RELATED_PRODUCT,
  payload: related,
})

export const deleteRelatedProductSuccess = related => ({
  type: DELETE_RELATED_PRODUCT_SUCCESS,
  payload: related,
})

export const deleteRelatedProductFail = error => ({
  type: DELETE_RELATED_PRODUCT_FAIL,
  payload: error,
})