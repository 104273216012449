import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import classnames from "classnames"
import axios from "axios"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Badge,
  Spinner,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import Dropzone from "react-dropzone"

import Relations from "./relations"
import Variants from "./variants"

import { useFormik, FormikProvider, FieldArray, Field } from "formik"
import { Multiselect } from "multiselect-react-dropdown"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getProducts as onGetProducts,
  addProduct as onAddProduct,
  updateProduct as onUpdateProduct,
  deleteProduct as onDeleteProduct,
  getCategories as onGetCategories,
  getFeatures as onGetFeatures,
  getUnits as onGetUnits,
  getAttributes as onGetAttributes,
  getReturnPackages as onGetReturnPackages,
  deleteImage as onDeleteImage,
  addCreatedVariants as onAddCreatedVariants,
  addProductSuccess as onAddProductSuccess,
  addProductFail as onAddProductFail,
  updateProductSuccess as onUpdateProductSuccess,
  updateProductFail as onUpdateProductFail,
} from "store/catalog/actions"

import { getDeliveryForms as onGetDeliveryForms } from "store/delivery-forms/actions"

import { getManufacturers as onGetManufacturers } from "store/users/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { API_URL } from "../../../helpers/api_helper"

const Products = props => {
  //meta title
  document.title = "Produkty - Panel administracyjny"

  const dispatch = useDispatch()

  const [product, setProduct] = useState(null)
  const [selectedFiles, setFiles] = useState([])
  const [attr1, setAttr1] = useState({ attribute_id: "", attribute_value: [] })
  const [attr2, setAttr2] = useState({ attribute_id: "", attribute_value: [] })
  const [attr3, setAttr3] = useState({ attribute_id: "", attribute_value: [] })
  const [isProductSave, setIsProductSave] = useState(true)

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setFiles(files)
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bajtów"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bajtów", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  let token = null
  const obj = JSON.parse(localStorage.getItem("authUser"))
  if (obj) {
    token = "Bearer " + obj.token
  }

  const { products } = useSelector(state => ({
    products: state.catalog.dataProducts.products,
  }))

  const { success } = useSelector(state => ({
    success: state.catalog.dataProducts.success,
  }))

  const { error } = useSelector(state => ({
    error: state.catalog.dataProducts.error,
  }))

  const { categories } = useSelector(state => ({
    categories: state.catalog.dataCategories.categories,
  }))

  const { features } = useSelector(state => ({
    features: state.catalog.dataFeatures.features,
  }))

  const { units } = useSelector(state => ({
    units: state.catalog.dataUnits.units,
  }))

  const { returnPackages } = useSelector(state => ({
    returnPackages: state.catalog.dataReturnPackages.returnPackages,
  }))

  const { manufacturers } = useSelector(state => ({
    manufacturers: state.users.dataManufacturers.manufacturers,
  }))

  const { attributes } = useSelector(state => ({
    attributes: state.catalog.dataAttributes.attributes,
  }))

  const { createdVariants } = useSelector(state => ({
    createdVariants: state.catalog.dataCreatedVariants.createdVariants,
  }))

  const { related } = useSelector(state => ({
    related: state.catalog.dataRelated.related,
  }))

  const { deliveryForms } = useSelector(state => ({
    deliveryForms: state.deliveryForms.dataDeliveryForms.deliveryForms,
  }))

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useEffect(() => {
    if (customActiveTab === "8") {
      setIsProductSave(false)
    } else {
      setIsProductSave(true)
    }
  }, [customActiveTab])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const columns = [
    {
      dataField: "product_id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "product_sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "images",
      text: "Zdjęcie",
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent[0] ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"
                }
                width="50"
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={
                  "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/products/50x50/" +
                  cellContent[0].product_image
                }
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "product_name",
      text: "Nazwa",
      sort: true,
    },
    {
      dataField: "category.category_name",
      text: "Kategoria",
      sort: true,
    },
    {
      dataField: "manufacturer.name",
      text: "Producent",
      sort: true,
    },
    {
      dataField: "product_active",
      text: "Aktywny",
      sort: true,
      formatter: cellContent =>
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleProductClick(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    // if (products && !products.length) {
    dispatch({
      type: "CLEARING_PRODUCT_DATA",
    })
    dispatch(onGetProducts())
    // }
  }, [dispatch])

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetCategories())
    }
  }, [dispatch, categories])

  useEffect(() => {
    if (!deliveryForms) {
      dispatch(onGetDeliveryForms())
    }
  }, [dispatch, deliveryForms])

  useEffect(() => {
    if (features && !features.length) {
      dispatch(onGetFeatures())
    }
  }, [dispatch, features])

  useEffect(() => {
    if (units && !units.length) {
      dispatch(onGetUnits())
    }
  }, [dispatch, units])

  useEffect(() => {
    if (returnPackages && !returnPackages.length) {
      dispatch(onGetReturnPackages())
    }
  }, [dispatch, returnPackages])

  useEffect(() => {
    if (manufacturers && !manufacturers.length) {
      dispatch(onGetManufacturers())
    }
  }, [dispatch, manufacturers])

  useEffect(() => {
    if (attributes && !attributes.length) {
      dispatch(onGetAttributes())
    }
  }, [dispatch, attributes])

  useEffect(() => {
    setProduct(products)
  }, [products])

  useEffect(() => {
    if (!isEmpty(products) && !!isEdit) {
      setProduct(products)
      setIsEdit(false)
    }
  }, [products])

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PRODUCT_SUCCESS",
        })
      }, 5000)
    }
  }, [success])

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PRODUCT_FAIL",
        })
      }, 5000)
    }
  }, [error])

  //validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      product_id: (product && product.product_id) || "",
      product_name: (product && product.product_name) || "",
      product_sku: (product && product.product_sku) || "",
      category_id: (product && product.category_id) || "",
      manufacturer_id: (product && product.manufacturer_id) || "",
      product_popularity: (product && product.product_popularity) || 0,
      product_iban: (product && product.product_iban) || 0,
      product_ean: (product && product.product_ean) || 0,
      product_wapro_index: (product && product.product_wapro_index) || 0,
      product_wapro_id: (product && product.product_wapro_id) || 0,
      product_short_description:
        (product && product.product_short_description) || "",
      product_description: (product && product.product_description) || "",
      product_delivery_description: product?.product_delivery_description || "",
      product_active: (product && product.product_active) || 0,
      product_package_type: (product && product.product_package_type) || "",
      product_package_type_full:
        (product && product.product_package_type_full) || 0,
      product_package_type_full_qty:
        (product && product.product_package_type_full_qty) || 0,
      product_volume: (product && product.product_volume) || 1,
      product_weight: (product && product.product_weight) || 1,
      product_qty_low: (product && product.product_qty_low) || 10,
      product_qty_medium: (product && product.product_qty_medium) || 50,
      product_qty_high: (product && product.product_qty_high) || 100,
      product_price_percent: (product && product.product_price_percent) || 25,
      product_price_id: (product && product.product_price_id) || "",
      product_price: (product && product.product_price) || 1,
      product_promo_price: (product && product.product_promo_price) || 0,
      product_promo_quantity: (product && product.product_promo_quantity) || 0,
      product_promo_price_on: (product && product.product_promo_price_on) || 0,
      product_promo_on: (product && product.product_promo_on) || 0,
      product_promo_home_on: (product && product.product_promo_home_on) || 0,
      product_promo_price_date_from:
        (product && product.product_promo_price_date_from) || "",
      product_promo_price_date_to:
        (product && product.product_promo_price_date_to) || "",
      images: (product && product.images) || "",
      product_meta_title: (product && product.product_meta_title) || "",
      product_meta_keywords: (product && product.product_meta_keywords) || "",
      product_meta_description:
        (product && product.product_meta_description) || "",
      product_features: (product && product.product_features) || [],
      product_attributes: [
        {
          id: 1,
          attribute_id:
            (attr1 && attr1.attribute_id) ||
            (product && product.product_attribute_1) ||
            "",
          name: "Atrybut 1",
          attribute_value: attr1.attribute_value,
        },
        {
          id: 2,
          attribute_id:
            (attr2 && attr2.attribute_id) ||
            (product && product.product_attribute_2) ||
            "",
          name: "Atrybut 2",
          attribute_value: attr2.attribute_value,
        },
        {
          id: 3,
          attribute_id:
            (attr3 && attr3.attribute_id) ||
            (product && product.product_attribute_3) ||
            "",
          name: "Atrybut 3",
          attribute_value: attr3.attribute_value,
        },
      ],
      product_created_at: (product && product.product_created_at) || "",
      product_notes: (product && product.product_notes) || "",
      product_return_package_id:
        (product && product.product_return_package_id) || "",
      product_return_package_min:
        (product && product.product_return_package_min) || 0,
      product_return_package_max:
        (product && product.product_return_package_max) || 0,
      product_return_package_price:
        (product && product.product_return_package_price) || 0,
      product_video_link: (product && product.product_video_link) || "",
      delivery_forms:
        (product &&
          product.delivery_forms?.length > 0 &&
          product.delivery_forms?.map(delivery_form =>
            delivery_form.id.toString()
          )) ||
        [],
    },
    validationSchema: Yup.object({
      product_name: Yup.string().required("To pole jest wymagane"),
      product_sku: Yup.string().required("To pole jest wymagane"),
      category_id: Yup.string().required("To pole jest wymagane"),
      manufacturer_id: Yup.string().required("To pole jest wymagane"),
      product_package_type: Yup.string().required("To pole jest wymagane"),
      product_meta_title: Yup.string().required("To pole jest wymagane"),
      product_meta_keywords: Yup.string().required("To pole jest wymagane"),
      product_volume: Yup.string().required("To pole jest wymagane"),
      product_weight: Yup.string().required("To pole jest wymagane"),
      product_price: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: values => {
      if (isEdit && isProductSave) {
        const data = new FormData()
        selectedFiles.forEach(file => {
          data.append("images[]", file)
        }),
          data.append("product_id", product.product_id),
          data.append("product_name", values.product_name),
          data.append("product_sku", values.product_sku),
          data.append("category_id", values.category_id),
          data.append("manufacturer_id", values.manufacturer_id),
          data.append("product_popularity", values.product_popularity),
          data.append("product_iban", values.product_iban),
          data.append("product_ean", values.product_ean),
          data.append("product_wapro_index", values.product_wapro_index),
          data.append("product_wapro_id", values.product_wapro_id),
          data.append(
            "product_short_description",
            values.product_short_description
          ),
          data.append("product_description", values.product_description),
          data.append(
            "product_delivery_description",
            values.product_delivery_description
          ),
          data.append("product_active", parseInt(values.product_active)),
          data.append("product_package_type", values.product_package_type),
          data.append(
            "product_package_type_full",
            values.product_package_type_full
          ),
          data.append(
            "product_package_type_full_qty",
            values.product_package_type_full_qty
          ),
          data.append("product_volume", values.product_volume),
          data.append("product_weight", values.product_weight),
          data.append("product_qty_low", values.product_qty_low),
          data.append("product_qty_medium", values.product_qty_medium),
          data.append("product_qty_high", values.product_qty_high),
          data.append("product_price_percent", values.product_price_percent),
          data.append("product_price", values.product_price),
          data.append("product_promo_price", values.product_promo_price),
          data.append("product_promo_quantity", values.product_promo_quantity),
          data.append("product_promo_price_on", values.product_promo_price_on),
          data.append("product_promo_on", values.product_promo_on),
          data.append("product_promo_home_on", values.product_promo_home_on),
          data.append(
            "product_promo_price_date_from",
            values.product_promo_price_date_from
          ),
          data.append(
            "product_promo_price_date_to",
            values.product_promo_price_date_to
          ),
          data.append("product_meta_title", values.product_meta_title),
          data.append("product_meta_keywords", values.product_meta_keywords),
          data.append(
            "product_meta_description",
            values.product_meta_description
          ),
          data.append(
            "product_features",
            JSON.stringify(values.product_features)
          ),
          data.append(
            "product_attribute_1",
            values.product_attributes[0].attribute_id
          ),
          data.append(
            "product_attribute_2",
            values.product_attributes[1].attribute_id
          ),
          data.append(
            "product_attribute_3",
            values.product_attributes[2].attribute_id
          ),
          data.append("product_notes", values.product_notes),
          data.append(
            "product_return_package_id",
            values.product_return_package_id
          ),
          data.append(
            "product_return_package_min",
            values.product_return_package_min
          ),
          data.append(
            "product_return_package_max",
            values.product_return_package_max
          ),
          data.append(
            "product_return_package_price",
            values.product_return_package_price
          ),
          data.append("product_video_link", values.product_video_link),
          data.append("delivery_forms", values.delivery_forms),
          // update product
          // dispatch(onUpdateProduct(Object.fromEntries(data)));

          axios
            .post(
              API_URL + "/admin/products/product/update",
              data,
              ((axios.defaults.headers.common["Authorization"] = token),
              (axios.defaults.headers.common["Access-Control-Allow-Origin"] =
                "*"),
              (axios.defaults.headers.common["Content-Type"] =
                "multipart/form-data"))
            )
            .then(function (response) {
              dispatch({
                type: "UPDATE_PRODUCT_SUCCESS",
                payload: response.data.data,
              })
              validation.resetForm()
            })
            .catch(error => {
              dispatch({
                type: "UPDATE_PRODUCT_FAIL",
                payload: error,
              })
            })
        toggle()
      } else if (isProductSave) {
        const data = new FormData()
        selectedFiles.forEach(file => {
          data.append("images[]", file)
        }),
          data.append("product_name", values["product_name"]),
          data.append("product_sku", values["product_sku"]),
          data.append("category_id", values["category_id"]),
          data.append("manufacturer_id", values["manufacturer_id"]),
          data.append("product_popularity", values["product_popularity"]),
          data.append("product_iban", values["product_iban"]),
          data.append("product_ean", values["product_ean"]),
          data.append("product_wapro_index", values["product_wapro_index"]),
          data.append("product_wapro_id", values["product_wapro_id"]),
          data.append(
            "product_short_description",
            values["product_short_description"]
          ),
          data.append("product_description", values["product_description"]),
          data.append(
            "product_delivery_description",
            values["product_delivery_description"]
          ),
          data.append("product_active", values["product_active"]),
          data.append("product_package_type", values["product_package_type"]),
          data.append(
            "product_package_type_full",
            values["product_package_type_full"]
          ),
          data.append(
            "product_package_type_full_qty",
            values["product_package_type_full_qty"]
          ),
          data.append("product_volume", values["product_volume"]),
          data.append("product_weight", values["product_weight"]),
          data.append("product_qty_low", values["product_qty_low"]),
          data.append("product_qty_medium", values["product_qty_medium"]),
          data.append("product_qty_high", values["product_qty_high"]),
          data.append("product_price_percent", values["product_price_percent"]),
          data.append("product_price", values["product_price"]),
          data.append("product_promo_price", values["product_promo_price"]),
          data.append(
            "product_promo_quantity",
            values["product_promo_quantity"]
          ),
          data.append(
            "product_promo_price_on",
            values["product_promo_price_on"]
          ),
          data.append("product_promo_on", values["product_promo_on"]),
          data.append("product_promo_home_on", values["product_promo_home_on"]),
          data.append(
            "product_promo_price_date_from",
            values["product_promo_price_date_from"]
          ),
          data.append(
            "product_promo_price_date_to",
            values["product_promo_price_date_to"]
          ),
          data.append("product_meta_title", values["product_meta_title"]),
          data.append("product_meta_keywords", values["product_meta_keywords"]),
          data.append(
            "product_meta_description",
            values["product_meta_description"]
          ),
          data.append(
            "product_features",
            JSON.stringify(values["product_features"])
          ),
          data.append("product_attribute_1", values["product_attribute_1"]),
          data.append("product_attribute_2", values["product_attribute_2"]),
          data.append("product_attribute_3", values["product_attribute_3"]),
          data.append("product_notes", values["product_notes"]),
          data.append(
            "product_return_package_id",
            values["product_return_package_id"]
          ),
          data.append(
            "product_return_package_min",
            values["product_return_package_min"]
          ),
          data.append(
            "product_return_package_max",
            values["product_return_package_max"]
          ),
          data.append(
            "product_return_package_price",
            values["product_return_package_price"]
          ),
          data.append("product_video_link", values["product_video_link"]),
          data.append("delivery_forms", values.delivery_forms),
          // add product
          // dispatch(onAddProduct(Object.fromEntries(data)));

          axios
            .post(
              API_URL + "/admin/products/product/add",
              data,
              ((axios.defaults.headers.common["Authorization"] = token),
              (axios.defaults.headers.common["Access-Control-Allow-Origin"] =
                "*"),
              (axios.defaults.headers.common["Content-Type"] =
                "multipart/form-data"))
            )
            .then(function (response) {
              dispatch({
                type: "ADD_PRODUCT_SUCCESS",
                payload: response.data.data,
              })
              validation.resetForm()
            })
            .catch(error => {
              dispatch({
                type: "ADD_PRODUCT_FAIL",
                payload: error,
              })
            })
        toggle()
      }
    },
  })

  const toggle = () => {
    setModal(!modal)
    setFiles([])
    setAttr1({ attribute_id: "", attribute_value: [] })
    setAttr2({ attribute_id: "", attribute_value: [] })
    setAttr3({ attribute_id: "", attribute_value: [] })
    setcustomActiveTab("1")
  }

  const handleProductClick = arg => {
    const product = arg

    setProduct({
      product_id: product.product_id,
      product_name: product.product_name,
      product_sku: product.product_sku,
      category_id: product.category_id,
      manufacturer_id: product.manufacturer_id,
      product_popularity: product.product_popularity,
      product_iban: product.product_iban,
      product_ean: product.product_ean,
      product_wapro_index: product.product_wapro_index,
      product_wapro_id: product.product_wapro_id,
      product_short_description: product.product_short_description,
      product_description: product.product_description,
      product_delivery_description: product.product_delivery_description,
      product_active: product.product_active,
      product_package_type: product.product_package_type,
      product_package_type_full: product.product_package_type_full,
      product_package_type_full_qty: product.product_package_type_full_qty,
      product_volume: product.product_volume,
      product_weight: product.product_weight,
      product_qty_low: product.product_qty_low,
      product_qty_medium: product.product_qty_medium,
      product_qty_high: product.product_qty_high,
      product_price_percent: product.product_price_percent,
      product_price_id: product.prices ? product.prices.price_id : "",
      product_price: product.prices ? product.prices.price : "",
      product_promo_price: product.prices ? product.prices.promo_price : "",
      product_promo_quantity: product.prices
        ? product.prices.promo_quantity
        : "",
      product_promo_price_on: product.prices
        ? product.prices.promo_price_on
        : "",
      product_promo_on: product.prices ? product.prices.promo_on : "",
      product_promo_home_on: product.prices ? product.prices.promo_home_on : "",
      product_promo_price_date_from: product.prices
        ? product.prices.promo_price_date_from
        : "",
      product_promo_price_date_to: product.prices
        ? product.prices.promo_price_date_to
        : "",
      images: product.images,
      product_meta_title: product.product_meta_title,
      product_meta_keywords: product.product_meta_keywords,
      product_meta_description: product.product_meta_description,
      product_features: product.features,
      product_attribute_1: product.product_attribute_1,
      product_attribute_2: product.product_attribute_2,
      product_attribute_3: product.product_attribute_3,
      product_notes: product.product_notes ? product.product_notes : "",
      product_return_package_id: product.product_return_package
        ? product.product_return_package
            .product_return_package_return_package_id
        : "",
      product_return_package_min: product.product_return_package
        ? product.product_return_package.product_return_package_min
        : "",
      product_return_package_max: product.product_return_package
        ? product.product_return_package.product_return_package_max
        : "",
      product_return_package_price: product.product_return_package
        ? product.product_return_package.product_return_package_price
        : "",
      product_video_link: product.product_video_link,
      delivery_forms: product.delivery_forms || [],
    })
    dispatch({
      type: "GET_RELATED",
      payload: product.related,
    })
    setIsEdit(true)

    toggle()
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = product => {
    setProduct(product)
    setDeleteModal(true)
  }

  const handleDeleteProduct = () => {
    if (product.product_id) {
      dispatch(onDeleteProduct(product))
      setDeleteModal(false)
    }
  }

  const handleDeleteImage = image_id => {
    dispatch(
      onDeleteImage({ product_id: product.product_id, image_id: image_id })
    )
    setProduct({
      ...product,
      images: product.images.filter(
        img => img.product_image_id.toString() !== image_id.toString()
      ),
    })
  }

  const handleProductClicks = () => {
    setProduct("")
    setIsEdit(false)
    toggle()
  }

  const [attrValues1, setAttrValues1] = useState([])
  const [attrValues2, setAttrValues2] = useState([])
  const [attrValues3, setAttrValues3] = useState([])

  useEffect(() => {
    if (validation.values.product_attributes[0].attribute_id) {
      const array = attributes.find(
        attr =>
          attr.attribute_id ===
          Number(validation.values.product_attributes[0].attribute_id)
      )
        ? attributes
            .find(
              attr =>
                attr.attribute_id ===
                Number(validation.values.product_attributes[0].attribute_id)
            )
            .items.map(item => item)
        : []
      setAttrValues1(array)
    }
  }, [validation.values.product_attributes[0].attribute_id])

  useEffect(() => {
    if (validation.values.product_attributes[1].attribute_id) {
      const array = attributes.find(
        attr =>
          attr.attribute_id ===
          Number(validation.values.product_attributes[1].attribute_id)
      )
        ? attributes
            .find(
              attr =>
                attr.attribute_id ===
                Number(validation.values.product_attributes[1].attribute_id)
            )
            .items.map(item => item)
        : []
      setAttrValues2(array)
    }
  }, [validation.values.product_attributes[1].attribute_id])

  useEffect(() => {
    if (validation.values.product_attributes[2].attribute_id) {
      const array = attributes.find(
        attr =>
          attr.attribute_id ===
          Number(validation.values.product_attributes[2].attribute_id)
      )
        ? attributes
            .find(
              attr =>
                attr.attribute_id ===
                Number(validation.values.product_attributes[2].attribute_id)
            )
            .items.map(item => item)
        : []
      setAttrValues3(array)
    }
  }, [validation.values.product_attributes[2].attribute_id])

  const handleAttributeValues = (value, index, id) => {
    if (index === 0) {
      const copy = [...attr1.attribute_value]
      copy.push(value)
      setAttr1({ attribute_id: id, attribute_value: copy })
    }
    if (index === 1) {
      const copy = [...attr2.attribute_value]
      copy.push(value)
      setAttr2({ attribute_id: id, attribute_value: copy })
    }
    if (index === 2) {
      const copy = [...attr3.attribute_value]
      copy.push(value)
      setAttr3({ attribute_id: id, attribute_value: copy })
    }
  }

  const handleDelAttributeValue = (value, index, id) => {
    if (index === 0) {
      const copy = [...attr1.attribute_value]
      const del = copy.indexOf(value)
      copy.splice(del, 1)
      setAttr1({ attribute_id: id, attribute_value: copy })
    }
    if (index === 1) {
      const copy = [...attr2.attribute_value]
      const del = copy.indexOf(value)
      copy.splice(del, 1)
      setAttr2({ attribute_id: id, attribute_value: copy })
    }
    if (index === 2) {
      const copy = [...attr3.attribute_value]
      const del = copy.indexOf(value)
      copy.splice(del, 1)
      setAttr3({ attribute_id: id, attribute_value: copy })
    }
  }

  function cartesian() {
    let r = [],
      arg = arguments,
      max = arg.length - 1

    function helper(arr, i) {
      for (let j = 0, l = arg[i].length; j < l; j++) {
        let a = arr.slice(0)
        a.push(arg[i][j])
        if (i == max) r.push(a)
        else helper(a, i + 1)
      }
    }
    helper([], 0)

    return r
  }

  const handleCreateVariants = () => {
    let v1arr = [],
      v2arr = [],
      v3arr = [],
      newarr = []

    if (validation.values.product_attributes[0].attribute_value.length) {
      validation.values.product_attributes[0].attribute_value.map(attr =>
        v1arr.push({
          name: attr.attribute_item_name,
          id: attr.attribute_item_id,
        })
      )
    }
    if (validation.values.product_attributes[1].attribute_value.length) {
      validation.values.product_attributes[1].attribute_value.map(attr =>
        v2arr.push({
          name: attr.attribute_item_name,
          id: attr.attribute_item_id,
        })
      )
    }
    if (validation.values.product_attributes[2].attribute_value.length) {
      validation.values.product_attributes[2].attribute_value.map(attr =>
        v3arr.push({
          name: attr.attribute_item_name,
          id: attr.attribute_item_id,
        })
      )
    }

    if (v1arr.length > 0 && v2arr.length > 0 && v3arr.length > 0) {
      newarr = cartesian(v1arr, v2arr, v3arr)
    }
    if (v1arr.length > 0 && v2arr.length > 0 && v3arr.length == 0) {
      newarr = cartesian(v1arr, v2arr)
    }
    if (v1arr.length > 0 && v2arr.length == 0 && v3arr.length == 0) {
      newarr = cartesian(v1arr)
    }

    const copy = [...createdVariants]
    newarr.forEach(variant =>
      copy.push({
        product_id: validation.values.product_id,
        product_variant_name:
          `${validation.values.product_name} ` +
          variant.map(n => n.name).join(" "),
        product_variant_sku: "",
        product_variant_attr1: variant[0] ? variant[0].id : 0,
        product_variant_attr2: variant[1] ? variant[1].id : 0,
        product_variant_attr3: variant[2] ? variant[2].id : 0,
        product_variant_price_id: "",
        product_variant_price: "",
        product_variant_promo_price: "",
        product_variant_promo_price_on: false,
        product_variant_promo_on: false,
        product_variant_promo_home_on: false,
        product_variant_promo_price_date_from: "",
        product_variant_promo_price_date_to: "",
        product_variant_promo_quantity: "",
        product_variant_short_description: "",
        product_variant_description: "",
        product_delivery_description: "",
        product_variant_active: 0,
        product_variant_wapro_index: 0,
        product_variant_wapro_nr: 0,
        product_variant_ean: 0,
        product_variant_meta_title: "",
        product_variant_meta_description: "",
        product_variant_meta_keywords: "",
        product_variant_return_package_id: "",
        product_variant_return_package_min: 0,
        product_variant_return_package_max: 0,
        product_variant_return_package_price: 0,
        product_variant_volume: 0,
        product_variant_weight: 0,
        helpful_nr: Math.random().toString(16).slice(2),
        product_variant_order: 0,
        product_variant_package_type_full_qty: "",
      })
    )
    dispatch(onAddCreatedVariants(copy))
  }

  const defaultSorted = [
    {
      dataField: "product_id",
      order: "desc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteProduct}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Katalog" breadcrumbItem="Produkty" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && (
                      <Row>
                        <Col lg="auto">
                          <Alert isOpen={success.open}>{success.text}</Alert>
                          <Alert isOpen={error.open} color="danger">
                            {error.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <ToolkitProvider
                      keyField="product_id"
                      data={products}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleProductClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj produkt
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                size="xl"
                                style={{
                                  maxWidth: "90%",
                                  maxHeight: "90vh",
                                  marginTop: 40,
                                  marginBottom: 0,
                                }}
                              >
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                  }}
                                >
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edytuj produkt"
                                      : "Dodaj produkt"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Row>
                                      <Col className="col-12">
                                        <Nav
                                          tabs
                                          className="nav-tabs-custom nav-justified"
                                        >
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "1",
                                              })}
                                              onClick={() => {
                                                toggleCustom("1")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Dane produktu
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "2",
                                              })}
                                              onClick={() => {
                                                toggleCustom("2")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Zaawansowane
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "3",
                                              })}
                                              onClick={() => {
                                                toggleCustom("3")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-envelope"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Ceny
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "4",
                                              })}
                                              onClick={() => {
                                                toggleCustom("4")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-envelope"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Opakowania zwrotne
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "5",
                                              })}
                                              onClick={() => {
                                                toggleCustom("5")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Zdjęcia
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "6",
                                              })}
                                              onClick={() => {
                                                toggleCustom("6")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Dane meta
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "7",
                                              })}
                                              onClick={() => {
                                                toggleCustom("7")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Szczegóły techniczne
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          {!!isEdit ? (
                                            <NavItem>
                                              <NavLink
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                className={classnames({
                                                  active:
                                                    customActiveTab === "8",
                                                })}
                                                onClick={() => {
                                                  toggleCustom("8")
                                                }}
                                              >
                                                <span className="d-block d-sm-none">
                                                  <i className="fas fa-cog"></i>
                                                </span>
                                                <span className="d-none d-sm-block">
                                                  Warianty
                                                </span>
                                              </NavLink>
                                            </NavItem>
                                          ) : null}
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "9",
                                              })}
                                              onClick={() => {
                                                toggleCustom("9")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Produkty powiązane
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active:
                                                  customActiveTab === "10",
                                              })}
                                              onClick={() => {
                                                toggleCustom("10")
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Formy dostawy
                                              </span>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                        <TabContent
                                          activeTab={customActiveTab}
                                          className="p-3 text-muted"
                                        >
                                          <TabPane tabId="1">
                                            <Row>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Nazwa
                                                  </Label>
                                                  <Input
                                                    name="product_name"
                                                    type="text"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_name
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_name &&
                                                      validation.errors
                                                        .product_name
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .product_name &&
                                                  validation.errors
                                                    .product_name ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_name
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Kod SKU
                                                  </Label>
                                                  <Input
                                                    name="product_sku"
                                                    type="text"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_sku
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_sku &&
                                                      validation.errors
                                                        .product_sku
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .product_sku &&
                                                  validation.errors
                                                    .product_sku ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_sku
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Kategoria
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="category_id"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .category_id || ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .category_id &&
                                                      validation.errors
                                                        .category_id
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <option value="">
                                                      -- Wybierz --
                                                    </option>
                                                    {categories.map(
                                                      (category, index) => (
                                                        <option
                                                          value={
                                                            category.category_id
                                                          }
                                                          key={
                                                            category.category_id
                                                          }
                                                        >
                                                          {
                                                            category.category_name
                                                          }
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                  {validation.touched
                                                    .category_id &&
                                                  validation.errors
                                                    .category_id ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .category_id
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Producent
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="manufacturer_id"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .manufacturer_id || ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .manufacturer_id &&
                                                      validation.errors
                                                        .manufacturer_id
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <option value="">
                                                      -- Wybierz --
                                                    </option>
                                                    {manufacturers.map(
                                                      (manufacturer, index) => (
                                                        <option
                                                          value={
                                                            manufacturer.id
                                                          }
                                                          key={manufacturer.id}
                                                        >
                                                          {manufacturer.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                  {validation.touched
                                                    .manufacturer_id &&
                                                  validation.errors
                                                    .manufacturer_id ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .manufacturer_id
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Popularność
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="product_popularity"
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_popularity ||
                                                      ""
                                                    }
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                  </Input>
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Nr Iban
                                                  </Label>
                                                  <Input
                                                    name="product_iban"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_iban
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Index Wapro
                                                  </Label>
                                                  <Input
                                                    name="product_wapro_index"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_wapro_index
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Nr Wapro
                                                  </Label>
                                                  <Input
                                                    name="product_wapro_id"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_wapro_id
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Krótki opis
                                                </Label>
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  name="product_short_description"
                                                  data={
                                                    validation.values
                                                      .product_short_description
                                                  }
                                                  onReady={editor => {
                                                    // console.log(editor);
                                                  }}
                                                  onChange={(event, editor) => {
                                                    validation.setFieldValue(
                                                      "product_short_description",
                                                      editor.getData()
                                                    )
                                                  }}
                                                />
                                              </Col>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Opis
                                                </Label>
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  name="product_description"
                                                  data={
                                                    validation.values
                                                      .product_description
                                                  }
                                                  onReady={editor => {
                                                    // console.log(editor);
                                                  }}
                                                  onChange={(event, editor) => {
                                                    validation.setFieldValue(
                                                      "product_description",
                                                      editor.getData()
                                                    )
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="6 mb-3">
                                                <Label className="form-label">
                                                  Dostawa
                                                </Label>
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  name="product_delivery_description"
                                                  data={
                                                    validation.values
                                                      .product_delivery_description
                                                  }
                                                  onReady={editor => {
                                                    // console.log(editor);
                                                  }}
                                                  onChange={(event, editor) => {
                                                    validation.setFieldValue(
                                                      "product_delivery_description",
                                                      editor.getData()
                                                    )
                                                  }}
                                                />
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Notatki
                                                  </Label>
                                                  <Input
                                                    type="textarea"
                                                    id="textarea"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    rows="3"
                                                    name="product_notes"
                                                    value={
                                                      validation.values
                                                        .product_notes
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="2">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Aktywny
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="product_active"
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_active || ""
                                                    }
                                                  >
                                                    <option value="0">
                                                      Nie
                                                    </option>
                                                    <option value="1">
                                                      Tak
                                                    </option>
                                                  </Input>
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Rodzaj jednostki
                                                    sprzedażowej
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="product_package_type"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_package_type ||
                                                      ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_package_type &&
                                                      validation.errors
                                                        .product_package_type
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <option value="">
                                                      -- Wybierz --
                                                    </option>
                                                    {units.map(
                                                      (unit, index) => (
                                                        <option
                                                          value={
                                                            unit.package_id
                                                          }
                                                          key={unit.package_id}
                                                        >
                                                          {unit.package_name}
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                  {validation.touched
                                                    .product_package_type &&
                                                  validation.errors
                                                    .product_package_type ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_package_type
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Rodzaj pełnego opakowania
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="product_package_type_full"
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_package_type_full
                                                    }
                                                  >
                                                    <option value="0">
                                                      -- Wybierz --
                                                    </option>
                                                    {units.map(
                                                      (unit, index) => (
                                                        <option
                                                          value={
                                                            unit.package_id
                                                          }
                                                          key={unit.package_id}
                                                        >
                                                          {unit.package_name}
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Ilość w pełnym opakowaniu
                                                  </Label>
                                                  <Input
                                                    name="product_package_type_full_qty"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_package_type_full_qty
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Kubatura (m3)
                                                  </Label>
                                                  <Input
                                                    name="product_volume"
                                                    type="text"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_volume
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_volume &&
                                                      validation.errors
                                                        .product_volume
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .product_volume &&
                                                  validation.errors
                                                    .product_volume ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_volume
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Waga (kg)
                                                  </Label>
                                                  <Input
                                                    name="product_weight"
                                                    type="text"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_weight
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_weight &&
                                                      validation.errors
                                                        .product_weight
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .product_weight &&
                                                  validation.errors
                                                    .product_weight ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_weight
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Mała ilość magazynowa
                                                  </Label>
                                                  <Input
                                                    name="product_qty_low"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_qty_low
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Średnia ilość magazynowa
                                                  </Label>
                                                  <Input
                                                    name="product_qty_medium"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_qty_medium
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Duża ilość magazynowa
                                                  </Label>
                                                  <Input
                                                    name="product_qty_high"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_qty_high
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Watrość modyfikatora ceny
                                                    (%)
                                                  </Label>
                                                  <Input
                                                    name="product_price_percent"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_price_percent
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="3">
                                            <Row>
                                              <Col lg="3">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Cena (do wyceny)
                                                  </Label>
                                                  <Input
                                                    name="product_price"
                                                    type="text"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_price
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_price &&
                                                      validation.errors
                                                        .product_price
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {(
                                                    validation.values
                                                      .product_price -
                                                    validation.values
                                                      .product_price *
                                                      (validation.values
                                                        .product_price_percent /
                                                        100)
                                                  ).toFixed(2) +
                                                    " - " +
                                                    (
                                                      parseFloat(
                                                        validation.values
                                                          .product_price
                                                      ) +
                                                      validation.values
                                                        .product_price *
                                                        0.25
                                                    ).toFixed(2)}
                                                  {validation.touched
                                                    .product_price &&
                                                  validation.errors
                                                    .product_price ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_price
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                              <Col lg="3">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Cena promocyjna / kup teraz
                                                  </Label>
                                                  <Input
                                                    name="product_promo_price"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_promo_price
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="3">
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Ostatnia najniższa cena z 30
                                                    dni
                                                  </Label>
                                                  <Input
                                                    name="product_ean"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_ean
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="3">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Ilość promocyjna / kup teraz
                                                  </Label>
                                                  <Input
                                                    name="product_promo_quantity"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_promo_quantity
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                              <Col lg="6">
                                                <Row>
                                                  <Col lg="4">
                                                    <div className="mb-4">
                                                      <Label className="mb-0">
                                                        <Input
                                                          name="product_promo_price_on"
                                                          type="checkbox"
                                                          className="selection-input-4"
                                                          onChange={
                                                            validation.handleChange
                                                          }
                                                          onBlur={
                                                            validation.handleBlur
                                                          }
                                                          checked={
                                                            validation.values
                                                              .product_promo_price_on ===
                                                              1 ||
                                                            validation.values
                                                              .product_promo_price_on
                                                              .length
                                                              ? 1
                                                              : 0
                                                          }
                                                          value={
                                                            validation.values
                                                              .product_promo_price_on ===
                                                            1
                                                              ? 0
                                                              : 1
                                                          }
                                                        />{" "}
                                                        Aktywuj kup teraz
                                                      </Label>
                                                    </div>
                                                  </Col>
                                                  <Col lg="4">
                                                    <div className="mb-4">
                                                      <Label className="mb-0">
                                                        <Input
                                                          name="product_promo_on"
                                                          type="checkbox"
                                                          className="selection-input-4"
                                                          onChange={
                                                            validation.handleChange
                                                          }
                                                          onBlur={
                                                            validation.handleBlur
                                                          }
                                                          checked={
                                                            validation.values
                                                              .product_promo_on ===
                                                              1 ||
                                                            validation.values
                                                              .product_promo_on
                                                              .length
                                                              ? 1
                                                              : 0
                                                          }
                                                          value={
                                                            validation.values
                                                              .product_promo_on ===
                                                            1
                                                              ? 0
                                                              : 1
                                                          }
                                                        />{" "}
                                                        Aktywuj promocję
                                                      </Label>
                                                    </div>
                                                  </Col>
                                                  <Col lg="4">
                                                    <div className="mb-4">
                                                      <Label className="mb-0">
                                                        <Input
                                                          name="product_promo_home_on"
                                                          type="checkbox"
                                                          className="selection-input-4"
                                                          onChange={
                                                            validation.handleChange
                                                          }
                                                          onBlur={
                                                            validation.handleBlur
                                                          }
                                                          checked={
                                                            validation.values
                                                              .product_promo_home_on ===
                                                              1 ||
                                                            validation.values
                                                              .product_promo_home_on
                                                              .length
                                                              ? 1
                                                              : 0
                                                          }
                                                          value={
                                                            validation.values
                                                              .product_promo_home_on ===
                                                            1
                                                              ? 0
                                                              : 1
                                                          }
                                                        />{" "}
                                                        Aktywuj na stronie
                                                        głównej
                                                      </Label>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col lg="6">
                                                <Row>
                                                  <Col lg="4">
                                                    <div className="mb-4">
                                                      <Label>
                                                        od
                                                        <Input
                                                          name="product_promo_price_date_from"
                                                          type="date"
                                                          onChange={
                                                            validation.handleChange
                                                          }
                                                          onBlur={
                                                            validation.handleBlur
                                                          }
                                                          value={
                                                            validation.values
                                                              .product_promo_price_date_from
                                                          }
                                                        />
                                                      </Label>
                                                    </div>
                                                  </Col>
                                                  <Col lg="4">
                                                    <div className="mb-4">
                                                      <Label>
                                                        do
                                                        <Input
                                                          name="product_promo_price_date_to"
                                                          type="date"
                                                          onChange={
                                                            validation.handleChange
                                                          }
                                                          onBlur={
                                                            validation.handleBlur
                                                          }
                                                          value={
                                                            validation.values
                                                              .product_promo_price_date_to
                                                          }
                                                        />
                                                      </Label>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="4">
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Rodzaj opakowania zwrotnego
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="product_return_package_id"
                                                    className="form-select"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_return_package_id ||
                                                      ""
                                                    }
                                                  >
                                                    <option value="">
                                                      -- Wybierz --
                                                    </option>
                                                    {returnPackages.map(
                                                      (
                                                        returnPackage,
                                                        index
                                                      ) => (
                                                        <option
                                                          value={
                                                            returnPackage.return_package_id
                                                          }
                                                          key={
                                                            returnPackage.return_package_id
                                                          }
                                                        >
                                                          {
                                                            returnPackage.return_package_name
                                                          }
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Minimalna ilość dla
                                                    opakowania
                                                  </Label>
                                                  <Input
                                                    name="product_return_package_min"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_return_package_min
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Maksymalna ilość dla
                                                    opakowania
                                                  </Label>
                                                  <Input
                                                    name="product_return_package_max"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_return_package_max
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Kaucja za opakowanie (zł)
                                                  </Label>
                                                  <Input
                                                    name="product_return_package_price"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_return_package_price
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="5">
                                            <Row className="mb-4">
                                              {!!isEdit
                                                ? product.images.map(
                                                    (image, i) => (
                                                      <Col
                                                        lg="2"
                                                        key={
                                                          image.product_image_id
                                                        }
                                                      >
                                                        <img
                                                          className="img-thumbnail"
                                                          alt={image.alt}
                                                          width="320"
                                                          src={
                                                            "https://budujpl.s3.eu-north-1.amazonaws.com/uploads/products/320x320/" +
                                                            image.product_image
                                                          }
                                                          style={{
                                                            padding: "0",
                                                          }}
                                                        />
                                                        <Button
                                                          className="btn btn-danger mt-2"
                                                          onClick={() =>
                                                            handleDeleteImage(
                                                              image.product_image_id
                                                            )
                                                          }
                                                        >
                                                          Usuń
                                                        </Button>
                                                      </Col>
                                                    )
                                                  )
                                                : ""}
                                            </Row>
                                            <Row>
                                              <div className="mb-4">
                                                <Col sm="6">
                                                  <Label className="form-label">
                                                    Link do wideo
                                                  </Label>
                                                  <Input
                                                    name="product_video_link"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_video_link
                                                    }
                                                  />
                                                </Col>
                                              </div>
                                            </Row>
                                            <Row>
                                              <Col sm="12">
                                                <Dropzone
                                                  onDrop={acceptedFiles =>
                                                    handleAcceptedFiles(
                                                      acceptedFiles
                                                    )
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div>
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="dz-message needsclick">
                                                          <div className="mb-3">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                          </div>
                                                          <h4>
                                                            Kliknij lub
                                                            przeciągnij i upuśc
                                                            aby przesłać.
                                                          </h4>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <div
                                                  className="dropzone-previews mt-3"
                                                  id="file-previews"
                                                >
                                                  {selectedFiles.map((f, i) => {
                                                    return (
                                                      <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                      >
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted font-weight-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>
                                                                  {
                                                                    f.formattedSize
                                                                  }
                                                                </strong>
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    )
                                                  })}
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="6">
                                            <Row>
                                              <Col sm="12">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Tytuł
                                                  </Label>
                                                  <Input
                                                    name="product_meta_title"
                                                    type="text"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .product_meta_title
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_meta_title &&
                                                      validation.errors
                                                        .product_meta_title
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .product_meta_title &&
                                                  validation.errors
                                                    .product_meta_title ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_meta_title
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col sm="12">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Słowa kluczowe
                                                  </Label>
                                                  <Input
                                                    type="textarea"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    id="textarea"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    rows="3"
                                                    name="product_meta_keywords"
                                                    value={
                                                      validation.values
                                                        .product_meta_keywords
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .product_meta_keywords &&
                                                      validation.errors
                                                        .product_meta_keywords
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .product_meta_keywords &&
                                                  validation.errors
                                                    .product_meta_keywords ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .product_meta_keywords
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col sm="12">
                                                <div className="mb-4">
                                                  <Label className="form-label">
                                                    Opis
                                                  </Label>
                                                  <Input
                                                    type="textarea"
                                                    id="textarea1"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    rows="3"
                                                    name="product_meta_description"
                                                    value={
                                                      validation.values
                                                        .product_meta_description
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="7">
                                            <Row>
                                              <Col lg="6">
                                                <FieldArray
                                                  name="product_features"
                                                  validateOnChange={false}
                                                  render={array_features => (
                                                    <React.Fragment>
                                                      {validation.values
                                                        .product_features &&
                                                      validation.values
                                                        .product_features
                                                        .length > 0
                                                        ? validation.values.product_features.map(
                                                            (
                                                              feature,
                                                              index
                                                            ) => (
                                                              <Row
                                                                key={
                                                                  feature.product_feature_id
                                                                }
                                                              >
                                                                <Col lg="4 mb-4">
                                                                  <Field
                                                                    name={`product_features[${index}].feature_id`}
                                                                    className="form-control"
                                                                    as="select"
                                                                  >
                                                                    <option value="">
                                                                      ---
                                                                      Wybierz
                                                                      ---
                                                                    </option>
                                                                    {features.map(
                                                                      (
                                                                        item,
                                                                        index
                                                                      ) => (
                                                                        <option
                                                                          value={
                                                                            item.feature_id
                                                                          }
                                                                          key={
                                                                            item.feature_id
                                                                          }
                                                                        >
                                                                          {
                                                                            item.feature_name
                                                                          }
                                                                        </option>
                                                                      )
                                                                    )}
                                                                  </Field>
                                                                </Col>
                                                                <Col lg="4 mb-4">
                                                                  <Field
                                                                    name={`product_features[${index}].product_feature_value`}
                                                                    className="form-control"
                                                                  />
                                                                </Col>
                                                                <Col lg="1 mb-4">
                                                                  <Button
                                                                    className="btn btn-danger"
                                                                    onClick={() =>
                                                                      array_features.remove(
                                                                        index
                                                                      )
                                                                    }
                                                                  >
                                                                    Usuń
                                                                  </Button>
                                                                </Col>
                                                              </Row>
                                                            )
                                                          )
                                                        : ""}
                                                      <Row>
                                                        <Col lg="4">
                                                          <Button
                                                            className="btn btn-success"
                                                            onClick={() =>
                                                              array_features.push(
                                                                {
                                                                  feature_id:
                                                                    "",
                                                                  product_feature_id:
                                                                    "",
                                                                  product_feature_value:
                                                                    "",
                                                                  product_id:
                                                                    product.product_id
                                                                      ? product.product_id
                                                                      : "",
                                                                }
                                                              )
                                                            }
                                                          >
                                                            Dodaj
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </React.Fragment>
                                                  )}
                                                />
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          {!!isEdit ? (
                                            <TabPane tabId="8">
                                              <FieldArray
                                                name="product_attributes"
                                                validateOnChange={false}
                                              >
                                                <Row>
                                                  {validation.values
                                                    .product_attributes &&
                                                  validation.values
                                                    .product_attributes.length >
                                                    0
                                                    ? validation.values.product_attributes.map(
                                                        (attribute, index) => (
                                                          <Col
                                                            lg="4"
                                                            key={attribute.id}
                                                          >
                                                            <Label className="form-label">
                                                              {attribute.name}
                                                            </Label>
                                                            <Field
                                                              name={`product_attributes[${index}].attribute_id`}
                                                              className="form-control mb-3"
                                                              as="select"
                                                            >
                                                              <option value="">
                                                                --- Wybierz ---
                                                              </option>
                                                              {attributes.map(
                                                                (
                                                                  attribute,
                                                                  index
                                                                ) => (
                                                                  <option
                                                                    value={
                                                                      attribute.attribute_id
                                                                    }
                                                                    key={
                                                                      attribute.attribute_id
                                                                    }
                                                                  >
                                                                    {
                                                                      attribute.attribute_name
                                                                    }
                                                                  </option>
                                                                )
                                                              )}
                                                            </Field>
                                                            <Multiselect
                                                              id={`product_attributes[${index}].attribute_value`}
                                                              onSelect={(
                                                                e,
                                                                value
                                                              ) =>
                                                                handleAttributeValues(
                                                                  value,
                                                                  index,
                                                                  validation
                                                                    .values
                                                                    .product_attributes[
                                                                    `${index}`
                                                                  ].attribute_id
                                                                )
                                                              }
                                                              onRemove={(
                                                                e,
                                                                value
                                                              ) =>
                                                                handleDelAttributeValue(
                                                                  value,
                                                                  index,
                                                                  validation
                                                                    .values
                                                                    .product_attributes[
                                                                    `${index}`
                                                                  ].attribute_id
                                                                )
                                                              }
                                                              options={
                                                                index === 0
                                                                  ? attrValues1
                                                                  : index === 1
                                                                  ? attrValues2
                                                                  : index === 2
                                                                  ? attrValues3
                                                                  : []
                                                              }
                                                              displayValue="attribute_item_name"
                                                              placeholder="Wybierz wartość"
                                                              emptyRecordMsg="Nie znaleziono żadnych wartości"
                                                              className="mb-3"
                                                            />
                                                          </Col>
                                                        )
                                                      )
                                                    : ""}
                                                </Row>
                                              </FieldArray>
                                              <Button
                                                className="btn btn-success"
                                                onClick={handleCreateVariants}
                                              >
                                                Utwórz warianty
                                              </Button>
                                              <Row>
                                                <Col sm="12">
                                                  <Variants
                                                    product={product}
                                                    createdVariants={
                                                      createdVariants
                                                    }
                                                    returnPackages={
                                                      returnPackages
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            </TabPane>
                                          ) : null}
                                          <TabPane tabId="9">
                                            <Row>
                                              <Col sm="12">
                                                <Relations
                                                  products={products}
                                                  product_id={
                                                    product
                                                      ? product.product_id
                                                      : null
                                                  }
                                                  related={related}
                                                />
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="10">
                                            <Row>
                                              <Col sm="12">
                                                {deliveryForms &&
                                                deliveryForms.length > 0
                                                  ? deliveryForms.map(
                                                      deliveryForm => {
                                                        return (
                                                          <Row
                                                            key={
                                                              deliveryForm.id
                                                            }
                                                          >
                                                            <Col lg="4">
                                                              <Label className="form-label">
                                                                <Input
                                                                  name="delivery_forms"
                                                                  type="checkbox"
                                                                  onChange={
                                                                    validation.handleChange
                                                                  }
                                                                  onBlur={
                                                                    validation.handleBlur
                                                                  }
                                                                  value={deliveryForm.id.toString()}
                                                                  checked={validation.values.delivery_forms.includes(
                                                                    deliveryForm.id.toString()
                                                                  )}
                                                                />{" "}
                                                                {
                                                                  deliveryForm.display_name
                                                                }
                                                              </Label>
                                                            </Col>
                                                          </Row>
                                                        )
                                                      }
                                                    )
                                                  : null}
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                </Form>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  )
}

export default withRouter(Products)
