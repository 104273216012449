import {
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAIL,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  GET_BRANCH_PROFILE_SUCCESS,
  GET_BRANCH_PROFILE_FAIL,
  HIDE_BRANCH_SUCCESS,
  HIDE_BRANCH_FAIL
} from "./actionTypes"

const INIT_STATE = {
  dataBranches: {
    branches: [],
    success: {
      open: false,
      text: ""
    },
    error: {
      open: false,
      text: ""
    }
  },
  branchProfile: {},
  error: {},
}

const branches = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          branches: action.payload,
        },
      }

    case GET_BRANCHES_FAIL:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          // error: action.payload,
        }
      }

    case GET_BRANCH_PROFILE_SUCCESS:
      return {
        ...state,
        branchProfile: action.payload,
      }

    case GET_BRANCH_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          branches: [...state.dataBranches.branches, action.payload],
          success: {
            open: true,
            text: "Oddział został dodany."
          }
        }
      }

    case ADD_BRANCH_FAIL:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          error: {
            open: true,
            text: "Podczas dodawania oddziału wystąpił błąd, spróbuj ponownie.",
          }
        }
      }

    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          branches: state.dataBranches.branches.map(branch =>
            branch.branch_id.toString() === action.payload.branch_id.toString()
              ? { branch, ...action.payload }
              : branch
          ),
          success: {
            open: true,
            text: "Oddział został zaktualizowany."
          } 
        }
      }
  
    case UPDATE_BRANCH_FAIL:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          error: {
            open: true,
            text: "Podczas aktualizacji oddziału wystąpił błąd, spróbuj ponownie.",
          }
        }
      }
  
    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          branches: state.dataBranches.branches.filter(
            branch => branch.branch_id.toString() !== action.payload.toString()
          ),
          success: {
            open: true,
            text: "Oddział został usunięty."
          } 
        }
      }

    case DELETE_BRANCH_FAIL:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          error: {
            open: true,
            text: "Podczas usuwania oddziału wystąpił błąd, spróbuj ponownie."
          }
        }
      }

    case HIDE_BRANCH_SUCCESS:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          success: {
            ...state.dataBranches.success,
            open: false
          }
        },
      }

    case HIDE_BRANCH_FAIL:
      return {
        ...state,
        dataBranches: {
          ...state.dataBranches,
          error: {
            ...state.dataBranches.error,
            open: false
          }
        },
      }

    default:
      return state
  }
}

export default branches
