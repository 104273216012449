import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
  Spinner,
  Badge,
  Button
} from "reactstrap"
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getPPCategories as onGetPPCategories,
  addPPCategory as onAddPPCategory,
  updatePPCategory as onUpdatePPCategory,
  deletePPCategory as onDeletePPCategory,
} from "store/partnerprogram/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const PPCategories = props => {
  //meta title
  document.title = "Kategorie - Program Partnerski - Panel administracyjny";
  
  const dispatch = useDispatch();

  const [category, setCategory] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pp_category_id: (category && category.pp_category_id) || '',
      pp_category_name: (category && category.pp_category_name) || '',
      pp_category_description: (category && category.pp_category_description) || '',
      pp_category_active: (category && category.pp_category_active) || 0,
      pp_category_image: (category && category.pp_category_image) || '',
    },
    validationSchema: Yup.object({
      pp_category_name: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatePPCategory = {
          pp_category_id: category ? category.pp_category_id : 0,
          pp_category_name: values.pp_category_name,
          pp_category_description: values.pp_category_description,
          pp_category_active: values.pp_category_active,
          pp_category_image: values.pp_category_image
        }
        // update category
        dispatch(onUpdatePPCategory(updatePPCategory));
        validation.resetForm();
      } else {
        const addPPCategory = {
          pp_category_name: values["pp_category_name"],
          pp_category_description: values["pp_category_description"],
          pp_category_active: values["pp_category_active"],
          pp_category_image: values["pp_category_image"]
        }
        // add category
        dispatch(onAddPPCategory(addPPCategory));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { categories } = useSelector(state => ({
    categories: state.partnerprogram.dataCategories.categories,
  }));

  const { success } = useSelector(state => ({
    success: state.partnerprogram.dataCategories.success,
  }));

  const { error } = useSelector(state => ({
    error: state.partnerprogram.dataCategories.error,
  }));

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "pp_category_id",
      text: "ID",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {cellContent}
        </React.Fragment>
      ),
    },
    {
      dataField: "pp_category_image",
      text: "Zdjęcie",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {!cellContent ? (
            <div className="avatar-xs">
              <img
                className="avatar-xs"
                src={"https://budujpl.s3.eu-north-1.amazonaws.com/uploads/image_placeholder1.png"} 
                width="50" 
                height="50"
                alt=""
              />
            </div>
          ) : (
            <div>
              <img
                className="avatar-xs"
                src={"https://budujpl.s3.eu-north-1.amazonaws.com/uploads/users/50x50/" + cellContent}
                alt=""
              />
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "pp_category_name",
      text: "Nazwa",
      sort: true,
      formatter: cellContent => (
        <React.Fragment>
          {cellContent}
        </React.Fragment>
      ),
    },
    {
      dataField: "pp_category_active",
      text: "Aktywna",
      sort: true,
      formatter: cellContent => 
        cellContent === 1 ? (
          <Badge color="success" className="bg-success font-size-12">
            Tak
          </Badge>
        ) : (
          <Badge color="danger" className="bg-danger font-size-12">
            Nie
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Opcje",
      formatter: (cellContent, category) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleCategoryClick(category)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edycja
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(category)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Usuń
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetPPCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(categories) && !!isEdit) {
      setCategory(categories);
      setIsEdit(false);
    }
  }, [categories]);

  useEffect(() => {
    if (success.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PP_CATEGORY_SUCCESS",
        });
      }, 5000)
      
    }
  }, [success]);

  useEffect(() => {
    if (error.open) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_PP_CATEGORY_FAIL",
        });
      }, 5000)
      
    }
  }, [error]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleCategoryClick = arg => {
    const category = arg;

    setCategory({
      pp_category_id: category.pp_category_id,
      pp_category_name: category.pp_category_name,
      pp_category_description: category.pp_category_description,
      pp_category_active: category.pp_category_active,
      pp_category_image: category.pp_category_image,
    });

    setIsEdit(true);

    toggle();
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (category) => {
    setCategory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    if (category.pp_category_id) {
      dispatch(onDeletePPCategory(category));
      setDeleteModal(false);
    }
  };

  const handleCategoryClicks = () => {
    setCategory("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "pp_category_id",
      order: "asc",
    },
  ]

  const NoDataIndication = () => (
    <React.Fragment>
      <Spinner color="secondary"></Spinner>
      <div>Ładowanie danych ...</div>
    </React.Fragment>
  )

  return (
    <FormikProvider value={validation}>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCategory}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Program partnerski" breadcrumbItem="Kategorie" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(success || error) && 
                    <Row>
                      <Col lg="auto">
                        <Alert isOpen={success.open}>
                          {success.text}
                        </Alert>
                        <Alert isOpen={error.open} color="danger">
                          {error.text}
                        </Alert>
                      </Col>
                    </Row>}
                    <ToolkitProvider
                      keyField="pp_category_id"
                      data={categories}
                      columns={columns}
                      bootstrap4
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Szukaj..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={handleCategoryClicks}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Dodaj kategorię
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                classes={"align-middle"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => <NoDataIndication />}
                              />
                              <Modal isOpen={modal} toggle={toggle} size="lg">
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edytuj kategorię" : "Dodaj kategorię"}
                                </ModalHeader>
                                <ModalBody>
                                  <Form
                                    onSubmit={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                      return false
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "1",
                                              })}
                                              onClick={() => {
                                                toggleCustom("1");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Dane kategorii</span>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                active: customActiveTab === "2",
                                              })}
                                              onClick={() => {
                                                toggleCustom("2");
                                              }}
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">Zdjęcie</span>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>

                                        <TabContent
                                          activeTab={customActiveTab}
                                          className="p-3 text-muted"
                                        >
                                          <TabPane tabId="1">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Nazwa</Label>
                                                <Input
                                                  name="pp_category_name"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_category_name}
                                                  invalid={
                                                    validation.touched.pp_category_name && validation.errors.pp_category_name ? true : false
                                                  }
                                                />
                                                {validation.touched.pp_category_name && validation.errors.pp_category_name ? (
                                                  <FormFeedback type="invalid">{validation.errors.pp_category_name}</FormFeedback>
                                                ) : null}
                                              </Col>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Opis</Label>
                                                <Input
                                                  type="textarea"
                                                  id="textarea"
                                                  onChange={validation.handleChange}
                                                  rows="3"
                                                  name="pp_category_description"
                                                  value={validation.values.pp_category_description}
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="2 mb-3">
                                                <Label className="form-label">Aktywna</Label>
                                                <Input
                                                  type="select"
                                                  name="pp_category_active"
                                                  className="form-select"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.pp_category_active}
                                                >
                                                  <option value="0">Nie</option>
                                                  <option value="1">Tak</option>
                                                </Input>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <Row>
                                              <Col lg="12 mb-3">
                                                <Label className="form-label">Zdjęcie</Label>
                                                <Input
                                                  type="file"
                                                  name="pp_category_image"
                                                  onChange={(event) => {
                                                    validation.setFieldValue('pp_category_image', event.currentTarget.files[0]);
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Zapisz
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </FormikProvider>
  );
};

export default withRouter(PPCategories);
