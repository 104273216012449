import {
  GET_SMS_NOTIFICATIONS_SUCCESS,
  GET_SMS_NOTIFICATIONS_FAIL,
  UPDATE_SMS_NOTIFICATIONS_SUCCESS,
  UPDATE_SMS_NOTIFICATIONS_FAIL,
  HIDE_SMS_NOTIFICATIONS_SUCCESS,
  HIDE_SMS_NOTIFICATIONS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  sms: null,
  success: {
    open: false,
    text: ""
  },
  error: {
    open: false,
    text: ""
  },
}

const notifications = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_SMS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        sms: action.payload,
      }

    case GET_SMS_NOTIFICATIONS_FAIL:
      return {
        ...state,
        // error: action.payload,
      }

    case UPDATE_SMS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        sms: action.payload,
        success: {
          open: true,
          text: "Powiadomienia SMS zostały zaktualizowane."
        },
      }

    case UPDATE_SMS_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: {
          open: true,
          text: "Podczas aktualizacji powiadomień SMS wystąpił błąd, spróbuj ponownie.",
        },
      }

    case HIDE_SMS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        success: {
          ...state.success,
          open: false
        },
      }

    case HIDE_SMS_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: {
          ...state.error,
          open: false
        },
      }

      default:
        return state
    }
  }
  
export default notifications;